// Styles for RnBHeroImageCarousel go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledRnBHeroImageCarousel = styled.div`
  .glide {
    &__controls_wrapper {
      position: absolute;
      right: 0;
      left: 0;
      width: fit-content;
      margin: auto;
      bottom: ${toRem(92)};
      height: ${toRem(32)};
      min-width: ${toRem(94)};
      border-radius: ${toRem(22)};
      background-color: rgba(0, 0, 0, 0.33);
    }

    &__bullet {
      border-radius: 50%;
      background-color: ${baseVariables.color['base20']};
      height: ${toRem(6)};
      width: ${toRem(6)};
      opacity: 0.34;
      outline: none;
      margin-right: ${toRem(8)};
      pointer-events: none;
      cursor: default;
      &--active {
        opacity: 1;
        height: ${toRem(8)};
        width: ${toRem(8)};
      }
    }
    &__arrow {
      background: none;
      width: ${toRem(40)};
      height: ${toRem(32)};
      border-color: transparent;
      font-size: ${toRem(24)};
      color: ${baseVariables.color['base20']};
      box-shadow: ${baseVariables.shadows['shadowSubtle-04']};
      position: static;
      transform: none;

      &--disabled {
        opacity: 0.2;
      }
    }
    &__slides {
      width: 100%;
      .glide__slide {
        list-style: none;
      }
    }
    &__track {
      position: relative;
      overflow: hidden;
    }
  }

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}) {
    .glide__arrows
    {
      .icon-arrow-left {
        &::before{
          content: "\\e922";
          cursor: pointer;
        }
      }
      .icon-arrow-right {
        &::before{
          content: "\\e921";
          cursor: pointer;
        }
      }
    }

    .disabled-rtl-arrow {
      opacity: 0.2;
    }

    .glide__arrow--disabled {
      opacity: unset;
    }

    .glide__track {
      overflow : hidden;
    }
  }
`}
`;
