import { useEffect, useState } from 'react';
import { StyledHomeEditContainer } from './HomePageEditorial.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, Image } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useGetBreakpoint, trackImpression } from '../../modules/utils/helpers';
import { HomePageEditorialProps } from './index';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const HomePageEditorial = (props: HomePageEditorialProps) => {
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');

  const handleReadMoreClick = () => {
    let target = '';
    target = props?.openInNewTab ? '_blank' : '';
    window.open(props?.ctaLink, target);
  };

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack)
      trackImpression(props?.trackingProperties || {}, 'Home Page Editorial' || '');
  }, [props?.trackingProperties]);

  const { EDITORIAL_FEATURE, READ_MORE_BUTTON, EXTERNAL_LINK } = TRACKING_CONSTANT;

  return (
    <StyledHomeEditContainer data-component-name="o-rnb-editorialfeature" data-testid="editorialfeature">
      <div className={isMobileViewPort ? '' : 'container'}>
        {!!props.headerText && (
          <h3 className="t-title-inverse-s main-editorial-title m-0 mb-4 p-0 pt-3 text-center">{props.headerText}</h3>
        )}
        <div className="chef-details-container d-flex flex-column flex-xl-row py-0 pt-0 pt-md-3 pb-3">
          <div className={isMobileViewPort ? '' : 'col-12 col-md-12 col-lg-12 col-xl-6 chef-details-left-container'}>
            {!!props.dynamicMedia && (
              <Image
                title="chefImage"
                customClass="chef-image"
                loading="lazy"
                altText={props?.dynamicMedia?.altText}
                renditions={props?.dynamicMedia?.renditions}
                dynamic={props?.dynamicMedia?.dynamic}
                defaultImageURL={props?.dynamicMedia?.assetPath}
              />
            )}
          </div>
          <div className="chef-details-right-container  pb-0 pl-5 pr-5 pt-5 p-md-0 pt-md-5 text-center text-lg-left">
            {!!props.eyebrowText && (
              <h4 className="t-overline-inverse-normal chef-spotlight m-0 px-0 pb-3 pt-0 pt-md-4">
                {props.eyebrowText}
              </h4>
            )}
            {!!props.title && (
              <h3
                className={clsx(
                  'chef-name p-2 p-md-0 p-xl-0 mb-3',
                  isMobileViewPort ? 't-title-inverse-m' : 't-title-inverse-l'
                )}
              >
                {props.title}
              </h3>
            )}
            <div className="chef-address py-2 px-0 m-0 mb-3">
              {!!props.locationText && (
                <p className="t-font-inverse-m chef-address-deatils m-0">
                  <span className="icon-location icon-inverse chef-map-icon"></span>
                  {props.locationText}
                </p>
              )}
            </div>
            {!!props?.description && (
              <div
                className="t-font-inverse-s chef-description m-0 mb-3"
                dangerouslySetInnerHTML={{ __html: props?.description }}
              ></div>
            )}
            <Button
              testId="read-more"
              className="m-button-secondary-inverse chef-read-more m-0 px-5 py-2"
              callback={() => handleReadMoreClick()}
              custom_click_track_value={`${EDITORIAL_FEATURE}|${READ_MORE_BUTTON}|${EXTERNAL_LINK}`}
            >
              {props.ctaLabel}
            </Button>
          </div>
        </div>
      </div>
    </StyledHomeEditContainer>
  );
};

export const HomePageEditorialConfig = {
  emptyLabel: 'editorialfeature',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/editorialfeature`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HomePageEditorialEditable = (props: any) => (
  <EditableComponent config={HomePageEditorialConfig} {...props}>
    <HomePageEditorial {...props} />
  </EditableComponent>
);

export default HomePageEditorial;
