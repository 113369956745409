import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { border, color, font } = baseVariables;

export const StyledTextEditor = styled.div`
  position: relative;

  .menu {
    width: calc(100% - ${toRem(4)});
    height: ${toRem(40)};
    gap: ${toRem(8)};
    position: absolute;
    top: ${toRem(2)};
    left: ${toRem(2)};
    border-bottom: ${border.borderWidth02} ${border.borderStyleDefault} ${color.neutral30};
    z-index: 1;

    .menu-item {
      border-radius: ${toRem(4)};
      background: transparent;
      &:hover,
      &:focus,
      &.is-active {
        background-color: ${color.bvyAccent40};
      }
    }
  }

  .placeholder {
    font-size: ${font.fontM};
    line-height: ${font.lineHeightXl};
    position: absolute;
    top: ${toRem(48)};
    left: ${toRem(8)};
    opacity: 0.6;
    pointer-events: none;
  }

  .ProseMirror {
    padding: ${toRem(48)} ${toRem(8)} ${toRem(8)};
    min-height: ${toRem(200)};
    border: ${border.borderWidth02} ${border.borderStyleDefault} ${color.neutral40};
    border-radius: ${border.borderRadius02};
  }

  .ProseMirror-focused {
    outline: none;
    border-color: ${color.base10};
  }
`;
