import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOutletTabContainer = styled.div`
  flex-basis: 61%;

  .tab-list {
    height: ${toRem(38)};
  }

  .tab-title-container {
    .tab-title {
      list-style-type: none;
    }
  }

  .glide__arrow--disabled {
    opacity: 0.2;
  }

  .glide__arrow {
    position: static;
    transform: none;
    box-shadow: none;
  }

  .scrollable-section {
    max-height: ${toRem(300)};
    overflow: auto;
    cursor: grab;
  }

  .scrollable-section.active {
    cursor: grabbing;
  }

  .scrollable-section::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollable-section {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media ${baseVariables.mediaQuery.md} {
    .scrollable-div {
      max-height: ${toRem(200)};
    }
  }
`;
