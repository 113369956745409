import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { PasswordFieldErrorTypes, PasswordFieldErrorStatusMap, PasswordFieldProps } from './PasswordField.types';
import { StyledPasswordField } from './PasswordField.styles';
import { TextFormField } from '../TextFormField';
import { useFocusState } from '../../hooks';

export const PasswordField: FC<PasswordFieldProps> = ({
  name,
  labels,
  control,
  showErrorMessage,
  passwordChecks,
  triggerError,
}) => {
  const [errors, setErrors] = useState<PasswordFieldErrorStatusMap>({
    required: true,
    characterLimit: true,
    lowerCase: true,
    upperCase: true,
    validCharacters: true,
  });
  const { focused, handleFocus, handleBlur } = useFocusState({});

  const validatePassword = (value: string) => {
    const errors = {
      required: !value,
      characterLimit: !(value && value.length >= 8 && value.length <= 20),
      lowerCase: !(value && /[a-z]/.test(value)),
      upperCase: !(value && /[A-Z]/.test(value)),
      validCharacters: !(value && /[0-9$,!,#,&,@,?,%,=,-]/.test(value)),
    };
    setErrors(errors);
    return !Object.values(errors).some(value => value);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          custom: validatePassword,
        },
      }}
      render={({ field }) => (
        <StyledPasswordField
          data-component-name="m-groups-PasswordField"
          data-testid="groups-PasswordField"
          className="m-input-field"
        >
          <TextFormField
            {...field}
            type="password"
            label={labels.label}
            className="m-input-field"
            maxLength={labels.maxLength}
            showErrorMessage={showErrorMessage}
            errorMessage={errors.required ? labels.requiredError : ''}
            onChange={e => {
              field.onChange(e);
              triggerError();
            }}
            onFocus={() => handleFocus(field.name)}
            onBlur={() => handleBlur(field.name)}
          />
          <ul
            className={clsx('m-exp-dropdown-panel', focused[field.name] ? 'd-flex flyout' : 'd-none')}
            data-testid="password-flyout"
          >
            {Object.keys(passwordChecks).map(errorType => {
              const isError = errors[errorType as PasswordFieldErrorTypes];
              return (
                <li key={errorType} className={clsx('justify-content-start px-3', isError ? 'invalid' : 'valid')}>
                  <span className={clsx('icon', isError ? 'icon-clear' : 'icon-check')}></span>
                  <span>{passwordChecks[errorType as PasswordFieldErrorTypes]}</span>
                </li>
              );
            })}
          </ul>
        </StyledPasswordField>
      )}
    />
  );
};
