import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledErrorModal = styled.div`
  .rnb-error-modal {
    display: flex;
    align-items: center;
    .custom-scrollbar {
      bottom: auto;
    }
  }

  .modal-header {
    .header-heading {
      font-size: ${toRem(30)};
    }
  }
`;
