//Basic hero banner skeleton loader to serve target puropose.

import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';

import { MerchandisingHeroBannerSkeletonLoaderProps } from './MerchandisingHeroBannerSkeletonLoader.types';
import { Types } from '@marriott/mi-ui-library';
const { HeaderCopyWidth, ImageAspectRatio } = Types;

const { DEFAULT } = HeaderCopyWidth;
const { SIZE_16x9, SIZE_3x1, SIZE_2X1 } = ImageAspectRatio;

export const MerchandisingHeroBannerSkeletonLoader = ({
  width,
  copyBlockWidth,
  copyBlockWidthContainerClass,
  isVerticalImage,
  eyebrowText,
  heroSize,
  cobrandmathhero,
  descriptionLong,
  callToActionText2,
  callToActionText1,
  headerMedium,
  appliedCssClassNames,
}: MerchandisingHeroBannerSkeletonLoaderProps) => {
  return (
    <div className={clsx('hero-banner hb__scrim', appliedCssClassNames)}>
      <div
        className={clsx(
          'hb__cnt-sec gradient',
          !(appliedCssClassNames?.includes('light-scrim') || appliedCssClassNames?.includes('no-scrim'))
            ? copyBlockWidth
            : '',
          { 'overlay-text-hero3x1': heroSize === SIZE_3x1 }
        )}
      >
        <div className="container hb__cnt-ctr hero-3x1">
          {!isVerticalImage && eyebrowText && (
            <div className="row">
              <div
                className={
                  copyBlockWidth === DEFAULT || (width && width < 576)
                    ? 'col-12 col-xl-5 col-md-6 col-lg-6'
                    : copyBlockWidthContainerClass
                }
              >
                <Skeleton width={`${eyebrowText}ch`} />
              </div>
            </div>
          )}
          {headerMedium && (
            <div className="row">
              <div
                className={clsx(
                  copyBlockWidth === DEFAULT || (width && width < 576)
                    ? {
                        'col-12 col-lg-6 col-xl-5': !isVerticalImage && !cobrandmathhero,
                        'col-12 col-lg-12': isVerticalImage,
                        'col-sm-6 col-md-6': (heroSize === SIZE_16x9 || heroSize === SIZE_2X1) && !isVerticalImage,
                        'col-sm-5 col-md-6': heroSize === SIZE_3x1 && !isVerticalImage,
                        'col-12 col-lg-8 col-xl-6': !isVerticalImage && cobrandmathhero,
                      }
                    : copyBlockWidthContainerClass
                )}
              >
                <Skeleton height="2.5rem" width={`${headerMedium.length}ch`} />
              </div>
            </div>
          )}
          {descriptionLong && (
            <div className="row">
              <div
                className={clsx(
                  copyBlockWidth === DEFAULT || (width && width < 576)
                    ? {
                        'col-11 col-lg-6 col-xl-5': !isVerticalImage && !cobrandmathhero,
                        'col-12 col-lg-9 col-md-12 col-sm-auto ': isVerticalImage,
                        'col-sm-6 col-md-6': (heroSize === SIZE_16x9 || heroSize === SIZE_2X1) && !isVerticalImage,
                        'col-sm-5 col-md-6': heroSize === SIZE_3x1 && !isVerticalImage,
                        'col-9 col-lg-7 col-xl-6': !isVerticalImage && cobrandmathhero,
                      }
                    : copyBlockWidthContainerClass
                )}
              >
                <Skeleton height="2rem" width={`${descriptionLong}ch`} />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div
                className={clsx({
                  flexLayout: !isVerticalImage,
                })}
              >
                {callToActionText1 && <Skeleton height={'2rem'} width={`${callToActionText1.length}ch`} />}
                {!isVerticalImage && callToActionText2 && (
                  <Skeleton height={'2rem'} width={`${callToActionText2.length}ch`} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
