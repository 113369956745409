import React, { FC } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useRouter } from 'next/router';
import { Button, Heading, Image, Types } from '@marriott/mi-ui-library';
import { EventStatus } from '@marriott/mi-groups-graphql';
import { PlannerEventCardProps } from './PlannerEventCard.types';
import { StyledPlannerEventCard } from './PlannerEventCard.styles';
import { DateBlock } from '../DateBlock';
import { BrandLogo } from '../BrandLogo';
import { getFormattedDate } from '../../../utils';

export const PlannerEventCard: FC<PlannerEventCardProps> = ({ plannerEventLabels, plannerEventData }) => {
  const {
    propertyImageURL,
    eventName,
    modifiedDate,
    arrivalDate,
    departureDate,
    cutOffDate,
    brandCode,
    brandName,
    hotelName,
    addressLine1,
    city,
    stateProvince,
    zipCode,
    countryCode,
    marshaCode,
    roomingListId,
    eventStatus,
  } = plannerEventData;

  const router = useRouter();

  return (
    <StyledPlannerEventCard>
      <div className="d-flex mb-5 event-card-container">
        <div className={clsx('event-image', !propertyImageURL && 'alternate-image')}>
          {propertyImageURL && (
            <Image defaultImageURL={`${propertyImageURL}?resize=244:162`} altText={hotelName} loading="lazy" />
          )}
        </div>
        <div className="d-flex flex-column event-card-details">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <Heading
              titleText={eventName}
              variation={Types.headingType.title}
              customClass="t-title-s m-0"
              element={Types.tags.h2}
            />
            <p className="t-label-alt-xs m-0">
              <span>{plannerEventLabels.modifiedDate} </span>
              <span>{modifiedDate && getFormattedDate(moment(modifiedDate), 'dateWithDayMonthYearAndTime')}</span>
            </p>
          </div>
          <div>
            <DateBlock
              arrivalDateField={{
                label: plannerEventLabels.arrivalDate,
                value: arrivalDate && getFormattedDate(moment(arrivalDate), 'dateWithDayMonthAndYear'),
              }}
              departureDateField={{
                label: plannerEventLabels.departureDate,
                value: departureDate && getFormattedDate(moment(departureDate), 'dateWithDayMonthAndYear'),
              }}
              cutOffDateField={{
                label: plannerEventLabels.cutOffDate,
                value: cutOffDate && getFormattedDate(moment(cutOffDate), 'dateWithDayMonthAndYear'),
              }}
            ></DateBlock>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-wrap hotel-info-container">
            <div className="d-flex align-items-center hotel-info">
              <BrandLogo brandCode={brandCode} ariaLabel={brandName} />
              <div className="hotel-details">
                <span className="t-subtitle-m d-block">{hotelName}</span>
                <span className="t-font-alt-s">{addressLine1} </span>
                <span className="t-font-alt-s">{city} </span>
                <span className="t-font-alt-s">{stateProvince} </span>
                <span className="t-font-alt-s">{zipCode} </span>
                <span className="t-font-alt-s">{countryCode} </span>
              </div>
            </div>
            <div className="select-cta">
              <Button
                className="m-button-m m-button-secondary"
                callback={() => {
                  router.push(
                    `${plannerEventLabels.newEventUri}?marshaCode=${marshaCode}&roominglistId=${roomingListId}`
                  );
                }}
              >
                {plannerEventLabels.select}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 associate-action">
        <strong>{plannerEventLabels.associateActionTaken} </strong>
        {eventStatus === EventStatus.NEW || eventStatus === EventStatus.OPENED ? (
          <span>{plannerEventLabels.eventSummaryCreated}</span>
        ) : null}
        {eventStatus === EventStatus.UPLOAD_SUCCESS ? <span>{plannerEventLabels.submittedRoomingList}</span> : null}
      </div>
    </StyledPlannerEventCard>
  );
};
