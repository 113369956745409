/*
To convert phone number format for DE domain
*/
export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export const convertPhoneNumberToDE = (phoneNumber: string): string => {
  let result = '';
  const num = phoneNumber.split(' ');
  let i = 0;
  if (num.length > 1) {
    while (i < num.length - 1) {
      if (i !== 0 && i < num.length - 1) num[i] += '-';
      else num[i] += ' ';
      i++;
    }
    result = num.join('');
  } else {
    const n = num.toString();
    result = `${n.slice(0, 2)} ${n.slice(2, 5)}-${n.slice(5, 8)}-${n.slice(8, n.length)}`;
  }
  return result;
};

export const formatCurrency = (amount: number, locale: string, currency: string): string => {
  const pricingFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'code',
  });

  return pricingFormat.format(amount).replace(currency, '').trim();
};
