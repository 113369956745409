import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { baseVariables } from '@marriott/mi-ui-library';
import { Lookup, LookupType } from '@marriott/mi-groups-graphql';
import { TextFormField } from '../TextFormField';
import { Dropdown } from '../Dropdown';
import { DEFAULT_CALLING_CODE, EMAIL_REGEX, USER_NAME_REGEX, NUM_REGEX, USA } from '../../constants';
import { useLookupsByType, useMediaQuery } from '../../hooks';
import { useLocaleStore } from '../../store';
import { DropdownOption } from '../Dropdown/Dropdown.types';
import { UserInfoProps } from './UserInfo.types';
import { StyledUserInfo } from './UserInfo.styles';

export const UserInfo: FC<UserInfoProps> = props => {
  const { labels, customerCallingCountry, isEmailDisabled, control, errors, setValue, clearErrors, onApiError } = props;
  const { title, firstName, lastName, email, callingCode, phoneNumber } = labels;

  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);

  const { locale } = useLocaleStore();

  const [callingCodeOptions, setCallingCodeOptions] = useState<DropdownOption<Lookup>[]>([]);
  const [selectedCallingCode, setSelectedCallingCode] = useState(DEFAULT_CALLING_CODE);
  const [selectedCallingCodeIndex, setSelectedCallingCodeIndex] = useState<number>();

  // to validate phone number based on selected calling code
  const isUsCallingCode = callingCodeOptions[selectedCallingCodeIndex as number]?.shortDescription === USA;
  const phoneNumberMinLength = isUsCallingCode ? phoneNumber.usMinLength : phoneNumber.nonUsMinLength;
  const phoneNumberMaxLength = isUsCallingCode ? phoneNumber.usMaxLength : phoneNumber.nonUsMaxLength;
  const phoneNumberFormatError = isUsCallingCode ? phoneNumber.usFormatError : phoneNumber.nonUsFormatError;

  const { lookups: countries } = useLookupsByType(LookupType.COUNTRIES);
  const {
    lookups: callingCodes,
    loading: callingCodesLoading,
    error: callingCodesError,
  } = useLookupsByType(LookupType.CALLING_CODES);

  const sortOptions = (lookups: Lookup[]) => {
    return [...lookups]
      .sort((a, b) => a.description.localeCompare(b.description))
      .map(lookup => ({
        ...lookup,
        shortDescription: lookup.label,
        label: `${lookup.description} <span>+${lookup.code}</span>`,
        value: `+${lookup.code}`,
      }));
  };

  useEffect(() => {
    if (callingCodes) {
      const options = sortOptions(callingCodes);
      setCallingCodeOptions(options);
    }
  }, [callingCodes]);

  useEffect(() => {
    const localeCountryCode = locale?.split('-')[1];
    const matchingCountry = countries?.find((country: Lookup) => country.code === localeCountryCode);
    const defaultCountry = customerCallingCountry || matchingCountry?.code || '';
    const matchingCallingCodeIndex = callingCodeOptions.findIndex(
      callingCodeOption => callingCodeOption.shortDescription?.toLowerCase() === defaultCountry.toLowerCase()
    );
    const matchingCallingCodeOption = callingCodeOptions[matchingCallingCodeIndex];
    matchingCallingCodeOption && setValue('userInfo.callingCode', matchingCallingCodeOption);
    setSelectedCallingCodeIndex(matchingCallingCodeIndex);
    matchingCallingCodeOption && setSelectedCallingCode(`+${matchingCallingCodeOption?.code}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCallingCountry, locale, countries, callingCodeOptions]);

  useEffect(() => {
    if (onApiError && callingCodesError) {
      onApiError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingCodesError]);

  const firstNameField = (
    <Controller
      name="userInfo.firstName"
      control={control}
      rules={{
        required: firstName.requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={firstName.label}
          ariaLabel={firstName.ariaLabel}
          className="m-input-field"
          maxLength={firstName.maxLength}
          showErrorMessage={!!errors?.userInfo?.firstName?.message}
          errorMessage={errors?.userInfo?.firstName?.message}
          testId="first-name"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const lastNameField = (
    <Controller
      name="userInfo.lastName"
      control={control}
      rules={{
        required: lastName.requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={lastName.label}
          ariaLabel={lastName.ariaLabel}
          className="m-input-field"
          maxLength={lastName.maxLength}
          showErrorMessage={!!errors?.userInfo?.lastName?.message}
          errorMessage={errors?.userInfo?.lastName?.message}
          testId="last-name"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const emailField = (
    <Controller
      name="userInfo.email"
      control={control}
      rules={{
        required: email.requiredError,
        pattern: {
          value: EMAIL_REGEX,
          message: `${email.formatError}`,
        },
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={email.label}
          ariaLabel={email.ariaLabel}
          className="m-input-field"
          maxLength={email.maxLength}
          disabled={isEmailDisabled}
          showErrorMessage={!!errors?.userInfo?.email?.message}
          errorMessage={errors?.userInfo?.email?.message}
          testId="email"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const callingCodeField = (
    <Controller
      name="userInfo.callingCode"
      control={control}
      render={({ field }) => {
        return (
          <Dropdown
            name="callingCode"
            label={isDesktop ? callingCode.label : callingCode.mobileLabel}
            ariaLabel={callingCode.ariaLabel}
            expandIconAriaLabel={callingCode.expandIconAriaLabel}
            collapseIconAriaLabel={callingCode.collapseIconAriaLabel}
            className="calling-code"
            options={callingCodeOptions}
            selectedValue={selectedCallingCode}
            selectedIndex={selectedCallingCodeIndex}
            disabled={callingCodesLoading || !!callingCodesError}
            useValueAsLabel={true}
            renderLabelAsHtml={true}
            onChange={event => {
              field.onChange(event);
              setSelectedCallingCode(event.value);
              const callingCodeIndex = callingCodeOptions.findIndex(
                callingCodeOption => callingCodeOption.description?.toLowerCase() === event.description.toLowerCase()
              );
              setSelectedCallingCodeIndex(callingCodeIndex);
            }}
          />
        );
      }}
    />
  );

  const phoneNumberField = (
    <Controller
      name="userInfo.phoneNumber"
      control={control}
      rules={{
        required: phoneNumber.requiredError,
        pattern: {
          value: NUM_REGEX,
          message: phoneNumberFormatError,
        },
        validate: value => (value.length < phoneNumberMinLength ? phoneNumberFormatError : true),
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={phoneNumber.label}
          ariaLabel={phoneNumber.ariaLabel}
          className="m-input-field"
          maxLength={phoneNumberMaxLength}
          showErrorMessage={!!errors?.userInfo?.phoneNumber?.message}
          errorMessage={errors?.userInfo?.phoneNumber?.message}
          testId="phone-number"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  return (
    <StyledUserInfo data-component-name="m-groups-UserInfo" data-testid="groups-UserInfo">
      <div className="t-subtitle-l title">{title}</div>
      <div className="row">
        <div className="col-md-6 pb-5">{firstNameField}</div>
        <div className="col-md-6 pb-5">{lastNameField}</div>
      </div>
      <div className="row">
        <div className="col-md-6 pb-5">{emailField}</div>
        <div className="col-4 col-md-2 pb-5">{callingCodeField}</div>
        <div className="col-8 col-md-4 pb-5">{phoneNumberField}</div>
      </div>
    </StyledUserInfo>
  );
};
