import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledEmailForm = styled.div`
  .cta-container {
    gap: ${toRem(16)};
    flex-direction: column;
    @media ${baseVariables.mediaQuery.sm} {
      flex-direction: row;
    }
    .m-link-tertiary-button {
      align-self: center;
      &::before,
      &::after {
        content: '';
      }
    }
    .back-to-hub {
      cursor: pointer;
      background: transparent;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto ${toRem(1)};
      }
    }
  }
`;

export const StyledEmailMessage = styled.div`
  &.invalid-message {
    .message-label,
    .error-text {
      color: ${color.alert50};
    }
    .message > div {
      border-color: ${color.alert50};
    }
  }
  .message > div {
    appearance: textarea;
    border: ${toRem(1)} solid ${color.neutral30};
    border-radius: ${toRem(4)};
    height: fit-content
    overflow: auto;
    padding: ${toRem(30)};
    background-color: ${color.base20};
    &[contenteditable] {
      outline: 0 solid transparent;
    }
  }
`;
