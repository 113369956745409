import axios from 'axios';
import { UseFormSetValue } from 'react-hook-form';
import {
  RfpPropertyDetails,
  Media,
  SubmitRfpInput,
  RoomType,
  PropertyContactNumber,
  SearchGroupRatesEdge,
} from '@marriott/mi-groups-graphql';
import { getDurationBetweenDates, getNextDateObject } from '@marriott/mi-ui-library';
import { RfpProperty } from '../organisms/RfpTray/RfpCard/RfpCard.types';
import { RfpSessionInput } from '../organisms/RfpTray/RfpTray.types';
import { ViewType } from '../organisms/SearchResults/SearchResults.types';
import { getDatesInRange, getFormattedDate, getFormattedDateObject, getFormattedDateString } from './date';
import { getImageType, getPropertyImages } from './propertyMedia';
import { getSearchQueryOptions, recentSearchesStorage, BookNowPayload, SearchQueryOptions } from './search';
import { getQueryParams } from './searchResults';
import { FILE_DELETE_URL, FILE_UPLOAD_URL } from '../constants';
import { EventProfileFormData } from '../organisms/EventProfile/EventProfile.types';
import { EventDetailsFormData } from '../organisms/EventDetails/EventDetails.types';
import { ContactInfoFormData } from '../organisms/ContactInfo/ContactInfo.types';
import { OptionType } from '../molecules/EventInfo/EventInfo.types';
import { IntlRfpFormData } from '../organisms/IntlRfpEventInfo/IntlRfpEventInfo.types';
import { EventSpaceConfigurationOption } from '../molecules/EventSpaceConfigurationPicker/EventSpaceConfigurationPicker.types';
import { formatPhoneNumber } from '../admin/utils/roomingList';

const SEARCH_CRITERIA_MAP: {
  [key in keyof EventProfileFormData]?: keyof RfpSessionInput['AriesGroupSearch']['groupSearchCriteria'];
} = {
  startDate: 'checkInDate',
  endDate: 'checkOutDate',
  flexibleDates: 'isFlexibleDate',
  eventType: 'eventSearchType',
  guestRooms: 'guestRoomCount',
  attendees: 'sizeLargestMeetingRoom',
};

export const getRfpSessionData = (
  propertyCount: number,
  shortlistedMarshaCodes: Record<string, string>,
  isQuickGroupEnabled: boolean,
  locale: string
): RfpSessionInput => {
  const lastSearchedData = recentSearchesStorage.getLatestItem();
  const queryOption = getSearchQueryOptions(getQueryParams(window.location.search));
  const rfpSession = {
    AriesGroupSearch: {
      groupPropertyRecordsCount: propertyCount,
      groupSearchCriteria: {
        address: {
          latitude: queryOption?.latitude || lastSearchedData.latitude,
          longitude: queryOption?.longitude || lastSearchedData.longitude,
          destination: queryOption?.destination || lastSearchedData.value,
          destinationAddressMainText: lastSearchedData.city,
          city: lastSearchedData.city,
          stateProvince: lastSearchedData.states,
          country: lastSearchedData.countries,
        },
        checkInDate: `${queryOption?.startDate || lastSearchedData.startDate}T00:00:00.000+0000`,
        checkOutDate: `${queryOption?.endDate || lastSearchedData.endDate}T00:00:00.000+0000`,
        isFlexibleDate: queryOption?.flexibleDates || lastSearchedData.flexibleDates || '',
        eventSearchType: queryOption?.eventType || lastSearchedData.eventType || '',
        guestRoomCount: parseInt(queryOption?.guestRooms || lastSearchedData.eventType || '0', 10),
        sizeLargestMeetingRoom: parseInt(queryOption?.attendees || lastSearchedData.attendees || '0', 10),
        roomsOnlySelected: false,
      },
      isGroupSearch: true,
      isQuickGroupEnabled,
      rfpShortlistedMarshaCodes: {
        ...shortlistedMarshaCodes,
      },
      localeForERFP: locale,
    },
  };
  return rfpSession;
};

export const getShortlistedMarshaCodes = (properties: RfpProperty[]) => {
  const shortlistedMarshaCodes: Record<string, string> = {};
  properties.forEach(property => {
    shortlistedMarshaCodes[property.id] = '';
  });
  return shortlistedMarshaCodes;
};

export const getRfpProperty = (property: RfpPropertyDetails, isDesktop: boolean, isTablet: boolean) => {
  const { id, seoNickname, media, airports, reviews, basicInformation, meetingRooms, contactInformation } = property;
  const { brand } = basicInformation;

  const imageType = getImageType(ViewType.LIST_VIEW, isDesktop, isTablet, false);
  const brandImage = brand?.photos?.length && brand.photos[0]?.content?.length ? brand.photos[0].content[0] : undefined;
  const image = getPropertyImages(imageType, media?.primaryImage as Media, undefined, brandImage)[0];

  return {
    id,
    seoNickname,
    image,
    media,
    airports,
    reviews,
    basicInformation,
    meetingRooms,
    contactInformation,
    isMarkForRemoved: false,
  };
};

const getUpdatedOptions = (
  options: OptionType[],
  fieldName: keyof EventProfileFormData,
  fieldValue: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
) => {
  return options.map((option: OptionType) => {
    const isSelected = option.code.toLowerCase() === fieldValue.toLowerCase();
    const updatedOption = { ...option, selected: isSelected };
    isSelected && setValue(fieldName, updatedOption);
    return updatedOption;
  });
};

export const prepopulateSearchCriteria = (
  groupSearchData: RfpSessionInput,
  flexibleDateOptions: OptionType[],
  eventTypeOptions: OptionType[],
  setFlexibleDateOptions: (flexibleDateOptions: OptionType[]) => void,
  setEventTypeOptions: (eventTypeOptions: OptionType[]) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
) => {
  const groupSearchCriteria = groupSearchData?.AriesGroupSearch?.groupSearchCriteria;
  if (groupSearchCriteria) {
    Object.entries(SEARCH_CRITERIA_MAP).forEach(([formField, groupSearchField]) => {
      const fieldValue = groupSearchCriteria[groupSearchField]?.toString();
      if (fieldValue) {
        if (groupSearchField === 'isFlexibleDate') {
          setFlexibleDateOptions(
            getUpdatedOptions(flexibleDateOptions, 'flexibleDates', fieldValue === 'true' ? 'yes' : 'no', setValue)
          );
        } else if (groupSearchField === 'eventSearchType') {
          setEventTypeOptions(getUpdatedOptions(eventTypeOptions, 'eventType', fieldValue, setValue));
        } else {
          setValue(formField as keyof EventProfileFormData, fieldValue);
        }
      }
    });
  }
};

export const convertFileSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB'];
  if (bytes === 0) return `0 ${sizes[0]}`;
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};

export const uploadFile = async (file: File, clientId: 'rfp' | 'reslink') => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(FILE_UPLOAD_URL, formData, {
    headers: {
      'Content-Type': 'multipart-formData',
      clientId,
    },
  });

  return response;
};

export const deleteFile = async (documentId: string, clientId: 'rfp' | 'reslink') => {
  const response = await axios.post(
    `${FILE_DELETE_URL}?documentId=${documentId}`,
    {},
    {
      headers: {
        clientId,
      },
    }
  );

  return response;
};

export const getBookNowPayloadForRfp = (
  groupRatesData: SearchGroupRatesEdge,
  eventProfileData: EventProfileFormData,
  locale: string
) => {
  const { startDate, endDate, guestRooms, attendees, eventType } = eventProfileData;
  const { node, groupRate } = groupRatesData ?? {};
  const { id, basicInformation, contactInformation } = node ?? {};
  const roomCount = +(guestRooms || 0);
  const attendeesCount = +(attendees || 0);

  const bookNowPayload: BookNowPayload = {
    searchType: 'InCity',
    siteId: locale.split('_')?.[1]?.toUpperCase() || 'US',
    dateFormatPattern: 'MM/dd/yy',
    hwsSearchSgo: 'true',
    isHwsQGForm: 'true',
    'destinationAddress.city': contactInformation?.address?.city,
    'destinationAddress.stateCode': contactInformation?.address?.stateProvince?.code as string,
    'destinationAddress.stateProvince': contactInformation?.address?.stateProvince?.description as string,
    'destinationAddress.country': contactInformation?.address?.country?.code as string,
    hwsMarshaCode: id.toString(),
    hwsPropertyName: basicInformation?.name || '',
    marshaCode: id.toString(),
    roomsOnlySelected: !!roomCount,
    roomsAndEventSelected: !!attendeesCount,
    roomOnlySelectedAries: !!roomCount && !attendeesCount,
    roomAndEventSelectedAries: !!roomCount && !!attendeesCount,
    roomCount: roomCount ? roomCount.toString() : '',
    largestMeetingSpaceOrAttendees: attendeesCount ? attendeesCount.toString() : '',
    eventType: eventType.label,
    populateTodateFromFromDate: 'true',
    sgoSearch: 'false',
    sgoSupported: 'true',
    defaultToDateDays: '1',
    eventOptionsRadio: 'roomsOnly',
    formType: 'InCity',
    meetingSpaceUnits: '# of attendees',
    fromDate: getFormattedDateString(startDate, 'slashedDateWithMonthNoAndYear'),
    toDate: getFormattedDateString(endDate, 'slashedDateWithMonthNoAndYear'),
    monthNames: 'January,February,March,April,May,June,July,August,September,October,November,December',
    weekDays: 'S,M,T,W,T,F,S',
    brandCode: basicInformation?.brand?.id,
    functionSpaceID: groupRate?.eventSpaceRates?.[0]?.id || '',
    groupId: `M0${Date.now()}`,
    marshaBrandCode: basicInformation?.brand?.id,
    functionSpaceName: groupRate?.eventSpaceRates?.[0]?.name || '',
    eventSpaceSelection: getDatesInRange(startDate, endDate),
  };
  return bookNowPayload;
};

export const getEventSpaceConfigurationMap = (eventSpaceConfigurations: EventSpaceConfigurationOption[]) => {
  return eventSpaceConfigurations.reduce((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {} as Record<string, string>);
};

export const getSubmitRfpInput = (
  properties: RfpProperty[],
  eventProfileData: EventProfileFormData,
  eventDetailsData: EventDetailsFormData,
  contactDetails: ContactInfoFormData
): SubmitRfpInput => {
  const { days, eventSpaces } = eventDetailsData;
  const { userInfo, inlineEnrolment, addressInfo, additionalInfo, gsoInfo } = contactDetails;

  const propertyCode = properties
    .filter(property => !property.isMarkForRemoved)
    .map(property => property.id.toString());

  const eventProfile = {
    name: eventProfileData.eventName,
    startDate: eventProfileData.startDate,
    endDate: eventProfileData.endDate,
    isFlexibleDate: eventProfileData.flexibleDates.selected === true && eventProfileData.flexibleDates.code === 'Yes',
    eventType: eventProfileData.eventType.code,
    customEventType: eventProfileData.otherEventType,
    numberOfAttendees: +eventProfileData.attendees,
    numberOfRooms: +eventProfileData.guestRooms,
  };

  const attachedDocs = eventProfileData.files.map(file => ({
    key: file.key,
    name: file.fileDetail.name,
    description: file.description,
  }));

  const daywiseRoomTypes = new Map(days.map(day => [day.date, day]));
  const daywiseEventSpaces = new Map(eventSpaces.map(space => [space.date, space]));
  const uniqueDates = Array.from(new Set([...daywiseRoomTypes.keys(), ...daywiseEventSpaces.keys()]));

  const eventDetails = uniqueDates.map(date => {
    const roomType = daywiseRoomTypes.get(date);
    const eventSpace = daywiseEventSpaces.get(date);

    return {
      date,
      roomType: roomType
        ? [
            { type: RoomType.GEN_STANDARD, count: +roomType.singleBedCount },
            { type: RoomType.GEN_STANDARD_DOUBLE, count: +roomType.twinBedCount },
            { type: RoomType.GEN_SUITE, count: +roomType.suiteCount },
          ]
        : [],
      totalRoomCount: roomType ? +roomType.totalCount : 0,
      eventSpace: eventSpace
        ? eventSpace.eventList.map(event => ({
            startTime: event.time[0],
            endTime: event.time[1],
            numberOfAttendees: +event.attendees,
            isFoodBeverageRequired: event.amenities.includes('Food & Beverage'),
            isAudioVisualRequired: event.amenities.includes('Audio & Visual'),
            sittingArrangement: event.configuration,
          }))
        : [],
    };
  });

  const state = { name: '', code: '' };
  try {
    const stateOption = JSON.parse(addressInfo.state);
    state.name = stateOption.description;
    state.code = stateOption.code;
  } catch (error) {
    state.code = addressInfo.state;
  }

  const contactInfo = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    email: userInfo.email,
    phone: {
      dialingCountryCode: userInfo.callingCode.code,
      countryCode: userInfo.callingCode.shortDescription,
      number: userInfo.phoneNumber,
    },
    rewardId: userInfo.rewardId,
    enrollmentSourceCode: 'USMI',
    isJoinNow: inlineEnrolment.joinMarriottBonvoy,
    password: inlineEnrolment.createPassword,
    confirmPassword: inlineEnrolment.confirmPassword,
    isRememberMe: inlineEnrolment.rememberMe,
    isSubscription: inlineEnrolment.receiveUpdates,
    isThirdPartySubscription: inlineEnrolment.receiveOffers,
    address: {
      type: addressInfo.addressType,
      company: {
        name: addressInfo.companyName,
      },
      line1: addressInfo.addressLine1,
      line2: addressInfo.addressLine2,
      country: {
        code: addressInfo.country.code,
        name: addressInfo.country.label,
      },
      city: addressInfo.city,
      state,
      zipCode: addressInfo.zipcode,
    },
    gso: gsoInfo,
  };

  return { propertyCode, eventProfile, attachedDocs, eventDetails, contactInfo, additionalInformation: additionalInfo };
};

export const getSubmitIntlRfpInput = (
  properties: RfpProperty[],
  intlRfpData: IntlRfpFormData,
  locale: string
): SubmitRfpInput => {
  const { userInfo, addressInfo, additionalInfo } = intlRfpData;

  const propertyCode = properties
    .filter(property => !property.isMarkForRemoved)
    .map(property => property.id.toString());

  const eventProfile = {
    name: intlRfpData.eventName,
    startDate: intlRfpData.startDate,
    endDate: intlRfpData.endDate,
    isFlexibleDate: intlRfpData.flexibleDates.selected === true && intlRfpData.flexibleDates.code === 'Yes',
    eventType: intlRfpData.eventType.code,
    customEventType: intlRfpData.otherEventType,
    numberOfAttendees: +intlRfpData.attendees,
    numberOfRooms: +intlRfpData.guestRooms,
  };

  const eventDetails = [];
  let currentDate = getFormattedDateObject(intlRfpData.startDate);
  const numDays = getDurationBetweenDates(intlRfpData.startDate, intlRfpData.endDate);

  for (let i = 0; i <= numDays; i++) {
    eventDetails.push({
      date: getFormattedDate(currentDate, 'hyphenatedDateWithMonthNoAndYear'),
      roomType: [{ type: RoomType.GEN_STANDARD_DOUBLE, count: +intlRfpData.guestRooms }],
      totalRoomCount: +intlRfpData.guestRooms,
    });
    currentDate = getNextDateObject(currentDate);
  }

  const attachedDocs = intlRfpData.files.map(file => ({
    key: file.key,
    name: file.fileDetail.name,
    description: file.description,
  }));

  const state = { name: '' };
  try {
    const stateOption = JSON.parse(addressInfo.state);
    state.name = stateOption.description;
  } catch (error) {
    state.name = addressInfo.state;
  }

  const contactInfo = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    email: userInfo.email,
    phone: {
      dialingCountryCode: userInfo.callingCode.code,
      countryCode: userInfo.callingCode.shortDescription,
      number: userInfo.phoneNumber,
    },
    rewardId: userInfo.rewardId,
    address: {
      type: addressInfo.addressType,
      company: {
        name: addressInfo.companyName,
      },
      line1: addressInfo.addressLine1,
      line2: addressInfo.addressLine2,
      country: {
        code: addressInfo.country.code,
        name: addressInfo.country.label,
      },
      city: addressInfo.city,
      state,
      zipCode: addressInfo.zipcode,
    },
  };

  return {
    propertyCode,
    eventProfile,
    currency: intlRfpData.currency?.code,
    budgetAmount: +(intlRfpData?.budget || ''),
    attachedDocs,
    eventDetails,
    contactInfo,
    additionalInformation: additionalInfo,
    locale: locale.replace('-', '_'),
  };
};

export const getPropertyContactNumbers = (contactNumbers: PropertyContactNumber[]) =>
  contactNumbers
    .filter(contactNumber => contactNumber.phoneNumber.display)
    .slice(0, 3)
    .map(contactNumber => formatPhoneNumber(contactNumber.phoneNumber.display))
    .join(', ');

export const isSearchCriteriaFoundInSession = (groupSearchData: RfpSessionInput, queryOptions: SearchQueryOptions) => {
  if (groupSearchData) {
    const {
      address,
      checkInDate,
      checkOutDate,
      guestRoomCount,
      sizeLargestMeetingRoom: attendeesCount,
    } = groupSearchData.AriesGroupSearch.groupSearchCriteria;
    const startDate = checkInDate.split('T')[0];
    const endDate = checkOutDate.split('T')[0];

    if (
      address.destination === queryOptions.destination &&
      startDate === queryOptions.startDate &&
      endDate === queryOptions.endDate &&
      guestRoomCount.toString() === (queryOptions.guestRooms || '0') &&
      attendeesCount.toString() === (queryOptions.attendees || '0')
    ) {
      return true;
    }
  }
  return false;
};
