/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

export interface serviceStore {
  serviceData: {
    serviceClicked: boolean;
    serviceUXL: string;
    connectBtnClick: boolean;
    servicesLoaded: boolean;
    merchandisingAvailable: boolean;
  } | null;
  serviceLoading: boolean;
  serviceError: boolean;
  skipQuery: boolean;
  setservice: (data: any, loading: boolean, error: any, fallbackData: any) => void;
}

const initialState: serviceStore = {
  serviceData: {
    serviceClicked: false,
    serviceUXL: '',
    connectBtnClick: false,
    servicesLoaded: false,
    merchandisingAvailable: false,
  },
  serviceLoading: true,
  serviceError: false,
  skipQuery: false,
  setservice: () => {},
};

const ServiceStore: StateCreator<serviceStore> = set => {
  return {
    ...initialState,
    setservice: (data, loading, error, fallbackData) => {
      if (error) {
        console.log(fallbackData);
        set({
          serviceData: null,
          serviceLoading: loading,
          serviceError: true,
          skipQuery: true,
        });
      } else if (data && Object.keys(data).length) {
        set(state => ({
          serviceData: { ...JSON.parse(JSON.stringify(state)).serviceData, ...data },
          serviceLoading: loading,
          serviceError: false,
          skipQuery: true,
        }));
      }
    },
  };
};

export const useServiceStore = createAppStore(ServiceStore, {
  usePersistentStore: false,
});
