import React, { FC, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import History from '@tiptap/extension-history';
import { Button, Types } from '@marriott/mi-ui-library';
import { StyledTextEditor } from './TextEditor.styles';
import { TextEditorProps } from './TextEditor.types';
import { BoldIcon, UnderlineIcon, ItalicIcon } from './icons';

export const TextEditor: FC<TextEditorProps> = ({
  placeholder,
  maxLength,
  content = '',
  ariaLabel,
  boldAriaLabel,
  underlineAriaLabel,
  italicAriaLabel,
  onChange,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      History.configure({
        newGroupDelay: 10,
      }),
      Bold,
      Underline,
      Italic,
    ],
    content,
    onUpdate({ editor }) {
      if (maxLength && editor.getText().length > maxLength) {
        editor.commands.undo();
      }
      onChange(editor.getHTML(), editor.getText());
    },
  }) as Editor;

  useEffect(() => {
    editor?.commands.setContent(content);
    if (editorRef.current) {
      const contentBox = editorRef.current.querySelector('.ProseMirror');
      contentBox?.setAttribute('role', 'textbox');
      contentBox?.setAttribute('aria-label', ariaLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, editor]);

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <StyledTextEditor ref={editorRef}>
      <div className="d-flex align-items-center p-2 menu">
        <Button
          type={Types.ButtonTypeVariation.Button}
          className={clsx('px-2 py-1 menu-item', editor.isActive('bold') && 'is-active')}
          ariaLabel={boldAriaLabel}
          callback={toggleBold}
        >
          <BoldIcon />
        </Button>
        <Button
          type={Types.ButtonTypeVariation.Button}
          className={clsx('px-2 py-1 menu-item', editor.isActive('underline') && 'is-active')}
          ariaLabel={underlineAriaLabel}
          callback={toggleUnderline}
        >
          <UnderlineIcon />
        </Button>
        <Button
          type={Types.ButtonTypeVariation.Button}
          className={clsx('px-2 py-1 menu-item', editor.isActive('italic') && 'is-active')}
          ariaLabel={italicAriaLabel}
          callback={toggleItalic}
        >
          <ItalicIcon />
        </Button>
      </div>
      {placeholder && !editor.getText().length ? <div className="placeholder">{placeholder}</div> : null}
      <EditorContent editor={editor} />
    </StyledTextEditor>
  );
};
