import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { mediaQuery } = baseVariables;

export const StyledTimePicker = styled.div`
  position: relative;

  .dropdown-container {
    position: absolute;
    top: ${toRem(46)};
    width: 100%;
    z-index: 5;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: white;
    @media ${mediaQuery.md} {
      min-width: ${toRem(300)};
    }
    @media ${mediaQuery.lg} {
      min-width: 100%;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin: ${toRem(16)} auto;
  }
`;
