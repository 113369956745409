import { OffersData } from '../OffersMBEHero.types';

export const getHeader = (variation: string, isUserLoggedIn: boolean, offersData: OffersData) => {
  const titles = offersData?.hero || {};
  if (variation === 'active') {
    return isUserLoggedIn ? titles.authenticatedActiveTitle : titles.anonymousActiveTitle;
  } else {
    return isUserLoggedIn ? titles.authenticatedInactiveTitle : titles.anonymousInactiveTitle;
  }
};

export const getDescription = (variation: string, isUserLoggedIn: boolean, offersData: OffersData) => {
  const description = offersData?.hero || {};
  if (variation === 'active') {
    return isUserLoggedIn ? description.authenticatedActiveSubTitle : description.anonymousActiveSubTitle;
  } else {
    return isUserLoggedIn ? description.authenticatedInactiveSubTitle : description.anonymousInactiveSubTitle;
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getTextAlignClass = (isUserLoggedIn: boolean, utilityState: string, isMobileViewPort: boolean) => {
  return !isUserLoggedIn && utilityState === 'active' && !isMobileViewPort ? '' : 'text-align';
};

export const getWrapperClass = (isUserLoggedIn: boolean, isMobileViewPort: boolean) => {
  return isUserLoggedIn && isMobileViewPort
    ? 'mbeHero-wrapper-mobile'
    : !isUserLoggedIn && isMobileViewPort
    ? 'mbeHero-wrapper-anonymous-mobile'
    : 'mbeHero-wrapper';
};

export const getMBEWrapperClass = (isUserLoggedIn: boolean, utilityState: string, isMobileViewPort: boolean) => {
  return isUserLoggedIn && isMobileViewPort
    ? 'mbeMessage-wrapper-mobile'
    : !isUserLoggedIn && (isMobileViewPort || utilityState === 'active')
    ? ''
    : 'mbeMessage-wrapper';
};
