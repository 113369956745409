import CreditCardType from 'credit-card-type';
import { CustomerPaymentMethod } from '@marriott/mi-groups-graphql';
import {
  CreditCardInfo,
  SavedCardInfo,
  CreditCardType as CreditCardTypeEnum,
} from '../organisms/PaymentInfo/PaymentInfo.types';
import { DEFAULT_CARD_NUMBER_LENGTH, AMEX_CARD_NUMBER_LENGTH, UNION_PAY_CARD_NUMBER_LENGTH } from '../constants';

export const getCreditCardInfo = (cardNo: string): CreditCardInfo => {
  if (!cardNo) {
    return { cardType: '', cardNumber: '', cardNumberMaxLength: 0, cvvMaxLength: 0 };
  }

  CreditCardType.updateCard(CreditCardTypeEnum.UP, {
    gaps: [4, 8, 12, 16],
  });

  const creditCardType = CreditCardType(cardNo)[0];
  const cardType = creditCardType?.type || '';
  const cardNumber = formatCardNumber(cardNo, creditCardType?.gaps);
  const cardNumberMaxLength =
    (cardType === CreditCardTypeEnum.A
      ? AMEX_CARD_NUMBER_LENGTH
      : cardType === CreditCardTypeEnum.UP
      ? UNION_PAY_CARD_NUMBER_LENGTH
      : DEFAULT_CARD_NUMBER_LENGTH) + creditCardType?.gaps.length;
  const cvvMaxLength = creditCardType?.code.size || 0;

  return { cardType, cardNumber, cardNumberMaxLength, cvvMaxLength };
};

const formatCardNumber = (cardNumber: string, gapIndexes: number[]) => {
  let formattedCardNumber = '';
  cardNumber = cardNumber.trim().replace(/\D/g, '');

  for (let index = 0; index < cardNumber.length; index++) {
    formattedCardNumber += `${gapIndexes?.includes(index) ? ' ' : ''}` + cardNumber.charAt(index);
  }
  return formattedCardNumber;
};

export function getYearOptions(limit: number) {
  const currentYear = new Date().getFullYear();

  return Array.from({ length: limit }, (_, index: number) => ({
    label: `${currentYear + index}`,
    value: `${currentYear + index}`,
  }));
}

export const getSavedCardsInfo = (data: CustomerPaymentMethod[], months: string[]) => {
  const savedCards: SavedCardInfo[] = [];
  let preferredCard = {} as SavedCardInfo;

  if (data.length) {
    data.forEach(card => {
      const maskedCardNumber = generateMaskedCardNumber(card.tokenizedPAN, card.panLast4);
      const monthValue = card.expiration.month;
      const yearValue = card.expiration.year.toString();

      const savedCard = {
        id: card.id,
        cardType: card.issuerType.code as keyof typeof CreditCardTypeEnum,
        maskedCardNumber,
        expirationMonth: {
          label: months?.[monthValue - 1],
          value: monthValue.toString(),
        },
        expirationYear: {
          label: yearValue,
          value: yearValue,
        },
        tokenizedPAN: card.tokenizedPAN,
        panLast4: card.panLast4,
        preferred: card.preferred,
      };
      savedCards.push(savedCard);

      if (savedCard.preferred) {
        preferredCard = savedCard;
      }
    });
  }

  return { savedCards, preferredCard };
};

const generateMaskedCardNumber = (tokenizedPAN: string, panLast4: string): string => {
  if (!tokenizedPAN || !panLast4) return '';
  const prefixLength = tokenizedPAN.length - panLast4.length;
  const maskedPrefix = '•••• '.repeat(Math.floor(prefixLength / 4));
  return maskedPrefix + panLast4;
};

export const isValidExpirationMonth = (expirationMonth: string, expirationYear: string) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  return +expirationYear === currentYear && +expirationMonth < currentMonth ? false : true;
};
