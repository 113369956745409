export type SignInFormData = {
  userName: string;
  password: string;
};

export type SignInFormProps = SignInForm & {
  signInCta: string;
  signInCtaAriaLabel: string;
};

export type SignInForm = {
  title: string;
  email: TextField;
  password: TextField;
  notUserText: string;
  clearRemembered: {
    label: string;
    ariaLabel: string;
    targetUrl: string;
  };
  forgotPassword: {
    label: string;
    ariaLabel: string;
    targetUrl: string;
  };
  signInUrl: string;
  returnUrl: string;
  errorUrl: string;
  userDetailsUrl: string;
};

type TextField = {
  label: string;
  placeholder?: string;
  ariaLabel: string;
};

export enum PasswordType {
  TEXT = 'text',
  PASSWORD = 'password',
}
