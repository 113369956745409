/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState, useContext, useRef } from 'react';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import { PageParamsContext } from '../../modules/context/PageContext';

import { Ppv5Modal } from '../Ppv5Modal';
import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';

import { LetsConnectProps } from './LetsConnect.types';
import { StyledLetsConnect } from './LetsConnect.styles';
import { statesConstant } from '../../modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const LetsConnect: FC<LetsConnectProps> = pageProps => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const serviceData = useServiceStore((state: serviceStore) => state.serviceData);
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);

  const continueButtonRef = useRef(null);

  const [showModal, setShowModal] = useState<boolean>(false);
  const { state = '', currentStateMapping, sameTab = false, cna = false } = preProcessorResponse || {};
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState(<div></div>);

  useEffect(() => {
    if (state?.toLowerCase() === statesConstant.preconnect || state?.toLowerCase() === statesConstant.connect) {
      setModalTitle(pageProps?.letsConnectTitle);
      setModalBody(
        <div className="body m-2 amenities-connect">
          <div className="body-container d-flex flex-column internet-bar-component-dialog">
            <div className="d-flex flex-column">
              <h6 className="t-subtitle-m mb-4">{pageProps?.letsConnectDescription}</h6>
              <p className="mb-4 pb-4 t-font-s">{pageProps?.letsConnectSubDescription}</p>
            </div>
            <button
              className="m-button-m m-button-primary t-font-m lets-connect-button m-dialog-btn mb-4"
              data-dialog-id="login-modal"
              data-testid="lets-connect-btn"
              ref={continueButtonRef}
              onClick={e => {
                if (!(currentStateMapping && currentStateMapping?.noLoginInterfaceModal)) {
                  closeButtonHandler();
                }
                connectBtnTrigger(e);
              }}
            >
              {pageProps?.letsConnectButton}
            </button>
            <a
              href="#"
              className="t-font-s pt-3 pb-md-3 text-center internet-bar-component-modal-link lets-connect-close-button m-dialog-btn"
              onClick={e => {
                e.preventDefault();
                closeButtonHandler();
              }}
              data-testid="lets-connect-tertiary-btn"
            >
              {pageProps?.letsConnectTertiaryButton}
            </a>
          </div>
        </div>
      );
    } else if (
      state?.toLowerCase() === statesConstant.connected &&
      sessionStorage.getItem('amenityClicked') &&
      !sessionStorage.getItem('reloadConnectedUrl')
    ) {
      setModalTitle(pageProps?.youAreConnectedTitle);
      setModalBody(
        <div className="body mx-2 amenities-connect">
          <div className="body-container d-flex flex-column internet-bar-component-dialog">
            <div className="d-flex flex-column">
              <h6 className="t-subtitle-m mb-md-3 mb-4">{pageProps?.youAreConnectedDescription}</h6>
              <p className="mb-4 pb-4 t-font-s">{pageProps?.youAreConnectedSubDescription}</p>
            </div>
            <button
              className="m-button-m m-button-primary t-font-m mb-4 mt-3"
              id="openExternalAmenityPage"
              onClick={continueButtonHandle}
              ref={continueButtonRef}
              data-testid="you-are-connected-btn"
            >
              {pageProps?.youAreConnectedButton}
            </button>
            <a
              href="#"
              className="t-font-s pt-3 pb-md-3 text-center you-are-connected-close-button m-dialog-btn"
              onClick={() => {
                closeButtonHandler();
                sessionStorage.setItem('reloadConnectedUrl', 'true');
              }}
              data-testid="you-are-connected-close-button"
            >
              {pageProps?.youAreConnectedTertiaryButton}
            </a>
          </div>
        </div>
      );
    }
  }, []);

  useEffect(() => {
    if (serviceData?.serviceClicked) {
      setShowModal(true);
    }
    const timeout = setTimeout(() => {
      if (continueButtonRef && continueButtonRef?.current) {
        (continueButtonRef?.current as HTMLInputElement)?.focus();
      }
    }, 20);
    return () => clearTimeout(timeout);
  }, [serviceData]);

  const closeButtonHandler = () => {
    setShowModal(false);
    setServiceData(
      {
        serviceClicked: false,
      },
      false,
      false,
      {}
    );
  };

  const continueButtonHandle = () => {
    const url: string = sessionStorage.getItem('amenityClicked') ?? '';
    closeButtonHandler();
    sessionStorage.setItem('reloadConnectedUrl', 'true');
    window.open(url, sameTab || cna ? '_self' : '_blank');
  };

  const connectBtnTrigger = (e: any) => {
    e.stopPropagation();
    setServiceData(
      {
        connectBtnClick: true,
      },
      false,
      false,
      {}
    );
  };

  return (
    <StyledLetsConnect data-testid="lets-connect-modal" data-component-name="o-ppv5-lets-connect-modal">
      {showModal && (
        <Ppv5Modal
          showModal={showModal}
          modalTitle={modalTitle}
          modalContent={modalBody}
          closeBtnHandler={() => closeButtonHandler()}
        />
      )}
    </StyledLetsConnect>
  );
};

export const LetsConnectConfig = {
  emptyLabel: 'LetsConnect',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/letsConnect`,
} as Config<MappedComponentProperties>;

export const LetsConnectEditable = (props: any) => (
  <EditableComponent config={LetsConnectConfig} {...props}>
    <LetsConnect {...props} />
  </EditableComponent>
);
