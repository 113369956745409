import { FC, useState, useEffect, useRef } from 'react';
import moment, { Moment } from 'moment';
import { StepperTextBlock, Button } from '@marriott/mi-ui-library';
import { TextFormField } from '../TextFormField';
import { CALENDAR_TIME_FORMAT, ENTER_KEY } from '../../constants';
import { useFocusOut } from '../../hooks';
import { StyledTimePicker } from './TimePicker.styles';
import { TimePickerProps } from './TimePicker.types';

export const TimePicker: FC<TimePickerProps> = props => {
  const { labels, time, minStartTime, maxEndTime, onClick, onChange } = props;

  const [startTime, setStartTime] = useState(moment(time[0], CALENDAR_TIME_FORMAT));
  const [endTime, setEndTime] = useState(moment(time[1], CALENDAR_TIME_FORMAT));
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const timePickerElRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onChange(getFormattedTime(startTime), getFormattedTime(endTime));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  useEffect(() => {
    if (
      !startTime.isSame(moment(time[0], CALENDAR_TIME_FORMAT)) ||
      !endTime.isSame(moment(time[1], CALENDAR_TIME_FORMAT))
    ) {
      setStartTime(moment(time[0], CALENDAR_TIME_FORMAT));
      setEndTime(moment(time[1], CALENDAR_TIME_FORMAT));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useFocusOut({
    ref: timePickerElRef.current,
    onFocusOut: isFocusOut => {
      if (isFocusOut) {
        setIsFlyoutOpen(false);
      }
    },
    handleKeyup: true,
  });

  const getFormattedTime = (startTime: Moment, endTime?: Moment) => {
    if (endTime) {
      return `${startTime.format(CALENDAR_TIME_FORMAT)} - ${endTime.format(CALENDAR_TIME_FORMAT)}`;
    } else {
      return `${startTime.format(CALENDAR_TIME_FORMAT)}`;
    }
  };

  const incrementStartTime = () => {
    const newTime = startTime.clone().add(30, 'minutes');
    setStartTime(newTime);
  };

  const decrementStartTime = () => {
    const newTime = startTime.clone().subtract(30, 'minutes');
    setStartTime(newTime);
  };

  const incrementEndTime = () => {
    const newTime = endTime.clone().add(30, 'minutes');
    setEndTime(newTime);
  };

  const decrementEndTime = () => {
    const newTime = endTime.clone().subtract(30, 'minutes');
    setEndTime(newTime);
  };

  const isTimeDifferenceMinimum = () => {
    if (endTime.isBefore(startTime)) {
      const correctedEndTime = endTime.clone().add(1, 'day');
      return correctedEndTime.diff(startTime, 'minutes') === 30;
    } else {
      return endTime.diff(startTime, 'minutes') === 30;
    }
  };

  const canDecrementStartTime = () => {
    return startTime.isSame(moment(minStartTime, CALENDAR_TIME_FORMAT));
  };

  const canIncrementEndTime = () => {
    return endTime.isSame(moment(maxEndTime, CALENDAR_TIME_FORMAT));
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      setIsFlyoutOpen(!isFlyoutOpen);
    }
  };

  const timeField = (
    <TextFormField
      label={labels.timeLabel}
      ariaLabel={labels.timeAriaLabel}
      className="m-input-field"
      value={getFormattedTime(startTime, endTime)}
      testId="time-picker"
      getInputProps={() => ({
        readOnly: true,
      })}
      onClick={() => {
        setIsFlyoutOpen(true);
        onClick?.();
      }}
      onKeyDown={handleKeydown}
    />
  );

  return (
    <StyledTimePicker ref={timePickerElRef} data-component-name="m-groups-TimePicker" data-testid="groups-TimePicker">
      <div>{timeField}</div>
      {isFlyoutOpen && (
        <div className="m-dropdown-container dropdown-container px-3">
          <div>
            <StepperTextBlock
              copyText={labels.startTimeLabel}
              stepperValue={getFormattedTime(startTime)}
              increamentLabel={`${labels.increase} ${labels.startTimeLabel}`}
              decreamentLabel={`${labels.decrease} ${labels.startTimeLabel}`}
              disableIncrement={isTimeDifferenceMinimum()}
              disableDecrement={canDecrementStartTime()}
              handleIncrement={incrementStartTime}
              handleDecrement={decrementStartTime}
            />
          </div>
          <div>
            <StepperTextBlock
              copyText={labels.endTimeLabel}
              stepperValue={getFormattedTime(endTime)}
              increamentLabel={`${labels.increase} ${labels.endTimeLabel}`}
              decreamentLabel={`${labels.decrease} ${labels.endTimeLabel}`}
              disableDecrement={isTimeDifferenceMinimum()}
              disableIncrement={canIncrementEndTime()}
              handleIncrement={incrementEndTime}
              handleDecrement={decrementEndTime}
            />
          </div>
          <div className="button-container">
            <Button
              buttonCopy={labels.applyLabel}
              ariaLabel={labels.applyAriaLabel}
              className="m-button-primary d-block"
              callback={() => setIsFlyoutOpen(false)}
            />
          </div>
        </div>
      )}
    </StyledTimePicker>
  );
};
