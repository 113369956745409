// Styles for LocalHeader go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables, contextualVariable } from '@marriott/mi-ui-library';

export const StyledLocalServicesMenu = styled.div`
  ${rtl`
  .local-service-wrapper {
    div[class*='color-scheme'] {
      background-color: ${contextualVariable.brandedBackgroundColor};
      color: ${contextualVariable.brandedFontColor};
    }
    .local-services-content {
      &-backdrop {
        position: absolute;
        height: ${toRem(36)};
        width: 100%;
        margin-left: ${toRem(-16)};
        background: ${baseVariables.color.base10};
        top: ${toRem(-1)};
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          color: inherit;
          list-style-type: none;
        }
      }
      &-item {
        height: inherit;
      }
      &-link {
        box-sizing: border-box;
        border: 0 solid ${baseVariables.color.neutral20};
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        height: 100%;
        color: ${contextualVariable.brandedFontColor};
        cursor: pointer;

        @media ${baseVariables.mediaQuery.md} {
          padding-top: ${toRem(1)};
          padding-bottom: ${toRem(1)};
        }

        @media ${(baseVariables.mediaQuery.lg, baseVariables.mediaQuery.xl)} {
          padding-top: 0;
          padding-bottom: 0;
        }

        span[class^='icon-'],
        span[class^='icon-']::before {
          color: ${contextualVariable.brandedFontColor};
        }

        .menu-icon-arrow:before {
          content: '\u{e942}';
          position: relative;
          transform: rotate(-45deg);
          transition: all 0.15s ease-in;
          font-size: ${toRem(18)};
          right: unset;
        }
        p {
          color: ${contextualVariable.brandedFontColor};
        }
      }
      &-options {
        [class*='icon-'],
        [class*='icon-']::before {
          color: ${baseVariables.color.linkNavBorderColor};
        }
        &-item {
          .arrow {
            visibility: hidden;
          }
          &-link {
            min-height: ${toRem(72)};
            border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
            &.last {
              border-bottom: none;
            }
            &.extra {
              border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
            }
          }
          &:last-child {
            @media ${baseVariables.mediaQuery.md} {
              a {
                border-bottom: ${toRem(0)} solid ${baseVariables.color.neutral30};
              }
            }
          }
          &:hover,
          &:focus {
            outline: ${toRem(1)} solid ${baseVariables.color.neutral30};
            background-color: ${baseVariables.color.neutral10};
            .arrow {
              visibility: visible;
            }
            .local-services-content-options-item-link {
              border-bottom: none;
              &-text {
                font-weight: ${baseVariables.font.fontWeightMedium};
              }
            }
          }
        }
      }
    }
    .mdc-dialog {
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 150;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: none;
      display: block;
      min-height: 100%;
      transform: none;
      opacity: 1;
      pointer-events: none;
      position: fixed;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      &__scrim {
        position: fixed;
        pointer-events: auto;
      }
    }

    .ppv5modal {
      &__container {
        position: relative;
        overflow: visible;
        min-width: ${toRem(280)};
        box-sizing: border-box;
        pointer-events: auto;
        border: 0 solid ${baseVariables.color.base10};
        @media ${baseVariables.mediaQuery.md} {
          &-dining.col-md-10 {
            flex: 0 0 83.3333333333%;
            max-width: 83.3333333333%;
          }
        }
        @media ${baseVariables.mediaQuery.xl} {
          &-dining.col-xl-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
          }
        }
      }
      &__header {
        border: ${toRem(1)} solid ${baseVariables.color.base10};
        h2 {
          height: ${toRem(20)};
        }
      }
      &__close-btn {
        margin-right: 0 !important;
      }
    }
  }
  `}
`;
