import React, { FC } from 'react';
import { DateBlockProps } from './DateBlock.types';
import { StyledDateBlock, StyledDate } from './DateBlock.styles';
import { Eyebrow, Text, Types } from '@marriott/mi-ui-library';

export const DateBlock: FC<DateBlockProps> = props => {
  const { arrivalDateField, departureDateField, cutOffDateField, className, variant = 'compact' } = props;
  return (
    <StyledDateBlock className={`d-flex flex-column flex-md-row justify-content-between ${className}`}>
      <StyledDate className={`${variant === 'regular' ? 'pr-3' : 'pr-1'}`}>
        <Eyebrow text={arrivalDateField.label} />
        <Text
          customClass="text-nowrap"
          copyText={arrivalDateField.value}
          fontSize={Types.size.medium}
          element={Types.tags.paragraph}
        />
      </StyledDate>
      <StyledDate className={`${variant === 'regular' ? 'pr-3' : 'pr-1'} ml-md-3`}>
        <Eyebrow text={departureDateField.label} />
        <Text
          customClass="text-nowrap"
          copyText={departureDateField.value}
          fontSize={Types.size.medium}
          element={Types.tags.paragraph}
        />
      </StyledDate>
      <StyledDate className={`${variant === 'regular' ? 'pr-3' : 'pr-1'} ml-md-3`}>
        <Eyebrow text={cutOffDateField.label} />
        <Text
          customClass="text-nowrap"
          copyText={cutOffDateField.value}
          fontSize={Types.size.medium}
          element={Types.tags.paragraph}
        />
      </StyledDate>
    </StyledDateBlock>
  );
};
