import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledDateBlock = styled.div`
  gap: ${toRem(16)};
  @media ${baseVariables.mediaQuery.md} {
    gap: 0;
    flex: 0 0 70%;
  }
  @media ${baseVariables.mediaQuery.lg} {
    flex: 0 0 60%;
  }
`;

export const StyledDate = styled.div`
  flex: 0 0 33.33%;
  margin-right: 0;
  @media ${baseVariables.mediaQuery.md} {
    border-right: ${toRem(1)} solid;
    flex: 0 0 calc(33.33% - ${toRem(20)});
    margin-right: ${toRem(20)};
  }
  & > span {
    display: block;
    white-space: nowrap;
  }
  &:last-child {
    border-right: none;
  }
`;
