import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import { SubmitRfpResponse, AddressType } from '@marriott/mi-groups-graphql';
import { EventProfileFormData } from '../organisms/EventProfile/EventProfile.types';
import { EventDetailsFormData } from '../organisms/EventDetails/EventDetails.types';
import { ContactInfoFormData } from '../organisms/ContactInfo/ContactInfo.types';
import { IntlRfpFormData } from '../organisms/IntlRfpEventInfo/IntlRfpEventInfo.types';
import { RfpSessionInput } from '../organisms/RfpTray/RfpTray.types';
import { RfpPageIdentifier } from '../organisms/RfpHeader/RfpHeader.types';

type RfpState = {
  eventProfileData: EventProfileFormData;
  eventDetailsData: EventDetailsFormData;
  eventSpaceConfigurationMap: Record<string, string>;
  contactInfoData: ContactInfoFormData;
  isCustomerInfoFetched: boolean;
  eventInfoData: IntlRfpFormData;
  rfpData: SubmitRfpResponse;
  rfpSessionData: RfpSessionInput;
  pageVisited: {
    [RfpPageIdentifier.EVENT_PROFILE]: boolean;
    [RfpPageIdentifier.EVENT_DETAILS]: boolean;
    [RfpPageIdentifier.EVENT_CONTACT]: boolean;
  };
};

type RfpActions = {
  setEventProfileData: (data: EventProfileFormData) => void;
  setEventDetailsData: (data: EventDetailsFormData) => void;
  setEventSpaceConfigurationMap: (data: Record<string, string>) => void;
  getEventSpaceConfiguration: (key: string) => string;
  setContactInfoData: (data: ContactInfoFormData) => void;
  setIsCustomerInfoFetched: (fetched: boolean) => void;
  setEventInfoData: (data: IntlRfpFormData) => void;
  setRfpData: (data: SubmitRfpResponse) => void;
  setRfpSessionData: (data: RfpSessionInput) => void;
  setPageVisited: (pageIdentifier: RfpPageIdentifier, visited: boolean) => void;
};

const eventProfileInitialData = {
  eventName: '',
  dates: '',
  startDate: '',
  endDate: '',
  flexibleDates: { code: '', label: '', selected: true },
  eventType: { code: '', label: '', selected: true },
  otherEventType: '',
  guestRooms: '',
  attendees: '',
  files: [],
};

const contactInfoInitialData = {
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    callingCode: {
      code: '',
      label: '',
      description: '',
      value: '',
      shortDescription: '',
    },
    phoneNumber: '',
    rewardId: '',
  },
  addressInfo: {
    addressType: AddressType.NONE,
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    country: {
      code: '',
      label: '',
      description: '',
      value: '',
    },
    city: '',
    state: '',
    zipcode: '',
  },
  additionalInfo: '',
};

const initialState: RfpState = {
  eventProfileData: eventProfileInitialData,
  eventDetailsData: {
    days: [],
    eventSpaces: [],
  },
  eventSpaceConfigurationMap: {},
  contactInfoData: {
    ...contactInfoInitialData,
    inlineEnrolment: {
      joinMarriottBonvoy: false,
      createPassword: '',
      confirmPassword: '',
      rememberMe: false,
      receiveUpdates: false,
      receiveOffers: false,
    },
    gsoInfo: {
      name: '',
      email: '',
    },
  },
  isCustomerInfoFetched: false,
  eventInfoData: {
    ...eventProfileInitialData,
    currency: {
      label: '',
      code: '',
      selected: false,
    },
    budget: '',
    ...contactInfoInitialData,
  },
  rfpData: { rfpId: '', rewardsId: '', enrollmentStatus: null },
  rfpSessionData: {
    AriesGroupSearch: {
      groupPropertyRecordsCount: 0,
      groupSearchCriteria: {
        address: {
          latitude: '',
          longitude: '',
          destination: '',
          destinationAddressMainText: '',
          city: '',
          stateProvince: '',
          country: '',
        },
        checkInDate: '',
        checkOutDate: '',
        isFlexibleDate: '',
        eventSearchType: '',
        guestRoomCount: 0,
        sizeLargestMeetingRoom: 0,
        roomsOnlySelected: false,
      },
      isGroupSearch: false,
      isQuickGroupEnabled: false,
      rfpShortlistedMarshaCodes: {},
      localeForERFP: '',
    },
  },
  pageVisited: {
    [RfpPageIdentifier.EVENT_PROFILE]: false,
    [RfpPageIdentifier.EVENT_DETAILS]: false,
    [RfpPageIdentifier.EVENT_CONTACT]: false,
  },
};

export const RfpStore: StateCreator<RfpState & RfpActions> = (set, get) => {
  return {
    ...initialState,

    setEventProfileData: (data: EventProfileFormData) => {
      set({ eventProfileData: { ...data } });
    },

    setEventDetailsData: (data: EventDetailsFormData) => {
      set({ eventDetailsData: { ...data } });
    },

    setEventSpaceConfigurationMap: (data: Record<string, string>) => {
      set({ eventSpaceConfigurationMap: data });
    },

    getEventSpaceConfiguration: (key: string) => {
      const { eventSpaceConfigurationMap } = get();
      return eventSpaceConfigurationMap[key] || '';
    },

    setContactInfoData: (data: ContactInfoFormData) => {
      set({ contactInfoData: { ...data } });
    },

    setIsCustomerInfoFetched: (fetched: boolean) => {
      set({ isCustomerInfoFetched: fetched });
    },

    setEventInfoData: (data: IntlRfpFormData) => {
      set({ eventInfoData: { ...data } });
    },

    setRfpData: (data: SubmitRfpResponse) => {
      set({ rfpData: { ...data } });
    },

    setRfpSessionData: (sessionData: RfpSessionInput) => {
      set({ rfpSessionData: { ...sessionData } });
    },

    setPageVisited: (pageIdentifier: RfpPageIdentifier, visited: boolean) => {
      const { pageVisited } = get();
      set({ pageVisited: { ...pageVisited, [pageIdentifier]: visited } });
    },
  };
};

export const useRfpStore = createAppStore(RfpStore);
