// Styles for __name__ go here.
import styled, { createGlobalStyle } from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledNavigationTab = styled.div`
  border: 0;
  border-top: ${toRem(1)};
  border-bottom: ${toRem(1)};
  border-style: solid;
  border-color: ${baseVariables.color.neutral20};
  background-color: ${baseVariables.color.base20};

  .m-standard-tab-list {
    border: none;
  }
`;

export const StyledNavigationTabMobile = styled.div<{
  showNavbar: boolean;
}>`
  height: ${props => (props?.showNavbar ? '100vh' : 'auto')};
  background-color: ${baseVariables.color.base20};

  .mobile {
    &__title-container {
      border: 0;
      border-top: ${toRem(1)};
      border-bottom: ${toRem(1)};
      border-style: solid;
      border-color: ${baseVariables.color.neutral20};
    }

    &__list-style {
      list-style-type: none;
    }

    &__list-item-style {
      padding: ${toRem(12)} 0;
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral20};
    }

    &__hamburger-button {
      background: none;

      span {
        font-size: ${toRem(24)};
      }
    }
  }
`;

export const CreateNavBarOverlay = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
