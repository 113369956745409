import styled from 'styled-components';

export const StyledEventType = styled.div`
  .radio-controls-list__radio {
    padding-left: 0;
    label {
      width: fit-content;
    }
  }
`;
