/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useState } from 'react';
import Glide from '@glidejs/glide';
import clsx from 'clsx';
import { StyledCardCarousel } from './CardCarouselContainer.styles';
import { ResponsiveGrid, EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, Eyebrow, Text, Types } from '@marriott/mi-ui-library';
import { CardVertical } from '../CardVertical';
import { CardLayered } from '../CardLayered';
import { CardCarouselProps } from './CardCarouselContainer.types';
import { HomePageEditorial } from '../../organisms/HomePageEditorial';
import { MAX_OFFER_CARDS_HOMEPAGE } from '../../modules/utils/constants';
import { getDirection } from '../../modules/utils/helpers';

const { NEXT_PUBLIC_AEM_SITE } = process.env;
const CardCarouselContainer: FC<CardCarouselProps> = (props: CardCarouselProps) => {
  const {
    titleText,
    subTitleText,
    ctaLabel,
    ctaLink,
    noOfCards,
    cardTypes,
    eyebrow,
    ctaType,
    cardCount,
    openInaNewTab,
    totalNumberOfCards,
    trackingProperties,
    cqItems,
    componentId,
    isAuthorMode,
    noOfCardsTablet,
    itemPath,
    pagePath,
  } = props;
  const [cardsPerView, setCardsPerView] = useState(noOfCards);

  const handleResize = useCallback(() => {
    const xs = window.matchMedia('(max-width: 575px)');
    const sm = window.matchMedia('(min-width:576px) and (max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const lg = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
    const xl = window.matchMedia('(min-width: 1200px)');
    if (xs?.matches) {
      if (cardTypes === 'cardlayered') setCardsPerView(2);
      else if (cardTypes === 'cardvertical') setCardsPerView(1);
      else setCardsPerView(1);
    } else if (sm?.matches) {
      if (cardTypes === 'cardlayered') setCardsPerView(3);
      else if (cardTypes === 'cardvertical') setCardsPerView(1);
      else setCardsPerView(noOfCardsTablet);
    } else if (md?.matches) {
      if (cardTypes === 'cardlayered') setCardsPerView(noOfCardsTablet);
      else if (cardTypes === 'cardvertical') setCardsPerView(noOfCardsTablet);
      else setCardsPerView(noOfCards);
    } else if (lg?.matches) {
      setCardsPerView(noOfCards);
    } else if (xl?.matches) {
      setCardsPerView(noOfCards);
    }
  }, [cardTypes, noOfCards, noOfCardsTablet]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const updateStyleComponent = document.getElementById(`${componentId}__slides`);
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            updateStyleComponent?.classList.add('glide__slides_authoring');
          } else if (currWcmMode?.includes('Preview')) {
            updateStyleComponent?.classList.remove('glide__slides_authoring');
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });
    handleResize();

    return () => {
      observer.disconnect();
    };
  }, [componentId, handleResize]);

  const ArrowDisabler = useCallback(
    (Glide: any, Components: any) => {
      return {
        mount() {
          // Only in effect when rewinding is disabled
          // if (Glide.settings.rewind) {
          //   return;
          // }
          Glide.on(['mount.after', 'run'], () => {
            const component = document?.getElementById(`${componentId}`);
            if (component) {
              component.style.visibility = 'visible';
            }

            // Filter out arrows_control
            for (const controlItem of Components.Controls.items) {
              if (controlItem.className !== 'glide__arrows') {
                continue;
              }

              // Set left arrow state
              const left = controlItem.querySelector('.glide__arrow--left');
              const direction = getDirection();
              if (left) {
                if (direction === 'rtl' && Glide.index === Components.Sizes.length - Glide.settings.perView) {
                  left.classList.add('.is-disabled'); // Disable on first slide
                } else if (direction === 'ltr' && Glide.index === 0) {
                  left.classList.add('.is-disabled'); // Disable on first slide
                } else {
                  left.classList.remove('.is-disabled'); // Enable on other slides
                }
              }

              // Set right arrow state
              const right = controlItem.querySelector('.glide__arrow--right');
              if (right) {
                if (direction === 'rtl' && Glide.index === 0) {
                  right.classList.add('.is-disabled'); // Disable on last slide
                } else if (direction === 'ltr' && Glide.index === Components.Sizes.length - Glide.settings.perView) {
                  right.classList.add('.is-disabled');
                } else {
                  right.classList.remove('.is-disabled'); // Disable on other slides
                }
              }
            }
          });
        },
      };
    },
    [componentId]
  );

  useEffect(() => {
    const carouselRootSelector = `#${componentId}`;
    const direction = getDirection();
    if (document?.querySelector(carouselRootSelector)) {
      try {
        if (cardTypes === 'cardlayered') {
          componentId &&
            new Glide(carouselRootSelector, {
              type: 'slider',
              bound: true,
              perView: noOfCards,
              peek: { before: 0, after: 0 },
              startAt: 0,
              rewind: direction === 'rtl' ? true : false,
              gap: 16,
              dragThreshold: false,
              direction: direction,
              breakpoints: {
                1200: {
                  perView: 5,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
                992: {
                  perView: noOfCardsTablet,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
                768: {
                  perView: 3,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
                575: {
                  perView: 3,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
                450: {
                  perView: 2,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
              },
              keyboard: false,
            }).mount({ ArrowDisabler });
        } else if (cardTypes === 'cardvertical') {
          componentId &&
            new Glide(carouselRootSelector, {
              type: 'slider',
              bound: true,
              perView: noOfCards,
              startAt: 0,
              rewind: direction === 'rtl' ? true : false,
              gap: 16,
              direction: direction,
              dragThreshold: false,
              breakpoints: {
                1200: {
                  perView: noOfCards,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
                992: {
                  perView: noOfCardsTablet,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
                768: {
                  perView: 1.25,
                  peek: {
                    before: 0,
                    after: 0,
                  },
                },
              },
              keyboard: false,
            }).mount({ ArrowDisabler });
        } else {
          setCardsPerView(1);
          componentId &&
            new Glide(carouselRootSelector, {
              type: 'slider',
              gap: 0,
              startAt: 0,
              focusAt: 'center',
              autoplay: false,
              bound: false,
              rewind: direction === 'rtl' ? true : false,
              direction: direction,
              dragThreshold: false,
              perView: 1,
              keyboard: false,
            }).mount({ ArrowDisabler });
        }

        document
          .querySelector(`#${componentId}_left_arrow`)
          ?.addEventListener('touchstart', (e: any) => e.preventDefault());
        document
          .querySelector(`#${componentId}_right_arrow`)
          ?.addEventListener('touchstart', (e: any) => e.preventDefault());
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
  }, [isAuthorMode, componentId, cardsPerView, cardTypes, noOfCards, noOfCardsTablet, ArrowDisabler]);

  const buttonCallback = () => {
    window.open(ctaLink, openInaNewTab ? '_blank' : '_self');
  };

  // Author Card
  const cardContainer = (index: number) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // Publish Card
  const cardComponent = (cardName: string, jsonData: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const item = cardItems[itemKey];
          if (cardTypes === 'cardlayered') {
            return (
              <li className="container p-0 m-0" key={`${cardName}-${itemKey}`}>
                <CardLayered
                  cardLayeredVariations={item.cardLayeredVariations}
                  eyebrow={item?.eyebrow}
                  header={item.header}
                  headerTag={item.headerTag}
                  badgeText={item.badgeText}
                  ctaLink={item.ctaLink}
                  badgeIcon={item.badgeIcon}
                  icon={item.icon}
                  dynamicMedia={item.dynamicMedia}
                  dynamicMediaTall={item.dynamicMediaTall}
                  openInNewTab={item.openInNewTab}
                  trackingProperties={item.trackingProperties}
                  fontSize={item.fontSize}
                  styleclass={item?.styleclass}
                />
              </li>
            );
          } else if (cardTypes === 'cardvertical') {
            return (
              <li className="container p-0" key={`${cardName}-${itemKey}`}>
                <CardVertical
                  cardVerticalVariations={item.cardVerticalVariations}
                  eyebrow={item?.eyebrow}
                  header={item.header}
                  subheader={item.subheader}
                  headerTag={item.headerTag}
                  ctaLink={item.ctaLink}
                  icon={item.icon}
                  dynamicMedia={item.dynamicMedia}
                  openInNewTab={item.openInNewTab}
                  trackingProperties={item.trackingProperties}
                  styleclass={item.styleclass || Types.Themes.Standard}
                  ctaLinkText={item.ctaLinkText}
                  description={item.description}
                />
              </li>
            );
          } else if (cardTypes === 'editorialfeature') {
            return (
              <li className="home-page-container p-0" key={`${cardName}-${itemKey}`}>
                <HomePageEditorial {...item} />
              </li>
            );
          }
          // }
          return null;
        }
      }
    }
    return null;
  };

  return (
    <StyledCardCarousel
      data-component-name="m-rnb-CardCarouselContainer"
      data-testid="rnb-CardCarouselContainer"
      cardType={cardTypes}
      className={clsx(
        {
          'm-container-fullbleed': props?.styleclass?.includes('fullbleed'),
        },
        props?.styleclass || 'inverse'
      )}
    >
      <div
        className={clsx(
          cardTypes === 'cardlayered' || cardTypes === 'cardvertical' ? 'container' : 'editorial-container'
        )}
      >
        <div className={cardTypes === 'cardlayered' || cardTypes === 'cardvertical' ? 'carousal_header' : 'd-none'}>
          <div>
            {titleText && <Eyebrow text={titleText} />}
            {subTitleText && (
              <Text
                copyText={subTitleText}
                fontSize={Types.size.medium}
                element={Types.tags.span}
                customClass="d-block"
              />
            )}
            {eyebrow && <Eyebrow text={eyebrow} />}
          </div>
          {ctaLabel && (
            <div className={clsx('carousal_header--cta')}>
              <Button
                href={ctaLink}
                isLink={ctaType === 'tertiaryLink'}
                target={openInaNewTab ? '_blank' : '_self'}
                callback={buttonCallback}
                className={[
                  '',
                  ctaType === 'primaryButton' ? 'm-button-primary' : '',
                  ctaType === 'secondaryButton' ? 'm-button-secondary' : '',
                  ctaType === 'tertiaryLink' ? 'm-link-tertiary-button' : '',
                  openInaNewTab
                    ? ctaType === 'primaryButton' || ctaType === 'secondaryButton'
                      ? 'm-button-external'
                      : 'm-link-tertiary-button-external'
                    : '',
                ]}
                trackingProperties={trackingProperties}
                buttonCopy={ctaLabel}
                isTextBeforeChildren={false}
              />
            </div>
          )}
        </div>
        <div
          id={componentId}
          className={clsx('glide', cardTypes === 'editorialfeature' ? 'editorial-container ' : '')}
          style={{ visibility: 'hidden' }}
        >
          <div className={clsx('glide__track')} data-glide-el="track">
            <ul
              className={clsx(
                'glide__slides d-flex list-container pl-0 list-unstyled',
                cardTypes === 'editorialfeature' ? 'p-0' : 'pb-4',
                cardTypes === 'cardlayered' ? 'card-layered-slides' : ''
              )}
              id={`${componentId}__slides`}
            >
              {!isAuthorMode && cardTypes === 'cardvertical'
                ? totalNumberOfCards
                    ?.slice(0, MAX_OFFER_CARDS_HOMEPAGE)
                    ?.map((cardName: string) => cardComponent(cardName, cqItems))
                : totalNumberOfCards.map((cardName: string) => cardComponent(cardName, cqItems))}
              {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => cardContainer(i))}
            </ul>
          </div>
          {cardCount !== cardsPerView && (
            <div className={clsx('center-align', cardTypes === 'editorialfeature' ? 'editorial-carousal' : '')}>
              <div
                className={clsx(
                  cardTypes === 'editorialfeature' ? 'carouselControlType2 inverse' : 'carouselControlType1'
                )}
              >
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('left-arrow', 'glide__arrow--left')}
                    id={`${componentId}_left_arrow`}
                    data-glide-dir="<"
                    data-content="Previous"
                    aria-label="Previous"
                  >
                    <span className="icon-arrow-left" data-id="left"></span>
                  </button>
                </div>
                <div data-glide-el="controls[nav]">
                  {Array.from(
                    {
                      length: cardCount - (cardsPerView - 1),
                    },
                    (_, i) => (
                      <button
                        className=""
                        data-glide-dir={i}
                        key={cardsPerView + i}
                        aria-label={`glide-bullet-${i}`}
                      ></button>
                    )
                  )}
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('right-arrow', 'glide__arrow--right')}
                    id={`${componentId}_right_arrow`}
                    data-glide-dir=">"
                    data-content="Next"
                    aria-label="Next"
                  >
                    <span className="icon-arrow-right" data-id="right"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledCardCarousel>
  );
};

const CardCarouselContainerConfig = {
  emptyLabel: 'cardcarouselcontainer',
  isEmpty: () => true,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/cardcarouselcontainer`,
};

const CardCarouselContainerEditable: FC<any> = props => (
  <EditableComponent config={CardCarouselContainerConfig} {...props}>
    <CardCarouselContainer {...props} />
  </EditableComponent>
);

export { CardCarouselContainer, CardCarouselContainerEditable };
