import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../../modules/utils/constants/constants';

export const StyledStepSection = styled.div`
  flex-basis: 100%;
  text-align: left;

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
      text-align: right;
    }`}

  .step-section {
    &__icon {
      font-size: ${toRem(32)};
      margin-bottom: ${toRem(12)};
    }
  }
`;
