import { FC, useContext } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OutletPrimaryDetails } from './OutletPrimaryDetails/index';
import { OutletAdditionalDetails } from './OutletAdditionalDetails/index';
import { OutletMainContainer, StyledOutletContainer, StyledOutletInfo } from './OutletDetails.styles';
import { OutletDetailsProps } from './index';
import { OutletTab } from './OutletTab/index';
import { OutletAuxiliarySection } from './OutletAuxiliarySection/index';
import { PageParamsContext } from '../../modules/context';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { outletDetailsState, useStore } from '../../modules/store/outletDetailsStore';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const OutletDetails: FC<OutletDetailsProps> = ({ ...props }) => {
  const outletData = useStore((state: outletDetailsState) => state.outletData);
  const outletDataError = useStore((state: outletDetailsState) => state.outletDataError);
  const { userId } = useContext(PageParamsContext);

  const basicProps = {
    outletDataProps: outletData,
    propDetails: props,
    hoursLabel: props?.hoursLabel,
    trackingProperties: props?.trackingProperties,
    iconPath: props?.iconPath,
    experiencesLabel: props?.experiencesLabel,
    iconPathExperiences: props?.iconPathExperiences,
  };

  const unSignedUserProps = {
    ...basicProps,
    BonvoyBenefitsLabel: props?.header,
    BonvoyBenefitsDescription: props?.bonvoyDescription,
    BonvoyBenefitsLabelCtaLink: props?.ctaLink,
    BonvoyBenefitsLabelCtaText: props?.ctaText,
  };

  const signedUserProps = {
    ...basicProps,
    BonvoyBenefitsLabel: props?.headerSignIn,
    BonvoyBenefitsDescription: props?.bonvoyDescriptionSignIn,
    BonvoyBenefitsLabelCtaLink: props?.ctaLinkSignIn,
    BonvoyBenefitsLabelCtaText: props?.ctaTextSignIn,
  };

  const outletAuxiliaryProps = userId ? signedUserProps : unSignedUserProps;
  return (
    outletData &&
    !outletDataError && (
      <OutletMainContainer
        data-component-name="o-rnb-outletinfo"
        data-testid="outletinfo"
        className={'outlet-details-container tranform-outlet mx-md-0'}
      >
        <div className="container">
          <StyledOutletContainer className="d-flex flex-column">
            <OutletPrimaryDetails outletDetails={outletData} {...props?.model} />
            <StyledOutletInfo className="pb-5 pb-md-0 pd-lg-0 pb-xl-0">
              <OutletTab outletDetails={outletData} {...props?.model} />
              <OutletAdditionalDetails outletData={outletData} {...props?.model} />
            </StyledOutletInfo>
            <OutletAuxiliarySection {...outletAuxiliaryProps} />
          </StyledOutletContainer>
        </div>
      </OutletMainContainer>
    )
  );
};

export const OutletDetailsConfig = {
  emptyLabel: 'OutletDetails',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/outletinfo`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OutletDetailsEditable = (props: any) => (
  <EditableComponent config={OutletDetailsConfig} {...props}>
    <OutletDetails {...props} />
  </EditableComponent>
);
