import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, mediaQuery } = baseVariables;

export const StyledAttachments = styled.div`
  .add-attachment {
    display: flex;
    flex-direction: column;

    .add-attachment-cta {
      font-size: ${toRem(13)};
      font-weight: 500;
    }

    .attachment-size {
      text-align: center;
      margin-top: ${toRem(16)};
      font-size: ${toRem(14)};
    }
  }

  .attachment-list {
    width: 100%;

    &-header,
    &-item {
      display: grid;
      grid-template-columns: 2fr 1fr;

      .file-name {
        text-align: left;
        font-size: ${toRem(16)};
        word-break: break-word;
        padding-right: ${toRem(16)};
      }

      .file-size {
        display: flex;
        justify-content: space-between;
        text-align: left;
        align-items: center;
      }
    }

    &-body {
      border-top: ${toRem(1)} solid ${color.neutral40};

      .delete-file {
        background-color: white;
        font-size: ${toRem(20)};
        margin: ${toRem(-4)} ${toRem(6)} 0 ${toRem(6)};
        padding: 0;
        &:focus-visible {
          outline: -webkit-focus-ring-color auto ${toRem(1)};
        }
      }
    }
  }

  @media ${mediaQuery.md} {
    .add-attachment {
      flex-direction: row;
      align-items: flex-end;

      .attachment-size {
        margin-left: ${toRem(48)};
      }
    }

    .attachment-list {
      width: 90%;

      &-header,
      &-item {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media ${mediaQuery.lg} {
    .attachment-list {
      width: 70%;
    }
  }
`;
