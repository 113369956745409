import { FC } from 'react';
import clsx from 'clsx';
import { EstimatedTotalProps } from './EstimatedTotal.types';
import { StyledEstimatedTotal } from './EstimatedTotal.styles';
import { Heading, Types, Text } from '@marriott/mi-ui-library';
import { formatAmount } from '../../utils/currency';

export const EstimatedTotal: FC<EstimatedTotalProps> = ({ labels, currency, amounts }) => {
  return (
    <StyledEstimatedTotal data-component-name="m-groups-EstimatedTotal" data-testid="groups-EstimatedTotal">
      <div className="row">
        <div className="col-md-7">
          <Heading
            variation={Types.headingType.subtitle}
            element={Types.tags.div}
            titleText={labels.title}
            fontSize={Types.size.large}
          />
          <Text element={Types.tags.div} copyText={labels.description} fontSize={Types.size.small} />
        </div>
        <div className="col-md-5 pt-3 pt-md-0 estimated-total">
          <Text
            element={Types.tags.span}
            copyText={formatAmount(amounts[0] + amounts[1])}
            fontSize={Types.size.large}
            customClass="t-subtitle-xl"
          />
          <Text
            element={Types.tags.span}
            copyText={currency}
            fontSize={Types.size.extraSmall}
            customClass="ml-1 mt-1 currency"
          />
        </div>
      </div>
      {amounts[0] && amounts[1]
        ? amounts.map((amount, index) => (
            <div className={clsx('row', index === 0 ? 'mt-4' : 'mt-1')}>
              <div className="col-7">
                <Text
                  element={Types.tags.span}
                  copyText={index === 0 ? labels.totalForGuestRooms : labels.totalForEventSpace}
                  fontSize={Types.size.small}
                />
              </div>
              <div className="col-5 estimated-total">
                <Text
                  element={Types.tags.span}
                  copyText={formatAmount(amount)}
                  fontSize={Types.size.small}
                  customClass="amount"
                />
                <Text
                  element={Types.tags.span}
                  copyText={currency}
                  fontSize={Types.size.extraSmall}
                  customClass="ml-1 currency"
                />
              </div>
            </div>
          ))
        : null}
    </StyledEstimatedTotal>
  );
};
