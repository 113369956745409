import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

const { color, mediaQuery } = baseVariables;

export const StyledProgressTracker = styled.div`
  .progress-tracker-item:not(.completed):not(.active) .progress-tracker-counter {
    background-color: ${color.neutral40};
  }
  .progress-tracker-label {
    @media ${mediaQuery.lg} {
      white-space: nowrap;
    }
  }
`;
