import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import { Types, Badge, Button, Heading, Icon, Image } from '@marriott/mi-ui-library';
import { CardLayeredProps, CardLayeredVariations } from './CardLayered.types';
import { StyledCardLayered } from './CardLayered.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const CardLayered: React.FC<CardLayeredProps> = props => {
  const {
    rel,
    badgeText,
    ctaLink,
    header,
    headerTag,
    cardLayeredVariations,
    badgeIcon,
    icon,
    fontSize,
    trackingProperties,
    dynamicMedia,
    dynamicMediaTall,
    dynamicMediaTallOneByTwo,
    dynamicMediaWide,
    openInNewTab,
    styleclass,
  } = props;

  const renditions =
    cardLayeredVariations === CardLayeredVariations.Wide
      ? dynamicMediaWide?.renditions
      : cardLayeredVariations === CardLayeredVariations.Tall
      ? dynamicMediaTall?.renditions
      : cardLayeredVariations === CardLayeredVariations.TallOneByTwo
      ? dynamicMediaTallOneByTwo?.renditions
      : dynamicMedia?.renditions;

  const altText =
    cardLayeredVariations === CardLayeredVariations.Wide
      ? dynamicMediaWide?.altText
      : cardLayeredVariations === CardLayeredVariations.Tall
      ? dynamicMediaTall?.altText
      : cardLayeredVariations === CardLayeredVariations.TallOneByTwo
      ? dynamicMediaTallOneByTwo?.altText
      : dynamicMedia?.altText;

  const assetPath =
    cardLayeredVariations === CardLayeredVariations.Wide
      ? dynamicMediaWide?.assetPath
      : cardLayeredVariations === CardLayeredVariations.Tall
      ? dynamicMediaTall?.assetPath
      : cardLayeredVariations === CardLayeredVariations.TallOneByTwo
      ? dynamicMediaTallOneByTwo?.assetPath
      : dynamicMedia?.assetPath;

  const fontSizeTallWide =
    cardLayeredVariations === CardLayeredVariations.Wide
      ? 's'
      : cardLayeredVariations === CardLayeredVariations.Tall
      ? 'xl'
      : cardLayeredVariations === CardLayeredVariations.TallOneByTwo
      ? 'xl'
      : '';

  const CardLayeredBtnRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;

  return (
    <StyledCardLayered className="col-12 p-0" data-component-name="m-rnb-CardLayered" data-testid="rnb-CardLayered">
      <div
        className={`card-layered ${styleclass ? styleclass : ''}`}
        data-variation={cardLayeredVariations}
        data-testid="card-layered"
      >
        <Image
          title={header}
          altText={altText || header}
          renditions={renditions}
          dynamic={
            dynamicMedia?.dynamic ||
            dynamicMediaTall?.dynamic ||
            dynamicMediaTallOneByTwo?.dynamic ||
            dynamicMediaWide?.dynamic
          }
          defaultImageURL={assetPath}
          loading="eager"
        />
        <div className="card-layered__content">
          <div className={`overlay ${!badgeText ? 'justify-content-end' : ''}`}>
            {badgeText && (
              <Badge
                badgeVariation={Types.BadgeVariation.Overlay}
                badgeText={badgeText}
                badgeIcon={badgeIcon?.split(':')[1] || badgeIcon}
              />
            )}
            {icon && (
              <Button
                className={clsx('socialIcons', 'm-icon-button-primary')}
                children={<Icon iconClass={[`icon-${icon}`, 'icon-m']} ariaLabel="socialIcons" />}
                aria-label="socialIcons"
                testId="socialIcons-btn"
              />
            )}
          </div>
          <div className="card-texts p-4">
            {/* {eyebrow && <Eyebrow text={eyebrow} variation={EyebrowEnum.Overlay} />} */}
            <div className="mt-2 d-flex">
              <Heading
                element={headerTag}
                variation={Types.headingType.body}
                titleText={header || ''}
                fontSize={
                  (fontSize?.split('-')[2] || fontSize || fontSizeTallWide) as Types.size.extraLarge | Types.size.large
                }
                customClass="cardTitle col-10 p-0 m-0 "
              />
              <span className="col-2"></span>
            </div>
          </div>
        </div>
        <Button
          isLink
          href={ctaLink}
          target={openInNewTab ? '_blank' : '_self'}
          className="chevronContainer"
          rel={rel}
          ref={CardLayeredBtnRef}
          trackingProperties={trackingProperties}
          ariaLabel={header || ''}
        >
          <Icon iconClass={`icon-m ${openInNewTab === true ? 'icon-arrow-right-external' : 'icon-arrow-right'} `} />
        </Button>
      </div>
    </StyledCardLayered>
  );
};

export const CardLayeredConfig = {
  emptyLabel: 'cardlayered',
  isEmpty: true,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/cardlayered`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CardLayeredEditable: FC<any> = props => (
  <EditableComponent config={CardLayeredConfig} {...props}>
    <CardLayered {...props} />
  </EditableComponent>
);
