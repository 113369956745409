export const localServicesMock = {
  diningCardLabel: 'Reservations',
  golfCtaLabel: 'Reserve Tee Time',
  diningCtaLabel: 'Make a Dining Reservation',
  heading: 'Our Services',
  diningTitle: 'Make a Dining Reservation',
  cardsCount: 0,
  diningModalTitle: 'Select a Restaurant',
  golfCardList: [],
  spaTitle: 'Book Spa Service',
  fnbTitle: 'Order Food & Beverage',
  diningReservationsCard: [],
  spaCardLabel: 'SPA',
  ':type': 'mi-aem-ppv5-spa/components/content/services-carousel',
  spaCard: [],
  golfReservationURL: '',
  closeModalTitle: 'Close Dialog',
  fnBorderingURL: 'https://www.taptastego.com/NYCMQ?lang=eng-usa',
  fnbOpenInNewTab: '_blank',
  hasCards: false,
  spaCtaLabel: 'Book Spa Service',
  golfTitle: 'Reserve Tee Time',
  golfCardLabel: 'GOLF',
  foodBeverageCard: {
    foodBeverageCtaLink: 'https://www.taptastego.com',
    foodBeverageCtaLabel: 'Order Food & Beverage',
    foodBeverageDescription:
      "See what's available from our hotel restaurants. Browse our menus, and where available, place your order or schedule one for later.",
    foodBeverageTitle: 'Browse, Tap, Eat',
    foodBeverageCardLabel: 'Ordering',
    foodBeverageImageSrc: '/content/dam/marriott/global/en/dining/merLAXMErf.1066653.jpg',
  },
  epicgolfCardList: [],
};

export const localServicesMockUXL = {
  data: {
    property: {
      restaurants: {
        edges: [
          {
            node: {
              description:
                'Savor fresh, locally-sourced food on-the-go or from the comfort of your room. All orders include eco-friendly utensils, napkins, and cups; call “Fresh Bites” from your room to place your order.',
              name: 'Fresh Bites: In-Room or On-the-Go',
              onProperty: true,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '5900776',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Bond 45 has a wide-ranging Italian menu including steak, seafood & an antipasti bar served in a sprawling space in the heart of the theater district.',
              name: 'Bond 45',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '6087094',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Settle into the heart of Times Square and experience our redesigned Broadway Lounge. Our quintessential New York restaurant serves up floor-to-ceiling views, classic cocktails, and contagious energy.',
              name: 'Broadway Lounge',
              onProperty: true,
              reservationURL: 'http://www.opentable.com/restaurant/profile/1221586/reserve?restref=1221586',
              media: {
                imageConnection: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-nycmq-broadway-lounge-67908:Classic-Hor',
                          square: '/is/image/marriotts7prod/mc-nycmq-broadway-lounge-67908:Square',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Lounge',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-2360-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-2360-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Broadway Lounge',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-6077-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-6077-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Broadway Lounge - Perch Terrace North',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/mc-nycmq-broadway-lounge-pizza-47040:Classic-Hor',
                          square: '/is/image/marriotts7prod/mc-nycmq-broadway-lounge-pizza-47040:Square',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Broadway Lounge Pizza Show Kitchen view',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-6090-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-6090-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Broadway Lounge - View',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCMQ/nycmq-lounge-0114-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-lounge-0114-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Times Square events',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                  ],
                  __typename: 'ProductImageConnection',
                },
                __typename: 'ProductMedia',
              },
              productId: '6669111',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description: 'Enjoy a variety of beverages and hot and cold food options on-the-go.',
              name: "Travel Trader's Gift Shop Cafe",
              onProperty: true,
              reservationURL: null,
              media: {
                imageConnection: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCMQ/nycmq-gift-shop-6083-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-gift-shop-6083-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: "Travel Trader's Gift Shop Cafe",
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                  ],
                  __typename: 'ProductImageConnection',
                },
                __typename: 'ProductMedia',
              },
              productId: '6783407',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Familiar, comforting and sneakily sophisticated, our new Revel & Rye Bar & Restaurant offers up an intimate dining experience tucked away from the hustle and bustle of Broadway.',
              name: 'Revel & Rye Bar and Restaurant',
              onProperty: true,
              reservationURL: 'http://www.opentable.com/restaurant/profile/1221583/reserve?restref=1221583',
              media: {
                imageConnection: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCMQ/nycmq-revel-rye-2363-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-revel-rye-2363-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Revel & Rye Bar & Restaurant',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCMQ/nycmq-revel-rye-2357-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-revel-rye-2357-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Revel & Rye - Atrium Dining',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/mc-nycmq-the-marquis-mentalist-18550:Classic-Hor',
                          square: '/is/image/marriotts7prod/mc-nycmq-the-marquis-mentalist-18550:Square',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Illusionist with floating cards and tea pot.',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-nycmq-revel-and-rye-view-90197:Classic-Hor',
                          square: '/is/image/marriotts7prod/mc-nycmq-revel-and-rye-view-90197:Square',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Revel & Rye sits under the 37 story atrium. Breakf',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-nycmq-lifestyle-31309:Classic-Hor',
                          square: '/is/image/marriotts7prod/mc-nycmq-lifestyle-31309:Square',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'breakfast, pancakes, guest, family',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                  ],
                  __typename: 'ProductImageConnection',
                },
                __typename: 'ProductMedia',
              },
              productId: '6792365',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Nestled eight floors above the heart of Times Square, enjoy exciting views of NYC from our newly redesigned outdoor terraces. The Perch Terraces at Broadway Lounge offer modern outdoor space for dining and events with sweeping views of New York City.',
              name: 'Perch Outdoor Terraces',
              onProperty: true,
              reservationURL: null,
              media: {
                imageConnection: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-6076-hor-clsc.jpg',
                          square: '/content/dam/marriott-renditions/NYCMQ/nycmq-broadway-lounge-6076-sq.jpg',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Broadway Lounge - Perch Terrace South',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                  ],
                  __typename: 'ProductImageConnection',
                },
                __typename: 'ProductMedia',
              },
              productId: '6798199',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description: "Bistro for dry-aged steaks & other French fare, with a bar that's strong on absinthe.",
              name: 'Boucherie Union Square',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '6839447',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description: 'No-frills counter-serve establishment serving New York-style slices & pies since 1975.',
              name: "Joe's Pizza",
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '6839460',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Featuring breathtaking panoramic views of Times Square, Executive Chef Anthony Paris has created a Contemporary American Grill. The restaurant is the perfect location for pre and post theater dining.',
              name: 'R Lounge at Two Times Square',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '125817',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Balthazar serves traditional bistro fare from breakfast through late-night supper. Opened by Keith McNally in the spring of 1997, Balthazar offers a French menu prepared by chefs de cuisine Riad Nasr and Lee Hanson.',
              name: 'Balthazar',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '110289',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Since the 1950’s, Junior’s has been famous for great food, great fun, great service and of course the best cheesecake in town.',
              name: 'Junior’s – Deli /American',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '110292',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'An augmented reality dining experience that immerses all the senses. Be delighted as an animated chef prepares a 4-course meal in front of your eyes. Enjoy whimsical animations showcasing the story behind each course in a unique presentation that amazes.',
              name: 'The View Restaurant  Le Petit Chef',
              onProperty: true,
              reservationURL: 'https://www.opentable.com/the-view-restaurant?page=1',
              media: {
                imageConnection: {
                  edges: [
                    {
                      node: {
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-nycmq-le-petit-chef-33669:Classic-Hor',
                          square: '/is/image/marriotts7prod/mc-nycmq-le-petit-chef-33669:Square',
                          __typename: 'ImageRendition',
                        },
                        alternateDescription: 'Le Petit Chef at The View Restaurant',
                        isPrimaryServices: false,
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                  ],
                  __typename: 'ProductImageConnection',
                },
                __typename: 'ProductMedia',
              },
              productId: '87227',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                "Located on the Ground Floor Level of the hotel, re-energize during your stay on Broadway with a visit to our on-site Starbucks®. Get your favorite beverage along with the coffee house's well-known pastries, breakfast foods and more.",
              name: 'Starbucks®',
              onProperty: true,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '77875',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Located on New York’s famed Restaurant Row in the heart of the theater district, Becco is open 7 days a week for both lunch & dinner.',
              name: 'Becco',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '110293',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Famous circa-1929 Village pizzeria slings trademark thin-crust pies in a soaring Midtown space.',
              name: "John's Pizzeria",
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '71637',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'Del Frisco’s of New York is an energetic, sprawling, three-story restaurant located in the heart of Mid-town Manhattan.',
              name: 'Del Frisco’s Double Eagle Steak House',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '71635',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'BLT Steak is our interpretation of the American Steakhouse and combines bistro ambiance with steakhouse fare.',
              name: 'BLT Steak',
              onProperty: false,
              reservationURL: null,
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '110286',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
          {
            node: {
              description:
                'After enjoying quintessential New York City fare at Revel & Rye Bar and Restaurant, experience an up close and personal show as Ken Salaz reads your mind, predicts the future and amazed all with his brilliant illusions.',
              name: 'The Marquis Mentalist - A Unique Dining Experience (Temp Closed)',
              onProperty: true,
              reservationURL: 'https://www.opentable.com/r/revel-and-rye-bar-and-restaurant-new-york',
              media: {
                imageConnection: null,
                __typename: 'ProductMedia',
              },
              productId: '6836140',
              __typename: 'Restaurant',
            },
            __typename: 'RestaurantEdge',
          },
        ],
        __typename: 'RestaurantConnection',
      },
      spa: {
        edges: [
          {
            node: {
              name: 'Allure Day Spa & Hair Design',
              description:
                'Allure Day Spa in NYC is renowned for its friendly, unpretentious staff, esthetically pleasing and soothing atmosphere, and more notably for its qualified and certified professional estheticians and therapists.',
              bookNowURL: null,
              id: 'nycmq.6755594',
              onProperty: false,
              __typename: 'PropertySpa',
            },
            __typename: 'PropertySpaEdge',
          },
        ],
        __typename: 'PropertySpaConnection',
      },
      basicInformation: {
        isOrderFoodAndBeverageByMobileSupported: true,
        __typename: 'HotelBasicInformation',
      },
      media: {
        photoGallery: {
          spa: null,
          __typename: 'PhotoGalleryImageConnection',
        },
        __typename: 'HotelMediaContent',
      },
      __typename: 'Hotel',
    },
    spa: {
      edges: [
        {
          node: {
            name: 'SAI SPA Munchen',
            description:
              'SAI SPA focuses on a classic selection of excellent massages from Europe and Asia, whose origins have been handed down for generations. All treatments are aimed at relaxing the body and allowing guests a moment of rest.',
            bookNowURL: null,
            id: 'mucwi.6497142',
            onProperty: false,
          },
        },
        {
          node: {
            name: 'ISARspa',
            description:
              'Inspired by the recreational value of the ISAR with its quiet natural reserves you will find your perfect personal time-out here.Please note kids are only allowed in the spa until 06:00pm.',
            bookNowURL: null,
            id: 'mucwi.6029909',
            onProperty: true,
          },
        },
        {
          node: {
            name: 'Medius Spa and Fitness at Four Points by Sheraton Munich Arabellapark',
            description:
              'Experience wellness and fitness from a completely new perspective at your Spa Hotel in Munich. Our 800m² large wellness area, including pool, sauna and spa awaits you on the 22nd and 23rd floor. Enjoy the unique view over the rooftops of Munich.',
            bookNowURL: null,
            id: 'mucwi.6710854',
            onProperty: false,
          },
        },
      ],
    },
    basicInformation: {
      isOrderFoodAndBeverageByMobileSupported: false,
    },
  },
};

export const localServicesMockUXLStorybook = {
  property: {
    restaurants: {
      edges: [
        {
          node: {
            description:
              'Sample Bavarian cuisine served by our friendly staff, dressed in traditional dirndl and lederhose, amidst a traditional ambiance. Enjoy a cold draught beer in Munich, Germany, in the attached beer garden, charmingly laid out with large chestnut trees.',
            name: "Paulaner's Wirtshaus & Beer Garden",
            onProperty: true,
            reservationURL: "https://www.tableresmarriott.com/paulaner's-restaurant-munich",
            productId: '6029906',
          },
        },
        {
          node: {
            description:
              "Munich's Greenhouse Restaurant offers a large, international breakfast, giving you the energy you need to start your day. Enjoy a variety of healthy dishes, along with classics such as pancakes and local specialties. The breakfast is 32€ per person.",
            name: 'Greenhouse Restaurant & Eventlocation',
            onProperty: true,
            reservationURL: null,
            productId: '6029907',
          },
        },
        {
          node: {
            description:
              'Make The Drop Botanical Bar & Eatery your all-day hotspot for homemade pastries, barista-quality coffees, healthy bowls, expertly mixed cocktails and revitalizing juices from Westin Fresh by The Juicery.',
            name: 'The Drop - Botanical Bar & Eatery',
            onProperty: true,
            reservationURL: null,
            productId: '6482848',
          },
        },
        {
          node: {
            description:
              'Rediscover the Bavarian cuisine and enjoy the flair of Munich at Schwarzreiter Tagesbar & Restaurant.',
            name: 'Schwarzreiter Tagesbar & Restaurant',
            onProperty: false,
            reservationURL: null,
            productId: '6496947',
          },
        },
        {
          node: {
            description:
              'Ristorante Cupido is an Italian gourmet restaurant in Munich. Enjoy fine Italian food and wines.',
            name: 'Ristorante Cupido',
            onProperty: false,
            reservationURL: null,
            productId: '6496948',
          },
        },
        {
          node: {
            description:
              'Experience exquisite fine French dining at Restaurant Atelier Gourmet. Our set menu is beautifully prepared, and you can choose from three, four or five course meals.',
            name: 'Restaurant Atelier Gourmet',
            onProperty: false,
            reservationURL: null,
            productId: '6498253',
          },
        },
        {
          node: {
            description:
              'Bring the whole family to visit little Italy in the heart of Munich! Enjoy pizza, pasta and other delicious Italian recipes.',
            name: 'Il Sogno',
            onProperty: false,
            reservationURL: null,
            productId: '6498254',
          },
        },
        {
          node: {
            description: 'The first Malaysian restaurant in Germany',
            name: 'Champor',
            onProperty: false,
            reservationURL: 'https://www.champor.de',
            productId: '6585764',
          },
        },
      ],
    },
    spa: {
      edges: [
        {
          node: {
            name: 'SAI SPA Munchen',
            description:
              'SAI SPA focuses on a classic selection of excellent massages from Europe and Asia, whose origins have been handed down for generations. All treatments are aimed at relaxing the body and allowing guests a moment of rest.',
            bookNowURL: null,
            id: 'mucwi.6497142',
            onProperty: false,
          },
        },
        {
          node: {
            name: 'ISARspa',
            description:
              'Inspired by the recreational value of the ISAR with its quiet natural reserves you will find your perfect personal time-out here.Please note kids are only allowed in the spa until 06:00pm.',
            bookNowURL: null,
            id: 'mucwi.6029909',
            onProperty: true,
          },
        },
        {
          node: {
            name: 'Medius Spa and Fitness at Four Points by Sheraton Munich Arabellapark',
            description:
              'Experience wellness and fitness from a completely new perspective at your Spa Hotel in Munich. Our 800m² large wellness area, including pool, sauna and spa awaits you on the 22nd and 23rd floor. Enjoy the unique view over the rooftops of Munich.',
            bookNowURL: null,
            id: 'mucwi.6710854',
            onProperty: false,
          },
        },
      ],
    },
    basicInformation: {
      isOrderFoodAndBeverageByMobileSupported: true,
    },
  },
};
