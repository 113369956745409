/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

export interface ppv5Store {
  ppv5Data: any;
  ppv5DataLoading: boolean;
  ppv5DataError: boolean;
  skipQuery: boolean;
  setPpv5Data: (data: any, loading: boolean, error: any, fallbackData: any) => void;
}

const initialState: ppv5Store = {
  ppv5Data: null,
  ppv5DataLoading: true,
  ppv5DataError: false,
  skipQuery: false,
  setPpv5Data: () => {},
};

const Ppv5Store: StateCreator<ppv5Store> = set => {
  return {
    ...initialState,
    setPpv5Data: (data, loading, error, fallbackData) => {
      if (error) {
        console.log(fallbackData);
        set({ ppv5Data: null, ppv5DataLoading: loading, ppv5DataError: true, skipQuery: true });
      } else if (data && Object.keys(data).length) {
        set({
          ppv5Data: { ...data },
          ppv5DataLoading: loading,
          ppv5DataError: false,
          skipQuery: true,
        });
      }
    },
  };
};

export const useStore = createAppStore(Ppv5Store, {
  usePersistentStore: false,
});
