/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { IMAGE_URL_DOMAIN } from '../../modules/utils/constants/constants';
import clsx from 'clsx';
import { StyledGolfLocationContainer, StyledVerticalGolfCards } from './GolfCourseLocationsContainer.styles';
import { PageParamsContext } from '../../modules/context/PageContext';
import { useApolloClient } from '@apollo/client';
import { golfCoursePropertiesDetails } from '@marriott/mi-rnb-graphql';
import Cookies from 'js-cookie';
import { golfCourseLocationsMock } from './__mocks__/mockModel';
/* eslint-disable @nx/enforce-module-boundaries */
import { PropertyCard } from '@marriott/mi-shop-components';
import { GolfCourseLocationsContainerProps } from './GolfCourseLocationsContainer.types';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const GolfCourseLocationsContainer = (props: GolfCourseLocationsContainerProps) => {
  const { currentLocale } = useContext(PageParamsContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [golfCourseLocations, setGolfCourseLocations] = useState<any>([]);
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');

  useEffect(() => {
    if (props?.isAuthorMode) {
      setGolfCourseLocations(golfCourseLocationsMock.data.searchPropertiesByDestination.edges);
    }
  }, [props?.isAuthorMode]);

  // UXL call to get Search Results
  const golfCourseLocationClient = useApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function getGolfCourseLocations(inputValues: any) {
    try {
      const { data } = await golfCourseLocationClient.query({
        fetchPolicy: 'no-cache',
        query: golfCoursePropertiesDetails,
        errorPolicy: 'all',
        ...inputValues,
      });
      if (data && data?.searchPropertiesByDestination && data?.searchPropertiesByDestination?.edges?.length > 0)
        setGolfCourseLocations(data?.searchPropertiesByDestination?.edges);
    } catch (error) {
      setGolfCourseLocations([]);
    }
  }

  useEffect(() => {
    if (!props?.isAuthorMode) {
      getGolfCourseLocations({
        variables: {
          search: {
            destination: 'United states',
            facets: {
              terms: [
                {
                  type: 'ACTIVITIES',
                  dimensions: ['golf'],
                },
              ],
            },
          },
          limit: 18,
          offset: 0,
        },
        context: {
          headers: {
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
    }
  }, [props?.isAuthorMode]);

  const getPropertyName = (propertyDetails: any) => {
    const titleDetails = {
      title: propertyDetails?.node?.basicInformation?.name,
      titleAriaLabel: propertyDetails?.node?.basicInformation?.name,
    };
    return titleDetails;
  };

  const getPropertyImages = (propertyDetails: any) => {
    const images: { defaultImageUrl: string } = {
      defaultImageUrl: '',
    };
    const courseImages = [];
    if (
      propertyDetails?.node?.media?.photoGallery?.golf &&
      propertyDetails?.node?.media?.photoGallery?.golf?.edges?.length > 0
    ) {
      propertyDetails?.node?.media?.photoGallery?.golf?.edges?.forEach((image: any) => {
        if (image?.node?.imageUrls?.classicHorizontal) {
          const imageUrl = `${IMAGE_URL_DOMAIN}${image?.node?.imageUrls?.classicHorizontal}?downsize=228:*`;
          images['defaultImageUrl'] = imageUrl;
          courseImages.push({ ...images });
        }
      });
    } else {
      const imageUrl = `${props?.dynamicMedia?.assetPath}?downsize=228:*`;
      images['defaultImageUrl'] = imageUrl;
      courseImages.push(images);
    }
    return courseImages;
  };

  const getPropertyAddress = (propertyDetails: any) => {
    if (
      propertyDetails?.node?.contactInformation &&
      propertyDetails?.node?.contactInformation?.address &&
      (propertyDetails?.node?.contactInformation?.address?.city ||
        propertyDetails?.node?.contactInformation?.address?.stateProvince?.description)
    )
      return (
        propertyDetails?.node?.contactInformation?.address?.city +
        ', ' +
        propertyDetails?.node?.contactInformation?.address?.stateProvince?.description
      );
    else return '';
  };

  const getFooterLinks = (propertyDetails: any) => {
    if (propertyDetails?.node?.id) {
      const footerlinks = {
        href: `/en-us/hotels/${propertyDetails?.node?.id?.toLowerCase()}`,
        target: `${props?.openInNewTab ? '_blank' : '_self'}`,
        rel: 'noreferrer',
        className: 'm-button-s m-button-primary custom_click_track reserve-cta-button',
        text: props?.viewHotelDetailsLabel,
        isLink: false,
      };
      return footerlinks;
    }
    return;
  };

  return golfCourseLocations && golfCourseLocations?.length > 0 ? (
    <StyledGolfLocationContainer className={clsx('pt-4 pb-5 px-0')}>
      <StyledVerticalGolfCards>
        {golfCourseLocations?.map((property: any, index: number) => {
          return (
            <PropertyCard
              titleDetails={getPropertyName(property)}
              images={getPropertyImages(property)}
              isVerticalCard={true}
              brandDetails={{
                brandId: property?.node?.basicInformation?.brand?.id,
                label: property?.node?.basicInformation?.brand?.name,
              }}
              address={getPropertyAddress(property)}
              description={property?.node?.basicInformation?.descriptions[0]?.text}
              showDescriptionAlways="true"
              footerLinkDetails={getFooterLinks(property)}
              key={`golf-location-${index}`}
            />
          );
        })}
      </StyledVerticalGolfCards>
    </StyledGolfLocationContainer>
  ) : null;
};

export const GolfCourseLocationsContainerFeatureConfig = {
  emptyLabel: 'golflocationscard',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/golflocationscard`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GolfCourseLocationsEditable = (props: any) => (
  <EditableComponent config={GolfCourseLocationsContainerFeatureConfig} {...props}>
    <GolfCourseLocationsContainer {...props} />
  </EditableComponent>
);
