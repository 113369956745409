// Imports for external libraries go here.
import { FC, useEffect, useRef } from 'react';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { ConnectContainerProps } from './ConnectContainer.types';
import { StyledConnectContainer } from './ConnectContainer.styles';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

// Use named rather than default exports.
export const ConnectContainer: FC<ConnectContainerProps> = (pageProps: ConnectContainerProps) => {
  const contentContainerRef = useRef(null);

  useEffect(() => {
    if (contentContainerRef && contentContainerRef?.current) {
      const propertyDetails = (contentContainerRef?.current as Element)?.querySelector('.chinese-separator');
      if (propertyDetails && propertyDetails?.parentElement) {
        propertyDetails?.parentElement?.remove();
      }
    }
  }, []);

  //Method for returning Responsive Grid for showing parsys in Author mode
  const ConnectContainer = () => {
    return (
      <div data-testid="connect-container" data-component-name="o-ppv5-staticwrapper">
        <StyledConnectContainer>
          <section
            dangerouslySetInnerHTML={{ __html: pageProps?.referencePath }}
            ref={contentContainerRef}
            className="authorBorder"
          />
        </StyledConnectContainer>
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL
  const ConnectContainerStaticWrapper = (props: ConnectContainerProps) => {
    const { referencePath } = props;
    return (
      <StyledConnectContainer id={pageProps?.contentID}>
        <section dangerouslySetInnerHTML={{ __html: referencePath }} ref={contentContainerRef} />
      </StyledConnectContainer>
    );
  };

  const showConnectContainer = pageProps.isAuthorMode ? (
    <div>{ConnectContainer()}</div>
  ) : (
    <div>{<ConnectContainerStaticWrapper {...pageProps} />}</div>
  );

  return <StyledConnectContainer>{showConnectContainer}</StyledConnectContainer>;
};

export const ConnectContainerConfig = {
  emptyLabel: 'ConnectContainer',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/common/staticwrapper`,
} as Config<MappedComponentProperties>;

export const ConnectContainerEditable = (props: ConnectContainerProps) => (
  <EditableComponent config={ConnectContainerConfig} {...props}>
    <ConnectContainer {...props} />
  </EditableComponent>
);
