import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { constants } from '../../modules/utils/constants/constants';
import rtl from 'styled-components-rtl';

export const StyledBonvoyMember = styled.div<{ styleClass: string }>`
  img {
    min-width: 100vw;
  }

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}){
    .bl_iconText-wrapper {
      left: ${toRem(30)};
      background: none;
    }

    .bl_content {
      text-align: right;
    }

    .banner-layerd-anchor {
      padding-right: ${toRem(8)};
    }

    .bl_sec-cta {
      margin-right: 0;

      @media ${baseVariables.mediaQuery.sm}, @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        margin-right: ${toRem(16)};
      }
    }

    .gradient {
      &:after {
        background: ${(props: { styleClass: string }) =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient05']}`
            : `${baseVariables.gradient['baseGradient01']}`};
        @media ${baseVariables.mediaQuery.sm} {
          background: ${(props: { styleClass: string }) =>
            props?.styleClass?.includes('inverse')
              ? 'linear-gradient(90deg,rgba(0,0,0,0) 26.95%,rgba(0,0,0,0.54) 67.69%,rgba(0,0,0,0.62) 100%)'
              : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 26.95%, rgba(255, 255, 255, 0.46) 66.99%, rgba(255, 255, 255, 0.54) 100%)'};
        }
        @media ${baseVariables.mediaQuery.lg} {
          background: ${(props: { styleClass: string }) =>
            props?.styleClass?.includes('inverse')
              ? 'linear-gradient(90deg,rgba(0,0,0,0) 26.95%,rgba(0,0,0,0.54) 67.69%,rgba(0,0,0,0.62) 100%)'
              : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 26.95%, rgba(255, 255, 255, 0.46) 66.99%, rgba(255, 255, 255, 0.54) 100%)'};
        }
      }
    }
    
  }
`}
`;
