import { DropdownOption } from '../../molecules/Dropdown/Dropdown.types';

export type PaymentInfoFormData = {
  paymentInfo: {
    cardType?: string;
    cardHolderName: string;
    cardNumber: string;
    expirationMonth: DropdownOption<unknown>;
    expirationYear: DropdownOption<unknown>;
    cvvNumber: string;
    tokenizedPAN?: string;
    panLast4?: string;
    isSavedCard?: boolean;
  };
};

export type PaymentInfoProps = {
  heading: string;
  descriptions: string[];
  selectCard: string;
  payUsing: string;
  enterCard: string;
  cardHolderName: InputField;
  creditDebitCardNumber: InputField;
  cardTypes: {
    imagePath: string;
  };
  expirationMonth: InputField & {
    longMonthNames: string[];
  };
  expirationYear: InputField;
  cvv: InputField;
  cvvInfo: {
    nonAmex: CvvInfo;
    amex: CvvInfo;
  };
  expandIconAriaLabel: string;
  collapseIconAriaLabel: string;
};

type InputField = {
  label: string;
  ariaLabel: string;
  defaultValue?: string;
  maxLength?: number;
  requiredError?: string;
  formatError?: string;
};

type CvvInfo = {
  instruction: string;
  imagePath: string;
  altText: string;
};

export type CreditCardInfo = {
  cardType: string;
  cardNumber: string;
  cardNumberMaxLength: number;
  cvvMaxLength: number;
};

export enum CreditCardType {
  V = 'visa',
  A = 'american-express',
  R = 'discover',
  M = 'mastercard',
  D = 'diners-club',
  J = 'jcb',
  UP = 'unionpay',
}

export type SavedCardInfo = {
  id: string;
  cardType: keyof typeof CreditCardType;
  maskedCardNumber: string;
  expirationMonth: DropdownOption<unknown>;
  expirationYear: DropdownOption<unknown>;
  tokenizedPAN: string;
  panLast4: string;
  preferred: boolean;
};
