import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOffersOverlayContent = styled.div`
  .overlay-content {
    &__image {
      border-radius: ${toRem(8)};
      aspect-ratio: 16/9;
      width: 100%;
      max-width: 100%;

      @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.lg},
        @media ${baseVariables.mediaQuery.xl} {
        aspect-ratio: 3/1;
      }
    }

    &__offer-section {
      .icon-location {
        font-size: ${toRem(20)};
      }
    }

    &__contact-section {
      margin-top: ${toRem(8)};
      row-gap: ${toRem(8)};

      @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.lg},
        @media ${baseVariables.mediaQuery.xl} {
        margin-top: ${toRem(12)};
        column-gap: ${toRem(16)};
      }
    }

    &__view-terms-cta,
    &__view-terms-cta:hover,
    &__view-terms-cta:focus,
    &__view-terms-cta:active,
    &__view-terms-cta:visited {
      background: none;
      outline: none;
      border: none;
      color: ${baseVariables.color.neutral00};

      .show-more-icon {
        font-size: ${toRem(16)};
      }
    }
  }
`;
