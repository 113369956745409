import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { baseVariables } from '@marriott/mi-ui-library';
import { useMediaQuery } from '../../hooks';
import { getFormattedDateObject, isToday, isValidNumeric, validateAttendees, validateGuestRooms } from '../../utils';
import { DEFAULT_LANG, LOCALE_MAP, NUM_REGEX } from '../../constants';
import { useLocaleStore } from '../../store';
import { TextFormField } from '../TextFormField';
import { DatePickerDesktop } from '../DatePicker/DatePickerDesktop';
import { DatePickerMobileContainer } from '../DatePicker/DatePickerMobileContainer';
import { SingleSelectList } from '../SingleSelectList';
import { Dropdown } from '../Dropdown';
import { DropdownOption } from '../Dropdown/Dropdown.types';
import { EventInfoProps, OptionType } from './EventInfo.types';
import { StyledEventInfo } from './EventInfo.styles';

export const EventInfo: FC<EventInfoProps> = props => {
  const {
    labels,
    flexibleDateOptions,
    eventTypeOptions,
    isOtherEvent,
    setIsOtherEvent,
    showBudget = false,
    control,
    getValues,
    setValue,
    errors,
    clearErrors,
  } = props;
  const { eventName, dates, flexibleDates, eventType, otherEventType, guestRooms, attendees, currency, budget } =
    labels;

  const selectedStartDate = getValues('startDate') ? getFormattedDateObject(getValues('startDate')) : null;
  const selectedEndDate = getValues('endDate') ? getFormattedDateObject(getValues('endDate')) : null;
  const currencyOptions = currency?.options.map(item => {
    return { ...item, value: item.code };
  });

  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);

  const { locale } = useLocaleStore();

  const [selectedCurrency, setSelectedCurrency] = useState('');

  useEffect(() => {
    if (showBudget) {
      const defaultCurrencyCode = LOCALE_MAP[DEFAULT_LANG]['currency'];
      const currencyCode = (LOCALE_MAP[locale] || LOCALE_MAP[DEFAULT_LANG])['currency'];
      const selectedCurrency = currencyOptions?.find(option => option.value === currencyCode)?.value;
      setSelectedCurrency(selectedCurrency || defaultCurrencyCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDatesChange = (dates: string, startDate: string, endDate: string) => {
    setValue('dates', dates);
    setValue('startDate', startDate);
    setValue('endDate', endDate);
    clearErrors('dates');
  };

  const eventNameField = (
    <Controller
      name="eventName"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={eventName.label}
          ariaLabel={eventName.ariaLabel}
          className="m-input-field"
          maxLength={eventName?.maxLength}
          showErrorMessage={Boolean(errors.eventName)}
          errorMessage={eventName.requiredError}
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const datesField = (
    <Controller
      name="dates"
      control={control}
      rules={{
        validate: () => (isToday(getValues('startDate')) ? dates.currentDateError : undefined),
      }}
      render={() => (
        <DatePickerDesktop
          dates={dates}
          align={'left'}
          showFlexibleDates={false}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          showErrorMessage={!!errors.dates}
          errorMessage={errors.dates?.message}
          onChange={handleDatesChange}
        />
      )}
    />
  );

  const mobileDatesField = (
    <Controller
      name="dates"
      control={control}
      rules={{
        validate: () => (isToday(getValues('startDate')) ? dates.currentDateError : undefined),
      }}
      render={() => (
        <DatePickerMobileContainer
          dates={dates}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          showFlexibleDates={false}
          showErrorMessage={!!errors.dates}
          errorMessage={errors.dates?.message}
          onChange={handleDatesChange}
        />
      )}
    />
  );

  const flexibleDatesField = (
    <Controller
      name="flexibleDates"
      control={control}
      render={({ field }) => (
        <SingleSelectList
          {...field}
          label={flexibleDates.label}
          placeholder={flexibleDates.select}
          ariaLabel={flexibleDates.ariaLabel}
          eyebrowText={flexibleDates.label}
          options={flexibleDateOptions}
          expandIconAriaLabel={`${flexibleDates.label} ${flexibleDates.expandIconAriaLabel}`}
          collapseIconAriaLabel={`${flexibleDates.label} ${flexibleDates.collapseIconAriaLabel}`}
          onChange={option =>
            setValue('flexibleDates', { label: option.label, code: option.code || '', selected: true })
          }
        />
      )}
    />
  );

  const eventTypeField = (
    <Controller
      name="eventType"
      control={control}
      render={({ field }) => (
        <SingleSelectList
          {...field}
          label={eventType.label}
          placeholder={eventType.select}
          ariaLabel={eventType.ariaLabel}
          eyebrowText={eventType.label}
          options={eventTypeOptions}
          expandIconAriaLabel={`${eventType.label} ${eventType.expandIconAriaLabel}`}
          collapseIconAriaLabel={`${eventType.label} ${eventType.collapseIconAriaLabel}`}
          onChange={option => {
            setValue('eventType', { label: option.label, code: option.code || '', selected: true });
            setIsOtherEvent(option.code === 'Other');
            setValue('otherEventType', '');
          }}
        />
      )}
    />
  );

  const otherEventTypeField = (
    <Controller
      name="otherEventType"
      control={control}
      rules={{ required: isOtherEvent }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={otherEventType.label}
          ariaLabel={otherEventType.ariaLabel}
          className="m-input-field"
          disabled={!isOtherEvent}
          maxLength={otherEventType.maxLength}
          showErrorMessage={isOtherEvent && !!errors.otherEventType}
          errorMessage={otherEventType.requiredError}
          testId="other-event-type"
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const guestRoomsField = (
    <Controller
      name="guestRooms"
      control={control}
      rules={{
        validate: value =>
          validateGuestRooms(
            value,
            getValues('attendees'),
            guestRooms.requiredError || '',
            guestRooms.minCountError || ''
          ),
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={guestRooms.label}
          placeholder={guestRooms.placeholder}
          ariaLabel={guestRooms.ariaLabel}
          className="m-input-field"
          showErrorMessage={!!errors.guestRooms}
          errorMessage={guestRooms.requiredError}
          onChange={event => {
            if (isValidNumeric(event.target.value)) {
              field.onChange(event);
              clearErrors(field.name);
            }
          }}
        />
      )}
    />
  );

  const attendeesField = (
    <Controller
      name="attendees"
      control={control}
      rules={{
        validate: value => validateAttendees(value, getValues('guestRooms'), attendees.requiredError || ''),
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={attendees.label}
          placeholder={attendees.placeholder}
          ariaLabel={attendees.ariaLabel}
          className="m-input-field"
          showHelperText={!!field.value && !showBudget}
          helperText={`${attendees.helperText}: ${Math.floor(+field.value * attendees.spaceMultiplier)}`}
          showErrorMessage={!!errors.attendees}
          errorMessage={attendees.requiredError}
          onChange={event => {
            if (isValidNumeric(event.target.value)) {
              field.onChange(event);
              clearErrors(field.name);
            }
          }}
        />
      )}
    />
  );

  const currencyField = (
    <Controller
      name="currency"
      control={control}
      render={({ field }) => {
        return (
          <Dropdown
            name="currency"
            label={currency?.label || ''}
            ariaLabel={currency?.ariaLabel}
            options={currencyOptions as DropdownOption<OptionType>[]}
            selectedValue={selectedCurrency}
            onChange={event => {
              field.onChange(event);
              setSelectedCurrency(event.label);
            }}
          />
        );
      }}
    />
  );

  const budgetField = (
    <Controller
      name="budget"
      control={control}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={budget?.label}
          ariaLabel={budget?.ariaLabel}
          placeholder={budget?.placeholder}
          className="m-input-field"
          maxLength={budget?.maxLength}
          onChange={event => {
            if (!event.target.value || NUM_REGEX.test(event.target.value)) {
              field.onChange(event);
            }
          }}
        />
      )}
    />
  );

  return (
    <StyledEventInfo data-component-name="m-groups-EventInfo" data-testid="groups-EventInfo" className="row">
      <div className="col-12 pb-5">{eventNameField}</div>
      <div className="col-md-6 pb-5">{isTablet ? datesField : mobileDatesField}</div>
      <div className="col-md-6 pb-5">{flexibleDatesField}</div>
      <div className="col-md-6 pb-5">{eventTypeField}</div>
      <div className="col-md-6 pb-5">{otherEventTypeField}</div>
      <div className={clsx('col-md-6 pb-5', !showBudget && 'pb-md-0')}>{guestRoomsField}</div>
      <div className={clsx('col-md-6', showBudget && 'pb-5')}>{attendeesField}</div>
      {showBudget && (
        <>
          <div className="col-md-6 pb-5 pb-md-0">{currencyField}</div>
          <div className="col-md-6">{budgetField}</div>
        </>
      )}
    </StyledEventInfo>
  );
};
