import React, { FC, useEffect, useState } from 'react';
import { EventTypeProps, OptionType } from './EventType.types';
import { Heading, RadiobuttonControlsList, Types, InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { StyledEventType } from './EventType.styles';

export const EventType: FC<EventTypeProps> = props => {
  const { labels, initialEventType, customClass = '', setValue, hasError } = props;
  const { title, options, requiredError } = labels;

  const [selectedEventType, setSelectedEventType] = useState<OptionType>({ code: '', label: '' });

  useEffect(() => {
    if (initialEventType) {
      setSelectedEventType(initialEventType);
    }
  }, [initialEventType]);

  return (
    <StyledEventType data-component-name="m-groups-EventType" data-testid="groups-EventType" className={customClass}>
      <Heading
        variation={Types.headingType.subtitle}
        element={Types.tags.h2}
        titleText={title}
        fontSize={Types.size.large}
        customClass="mb-2"
      />
      {hasError ? (
        <div className="mb-3">
          <InlineMessages type={InlineMessagesType.Error} title={requiredError} severity="1" />
        </div>
      ) : null}
      <RadiobuttonControlsList
        name="eventType"
        controls={options}
        defaultSelected={options.filter(option => option.code === selectedEventType.code)}
        colLength={12}
        tabIndexForInput={-1}
        onChange={option => {
          setValue('eventType', option[0]);
          setSelectedEventType(option[0] as OptionType);
        }}
      />
    </StyledEventType>
  );
};
