import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { mediaQuery, font } = baseVariables;

export const StyledSignInForm = styled.div`
  .form-title {
    margin-bottom: ${toRem(26)};
    font-size: ${font.fontL};
    line-height: ${toRem(22)};
    font-weight: ${font.fontWeightMedium};
  }
  .user-name {
    font-size: ${font.fontS};
  }
  .clear-user-cta {
    font-size: ${font.fontS};
    text-decoration: underline;
  }
  .forgot-password-cta {
    font-size: ${font.fontS};
    text-decoration: underline;
    margin-bottom: ${toRem(24)};
  }
  .button {
    margin-bottom: ${toRem(24)};
  }

  @media ${mediaQuery.md} {
    .form-title {
      margin-bottom: ${toRem(38)};
    }
    .forgot-password-cta {
      margin-bottom: ${toRem(14)};
    }
  }
`;
