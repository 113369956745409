/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from '@marriott/mi-ui-library';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledFaqModalWrapper } from './RnBFAQAccordionWrapper.styles';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccordionContainerEnhanced } from '@marriott/mi-common-static-components';
import { earnRedeemStore } from '../../modules/store/earnRedeemStore';
import { RnBFAQAccordionWrapperProps } from './RnBFAQAccordionWrapper.types';
const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const RnBFAQAccordionWrapper = (props: RnBFAQAccordionWrapperProps) => {
  const setShowFaqModal = earnRedeemStore((state: earnRedeemStore) => state?.setShowFaqModal);
  const showFaqModal = earnRedeemStore((state: earnRedeemStore) => state?.showFaqModal) || false;
  const faqModelHeader = earnRedeemStore((state: earnRedeemStore) => state?.faqModelHeader);
  const modelprop = props?.cqItems?.['card-0']?.[':items']?.accordioncontainerenhanced || {};
  const updatedProp = Object.assign({}, modelprop, {
    cqItems: modelprop?.[':items'],
    cqItemsOrder: modelprop?.[':itemsOrder'],
  });

  return showFaqModal ? (
    <StyledFaqModalWrapper data-component-name="m-rnb-RnBFAQAccordionWrapper" data-testid="rnb-RnBFAQAccordionWrapper">
      <Modal
        show={showFaqModal}
        handleBlur={true}
        popupOpenState={showFaqModal}
        allFilterPopUp={true}
        onClose={(): void => {
          setShowFaqModal(false);
        }}
      >
        <Modal.Header
          className="modal-header t-subtitle-xl modal-accordion-header"
          labelText={faqModelHeader}
          popupHeaderOnCLoseFunc={(): void => {
            setShowFaqModal(false);
          }}
        />

        <Modal.Body className="accordion-body custom-scrollbar">
          <AccordionContainerEnhanced model={updatedProp} allowedComponents={props?.allowedComponents} />
        </Modal.Body>
      </Modal>
    </StyledFaqModalWrapper>
  ) : null;
};

export const RnBFAQAccordionWrapperConfig = {
  emptyLabel: 'RnBFAQAccordionWrapper',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/rnbaccordionenhanced`,
};

export const RnBFAQAccordionWrapperEditable = (props: any) => (
  <EditableComponent config={RnBFAQAccordionWrapperConfig} {...props}>
    <RnBFAQAccordionWrapper {...props} />
  </EditableComponent>
);
