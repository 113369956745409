import { FC } from 'react';
import { AddressType } from '@marriott/mi-groups-graphql';
import { Accordion, Eyebrow, Icon, Text, Types } from '@marriott/mi-ui-library';
import { BillingAndContactData } from '../../store';
import { TextBlock } from '../../molecules';
import { ContactInfoSummaryProps } from './ContactInfoSummary.types';
import { ContactInfoFormData } from '../../organisms/ContactInfo/ContactInfo.types';
import { StyledContactInfoSummary } from './ContactInfoSummary.styles';

export const ContactInfoSummary: FC<ContactInfoSummaryProps> = props => {
  const { labels, data } = props;
  const { userInfo, addressInfo, additionalInfo, gsoInfo } = data as ContactInfoFormData;
  const { paymentInfo } = data as BillingAndContactData;

  const userName = `${userInfo.firstName} ${userInfo.lastName}`;
  const phoneNumber = `${userInfo.callingCode.value}-${userInfo.phoneNumber}`;
  const accountType = addressInfo.addressType === AddressType.BUSINESS ? labels.business : labels.personal;

  let state = '';
  try {
    const stateOption = JSON.parse(addressInfo.state);
    state = stateOption.value as string;
  } catch (error) {
    state = addressInfo.state;
  }
  state = state ? `${state}, ` : '';

  const addressLine2 = addressInfo.addressLine2 ? `${addressInfo.addressLine2}, ` : '';
  const zipcode = addressInfo.zipcode ? `${addressInfo.zipcode}, ` : '';
  const address = `${addressInfo.addressLine1}, ${addressLine2}${addressInfo.city}, ${state}${zipcode}${addressInfo.country.label}`;

  return (
    <StyledContactInfoSummary data-component-name="m-groups-ContactInfoSummary" data-testid="groups-ContactInfoSummary">
      <div className="t-subtitle-xl">{labels.title}</div>

      <div className="row">
        <TextBlock label={labels.contact} value={userName} customClass="col-md-6 col-xl-4" />
        <TextBlock label={labels.phoneAndEmail} value={[phoneNumber, userInfo.email]} customClass="col-md-6 col-xl-4" />
        {addressInfo.companyName && (
          <TextBlock label={labels.companyName} value={addressInfo.companyName} customClass="col-md-6 col-xl-4" />
        )}
        <TextBlock label={labels.typeOfAccount} value={accountType} customClass="col-md-6 col-xl-4" />
        <TextBlock label={labels.address} value={address} customClass="col-md-6 col-xl-4" />
        {gsoInfo?.name && (
          <TextBlock label={labels.accountRepresentativeName} value={gsoInfo.name} customClass="col-md-6 col-xl-4" />
        )}
        {gsoInfo?.email && (
          <TextBlock label={labels.accountRepresentativeEmail} value={gsoInfo.email} customClass="col-md-6 col-xl-4" />
        )}
        {paymentInfo?.cardNumber && (
          <div className="col-md-6 col-xl-4 pt-4 pt-md-5">
            <Eyebrow text={labels.payment} />
            <div className="d-flex pt-2">
              <Icon iconClass="icon-key-station pr-1" />
              <span className="t-font-m payment-info">{`${paymentInfo.cardType} •••• ${paymentInfo.cardNumber}`}</span>
            </div>
          </div>
        )}
      </div>

      {additionalInfo && (
        <div className="accordion-container mt-4 mt-md-5">
          <Accordion
            id={labels.additionalInformation}
            isOpen={true}
            customClass="pt-2 pt-md-3"
            headerChildren={
              <Text element={Types.tags.span} copyText={labels.additionalInformation} fontSize={Types.size.medium} />
            }
          >
            <Text
              element={Types.tags.paragraph}
              customClass="additional-info"
              copyText={additionalInfo}
              fontSize={Types.size.small}
            />
          </Accordion>
        </div>
      )}
    </StyledContactInfoSummary>
  );
};
