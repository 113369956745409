import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledPopupHeaderDiv = styled.div`
  &.hqv-modal-content-header {
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(16)};
    padding-right: ${toRem(20)};
    height: ${toRem(65)};
    visibility: hidden;
  }
  padding: ${toRem(35)};
  border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
  @media ${baseVariables.mediaQuery.md} {
    height: ${toRem(90)};
  }
  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    height: ${toRem(93)};
  }
  .popup-close {
    min-height: ${toRem(32)};
    min-width: ${toRem(32)};
    border-radius: ${toRem(4)};
    background-color: ${baseVariables.color.neutral20};
    color: ${baseVariables.color.base15};

    &:hover {
      cursor: pointer;
    }
    &:focus {
      cursor: pointer;
    }
  }

  @media only ${baseVariables.mediaQuery.sm} {
    padding: ${toRem(32)} ${toRem(32)};
    .header-heading {
      font-weight: ${baseVariables.font['fontWeightMedium']};
      font-size: ${toRem(22)};
    }
  }
`;
