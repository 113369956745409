// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOffersCard = styled.div`
  background-color: ${baseVariables.color.base20};
  box-shadow: rgba(0, 0, 0, 0.07) 0 ${toRem(4)} ${toRem(20)} 0;
  border-radius: ${toRem(14)};
  overflow: hidden;

  .offers-card {
    height: auto;

    &__details-container {
      width: 100%;
    }

    &__img-container {
      width: 100%;
      height: auto;
      position: relative;
      padding: ${toRem(16)} ${toRem(16)} 0;

      &--img {
        height: ${toRem(221)};
        min-height: ${toRem(221)};
        width: 100%;
        border-radius: ${toRem(8)};
      }
    }

    &__details-container_top,
    &__details-container_bottom {
      width: 100%;
    }

    &__details-container_bottom > div {
      min-height: ${toRem(32)};
    }

    &__details-container_desc {
      line-height: 1.5;
      height: 2lh;
    }

    &__tertiary-link {
      background: transparent;
      color: ${baseVariables.color.neutral00};

      &::after {
        line-height: ${toRem(16)};
      }
    }

    &__details-container_footer_link {
      outline: none !important;
    }
  }

  .icon-decorative {
    font-size: ${toRem(24)};
  }

  .m-badge-inline {
    background: rgba(28, 28, 28, 0.54);
    position: absolute;
    z-index: 2;
    bottom: ${toRem(16)};
    right: ${toRem(32)};
  }
`;
