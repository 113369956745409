/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useEffect, useState } from 'react';
import { CardCarouselContainer } from '@marriott/mi-common-static-components';
import { getOffers } from '@marriott/mi-headless-utils';

import { PropertyPortalMerchandisingProps } from './PropertyPortalMerchandising.types';
import { StyledPropertyPortalMerchandising } from './PropertyPortalMerchandising.styles';
import { experienceFragmentComponentTypes } from '../../modules/utils/constants';

export const PropertyPortalMerchandising: FC<PropertyPortalMerchandisingProps> = ({ componentProps, type }) => {
  const [targetContent, setTargetContent] = useState<any>({});

  const allowedComponents = [
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardlayered',
      title: 'Card Layered',
    },
  ];

  useEffect(() => {
    const getOffersData = async () => {
      const response = await getOffers('target-propertyPortal-mbox');
      const responseItem = { ...response?.[':items'] };
      if (responseItem && Object.keys(responseItem).length > 0) {
        for (const itemKey in responseItem) {
          if (
            responseItem[itemKey]?.[':type'] === experienceFragmentComponentTypes.cardCarousel &&
            Object.keys(responseItem[itemKey]?.[':items']).length > 0
          ) {
            const target = {
              model: responseItem[itemKey],
            };
            if (!target['model'].cqType) {
              target['model'].cqType = type;
            }
            setTargetContent({ ...target });
          }
        }
      } else {
        if (!componentProps['model'].cqType) {
          componentProps['model'].cqType = type;
        }
        setTargetContent({ ...componentProps });
      }
    };

    getOffersData();
  }, [componentProps, type]);

  return (
    <StyledPropertyPortalMerchandising
      data-component-name="o-ppv5-PropertyPortalMerchandising"
      data-testid="o-ppv5-PropertyPortalMerchandising"
      className="px-3 pt-md-3 "
    >
      {Object.keys(targetContent).length ? (
        <CardCarouselContainer
          {...targetContent}
          headerText={targetContent?.['model']?.headerText}
          subHeaderText={targetContent?.['model']?.subTitleText}
          noOfCards={targetContent?.['model']?.noOfCards}
          ctaLabel={targetContent?.['model']?.ctaLabel}
          ctaLink={targetContent?.['model']?.ctaLink}
          ctaType={targetContent?.['model']?.ctaType}
          cardCount={targetContent?.['model']?.cardCount}
          openInaNewTab={false}
          totalNumberOfCards={2}
          trackingProperties={targetContent?.['model']?.trackingProperties}
          styleclass={targetContent?.['model']?.appliedCssClassNames}
          cqItems={targetContent?.['model']?.[':items']}
          componentId={targetContent?.['model']?.componentId}
          pagePath={''}
          itemPath={''}
          wcmMode={targetContent?.['model']?.wcmMode}
          isAuthorMode={targetContent?.['model']?.isAuthor}
          noOfCardsTablet={targetContent?.['model']?.noOfCardsTablet}
          allowedComponents={allowedComponents}
          variation={targetContent?.['model']?.variation}
        />
      ) : null}
    </StyledPropertyPortalMerchandising>
  );
};
