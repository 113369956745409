import { FC } from 'react';
import { InternetBarErrorModalProps } from './InternetBarModal.types';
import { StyledErrorBody } from './InternetBarModal.styles';

export const InternetBarErrorModal: FC<InternetBarErrorModalProps> = (props: InternetBarErrorModalProps) => {
  const getErrorBody = (message: string, description: string) => {
    return (
      <StyledErrorBody>
        <div className="body-container d-flex flex-column ">
          {/* 1.1 Internet Bar Modal Error */}
          <div className="d-flex">
            <span className={`col-12 px-3 py-3 mb-4 d-flex align-items-center errorbody__container`}>
              {' '}
              <span className="t-font-s">{message}</span>
            </span>
          </div>
          <span className="t-font-xs pt-2">{description}</span>
        </div>
      </StyledErrorBody>
    );
  };

  const {
    wifiUpgradeGENError,
    wifiUpgradeLR,
    wifiUpgradeMAX,
    wifiUpgradeNoCC,
    wifiUpgradePMS,
    internetBarDataValues,
    showSettlementCurrencyErrorModal = false,
    currencyUxlFailureErrorFieldsBean,
    invalidCurrencyErrorFieldsBean,
  } = props;

  const { ERR = '' } = internetBarDataValues || {};

  const getErrorModal = () => {
    let modalBody: JSX.Element | JSX.Element[] | null = null;
    switch (ERR) {
      case 'GEN':
        modalBody =
          props?.currentStateMapping?.showUpgradeGenErr && wifiUpgradeGENError
            ? getErrorBody(wifiUpgradeGENError?.upgradeErrorMessage, wifiUpgradeGENError?.upgradeErrorDescription)
            : null;
        break;

      case 'LR':
        modalBody =
          props?.currentStateMapping?.showUpgradeLRErr && wifiUpgradeLR
            ? getErrorBody(wifiUpgradeLR?.errorMessageLr, wifiUpgradeLR?.descriptionLr)
            : null;
        break;

      case 'PMS':
        modalBody =
          props?.currentStateMapping?.showUpgradePMSErr && wifiUpgradePMS
            ? getErrorBody(wifiUpgradePMS?.errorMessagePms, wifiUpgradePMS?.descriptionPms)
            : null;
        break;

      case 'MAX':
        modalBody =
          props?.currentStateMapping?.showUpgradeMaxErr && wifiUpgradeMAX
            ? getErrorBody(wifiUpgradeMAX?.errorMessageMax, wifiUpgradeMAX?.descriptionMax)
            : null;
        break;

      case 'NOCC':
        modalBody =
          props?.currentStateMapping?.showUpgradeNoCCErr && wifiUpgradeNoCC
            ? getErrorBody(wifiUpgradeNoCC?.upgradeMessage, wifiUpgradeNoCC?.description1)
            : null;
        break;

      case 'UXL':
        /* modalBody =
          showSettlementCurrencyErrorModal && currencyUxlFailureErrorFieldsBean
            ? getErrorBody(
                currencyUxlFailureErrorFieldsBean?.errorMessageForCuf,
                currencyUxlFailureErrorFieldsBean?.descriptionForCuf
              )
            : null;
        break; */
        modalBody =
          showSettlementCurrencyErrorModal && currencyUxlFailureErrorFieldsBean
            ? getErrorBody(wifiUpgradePMS?.errorMessagePms, wifiUpgradePMS?.descriptionPms)
            : null;
        break;

      case 'INVALID_PRICING':
        /* modalBody =
          showSettlementCurrencyErrorModal && invalidCurrencyErrorFieldsBean
            ? getErrorBody(
                invalidCurrencyErrorFieldsBean?.errorMessageForIc,
                invalidCurrencyErrorFieldsBean?.descriptionForIc
              )
            : null;
        break; */
        modalBody =
          showSettlementCurrencyErrorModal && invalidCurrencyErrorFieldsBean
            ? getErrorBody(wifiUpgradePMS?.errorMessagePms, wifiUpgradePMS?.descriptionPms)
            : null;
        break;
    }

    return modalBody;
  };

  return <>{getErrorModal()}</>;
};
