import { FC, useMemo } from 'react';
import { Accordion, Eyebrow, Text, Types, baseVariables } from '@marriott/mi-ui-library';
import { getFormattedDate, getFormattedDateObject } from '../../utils/date';
import { useMediaQuery } from '../../hooks';
import { DayGuestRoomSummary, GuestRoomSummaryProps } from './GuestRoomSummary.types';
import { StyledGuestRoomSummary, StyledAccordionDivider } from './GuestRoomSummary.styles';
import { formatAmount } from '../../utils';

export const GuestRoomSummary: FC<GuestRoomSummaryProps> = props => {
  const { labels, data } = props;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const guestRoomSummary = useMemo(() => {
    if (!data.length) return [];

    const summaryData: DayGuestRoomSummary[] = [];
    const dates = Object.keys(data[0].roomData);

    // Iterate over each date
    dates.forEach(date => {
      const dayGuestRoomSummary: DayGuestRoomSummary = {
        date,
        roomsData: [],
        currency: data[0].currency,
      };

      // Iterate over each room
      data.forEach(guestRoom => {
        const { quantity, rate } = guestRoom.roomData[date];
        if (quantity > 0) {
          dayGuestRoomSummary.roomsData.push({
            name: `${guestRoom.name}, ${guestRoom.description}`,
            quantity: quantity.toString(),
            rate: rate.toString(),
            total: (quantity ? quantity * rate : 0).toString(),
          });
        }
      });

      summaryData.push(dayGuestRoomSummary);
    });

    return summaryData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [data]);

  const summaryHeader = [
    { label: labels.guestRoom, colWidth: 'col-6 col-md-4 guest-room' },
    { label: isTabletAndAbove ? labels.quantity : labels.qty, colWidth: 'col-2' },
    {
      label: `${labels.rates} (${data[0].currency}${labels.perNight})`,
      colWidth: `col-md-3 ${!isTabletAndAbove && 'd-none'} rate`,
    },
    { label: `${labels.total} (${data[0].currency}${labels.perNight})`, colWidth: 'col-4 col-md-3' },
  ];

  return (
    <StyledGuestRoomSummary data-component-name="m-groups-GuestRoomSummary" data-testid="groups-GuestRoomSummary">
      <div className="t-subtitle-xl pb-2">{labels.title}</div>
      {guestRoomSummary.map((dayGuestRoomSummary, index) => (
        <div key={index}>
          <StyledAccordionDivider />
          <Accordion
            id={`GuestRoomSummary-day${index}`}
            isOpen={true}
            headerChildren={<div>{getFormattedDate(getFormattedDateObject(dayGuestRoomSummary.date))}</div>}
            headerAriaLabel={`${labels.title} ${getFormattedDate(getFormattedDateObject(dayGuestRoomSummary.date))}`}
          >
            <div className="row summary-header">
              {summaryHeader.map((heading, index) => (
                <div key={`heading${index}`} className={heading.colWidth}>
                  <Eyebrow text={heading.label} />
                </div>
              ))}
            </div>
            {dayGuestRoomSummary.roomsData.map((room, index) => (
              <div key={`room${index}`} className="row">
                <Text
                  element={Types.tags.div}
                  copyText={room.name}
                  fontSize={Types.size.medium}
                  customClass="col-6 col-md-4 pt-3 t-font-weight-m guest-room"
                />
                <Text
                  element={Types.tags.div}
                  copyText={room.quantity}
                  fontSize={Types.size.medium}
                  customClass="col-2 pt-3 t-font-weight-m"
                />
                <Text
                  element={Types.tags.div}
                  copyText={formatAmount(+room.rate)}
                  fontSize={Types.size.medium}
                  customClass={`col-md-3 pt-3 t-font-weight-m ${!isTabletAndAbove && 'd-none'} rate`}
                />
                <Text
                  element={Types.tags.div}
                  copyText={formatAmount(+room.total)}
                  fontSize={Types.size.medium}
                  customClass="col-4 col-md-3 pt-3 t-font-weight-m"
                />
              </div>
            ))}
          </Accordion>
        </div>
      ))}
    </StyledGuestRoomSummary>
  );
};
