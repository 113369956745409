import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { createGlobalStyle } from 'styled-components';

interface RnBErrorMessageProps {
  isEmpty: boolean;
}

export const StyledErrorMessage = styled.div<RnBErrorMessageProps>`
  .rnb-error-message {
    display: ${props => (props.isEmpty ? 'none' : 'block')};
  }
`;

export const GlobalErrorStyle = createGlobalStyle`
  .rnb-search-form {
    padding: 0;
  }

  .rnb-search-form div:first-child {
    margin-top: 0;
  }

  .error-404-container {
    padding-bottom: ${toRem(16)};

    @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${
  baseVariables.mediaQuery.xl
} {
      padding-bottom: ${toRem(20)};
    } 
  }

  .rnb-error-container {
    position: absolute;
    top: ${toRem(316)};
    left: 0;
    right: 0;
    margin: 0 auto;

    @media ${baseVariables.mediaQuery.sm} {
      top: ${toRem(300)};
    } 

    @media ${baseVariables.mediaQuery.md} {
      top: ${toRem(290)};
    } 
    
    @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
      top: ${toRem(328)};
    }
}
`;
