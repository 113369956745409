import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledDualCardContainer = styled.div`
  .dual-card-container {
    row-gap: ${toRem(16)};

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      column-gap: ${toRem(24)};
    }
  }

  .dual-card-container > div {
    flex: 1 !important;
  }
`;
