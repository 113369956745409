import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledPasswordField = styled.div`
  .m-input-field {
    .contain {
      margin-top: 0;
    }
    &.is-error + .m-exp-dropdown-panel {
      top: ${toRem(46)};
    }
  }
  .m-exp-dropdown-panel.flyout {
      position: absolute;
      z-index: 1;
      width: 100%;
      background: ${color.base20};
      .icon {
        font-size: ${toRem(18)};
      }
      .invalid {
        color: ${color.alert50};
      }
      .valid {
        color: ${color.alert30};
      }
      li {
        column-gap: ${toRem(8)};
        span {
          width: auto !important;
        }
      }
    }
  }
`;
