// import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledRnBRichText = styled.div`
  text-align: left;

  .rich-text-link {
    a {
      text-decoration: underline;
    }
    p {
      padding-bottom: ${toRem(16)};
    }
  }

  .faq-label-class {
    text-decoration: underline;
    cursor: pointer;
    font-weight: ${baseVariables.font['fontWeightMedium']};
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
      text-align: right;
    }`}
`;
