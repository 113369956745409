export const constants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  APAC_COUNTRY: ['CN', 'VN', 'JP', 'KR'],
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  PAGE_LOADED: 'Page Loaded',
  PAGE_LOADING: 'Page Loading',
  ROUTING_CONFIG: 'routingConfig',
  APPLICATION_NAME: 'contactless-spa',
  EXPERIENCE_SEGMENT: ['NOT Logged In', 'In_Stay-Unknown'],
  BASE_IMG_URL:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiOAMAANUAz5n+TlUAAAAASUVORK5CYII=',
};

export const DATALAYER_CONSTANT = {
  headerKeys: [
    'accept-language',
    'user-agent',
    'x-request-id',
    'x-service-id',
    'x-forwarded-host',
    'x-host',
    'x-xm-site-id',
    'x-lite-site',
    'x-request-uri',
    'x-request-host',
    'sec-fetch-user',
    'x-forwarded-for',
    'x-schema',
    'sec-fetch-mode',
    'x-forwarded-port',
    'sec-fetch-site',
    'sec-fetch-dest',
    'x-forwarded-proto',
    'referer',
    'forwarded',
    'messageid',
    'x-akamai-edgescape',
    'x-akamai-device-characteristics',
    'x-client-ip',
    'x-akamai-config-log-detail',
    'akamai-origin-hop',
    'digitalserviceswitch',
  ],
};

export const HYBRID_CONFIG_CONSTANTS = {
  ROUTING_CONFIG: 'routingConfig',
  EXPERIENCE_SEGMENT: ['NOT Logged In', 'In_Stay-Unknown'],
  MOCK_SESSION_ID: '7FC3A108-DF69-5E10-B767-6D2FB461B924',
};

export const monthsArray = [
  { monthLabel: 'Jan' },
  { monthLabel: 'Feb' },
  { monthLabel: 'Mar' },
  { monthLabel: 'Apr' },
  { monthLabel: 'May' },
  { monthLabel: 'Jun' },
  { monthLabel: 'Jul' },
  { monthLabel: 'Aug' },
  { monthLabel: 'Sep' },
  { monthLabel: 'Oct' },
  { monthLabel: 'Nov' },
  { monthLabel: 'Dec' },
];

export const EEO_MEMBER_CORP_CODE: Record<string, string> = {
  P: 'E0P',
  G: 'E0G',
  S: 'E0S',
};

export const EEO_MEMBER_CODE = ['E0P', 'E0S', 'E0G'];

// adding default locale
export enum hardCodedAllLocale {
  en = 'en-US',
}

export const DEFAULT_LANG = 'en_US';

export const TRACKING_CONSTANT = {
  INTERNAL_LINK: 'internal',
  EXTERNAL_LINK: 'external',
  OUTLET_DETAILS: 'Outlet Primary Details',
  RESERVE_BUTTON: 'R&B Reserve Button',
  OUTLET_ADDITIONAL_SECTION: 'Outlet Additional Section',
  OUTLET_WEBSITE_LINK: 'R&B Website Link',
  OUTLET_RESERVATION_LINK: 'R&B Hotel Reservation Link',
  OUTLET_LOCATION_MAP: 'R&B Location on Map Link',
  OUTLET_PHONE_CONTACT: 'R&B Phone Link',
  OUTLET_EMAIL_CONTACT: 'R&B Email Link',
  OUTLET_AUXILIARY_SECTION: 'Outlet Auxiliary Section',
  OUTLET_BONVOY_FIND_MORE_LINK: 'R&B Bonvoy Find More Link',
  OUTLET_RECOMMENDATION_SECTION: 'Outlet Recommendation',
  EXPLORE_NEARBY: 'R&B Explore Nearby Link',
  MINI_CARD: 'Mini Card',
  HOME_PAGE_RECOMMENDATION_SECTION: 'Home Page Recommendation',
  HOME_PAGE_SEARCH_FORM: 'Home Search Form',
  SEARCH_RESULTS_SEARCH_FORM: 'Search Results Search Form',
  SEARCH_BUTTON: 'Search',
  UPDATE_SEARCH_BUTTON: 'Update Search',
  EDITORIAL_FEATURE: 'Editorial Feature',
  READ_MORE_BUTTON: 'R&B Read More',
  COLLAGE_EDITORIAL: 'Collage Editorial',
  LEARN_MORE_BUTTON: 'R&B Learn More',
  BONVOY_MEMBER_BANNER: 'Bonvoy Member Banner',
  JOIN_NOW_BUTTON: 'R&B Join Now',
  SIGN_IN_BUTTON: 'R&B Sign In',
  SEARCH_RESULTS_PAGINATION: 'Search Results Pagination',
  PREVIOUS_BUTTON: 'Previous',
  NEXT_BUTTON: 'Next',
  PAGE_BUTTON: 'Page Number',
  SEARCH_RESULT_PAGE: 'Search Result Page',
  FILTER_BUTTON: 'R&B Filter Button',
  SEARCH_RESULT_CARD: 'Search Result Card',
  RESTAURANT_DETAILS_BUTTON: 'Restaurant Details',
  SEARCH_FILTER_MODAL: 'R&B Search Filter Modal',
  BONVOY_FILTER: 'Bonvoy Filter',
  BRAND_FILTER: 'Brand Filter',
  CUISINE_FILTER: 'Cuisine Filter',
  DISTANCE_FILTER: 'Distance Filter',
  HOURS_FILTER: 'Hours Filter',
  SEATING_FILTER: 'Seating Filter',
  FILTER_OPTION: 'Option',
  SELECTED_FILTER_PILL: 'Selected Filter Pill',
};

export const trackingDetailArr = [
  { text: 'trackingContentPosition', pre: '' },
  { text: 'trackingDescription', pre: 'msg=' },
  { text: 'trackingOfferType', pre: 'type=' },
  { text: 'trackingTag', pre: 'tag=' },
];

export const mapURL = 'https://www.google.com/maps/search/';

export const baiduMapURL = 'https://map.baidu.com/search/';

export type InternetBarIcon = {
  showIcon: boolean;
  iconName: string;
  iconText: string;
};

export const internetBarIcons: Record<string, InternetBarIcon> = {
  preconnect: {
    showIcon: true,
    iconName: 'icon-wifi',
    iconText: 'Connect to the Internet',
  },
  in_stay: {
    showIcon: false,
    iconName: '',
    iconText: '',
  },
  upgrade: {
    showIcon: true,
    iconName: 'icon-success',
    iconText: 'Upgrade your connection',
  },
  upgraded: {
    showIcon: true,
    iconName: 'icon-success',
    iconText: 'Connection Upgraded',
  },
  connected: {
    showIcon: true,
    iconName: 'icon-success',
    iconText: 'Connected to the Internet',
  },
};

export const cna = 'cna';

export const weatherIcons = [
  { iconClass: 'icon-sunny', iconKey: 1 },
  { iconClass: 'icon-partly-sunny', iconKey: 2 },
  { iconClass: 'icon-partly-sunny', iconKey: 3 },
  { iconClass: 'icon-partly-sunny', iconKey: 4 },
  { iconClass: 'icon-cloud-fog', iconKey: 5 },

  { iconClass: 'icon-partly-cloudy', iconKey: 6 },
  { iconClass: 'icon-cloudy', iconKey: 7 },
  { iconClass: 'icon-cloudy', iconKey: 8 },
  { iconClass: 'icon-cloud-fog', iconKey: 9 },
  { iconClass: 'icon-light-showers', iconKey: 10 },

  { iconClass: 'icon-scattered-showers', iconKey: 11 },
  { iconClass: 'icon-scattered-showers', iconKey: 12 },
  { iconClass: 'icon-storm-lightning', iconKey: 13 },
  { iconClass: 'icon-storm-day', iconKey: 14 },
  { iconClass: 'icon-storm-day', iconKey: 15 },

  { iconClass: 'icon-rain', iconKey: 16 },
  { iconClass: 'icon-snow', iconKey: 17 },
  { iconClass: 'icon-snow', iconKey: 18 },
  { iconClass: 'icon-snow', iconKey: 19 },
  { iconClass: 'icon-snow-heavy', iconKey: 20 },

  { iconClass: 'icon-snow-medium', iconKey: 21 },
  { iconClass: 'icon-hail', iconKey: 22 },
  { iconClass: 'icon-hail', iconKey: 23 },
  { iconClass: 'icon-cloud-hail', iconKey: 24 },
  { iconClass: 'icon-cloud-hail', iconKey: 25 },

  { iconClass: 'icon-hot', iconKey: 26 },
  { iconClass: 'icon-cold', iconKey: 27 },
  { iconClass: 'icon-windy', iconKey: 28 },
  { iconClass: 'icon-moon', iconKey: 29 },
  { iconClass: 'icon-moon', iconKey: 30 },

  { iconClass: 'icon-night-cloud', iconKey: 31 },
  { iconClass: 'icon-night-cloud', iconKey: 32 },
  { iconClass: 'icon-night-cloud', iconKey: 33 },
  { iconClass: 'icon-night-cloud', iconKey: 34 },
  { iconClass: 'icon-rain-night', iconKey: 35 },

  { iconClass: 'icon-rain-night', iconKey: 36 },
  { iconClass: 'icon-storm-night', iconKey: 37 },
  { iconClass: 'icon-storm-night', iconKey: 38 },
  { iconClass: 'icon-snow-night', iconKey: 39 },
  { iconClass: 'icon-snow-night', iconKey: 40 },
];

export const serviceIcons = {
  iconDining: 'icon-dining',
  iconSpa: 'icon-spa',
  iconGolf: 'icon-golf',
  iconDiningChopsticks: 'icon-dining-chopsticks',
};

export const ModalValues = {
  LOGIN: 'LOGIN',
  LOGERR: 'LOGERR',
  ACCESS: 'ACCESS',
  ACCESSERR: 'ACCESSERR',
  REQSMS: 'REQSMS',
  REQSMSERR: 'REQSMSERR',
};

export const experienceFragmentComponentTypes = {
  mmnContainer: 'mi-aem-common-spa/components/content/mmn-container',
  bannerLayered: 'mi-aem-common-spa/components/content/bannerlayered',
  accuWeather: 'mi-aem-ppv5-spa/components/content/accuWeather',
  cardHorizontalMini: 'mi-aem-common-spa/components/content/cardhorizontalmini',
  experiencefragment: 'mi-aem-ppv5-spa/components/content/experiencefragment',
  staticwrapper: 'mi-aem-ppv5-spa/components/common/staticwrapper',
  offers: 'mi-aem-offers-spa/components/offers-cards',
  serviceCarousel: 'mi-aem-ppv5-spa/components/content/services-carousel',
  letsConnect: 'mi-aem-ppv5-spa/components/content/lets-connect-modal',
  responsive: 'nt:unstructured',
  cardCarousel: 'mi-aem-common-spa/components/content/cardcarouselcontainer',
  text: 'mi-aem-common-spa/components/text',
  title: 'mi-aem-common-spa/components/title',
};

//Common words to remove for fuzzy search
export const common_words =
  /\b(a|an|and|are|as|at|be|but|by|for|if|in|into|is|it|no|not|of|on|or|such|that|the|their|then|there|these|they|this|to|was|will and with|breakfast|restaurant|lounge|bar)\b/gi;

export const statesConstant = {
  preconnect: 'preconnect',
  connect: 'connect',
  connected: 'connected',
  upgrade: 'upgrade',
  upgraded: 'upgraded',
  in_stay: 'in_stay',
};

export const chineseLocations = ['CN', 'HK', 'MO', 'TW'];

export const servicesAmenitiesContectID = 'services-amenities';
export const merchandisingContectID = 'merchandising';
export const localOffersContectID = 'local-offers';

export const merchandisingLuxuryBrand = ['RC', 'XR', 'LC', 'WH', 'EB', 'JW', 'RZ'];

export const localServiceConstants = {
  foodAndBeverage: 'FoodAndBeverage',
  spa: 'Spa',
  golf: 'Golf',
  dinning: 'Dinning',
};

export const localServiceAnalyticsTrackConstant = {
  foodAndBeverage: 'Order F&B',
  spa: 'Spa Reservation',
  golf: 'Golf Reservation',
  dinning: 'Dining Reservation',
};

export const cacheURL = 'https://cache.marriott.com';

export const internetBarHeight = 64;

export const modalHorizontalSpacing = 68;
