import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';
import { UseSelectStateChange, useSelect } from 'downshift';
import { TextFormField } from '../index';
import { DropdownOption, DropdownProps } from './Dropdown.types';
import { StyledDropdownContainer, StyledOptionList, StyledOptionItem } from './Dropdown.styles';

export const Dropdown = <T,>(
  {
    label,
    ariaLabel,
    className,
    placeholder,
    isError,
    errorMessage,
    disabled = false,
    options,
    helperText = '',
    selectedValue,
    selectedIndex,
    useValueAsLabel = false,
    renderLabelAsHtml = false,
    expandIconAriaLabel = 'expand icon',
    collapseIconAriaLabel = 'collapse icon',
    onClick,
    onChange,
  }: DropdownProps<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const inputId = React.useId();
  const dropdownRef = useRef<HTMLInputElement>(null);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const selectedOptionRef = useRef<HTMLInputElement>(null);

  const itemToString = (item: DropdownOption<T> | null) => (item ? item.label : '');

  const onSelectedOptionChange = ({ selectedItem }: UseSelectStateChange<DropdownOption<T>>) => {
    if (
      selectedItem &&
      (selectedItem.value !== selectedOption?.value || selectedItem?.label !== selectedOption?.label)
    ) {
      onChange(selectedItem);
    }
  };

  const onIsOpenChange = ({ isOpen }: UseSelectStateChange<DropdownOption<T>>) =>
    isOpen && selectedOptionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  const {
    isOpen,
    toggleMenu,
    highlightedIndex,
    setHighlightedIndex,
    selectedItem: selectedOption,
    selectItem: selectOption,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    id: inputId,
    items: options,
    itemToString,
    onIsOpenChange: onIsOpenChange,
    onSelectedItemChange: onSelectedOptionChange,
  });

  useEffect(() => {
    if (selectedValue || selectedIndex) {
      const selectedOptionIndex = selectedIndex || options.findIndex(option => option.value === selectedValue);
      if (selectedOptionIndex !== -1) {
        selectOption(options[selectedOptionIndex]);
        setHighlightedIndex(selectedOptionIndex);
      }
    } else {
      selectOption(null);
      setHighlightedIndex(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedValue, selectedIndex]);

  return (
    <StyledDropdownContainer
      data-component-name="m-groups-Dropdown"
      data-testid="groups-Dropdown"
      ref={dropdownRef}
      className={clsx('m-input-field', className, isError ? 'is-error' : '')}
    >
      <TextFormField
        type="text"
        label={label}
        ariaLabel={ariaLabel}
        placeholder={placeholder}
        value={useValueAsLabel ? selectedOption?.value : selectedOption?.label || ''}
        disabled={disabled}
        readOnly={true}
        className={clsx('t-font-s', disabled ? 'is-disabled' : '')}
        showIcon={!disabled}
        iconClass={!isOpen ? 'icon icon-arrow-down' : 'icon icon-arrow-up'}
        isIconClickable={true}
        iconClickHandler={() => {
          document?.getElementById(inputId)?.focus();
          toggleMenu();
        }}
        iconAriaLabel={!isOpen ? expandIconAriaLabel : collapseIconAriaLabel}
        showHelperText={!!helperText && !isError}
        helperText={helperText}
        ref={inputFieldRef || ref}
        testId={inputId}
        getLabelProps={() => ({ htmlFor: inputId })}
        onFocus={onClick}
        {...getToggleButtonProps({ id: inputId, role: 'combobox', 'aria-label': ariaLabel })}
      />
      <StyledOptionList
        className={clsx('custom-scrollbar', isOpen === true ? 'd-block' : 'd-none')}
        tabIndex={-1}
        {...getMenuProps({ 'aria-labelledby': inputId })}
      >
        {options.map((option, index) => {
          let fragmentString = '';

          if (renderLabelAsHtml) {
            const sanitizedNode = DOMPurify.sanitize(option.label, { RETURN_DOM: true });
            fragmentString = sanitizedNode.innerHTML;
          }

          return (
            <StyledOptionItem
              key={index}
              className={clsx({ active: selectedOption === option, highlighted: highlightedIndex === index })}
              {...getItemProps({ item: option, index, ref: index === highlightedIndex ? selectedOptionRef : null })}
            >
              {renderLabelAsHtml ? (
                <div className="dropdown-option" dangerouslySetInnerHTML={{ __html: fragmentString }}></div>
              ) : (
                option.label
              )}
            </StyledOptionItem>
          );
        })}
      </StyledOptionList>
      {isError && <span className={'error-text t-font-xs'}>{errorMessage}</span>}
    </StyledDropdownContainer>
  );
};
