/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import clsx from 'clsx';
import { Heading, Button, Link, Image, Icon, Types, Text } from '@marriott/mi-ui-library';
import { useGetBreakpoint, getClassForBreakpoint } from '../../modules/utils/helpers';
import { OffersCardProps } from './OffersCard.types';
import { StyledOffersCard } from './OffersCard.styles';
const { size, tags } = Types;

export interface StyledOverlayProps {
  iconPath?: boolean;
}

export const OffersCard: React.FC<OffersCardProps> = ({
  titleDetails,
  cuisineOverlay,
  description,
  tertiaryLinkDetails,
  footerLinkDetails,
  images = [],
  showIcon,
  iconTitle,
  trackingProperties = {
    clickTrack: false,
  },
  propertyName,
  styleClass = '',
}) => {
  const [breakpoint] = useState<string | undefined>(useGetBreakpoint());

  return (
    <StyledOffersCard
      className={clsx(
        'offers-card container d-flex p-0 flex-column',
        getClassForBreakpoint(breakpoint, 'flex-nowrap', 'flex-nowrap', 'flex-column flex-nowrap'),
        styleClass
      )}
    >
      <div className="offers-card__img-container">
        {cuisineOverlay && (
          <div>
            <span className="m-badge-inline px-2 py-1">{cuisineOverlay}</span>
          </div>
        )}
        <div>
          <Image
            title=""
            defaultImageURL={`${images[0].defaultImageUrl}`}
            altText={`Slide 1`}
            customClass="offers-card__img-container--img"
            loading={'eager'}
          />
        </div>
      </div>

      <div className={clsx('offers-card__details-container d-flex flex-column justify-content-between px-4 py-4')}>
        <div className="offers-card__details-container_top">
          {propertyName && (
            <Text
              copyText={propertyName}
              customClass="offers-card__subTitle mb-1 t-label-alt-xs m-ellipsis-1line"
              fontSize={size.small}
              element={tags.span}
            />
          )}

          <div className="d-flex flex-row align-items-center mb-2">
            {showIcon && iconTitle && <Icon iconClass={clsx('icon-decorative', 'p-0', iconTitle)}></Icon>}

            {titleDetails?.title && (
              <Heading
                variation={Types.headingType.subtitle}
                titleText={titleDetails?.title}
                fontSize={Types.size.large}
                customClass={`offers-card__title mb-0 pl-2 m-ellipsis-1line`}
              ></Heading>
            )}
          </div>

          {description && (
            <p className={clsx('t-font-s offers-card__details-container_desc mt-0 mb-2 m-ellipsis-2lines')}>
              {description}
            </p>
          )}
        </div>
        <div className="offers-card__details-container_bottom pt-2">
          <div className="d-flex justify-content-between align-items-center">
            {tertiaryLinkDetails && Object.keys(tertiaryLinkDetails).length && (
              <Button
                type={Types.ButtonTypeVariation.Button}
                href={tertiaryLinkDetails?.href || ''}
                target={tertiaryLinkDetails?.target}
                buttonCopy={tertiaryLinkDetails?.buttonCopy || ''}
                className={clsx(tertiaryLinkDetails?.className || '', 'offers-card__tertiary-link')}
                linkAriaLabelOpenNewWindow={tertiaryLinkDetails?.linkAriaLabelOpenNewWindow || ''}
                isLink={tertiaryLinkDetails?.isLink}
                isTextBeforeChildren={tertiaryLinkDetails?.isTextBeforeChildren}
                custom_click_track_value={tertiaryLinkDetails?.custom_click_track_value || ''}
                callback={tertiaryLinkDetails?.onClick}
              ></Button>
            )}
            {footerLinkDetails && (
              <Link
                text={footerLinkDetails?.text || ''}
                linkClassName={clsx(
                  footerLinkDetails?.className || '',
                  `offers-card__details-container_footer_link text-center py-2 px-3`
                )}
                ariaLabel={footerLinkDetails?.linkAriaLabelOpenNewWindow || ''}
                custom_click_track_value={footerLinkDetails?.ratesClickTrackValue || ''}
                linkHref={footerLinkDetails?.href || ''}
                target={footerLinkDetails?.target || ''}
                callback={e => footerLinkDetails?.onClick && footerLinkDetails?.onClick(e)}
                trackingProperties={trackingProperties}
              ></Link>
            )}
          </div>
        </div>
      </div>
    </StyledOffersCard>
  );
};
