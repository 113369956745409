/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import { OUTLET_HERO_FALLBACK_IMAGE } from '../../modules/utils/constants/constants';

export interface outletDetailsState {
  outletData: any;
  outletDataLoading: boolean;
  outletDataError: boolean;
  skipQuery: boolean;
  outletGalleryTabData: any;
  outletGalleryError: boolean;
  outletMenuData: any;
  outletMenuCountError: boolean;
  outletMenuDetailsError: boolean;
  outletSignatureData: any;
  outletSignatureError: boolean;
  outletChefFeatureData: any;
  outletChefFeatureError: boolean;
  outletOffersData: any;
  outletOffersError: boolean;
  menuCount: number;
  signatureCount: number;
  setData: (data: any, loading: boolean, error: any, fallbackData: any, heroBannerImages: any) => void;
  setGalleryTabData: (data: any) => void;
  setMenuTabData: (data: any) => void;
  setOutletOffersData: (data: any) => void;
  setMenuCountError: (outletMenuSignatureError: boolean) => void;
  setMenuDetailsError: (outletMenuSignatureError: boolean) => void;
  setSignatureTabData: (data: any) => void;
  setSignatureError: (outletMenuSignatureError: boolean) => void;
  setChefTabData: (data: any) => void;
  setChefFeatureError: (outletMenuSignatureError: boolean) => void;
  setGalleryError: (outletGalleryError: boolean) => void;
  setOutletOfferError: (outletGalleryError: boolean) => void;
  setMenuCount: (value: number) => void;
  setSignatureCount: (value: number) => void;
}

const initialState: outletDetailsState = {
  outletData: null,
  outletDataLoading: true,
  outletDataError: false,
  skipQuery: false,
  outletGalleryTabData: null,
  outletMenuData: null,
  outletSignatureData: null,
  outletChefFeatureData: null,
  outletGalleryError: false,
  outletMenuCountError: false,
  outletMenuDetailsError: false,
  outletSignatureError: false,
  outletChefFeatureError: false,
  outletOffersData: null,
  outletOffersError: false,
  menuCount: 0,
  signatureCount: 0,
  setData: () => {},
  setGalleryTabData: () => {},
  setMenuTabData: () => {},
  setMenuCountError: () => {},
  setMenuDetailsError: () => {},
  setSignatureTabData: () => {},
  setSignatureError: () => {},
  setChefTabData: () => {},
  setChefFeatureError: () => {},
  setGalleryError: () => {},
  setOutletOffersData: () => {},
  setOutletOfferError: () => {},
  setMenuCount: () => {},
  setSignatureCount: () => {},
};

export const OutletDetailsStore: StateCreator<outletDetailsState> = set => {
  return {
    ...initialState,
    setData: (data, loading, error, fallbackData, heroBannerImages) => {
      if (error && !data) {
        set({ outletData: null, outletDataLoading: loading, outletDataError: true, skipQuery: true });
      } else if (data && Object.keys(data).length) {
        const outledIdDetails = data || '';
        const isNameAvlb = !!outledIdDetails?.basicInformation?.name?.length;

        const errorCondition = isNameAvlb === false;
        set({
          outletData: { ...data, heroBannerImages, fallbackData },
          outletDataLoading: loading,
          outletDataError: outledIdDetails && !errorCondition ? false : true,
          skipQuery: true,
        });
      }
    },
    setGalleryTabData: data => {
      set({
        outletGalleryTabData: data,
      });
    },
    setGalleryError: value => {
      set({
        outletGalleryError: value,
      });
    },
    setMenuTabData: data => {
      set({
        outletMenuData: data,
      });
    },
    setMenuCountError: (value: any) => {
      set({
        outletMenuCountError: value,
      });
    },
    setMenuDetailsError: (value: any) => {
      set({
        outletMenuDetailsError: value,
      });
    },
    setSignatureTabData: data => {
      set({
        outletSignatureData: data,
      });
    },
    setSignatureError: (value: any) => {
      set({
        outletSignatureError: value,
      });
    },
    setChefTabData: data => {
      set({
        outletChefFeatureData: data,
      });
    },
    setChefFeatureError: (value: any) => {
      set({
        outletChefFeatureError: value,
      });
    },
    setOutletOffersData: data => {
      set({
        outletOffersData: data,
      });
    },
    setOutletOfferError: data => {
      set({
        outletOffersError: data,
      });
    },
    setMenuCount: value => {
      set({
        menuCount: value,
      });
    },
    setSignatureCount: value => {
      set({
        signatureCount: value,
      });
    },
  };
};

export const useStore = createAppStore(OutletDetailsStore, {
  usePersistentStore: false,
});
