import styled from 'styled-components';

export const StyledSummaryRow = styled.div`
  .label {
    font-weight: bold;
  }
  .value {
    overflow-wrap: break-word;
  }
`;
