/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo, createContext, useContext } from 'react';
import dynamic from 'next/dynamic';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import {
  searchRestaurantsByGeolocation,
  searchRestaurantsByLocation,
  searchRestaurantsByOpenText,
} from '@marriott/mi-rnb-graphql';
import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import {
  PageSize,
  distanceFilterOptions,
  outletPromotionCurrPage,
  RnBFILTERS,
  otsUrlTypeTerm,
  otsUrlTypeValue,
} from '../../modules/utils/constants';

// organisms
import { SkeletonOutletCardLoader } from './SkeletonOutletCardLoader';
import { PromotionBanner } from './PromotionBanner';

// molecules
import { PaginationPanel } from '../../molecules/Pagination';

// Global Styles
import { StyledSearchResultContainer, GlobalStyle } from './SearchResults.styles';

// Helpers
import {
  useGetBreakpoint,
  getHttpStatusCode,
  getErrorMessage,
  getCategorizedBrands,
  getAEMKeyFromProps,
  getProcessedFilterData,
  getSubDirectoryLocale,
} from '../../modules/utils/helpers';

// Store
import { rnbErrorState, rnbErrorStore } from '../../modules/store/rnbErrorStore';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { autoCompleteState, useAutoCompleteStore } from '../../modules/store/autoCompleteDetailsStore';
import searchFilterStore, { searchFilterState } from '../../modules/store/store';

// Mocks
import searchResultsMockData from './__mocks__/SearchResultsFiltersMock.json';
import { PageParamsContext } from '../../modules/context';

// Dynamic Imports
const SearchAllFilters = dynamic<unknown>(() => import('./SearchAllFilters').then(mod => mod.SearchAllFilters));
const SearchOutletCard = dynamic<unknown>(() => import('./SearchOutletCard').then(mod => mod.SearchOutletCard));

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const SearchResults = (props: any) => {
  const { itemPath, isAuthorMode, pagePath, totalNumberOfCards, cqItems, cardCount } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const paginationChange = useStore((state: searchResultsState) => state.paginationChange);
  const pageNumber = useStore((state: searchResultsState) => state.pageNumber);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const searchResultsData = useStore((state: searchResultsState) => state.searchResultData);
  let filterUpdateData = useStore((state: searchResultsState) => state.facetsData);
  const searchResultsDataError = useStore((state: searchResultsState) => state.searchResultDataError);
  const setData = useStore((state: searchResultsState) => state.setData);
  const setPaginationChange = useStore((state: searchResultsState) => state.setPaginationChange);
  const setPageNumber = useStore((state: searchResultsState) => state.setPageNumber);
  const selectedPlaceDetailsData = useAutoCompleteStore((state: autoCompleteState) => state.selectedPlaceDetailsData);
  const searchResultDataLoading = useStore((state: searchResultsState) => state.searchResultDataLoading);
  const TotalPages = Math.ceil(searchResultsData?.total / PageSize);
  const promBannerIndex = Math.ceil(PageSize / 2);
  const setErrorState = rnbErrorStore((state: rnbErrorState) => state.setErrorState);
  const errorMessage = rnbErrorStore((state: rnbErrorState) => state.errorMessage);
  const [errorCheck, setErrorCheck] = useState(false);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const setIsComponentLoading = useStore((state: searchResultsState) => state.setIsComponentLoading);
  const isComponentLoading = useStore((state: searchResultsState) => state.isComponentLoading);
  const queryParam = useStore((state: searchResultsState) => state.queryParam);
  const setQueryParam = useStore((state: searchResultsState) => state.setQueryParam);
  const setIsLoaderVisible = useStore((state: searchResultsState) => state.setIsLoaderVisible);
  const shouldComponentReload = useStore((state: searchResultsState) => state.shouldComponentReload);
  const sortMessage = useStore((state: searchResultsState) => state.sortMessage);
  const searchTextMessage = useStore((state: searchResultsState) => state.searchTextMessage);
  const setSearchTypeMessage = useStore((state: searchResultsState) => state.setSearchTypeMessage);
  const searchTextMessageDisplay = useStore((state: searchResultsState) => state.searchTextMessageDisplay);
  const { campaignParams, currentLocale, currentPage: currentPageFromContext } = useContext(PageParamsContext);
  const subLocale = getSubDirectoryLocale(currentLocale || 'en-US');

  const pageContext = useContext(createContext<any>({}));

  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);

  const sessionID = Cookies.get('sessionID');
  let latitude: string | number | null = null;
  let longitude: string | number | null = null;
  let state: string | null = null;
  let country: string | null = null;
  let isOpenTextSearch: boolean | null = null;
  let userInputOpenSearchTerm: string | null = null;
  const terms: any = [];
  const ranges: any = [];
  const hours = searchFilterStore((state: searchFilterState) => state.hours);
  const cuisine = searchFilterStore((state: searchFilterState) => state.cuisine);
  const seating = searchFilterStore((state: searchFilterState) => state.seating);
  const bonvoyMembers = searchFilterStore((state: searchFilterState) => state.bonvoyMembers);
  const brands = searchFilterStore((state: searchFilterState) => state.brands);
  const venueType = searchFilterStore((state: searchFilterState) => state.venueType);
  const dietaryOptions = searchFilterStore((state: searchFilterState) => state.dietaryOptions);
  const experiences = searchFilterStore((state: searchFilterState) => state.experiences);
  const city = searchFilterStore((state: searchFilterState) => state.city);
  const stateProvinceDescription = searchFilterStore((state: searchFilterState) => state.stateProvinceDescription);
  const countryDescription = searchFilterStore((state: searchFilterState) => state.countryDescription);
  const hourSelection = searchFilterStore((state: searchFilterState) => state.hourSelection);
  const cuisineSelection = searchFilterStore((state: searchFilterState) => state.cuisineSelection);
  const distanceSelection = searchFilterStore((state: searchFilterState) => state.distanceSelection);
  const seatingSelection = searchFilterStore((state: searchFilterState) => state.seatingSelection);
  const bonvoySelection = searchFilterStore((state: searchFilterState) => state.bonvoySelection);
  const brandSelection = searchFilterStore((state: searchFilterState) => state.brandSelection);
  const venueTypeSelection = searchFilterStore((state: searchFilterState) => state.venueTypeSelection);
  const dietaryOptionsSelection = searchFilterStore((state: searchFilterState) => state.dietaryOptionsSelection);
  const experiencesSelection = searchFilterStore((state: searchFilterState) => state.experiencesSelection);
  const citySelection = searchFilterStore((state: searchFilterState) => state.citySelection);
  const stateProvinceDescSelection = searchFilterStore((state: searchFilterState) => state.stateProvinceDescSelection);
  const countryDescSelection = searchFilterStore((state: searchFilterState) => state.countryDescSelection);

  const onPageItemClick = (page: number): void => {
    setPaginationChange(true);
    setCurrentPage(page);
    setPageNumber(page);
    calculateIndex(page);
    window.scrollTo(0, 0);
  };

  const replacePlaceholderText = (requestedTerm: string, correctedTerm: string) => {
    const placeholders = searchTextMessage?.match(/\[.+?\]/g) || [];
    let updatedText = searchTextMessage;

    if (placeholders && placeholders[0]) {
      updatedText = updatedText?.replace(placeholders[0], requestedTerm);
    }
    if (placeholders && placeholders[1]) {
      updatedText = updatedText?.replace(placeholders[1], correctedTerm);
    }
    setSearchTypeMessage(sortMessage, searchTextMessage, placeholders ? updatedText : '');
  };

  useEffect(() => {
    setQueryParam(window.location.search);
  }, []);

  useEffect(() => {
    if (props?.isAuthorMode) {
      setData(searchResultsMockData?.data, false, false, props?.dynamicMedia);
    }
  }, [props?.isAuthorMode]);

  useEffect(() => {
    setPayloadDetails();
    setFacetsPayload();
  }, [queryParam, pageNumber, shouldComponentReload]);

  useEffect(() => {
    setIsComponentLoading(false);
  }, []);

  useEffect(() => {
    const handlePopstate = () => {
      // Reload the page when the user hits the back button
      window.location.reload();
    };

    // Attach the event listener when the component mounts
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (!paginationChange) {
      setCurrentPage(1);
      setPageNumber(1);
      calculateIndex(1);
    } else {
      calculateIndex(pageNumber);
    }
    if (searchResultsData && searchResultsData?.edges?.length < 1) {
      if (isOpenTextSearch) {
        setErrorState(0, 'openTextError');
      } else {
        window.location.search.includes(RnBFILTERS.distance)
          ? setErrorState(0, 'mileGenericError')
          : setErrorState(0, 'mileError');
      }
      setData([], false, false, []);
      setErrorCheck(true);
    } else if (
      searchResultsData &&
      searchResultsData?.edges?.length > 1 &&
      searchResultsData?.status?.[0]?.['__typename'] !== 'RestaurantsWithExpandedSearchRadiusInfo'
    ) {
      setErrorCheck(false);
      setErrorState(0, '');
    }
    if (
      searchResultsData &&
      searchResultsData?.edges?.length > 1 &&
      searchResultsData?.status?.[0]?.['code'] === 'SPELL_CORRECTED_INFO'
    ) {
      replacePlaceholderText(
        searchResultsData?.status?.[0]?.requestedSearchTerm,
        searchResultsData?.status?.[0]?.correctedSearchTerm
      );
    }
  }, [searchResultsData, shouldComponentReload, pageNumber]);

  useEffect(() => {
    /**
     * Getting current Data and Time to generate unique Correlation Id
     */
    if (!props?.isAuthorMode) {
      const currentTimestamp = Date.now();
      const currentDateTimeStamp = new Date(currentTimestamp).getTime();
      const isByLocationSearch = {
        country: country,
        state: state,
        facets: { terms: terms, ranges: ranges },
      };
      const isByGeoLocationSearch = {
        latitude: latitude,
        longitude: longitude,
        facets: { terms: terms, ranges: ranges },
      };
      const isByCountrySearch = {
        country: country,
        facets: { terms: terms, ranges: ranges },
      };

      const isByOpenTextSearch = {
        query: userInputOpenSearchTerm,
        facets: { terms: terms, ranges: ranges },
      };

      const locationQueryType =
        latitude && longitude
          ? searchRestaurantsByGeolocation
          : state && country
          ? searchRestaurantsByLocation
          : isOpenTextSearch
          ? searchRestaurantsByOpenText
          : searchRestaurantsByLocation;

      if ((latitude && longitude) || (state && country) || country || userInputOpenSearchTerm) {
        getSearchResultsDetails(
          {
            variables: {
              search:
                latitude && longitude
                  ? isByGeoLocationSearch
                  : state && country
                  ? isByLocationSearch
                  : isOpenTextSearch
                  ? isByOpenTextSearch
                  : isByCountrySearch,
              limit: PageSize,
              offset: pageNumber === 1 ? 0 : (pageNumber - 1) * PageSize,
            },
            context: {
              headers: {
                // Any header, including x-request-id, can be passed ui-libraryin args with query.
                // If you don't pass it in the authLink will generate a random ID.

                'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
                'x-request-id': requestId,
                'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
                'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
                'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
              },
            },
          },
          locationQueryType
        );
      }
    }
  }, [latitude, longitude, terms, ranges, pageNumber, state, country, shouldComponentReload, userInputOpenSearchTerm]);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setPayloadDetails = () => {
    const query = new URLSearchParams(queryParam);
    isOpenTextSearch = query.has(otsUrlTypeTerm) && query?.get(otsUrlTypeTerm) === otsUrlTypeValue;
    if (selectedPlaceDetailsData) {
      latitude = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.latitude;
      longitude = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.longitude;
      state = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.state;
      country = selectedPlaceDetailsData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.countryName;
    } else if (!selectedPlaceDetailsData && query.get('lat') && query.get('long')) {
      const lat: string | number = parseFloat(query?.get('lat')?.toString() ?? '0');
      const long: string | number = parseFloat(query?.get('long')?.toString() ?? '0');
      latitude = lat;
      longitude = long;
    } else if (
      !selectedPlaceDetailsData &&
      !query.get('lat') &&
      !query.get('long') &&
      query.get('term2') &&
      query.get('term3')
    ) {
      state = query?.get('term2');
      country = query?.get('term3');
    } else if (
      !selectedPlaceDetailsData &&
      !query.get('lat') &&
      !query.get('long') &&
      !query.get('term2') &&
      query.get('term3')
    ) {
      country = query?.get('term3');
    } else if (isOpenTextSearch) {
      userInputOpenSearchTerm = query?.get('term');
    }
  };

  const setFacetsPayload = () => {
    const query = new URLSearchParams(queryParam);
    let facetsVal: { type: string; dimensions: string[] } = {
      type: '',
      dimensions: [],
    };
    const routeKeys: string[] = [];
    query.forEach((_value, key) => {
      routeKeys.push(key);
    });
    const filteredArray = routeKeys.filter((value: string) => {
      return props?.filtersOrderList?.some((obj: any) => obj?.filterName === value);
    });
    hourSelection([]);
    cuisineSelection([]);
    distanceSelection([]);
    seatingSelection([]);
    bonvoySelection([]);
    brandSelection([]);
    venueTypeSelection([]);
    dietaryOptionsSelection([]);
    experiencesSelection([]);
    citySelection([]);
    stateProvinceDescSelection([]);
    countryDescSelection([]);

    if (filteredArray.length > 0) {
      filteredArray.forEach((filter: string) => {
        if (filter === 'brandId') {
          facetsVal = { type: '', dimensions: [] };
          const values: any = query?.get(filter) ? query?.get(filter) : '';
          let selectedVal: any = [];
          selectedVal = values?.split(',');
          facetsVal.type = filter;
          if (brands?.length > 0) {
            const getSelectedBrandObj = brands.filter((element: any) => selectedVal.includes(element.brandTagId));
            const brandTagIds = getSelectedBrandObj?.map((brand: any) => brand.brandTagId);
            facetsVal['dimensions'] = brandTagIds;
          } else facetsVal['dimensions'] = [...selectedVal];
          terms.push(facetsVal);
        } else if (filter === 'distance') {
          const distanceFacetsVal: {
            type: string;
            endpoints: string[];
            dimensions: string[];
          } = { type: '', endpoints: [], dimensions: ['1'] };
          const values: any = query?.get(filter) ? query?.get(filter) : '';
          distanceFacetsVal.type = filter;
          distanceFacetsVal['endpoints'] = [values.split(' ')[0], values.split(' ')[2]];
          ranges.push(distanceFacetsVal);
          storeUpdater(filter);
        } else {
          storeUpdater(filter);
        }
      });
    }
  };

  const generateQueryParams = (filterData: any, selectedVal: any, facetsVal: any) => {
    if (filterData?.length > 0) {
      const getSelectedFilter = filterData.filter((element: any) => selectedVal.includes(element.label));
      const filterLabels = getSelectedFilter?.map((filterName: any) => filterName.label);
      if (facetsVal.type === 'hours' || facetsVal.type === 'bonvoyMembers') {
        facetsVal['dimensions'] = getAEMKeyFromProps(props, filterLabels);
      } else if (facetsVal.type === 'city') {
        facetsVal['dimensions'] = filterLabels?.map((dimension: any) => {
          if (dimension?.includes(' -')) {
            const updatedDimension = dimension?.replace(' -', ',');
            return updatedDimension;
          } else return dimension;
        });
      } else facetsVal['dimensions'] = filterLabels;
    } else {
      if (facetsVal.type === 'hours' || facetsVal.type === 'bonvoyMembers') {
        facetsVal['dimensions'] = getAEMKeyFromProps(props, selectedVal);
      } else if (facetsVal.type === 'city') {
        facetsVal['dimensions'] = selectedVal?.map((dimension: any) => {
          if (dimension?.includes(' -')) {
            const updatedDimension = dimension?.replace(' -', ',');
            return updatedDimension;
          } else return dimension;
        });
      } else facetsVal['dimensions'] = [...selectedVal];
    }
    terms.push(facetsVal);
  };

  const storeUpdater = (filter: string) => {
    const query = new URLSearchParams(window.location.search);
    const facetsVal = { type: '', dimensions: [] };
    const values: any = query?.get(filter) ? query?.get(filter) : '';
    let selectedVal: string[] = [];
    selectedVal = values?.split(',');
    facetsVal.type = filter;
    switch (facetsVal.type) {
      case 'hours':
        generateQueryParams(hours, selectedVal, facetsVal);
        break;

      case 'cuisine':
        generateQueryParams(cuisine, selectedVal, facetsVal);
        break;

      case 'seating':
        generateQueryParams(seating, selectedVal, facetsVal);
        break;

      case 'bonvoyMembers':
        generateQueryParams(bonvoyMembers, selectedVal, facetsVal);
        break;

      case 'brandId':
        brandSelection(facetsVal);
        break;

      case 'distance': {
        const selectedDistance = distanceFilterOptions.filter(distance => distance.label === selectedVal[0]);
        distanceSelection(selectedDistance);
        break;
      }

      case 'venueType':
        generateQueryParams(venueType, selectedVal, facetsVal);
        break;

      case 'dietaryOptions':
        generateQueryParams(dietaryOptions, selectedVal, facetsVal);
        break;

      case 'experiences':
        generateQueryParams(experiences, selectedVal, facetsVal);
        break;

      case 'city':
        generateQueryParams(city, selectedVal, facetsVal);
        break;

      case 'stateProvinceDescription':
        generateQueryParams(stateProvinceDescription, selectedVal, facetsVal);
        break;

      case 'countryDescription':
        generateQueryParams(countryDescription, selectedVal, facetsVal);
        break;
    }
  };

  const calculateIndex = (page: number) => {
    const startInd = (page - 1) * PageSize;
    const endInd = startInd + PageSize;
    setStartIndex(startInd);
    setEndIndex(endInd);
    if (page === TotalPages) {
      setEndIndex(searchResultsData?.total);
    }
  };

  const updateStoreData = (name: string, filterName: string, storeFilterUpdater: any, query: URLSearchParams) => {
    const filterData = getProcessedFilterData(filterUpdateData?.facets, name, props);
    const filterValues: any = query.get(filterName) ? query.get(filterName) : '';
    if (filterValues) {
      let selectedFilter: any = [];
      selectedFilter = filterValues.split(',');
      const selectedFilterArr: any = [];
      filterData.forEach((item: any) => {
        if (selectedFilter.includes(item.label)) selectedFilterArr.push(item);
      });
      storeFilterUpdater(selectedFilterArr);
    }
  };

  // UXL call to get Search Results
  const searchResultDetailsClient = useApolloClient();
  async function getSearchResultsDetails(inputValues: any, locationQueryType: any) {
    setData([], true, false, []);
    let searchResults,
      searchResultsError = false,
      insufficentDataError = false;
    try {
      const { data } = await searchResultDetailsClient.query({
        query: locationQueryType,
        errorPolicy: 'all',
        ...inputValues,
      });
      if (
        data &&
        (data?.searchRestaurantsByGeolocation || data?.searchRestaurantsByLocation || data?.searchRestaurantsByOpenText)
      ) {
        let validData;
        if (locationQueryType === searchRestaurantsByGeolocation)
          validData = data?.searchRestaurantsByGeolocation?.edges?.filter((edge: any) => !!edge?.node?.name);
        else if (locationQueryType === searchRestaurantsByLocation)
          validData = data?.searchRestaurantsByLocation?.edges?.filter((edge: any) => !!edge?.node?.name);
        if (locationQueryType === searchRestaurantsByOpenText)
          validData = data?.searchRestaurantsByOpenText?.edges?.filter((edge: any) => !!edge?.node?.name);

        if (validData?.length === 0) {
          insufficentDataError = true;
          throw new Error('Insufficent data error');
        } else {
          insufficentDataError = false;

          if (locationQueryType === searchRestaurantsByGeolocation)
            searchResults = {
              ...data,
              searchRestaurantsByGeolocation: { ...data?.searchRestaurantsByGeolocation, edges: validData },
            };
          else if (locationQueryType === searchRestaurantsByLocation)
            searchResults = {
              ...data,
              searchRestaurantsByLocation: { ...data?.searchRestaurantsByLocation, edges: validData },
            };
          if (locationQueryType === searchRestaurantsByOpenText)
            searchResults = {
              ...data,
              searchRestaurantsByOpenText: { ...data?.searchRestaurantsByOpenText, edges: validData },
            };
        }
      } else throw new Error('Insufficent data error');
    } catch (error) {
      if (searchResults) searchResultsError = false;
      else searchResultsError = true;
      const errorCode = Number(getHttpStatusCode(error));
      const errorMessage = getErrorMessage(errorCode);
      if (errorCode) setErrorState(errorCode, errorMessage);
      else if (window.location.search.includes(RnBFILTERS.distance)) setErrorState(0, 'mileGenericError');
      else if (insufficentDataError) setErrorState(0, 'openTextError');
      else setErrorState(0, 'mileError');
      setData([], false, true, []);
      setIsLoaderVisible(false);
    } finally {
      if (
        !searchResultsError &&
        (searchResults?.searchRestaurantsByGeolocation?.edges?.length >= 0 ||
          searchResults?.searchRestaurantsByLocation?.edges?.length >= 0 ||
          searchResults?.searchRestaurantsByOpenText?.edges?.length >= 0)
      ) {
        if (
          searchResults?.searchRestaurantsByGeolocation?.edges?.length > 0 ||
          searchResults?.searchRestaurantsByLocation?.edges?.length > 0 ||
          searchResults?.searchRestaurantsByOpenText?.edges?.length > 0
        ) {
          filterUpdateData = searchResults?.searchRestaurantsByGeolocation
            ? searchResults?.searchRestaurantsByGeolocation
            : searchResults?.searchRestaurantsByOpenText
            ? searchResults?.searchRestaurantsByOpenText
            : searchResults?.searchRestaurantsByLocation;
          if (
            searchResults?.searchRestaurantsByGeolocation?.status[0]['__typename'] ===
            'RestaurantsWithExpandedSearchRadiusInfo'
          ) {
            setErrorState(0, props?.expandResultsError);
          } else setErrorState(0, '');
          setData(searchResults, false, false, props?.dynamicMedia);
          if (
            searchResults?.searchRestaurantsByOpenText &&
            searchResults?.searchRestaurantsByOpenText?.edges?.length > 1 &&
            searchResults?.searchRestaurantsByOpenText?.status?.[0]?.['code'] === 'SPELL_CORRECTED_INFO'
          ) {
            replacePlaceholderText(
              searchResults?.searchRestaurantsByOpenText?.status?.[0]?.requestedSearchTerm,
              searchResults?.searchRestaurantsByOpenText?.status?.[0]?.correctedSearchTerm
            );
          } else {
            setSearchTypeMessage(sortMessage, searchTextMessage, searchTextMessage);
          }
          if (filterUpdateData?.facets?.length > 0) {
            const query = new URLSearchParams(queryParam);

            const categorizedBrands = getCategorizedBrands(filterUpdateData?.facets, props?.categorizedBrandsArray);
            const values: any = query.get('brandId') ? query.get('brandId') : '';
            let selectedVal: string | any[] = [];
            selectedVal = values.split(',');
            const selectedBrandArr: any[] = [];
            categorizedBrands?.forEach((item: { brands: any[] }) => {
              item?.brands?.forEach((element: { brandTagId: any }) => {
                if (selectedVal.includes(element.brandTagId)) selectedBrandArr.push(element);
              });
            });
            brandSelection(selectedBrandArr);
            updateStoreData('hour', 'hours', hourSelection, query);
            updateStoreData('cuisine', 'cuisine', cuisineSelection, query);
            updateStoreData('seating', 'seating', seatingSelection, query);
            updateStoreData('bonvoyMember', 'bonvoyMembers', bonvoySelection, query);
            updateStoreData('city', 'city', citySelection, query);
            updateStoreData('stateProvinceDescription', 'stateProvinceDescription', stateProvinceDescSelection, query);
            updateStoreData('countryDescription', 'countryDescription', countryDescSelection, query);
            updateStoreData('venueType', 'venueType', venueTypeSelection, query);
            updateStoreData('dietaryOptions', 'dietaryOptions', dietaryOptionsSelection, query);
            updateStoreData('experiences', 'experiences', experiencesSelection, query);
          }
        } else {
          if (isOpenTextSearch) {
            setErrorState(0, 'openTextError');
            setData([], false, false, []);
          } else {
            if (window.location.search.includes(RnBFILTERS.distance)) setErrorState(0, 'mileGenericError');
            else setErrorState(0, 'mileError');
            setData([], false, false, []);
          }
        }
        setIsLoaderVisible(false);
      }
    }
  }

  const cardContainer = (index: number) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        config={{
          isEmpty: () => true,
          resourceType: `mi-aem-common-spa/components/container`,
        }}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardComponent = (cardName: string, jsonData: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          return <PromotionBanner {...item} />;
        }
      }
    }
    return null;
  };

  if (searchResultsDataError || isComponentLoading) {
    return null;
  }
  return searchResultDataLoading && !errorMessage ? (
    <div className="pt-5 pb-5">
      <SkeletonOutletCardLoader />
    </div>
  ) : !errorCheck && searchResultsData && searchResultsData?.total > 0 ? (
    <StyledSearchResultContainer data-component-name="o-rnb-searchresults" data-testid="searchresults">
      <GlobalStyle />
      <div className={clsx('container', isMobileViewPort ? ' ' : 'p-0')}>
        <SearchAllFilters {...props} isMobileViewPort={isMobileViewPort} currentPage={currentPageFromContext} />
        {searchTextMessageDisplay &&
          (!searchTextMessageDisplay.includes('[') || !searchTextMessageDisplay.includes(']')) && (
            <p className={clsx(isMobileViewPort ? 'pt-3 pb-0 t-subtitle-m' : 'pt-5 t-subtitle-l', 'm-0')}>
              {searchTextMessageDisplay}
            </p>
          )}
        <div className={clsx(isMobileViewPort ? 'pt-4' : 'pt-3', 'd-flex justify-content-between')}>
          <p className={clsx(isMobileViewPort ? 't-font-s' : 't-subtitle-l', 'pb-1', 'mb-0 pagination-details')}>
            {startIndex + 1}-{endIndex} of {searchResultsData?.total ? searchResultsData?.total : '0'} Restaurants
          </p>
          {sortMessage && <p className={clsx(isMobileViewPort ? 't-font-s' : 't-subtitle-l', 'm-0')}>{sortMessage}</p>}
        </div>

        {searchResultsData?.edges?.map((res: any, index: number) =>
          index === promBannerIndex && currentPage === outletPromotionCurrPage ? (
            <div key={`search-outlet-card-${index}`}>
              {isAuthorMode &&
                cardCount &&
                Array.from({ length: totalNumberOfCards.length }, (_, i) => cardContainer(i))}
              {!isAuthorMode &&
                cardCount &&
                totalNumberOfCards.map((cardName: string) => cardComponent(cardName, cqItems))}
              <SearchOutletCard restaurant={res} {...props} campaignParams={campaignParams} currentLocale={subLocale} />
            </div>
          ) : (
            <SearchOutletCard
              key={`search-outlet-card-${index}`}
              restaurant={res}
              {...props}
              campaignParams={campaignParams}
              currentLocale={subLocale}
            />
          )
        )}

        <div className="pagination-container mt-5" data-testid="pagination-panel">
          <PaginationPanel
            currentPage={currentPage}
            totalCount={searchResultsData?.total}
            pageSize={PageSize}
            onPageChange={(page: number): void => onPageItemClick(page)}
          />
        </div>
      </div>
    </StyledSearchResultContainer>
  ) : null;
};

export const SearchResultsConfig = {
  emptyLabel: 'SearchResults',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/searchresults`,
};

export const SearchResultsEditable = (props: any) => (
  <EditableComponent config={SearchResultsConfig} {...props}>
    <SearchResults {...props} />
  </EditableComponent>
);
