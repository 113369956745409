import React, { FC, useEffect, useState } from 'react';
import { PaymentInfoIframeProps } from './PaymentInfoIframe.types';
import { StyledPaymentInfoIframe } from './PaymentInfoIframe.styles';
import { useMounted } from '../../hooks';

export const PaymentInfoIframe: FC<PaymentInfoIframeProps> = props => {
  const { title, path, iframeRef, acceptedCreditCards, customerPaymentMethods, apiErrors, setPaymentSectionData } =
    props;

  const isMounted = useMounted();

  const [isPaymentSectionMounted, setIsPaymentSectionMounted] = useState<boolean>(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.origin) return;

      // set the state once child is mounted
      if (event.data?.isPaymentSectionMounted) {
        setIsPaymentSectionMounted(true);
      }

      // get the paymentSectionHeight from child and set it as iframe height
      if (iframeRef.current && event.data?.paymentSectionHeight) {
        iframeRef.current.height = event.data.paymentSectionHeight + 'px';
      }

      // get the field values from child and send to the parent
      if (event.data?.paymentSectionData) {
        setPaymentSectionData(event.data.paymentSectionData);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to send required data to child once child is mounted
  useEffect(() => {
    if (isPaymentSectionMounted && iframeRef?.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        { acceptedCreditCards, customerPaymentMethods, apiErrors },
        window.origin
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentSectionMounted, acceptedCreditCards, customerPaymentMethods, apiErrors]);

  if (!isMounted) {
    return null;
  }

  return (
    <StyledPaymentInfoIframe data-component-name="m-groups-PaymentInfoIframe" data-testid="groups-PaymentInfoIframe">
      <div className="loader">
        <div className="m-spinner-m"></div>
      </div>
      <iframe
        ref={iframeRef}
        title={title}
        src={path}
        className="container px-0"
        frameBorder="none"
        scrolling="no"
      ></iframe>
    </StyledPaymentInfoIframe>
  );
};
