import { FC, useEffect, useRef, useState, useContext } from 'react';
import clsx from 'clsx';
import { setCookie, getCookies } from '../../utils/cookieUtil';
import { PageParamsContext } from '../../../../modules/context/PageContext';
import { Constants, LOYALTY_ENABLED_ZONES } from '../../utils/constant';
import { submitDynamicForm, getEnrolledUserFlag, connectBtnTrigger } from '../../utils/handler';
import { LoginModalType } from '../../InternetBar.types';
import { InternetBarLoginModalProps } from './InternetBarModal.types';
import { Button, InputTextField } from '@marriott/mi-ui-library';
import { ButtonTypeVariation } from '../../../../modules/utils/enums/enums';

export const InternetBarLoginModal: FC<InternetBarLoginModalProps> = (props: InternetBarLoginModalProps) => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const { loginModal, loginModalType, internetBarDataValues, currentStateMapping } = props;
  const login = props?.login as LoginModalType;
  const [accessHref, setAccessHref] = useState<string>('');
  const [reqSMSHref, setReqSMSHref] = useState<string>('');
  const [isUserEnrolled, setIsUserEnrolled] = useState(false);
  const inputRef = useRef(null);
  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState<boolean>(false);
  const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] = useState<boolean>(false);

  const { unknownFreeConnection = false, offeredPlan = '' } = preProcessorResponse;
  const isNoLoginInterfaceModal = currentStateMapping?.noLoginInterfaceModal ?? false;

  const {
    MARSHA = '',
    postEndpoint = '',
    LR = '',
    Z = '',
    ACTION = '',
    DOMAIN = '',
    MS,
    APS,
    zoneMapping = '',
    usageAttr = '',
    USAGE = '',
    DURATION = '',
    ppv5Enrollment = false,
    loyaltyEnrollmentSourceCode,
  } = internetBarDataValues || {};

  const [isLoyaltyEnabled] = useState(
    (unknownFreeConnection || ppv5Enrollment) && LOYALTY_ENABLED_ZONES?.includes((Z || '').toUpperCase())
  );

  const [tierInfo] = useState(zoneMapping[Z ?? ''] ?? undefined);

  const [loginModalError, setLoginModalError] = useState<boolean>(loginModalType === 'LOGERR' ? true : false);

  const [loginDetails, setLoginDetails] = useState({
    roomNumber: '',
    lastName: '',
  });

  const [loginError, setLoginError] = useState({
    roomNumberError: false,
    lastNameError: false,
    showRoomNumberError: false,
    showLastNameError: false,
  });

  const getLoginModalInformation = () => {
    // saving cookie for user login data
    const userInfoCookie = getCookies('PPv5UserInfo');
    if (userInfoCookie) {
      const decodedText = window.atob(userInfoCookie);
      const textDecoderInstance = new TextDecoder('utf-8');
      const cookieDecoded = textDecoderInstance.decode(
        new Uint8Array([...decodedText].map(char => char.charCodeAt(0)))
      );
      const userInfoDetails = cookieDecoded.split('|');

      if (userInfoDetails.length === 3 && internetBarDataValues?.['MARSHA'] === userInfoDetails[0]) {
        setLoginDetails({
          lastName: userInfoDetails[1],
          roomNumber: userInfoDetails[2],
        });
      }
    }
  };

  const loginBtnTrigger = (): void => {
    const lastNameValue = loginDetails?.lastName;
    const roomNumberValue = loginDetails?.roomNumber;
    if (lastNameValue && roomNumberValue && postEndpoint) {
      try {
        const cookieKey = 'PPv5UserInfo';
        const textEncoderInstance = new TextEncoder();
        const encodedText = textEncoderInstance.encode(`${MARSHA}|${lastNameValue}|${roomNumberValue}`);
        const cookieValue = window.btoa(String.fromCharCode.apply(null, Array.from(encodedText)));
        setCookie(cookieKey, cookieValue);
        const obj = {
          MARSHA: MARSHA ? MARSHA : undefined,
          LR: !currentStateMapping?.showUpgradeModal ? LR : undefined,
          Z: !currentStateMapping?.showUpgradeModal ? Z : undefined,
          ACTION: ACTION ? ACTION : undefined,
          DOMAIN: DOMAIN ? DOMAIN : undefined,
          MS: MS || MS === '' ? MS : undefined,
          APS: APS,
          ROOM_NUMBER: roomNumberValue.trim(),
          LAST_NAME: lastNameValue.trim(),
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, postEndpoint);
      } catch (error) {
        // ignore
      }
    }
  };

  const freeConnectBtnTrigger = (): void => {
    setIsSecondaryButtonLoading(true);
    if (postEndpoint) {
      try {
        const aemUSAGE = usageAttr === Constants.usage.ELITE ? Constants.usage.ELITE : Constants.usage.FREE;
        const usage = USAGE ? USAGE : aemUSAGE;
        const sp =
          usageAttr === Constants.usage.ELITE &&
          (zoneMapping[Z ?? 'GUEST'] === 'FP' || zoneMapping[Z ?? 'GUEST'] === 'PP')
            ? Constants.plan.PLAN2
            : Constants.plan.PLAN1;
        const obj = {
          MARSHA: MARSHA ? MARSHA : undefined,
          LR: LR,
          Z: Z,
          ACTION: 'CONNECTED',
          DOMAIN: DOMAIN ? DOMAIN : undefined,
          MS: MS || MS === '' ? MS : undefined,
          APS: APS,
          USAGE: usage,
          SP: sp,
          DURATION: DURATION ? DURATION : undefined,
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, postEndpoint);
      } catch (error) {
        // ignore
      }
    }
  };

  const getPrimaryButtonCta = (): string => {
    let primaryBtnCTA: string | undefined = '';
    if (unknownFreeConnection || (isUserEnrolled && ppv5Enrollment)) {
      if (offeredPlan?.toUpperCase() === 'SF' || tierInfo?.toUpperCase() === 'SF') {
        primaryBtnCTA = login?.loyaltyContinueAsMemberCta;
      } else {
        primaryBtnCTA = login?.loginContinueButtonLabel;
      }
    } else {
      primaryBtnCTA = login?.loginButtonLabel;
    }
    return primaryBtnCTA || '';
  };

  const getSecondaryButtonCta = (): string => {
    let secondaryBtnCTA: string | undefined = '';
    if (
      (unknownFreeConnection || (isUserEnrolled && ppv5Enrollment)) &&
      (offeredPlan?.toUpperCase() === 'SF' ||
        tierInfo?.toUpperCase() === 'SF' ||
        offeredPlan?.toUpperCase() === 'FP' ||
        tierInfo?.toUpperCase() === 'FP')
    ) {
      secondaryBtnCTA = login?.loyaltyConnectAsGuestCta;
    } else {
      secondaryBtnCTA = login?.connectNowLabel;
    }

    return secondaryBtnCTA ?? '';
  };

  useEffect(() => {
    const handleQueryParamUpdate = (paramName: string, paramValue: string) => {
      const accessParams = new URLSearchParams(window.location.search);
      accessParams.set(paramName, paramValue);
      return `?${accessParams.toString()}`;
    };

    const access = handleQueryParamUpdate('MV', 'ACCESS');
    setAccessHref(access);
    const reqSMS = handleQueryParamUpdate('MV', 'REQSMS');
    setReqSMSHref(reqSMS);

    const enrolled = getEnrolledUserFlag(loyaltyEnrollmentSourceCode);
    setIsUserEnrolled(enrolled);
  }, []);

  useEffect(() => {
    if (loginModalType === 'LOGERR') {
      setLoginModalError(true);
      setLoginError({
        ...loginError,
        roomNumberError: true,
        lastNameError: true,
        showLastNameError: true,
        showRoomNumberError: true,
      });
    }
    getLoginModalInformation();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef && inputRef?.current && (inputRef?.current as HTMLInputElement)?.focus();
    }, 20);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {/* 1. Internet Bar Login Modal Default Configuration */}
      {loginModal?.default && (
        <div className="body-container d-flex flex-column">
          {/* 1.1 Internet Bar Login Modal Error */}
          {loginModal?.errorMessage && loginModalError && (
            <div className="d-flex pb-2">
              <span
                className={clsx(
                  `col-12 px-3 py-3 mb-4 internetbarmodal__error ${loginModal?.connectNowLabel ? 't-font-s' : ''}`
                )}
              >
                {login?.errorMessage}
              </span>
            </div>
          )}

          {isUserEnrolled && ppv5Enrollment && (
            <>
              {login?.loyaltyLoginWelcomeText && <h6 className="t-subtitle-m">{login?.loyaltyLoginWelcomeText}</h6>}
              {login?.loyaltyLoginDescription && <p className="t-font-xs pb-3">{login?.loyaltyLoginDescription}</p>}
            </>
          )}

          {unknownFreeConnection ? (
            <span className="t-subtitle-m pb-2">{login?.loyaltyGuestAtHotelDescription}</span>
          ) : isUserEnrolled && ppv5Enrollment ? null : (
            <span className="t-subtitle-m pb-2">{login?.guestLoginLabel}</span>
          )}

          {!(isUserEnrolled && ppv5Enrollment) && <span className="t-font-xs pb-4">{login?.requiredLabel}</span>}
          <div className="m-form-group pb-5 mb-3">
            <InputTextField
              type="text"
              label={login?.roomNumberLabel}
              placeHolderText={login?.roomNumberPlaceHolder}
              classNameForLineType="m-form-group mb-0"
              inputTextFieldClassName={`text-field pb-2 t-font-m px-0 ${
                loginError?.roomNumberError || loginModalError ? 'is-error' : ''
              }`}
              showIcon={false}
              getInputProps={() => ({
                ref: inputRef,
              })}
              variation="line-type"
              showUnderline={true}
              hideFontClass={true}
              togglePlaceholder={loginDetails?.roomNumber?.length > 0 ? false : true}
              assignInputValue={true}
              onBlur={() => {
                if (loginDetails?.roomNumber?.length === 0 && loginError?.showRoomNumberError) {
                  setLoginError({ ...loginError, roomNumberError: true });
                } else {
                  setLoginError({ ...loginError, roomNumberError: false });
                }
              }}
              getInputValue={value => {
                setLoginDetails({ ...loginDetails, roomNumber: value });
                setLoginError({ ...loginError, roomNumberError: false });
                if (value?.length > 0) {
                  setLoginError({ ...loginError, showRoomNumberError: true });
                  if (loginDetails?.lastName?.length > 0) {
                    setLoginModalError(false);
                  }
                }
              }}
              onFocus={() => {
                setIsPrimaryButtonLoading(false);
                setIsSecondaryButtonLoading(false);
                setLoginError({ ...loginError, roomNumberError: false });
              }}
              showErrorMessage={loginError?.roomNumberError || loginModalError}
              inputValue={loginDetails?.roomNumber}
            />
          </div>
          <div className="m-form-group pb-5 mb-3">
            <InputTextField
              type="text"
              label={login?.lastNameLabel}
              placeHolderText={login?.lastNamePlaceHolder}
              classNameForLineType="m-form-group mb-0"
              inputTextFieldClassName={`text-field pb-2 t-font-m px-0 ${
                loginError?.lastNameError || loginModalError ? 'is-error' : ''
              }`}
              togglePlaceholder={loginDetails?.lastName?.length > 0 ? false : true}
              showIcon={false}
              variation="line-type"
              showUnderline={true}
              hideFontClass={true}
              assignInputValue={true}
              onBlur={() => {
                if (loginDetails?.lastName?.length === 0 && loginError?.showLastNameError) {
                  setLoginError({ ...loginError, lastNameError: true });
                } else {
                  setLoginError({ ...loginError, lastNameError: false });
                }
              }}
              onFocus={() => {
                setIsPrimaryButtonLoading(false);
                setIsSecondaryButtonLoading(false);
                setLoginError({ ...loginError, lastNameError: false });
              }}
              getInputValue={value => {
                setLoginDetails({ ...loginDetails, lastName: value });
                setLoginError({ ...loginError, lastNameError: false });
                if (value?.length > 0) {
                  setLoginError({ ...loginError, showLastNameError: true });
                  if (loginDetails?.roomNumber?.length > 0) {
                    setLoginModalError(false);
                  }
                }
              }}
              showErrorMessage={loginError?.lastNameError || loginModalError}
              inputValue={loginDetails?.lastName}
            />
          </div>
          <Button
            isLink={false}
            type={ButtonTypeVariation.Button}
            className={clsx(
              `m-button-m m-button-primary t-font-m`,
              loginDetails?.lastName?.trim()?.length === 0 ||
                loginDetails?.roomNumber?.trim()?.length === 0 ||
                isPrimaryButtonLoading
                ? 'disabled'
                : ''
            )}
            isDisabled={
              loginDetails?.lastName?.trim()?.length === 0 ||
              loginDetails?.roomNumber?.trim()?.length === 0 ||
              isPrimaryButtonLoading
            }
            children={
              isPrimaryButtonLoading ? (
                <div className="m-spinner-s" data-testid="activate-spinner"></div>
              ) : (
                getPrimaryButtonCta()
              )
            }
            callback={
              !LR &&
              (offeredPlan?.toUpperCase() === 'SF' || tierInfo?.toUpperCase() === 'SF') &&
              (isLoyaltyEnabled || (isUserEnrolled && ppv5Enrollment))
                ? () => {
                    setIsPrimaryButtonLoading(true);
                    connectBtnTrigger(
                      (offeredPlan?.toUpperCase() === 'SF' || tierInfo?.toUpperCase() === 'SF') &&
                        (isLoyaltyEnabled || (isUserEnrolled && ppv5Enrollment))
                        ? true
                        : isNoLoginInterfaceModal,
                      internetBarDataValues
                    );
                  }
                : () => {
                    setIsPrimaryButtonLoading(true);
                    loginBtnTrigger();
                  }
            }
          />
        </div>
      )}
      <div className="d-flex flex-column">
        {/* 2. Internet Bar Login Modal Separator Configuration  */}
        {loginModal?.separatorLabel && (
          <span className="py-2 my-1 d-flex flex-row align-items-center justify-content-center">
            <span className="w-100 internetbarmodal__seperator"></span>
            <span className="px-2 mx-1 t-font-xs">{login?.separatorLabel}</span>
            <span className="w-100 internetbarmodal__seperator"></span>
          </span>
        )}
        {/* 2.1. Internet Bar Login Modal Connect Button Configuration  */}
        {loginModal?.connectNowLabel && !(isUserEnrolled && ppv5Enrollment && LR) && (
          <Button
            isLink={false}
            type={ButtonTypeVariation.Button}
            className={`m-button-m m-button-secondary ${isSecondaryButtonLoading ? 'disabled' : ''} t-font-m`}
            isDisabled={isSecondaryButtonLoading}
            children={
              isSecondaryButtonLoading ? (
                <div className="m-spinner-s" data-testid="activate-spinner"></div>
              ) : (
                getSecondaryButtonCta()
              )
            }
            callback={freeConnectBtnTrigger}
          />
        )}
        {/* 3. Internet Bar Login Modal Access Code Configuration  */}
        {loginModal?.userAccessCodeLabel && (
          <div className="d-flex flex-column mx-auto text-center">
            <a href={accessHref} className="t-font-s internetbarmodal__link">
              {login?.userAccessCodeLabel}
            </a>
          </div>
        )}
        {/* 4. Internet Bar Login Modal Access Code via SMS Configuration  */}
        {/* @todo Update this with Access Code via SMS  */}
        {loginModal?.requestAccessCodeLabel && (
          <div className="d-flex flex-column mx-auto text-center">
            <a
              href={reqSMSHref}
              className={clsx(
                `t-font-s internetbarmodal__link ${currentStateMapping?.showAccessModal ? 'mt-2 pt-1' : ''}`
              )}
            >
              {login?.requestAccessCodeLabel}
            </a>
          </div>
        )}
      </div>
    </>
  );
};
