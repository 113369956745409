// Styles for ConnectContainer go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';

export const StyledConnectContainer = styled.div`
  ${rtl`
  .nearby-outlet__filters {
    margin-bottom: ${toRem(48)} !important;
  }
  `}
`;
