// Styles for LetsConnect go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';

export const StyledLetsConnect = styled.div`
  ${rtl`
  .mdc-dialog {
    display: flex;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 150;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: none;
    display: block;
    min-height: 100%;
    transform: none;
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    &__scrim {
      position: fixed;
      pointer-events: auto;
    }

    .ppv5modal__header h2 {
      text-transform: capitalize;
    }

    .ppv5modal__body {
      margin-bottom: ${toRem(16)} !important;
      padding-bottom: 0 !important;
    }
    .lets-connect-close-button,
    .you-are-connected-close-button {
      text-decoration: underline;
    }
  }
  `}
`;
