import { FC, useState } from 'react';
import {
  Heading,
  Types,
  RadioButton,
  CheckBox,
  Button,
  Text,
  Modal,
  InlineMessages,
  InlineMessagesType,
} from '@marriott/mi-ui-library';
import { ENTER_KEY } from '../../constants';
import { BillingTypeSelectionProps } from './BillingTypeSelection.types';
import { StyledBillingTypeSelection } from './BillingTypeSelection.styles';

export const BillingTypeSelection: FC<BillingTypeSelectionProps> = ({
  labels,
  initialBillingType,
  optForIndividualReservations = true,
  initialPersonalizedContentOpted,
  setValue,
  hasError,
}) => {
  const {
    title,
    description,
    individualPaymentOption1,
    personalizedContent,
    whatsThis,
    whatsThisModal,
    individualPaymentOption2,
    groupPaymentOption1,
    groupPaymentOption2,
    requiredError,
  } = labels;

  const billingTypeOptions = (optForIndividualReservations ? [individualPaymentOption1] : []).concat([
    individualPaymentOption2,
    groupPaymentOption1,
    groupPaymentOption2,
  ]);

  const [selectedBillingType, setSelectedBillingType] = useState<string>(initialBillingType || '');
  const [isPersonalizedContentOpted, setIsPersonalizedContentOpted] = useState<boolean>(
    initialPersonalizedContentOpted || false
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleBillingTypeSelection = (selectedValue: string) => {
    setSelectedBillingType(selectedValue);
    setValue('billingType', selectedValue);
    if (selectedValue !== individualPaymentOption1) {
      setIsPersonalizedContentOpted(false);
      setValue('contentPersonalized', false);
    }
  };

  const handlePersonalizedContentSelection = (isChecked: boolean) => {
    setIsPersonalizedContentOpted(isChecked);
    setValue('contentPersonalized', isChecked);
  };

  return (
    <StyledBillingTypeSelection
      data-component-name="m-groups-BillingTypeSelection"
      data-testid="groups-BillingTypeSelection"
    >
      <Heading
        variation={Types.headingType.subtitle}
        element={Types.tags.h2}
        titleText={title}
        fontSize={Types.size.large}
      />
      {hasError ? (
        <div className="mb-3">
          <InlineMessages type={InlineMessagesType.Error} title={requiredError} severity="1" />
        </div>
      ) : null}
      <Text element={Types.tags.paragraph} copyText={description} fontSize={Types.size.small} customClass="mb-2" />
      {billingTypeOptions.map((billingTypeOption, index) => (
        <div key={`billingTypeOption${index}`}>
          <RadioButton
            radiobuttonId={`billingTypeOptionId${index}`}
            radiobuttonName={billingTypeOption}
            radiobuttonLabel={billingTypeOption}
            value={billingTypeOption}
            className="pt-3 pt-md-4"
            checked={selectedBillingType === billingTypeOption}
            setTabIndex={0}
            tabIndexForInput={-1}
            onChange={event => handleBillingTypeSelection(event.target.value)}
            onKeyDown={event => {
              if (event.key === ENTER_KEY) {
                event.preventDefault();
                handleBillingTypeSelection(billingTypeOption);
              }
            }}
          />
          {billingTypeOption === individualPaymentOption1 && selectedBillingType === individualPaymentOption1 && (
            <CheckBox
              checkboxId={`personalizedContent${index}`}
              checkboxName={personalizedContent}
              className="pt-3 pt-md-4 pl-5 personalized-content"
              checked={isPersonalizedContentOpted}
              setTabIndex={0}
              onChange={event => handlePersonalizedContentSelection(event.target.checked)}
              onKeyDown={event => {
                if (event.key === ENTER_KEY) {
                  event.preventDefault();
                  handlePersonalizedContentSelection(!isPersonalizedContentOpted);
                }
              }}
            >
              {personalizedContent}
              <Button
                buttonCopy={whatsThis}
                ariaLabel={whatsThis}
                className="t-font-xs whats-this-cta"
                callback={() => setShowModal(true)}
              />
            </CheckBox>
          )}
        </div>
      ))}

      {showModal ? (
        <Modal
          modalId="whatsThisModal"
          role="dialog"
          labelledBy="whatsThisModal"
          show={showModal}
          popupOpenState={showModal}
          handleBlur={true}
          disableScrollOnBody={true}
          setPopupOpenState={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
        >
          <Modal.Header
            labelText={whatsThisModal.title}
            customHeadingClass="t-subtitle-xl"
            closeBtnAriaLabel={whatsThisModal.closeCtaAriaLabel}
            popupHeaderOnCLoseFunc={() => setShowModal(false)}
          />
          <Modal.Body className="p-5">
            <div>{whatsThisModal.description}</div>
          </Modal.Body>
        </Modal>
      ) : null}
    </StyledBillingTypeSelection>
  );
};
