import React, { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { Button, Link, Types, baseVariables } from '@marriott/mi-ui-library';
import { useAuthStore, useLocaleStore } from '../../../store';
import { useMediaQuery, useFocusState } from '../../../hooks';
import { TextFormField } from '../../../molecules';
import { LoginPayload, login, getRememberedUserDetails, clearRememberedUserDetails } from '../../../utils';
import { PasswordType, SignInFormData, SignInFormProps } from './SignInForm.types';
import { StyledSignInForm } from './SignInForm.styles';

export const SignInForm: FC<SignInFormProps> = props => {
  const {
    title,
    email,
    password,
    notUserText,
    clearRemembered,
    forgotPassword,
    signInCta,
    signInCtaAriaLabel,
    signInUrl,
    returnUrl,
    errorUrl,
    userDetailsUrl,
  } = props;

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const { locale } = useLocaleStore();
  const { isRemembered, sessionId } = useAuthStore();

  const [loader, setLoader] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState<PasswordType>(PasswordType.PASSWORD);
  const [customerName, setCustomerName] = useState<string>('');
  const { focused, handleFocus, handleBlur } = useFocusState({});

  const { control, handleSubmit } = useForm<SignInFormData>({
    defaultValues: {
      userName: '',
      password: '',
    },
    reValidateMode: 'onSubmit',
  });

  const getRememberedUserInfo = useCallback(async () => {
    try {
      const response = await getRememberedUserDetails(userDetailsUrl, sessionId, locale);
      if (response?.data) {
        setCustomerName(response?.data?.headerSubtext?.consumerName);
      }
    } catch (error) {
      console.error('Error', error);
    }
  }, [userDetailsUrl, sessionId, locale]);

  useEffect(() => {
    if (isRemembered) {
      getRememberedUserInfo();
    }
  }, [isRemembered, getRememberedUserInfo]);

  const clearRememberedState = async (event: React.MouseEvent) => {
    event.preventDefault();
    try {
      const response = await clearRememberedUserDetails(clearRemembered.targetUrl);
      window.location.href = response.request?.responseURL;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const doLogin = async (payload: SignInFormData) => {
    const { userName, password } = payload;

    const loginPayload: LoginPayload = {
      userID: userName,
      password,
      signInUrl,
      returnUrl: `${returnUrl}${window.location.search}`,
      errorUrl,
    };

    try {
      setLoader(true);
      const response = await login(loginPayload);
      window.location.href = response.request?.responseURL;
    } catch (error) {
      console.error('Error', error);
    } finally {
      setLoader(false);
    }
  };

  const userNameField = (
    <Controller
      name="userName"
      control={control}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={email.label}
          placeholder={email.placeholder}
          maxLength={80}
          className="m-input-field mt-2"
          testId={'user-name'}
          onChange={event => {
            field.onChange(event);
          }}
        />
      )}
    />
  );

  const passwordField = (
    <Controller
      name="password"
      control={control}
      render={({ field }) => (
        <TextFormField
          {...field}
          type={passwordType}
          label={password.label}
          placeholder={password.placeholder}
          maxLength={20}
          className={clsx('m-input-field', isRemembered ? 'mt-2' : 'mt-5')}
          showIcon={focused[field.name] && !!field.value}
          iconClass={`icon icon-visibility${passwordType !== PasswordType.PASSWORD ? '-off' : ''}`}
          iconClickHandler={() => {
            setPasswordType(passwordType === PasswordType.PASSWORD ? PasswordType.TEXT : PasswordType.PASSWORD);
          }}
          testId={'password'}
          onChange={event => {
            field.onChange(event);
          }}
          onFocus={() => handleFocus(field.name)}
          onBlur={() => handleBlur(field.name)}
        />
      )}
    />
  );

  return (
    <StyledSignInForm>
      {isTabletAndAbove && <div className="form-title">{title}</div>}
      <form className="d-flex flex-column" onSubmit={handleSubmit(doLogin)}>
        {!isRemembered ? userNameField : null}
        {passwordField}
        {isRemembered ? (
          <div className="d-flex my-2">
            <div className="mr-2 user-name">{notUserText.replace('{XX}', customerName)}</div>
            <Link
              id="clear-remembered-user"
              target="_self"
              text={clearRemembered.label}
              linkClassName="clear-user-cta"
              linkHref="#"
              callback={clearRememberedState}
            />
          </div>
        ) : null}
        <Link
          id="forgot-password"
          target="_self"
          text={forgotPassword.label}
          linkClassName={'forgot-password-cta'}
          linkHref={forgotPassword.targetUrl}
        />
        <Button
          type={Types.ButtonTypeVariation.Submit}
          ariaLabel={signInCtaAriaLabel}
          className={clsx('m-button-primary button', loader && 'disabled skeleton-loader')}
          isDisabled={loader}
          testId="sign-in"
        >
          {signInCta}
        </Button>
      </form>
    </StyledSignInForm>
  );
};
