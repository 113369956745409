import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOverviewTab = styled.div`
  .overview-short-description {
    max-height: ${toRem(80)};
    overflow: hidden;
  }

  .overview-full-description {
    max-height: auto;
  }

  .overview-description {
    max-height: auto;
  }

  .show-more-button,
  .show-more-button:hover,
  .show-more-button:focus {
    background: none;
  }

  .show-more-icon {
    margin-left: ${toRem(14)};
    vertical-align: middle;
  }

  @media ${baseVariables.mediaQuery.md} {
    .overview-description {
      margin-bottom: ${toRem(20)};
    }
  }
`;
