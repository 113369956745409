import { useEffect, useState } from 'react';
import { StyledOverviewContainer, StyledCampaignContainer } from './OverviewSection.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Eyebrow, Heading, Text, Types } from '@marriott/mi-ui-library';
import { OverviewSectionProps, OverviewCampainDataProps } from './OverviewSection.types';
import { getDirection, useGetBreakpoint } from '../../modules/utils/helpers';
import clsx from 'clsx';
import { campaignDetailsStore } from '../../modules/store/campaignDetailsStore';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const OverviewSection: React.FC<OverviewSectionProps> = props => {
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const [campaignOverviewData, setCampaignOverviewData] = useState<OverviewCampainDataProps>();
  const campaignData = campaignDetailsStore(state => state.campaignData);
  const campaignDataError = campaignDetailsStore(state => state.campaignDataError);

  const isCampaignOverviewData: boolean =
    (props?.pageType === 'campaignPage' &&
      campaignOverviewData?.overviewTitle &&
      campaignOverviewData?.overviewDescription &&
      !campaignDataError) ||
    false;
  const isDirectionRTL = getDirection() === 'rtl';

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setCampaignOverviewData(campaignData);
  }, [campaignData, campaignDataError]);

  return isCampaignOverviewData || props?.pageType === 'earnRedeemPage' ? (
    <StyledCampaignContainer
      pageType={props?.pageType || ''}
      data-testid="overview"
      data-component-name="o-rnb-overview"
    >
      <div className="container">
        <div
          className={clsx(
            'd-flex flex-column',
            props?.pageType === 'earnRedeemPage'
              ? 'align-items-center pt-5 mt-3 mb-3 pb-5 text-center'
              : isDirectionRTL
              ? 'text-right'
              : 'text-left',
            'align-items-left campaign-overview-container'
          )}
        >
          {(campaignOverviewData?.overviewTitle || props?.title) && (
            <Heading
              titleText={
                props?.pageType === 'earnRedeemPage'
                  ? props?.title
                  : campaignOverviewData?.overviewTitle
                  ? campaignOverviewData?.overviewTitle
                  : ''
              }
              variation={Types.headingType.title}
              fontSize={props?.pageType === 'earnRedeemPage' ? Types.size.medium : Types.size.small}
              customClass={clsx(props?.pageType === 'earnRedeemPage' ? 'pb-1' : 'pb-2')}
            />
          )}
          {(campaignOverviewData?.overviewDescription || props?.bodyText) && (
            <Text
              copyText={
                props?.pageType === 'earnRedeemPage'
                  ? props?.bodyText
                  : campaignOverviewData?.overviewDescription
                  ? campaignOverviewData?.overviewDescription
                  : ''
              }
              fontSize={Types.size.medium}
              element={Types.tags.div}
              customClass={clsx(props?.pageType === 'earnRedeemPage' ? 'earn-redeem-description' : 'pb-2')}
            />
          )}
        </div>
      </div>
    </StyledCampaignContainer>
  ) : props?.pageType === 'cityPage' ? (
    <StyledOverviewContainer data-testid="overview" data-component-name="o-rnb-overview">
      <div className="container">
        <div className="p-xl-5 p-md-4 p-4 ">
          <div className="d-flex flex-column align-items-center m-xl-5 p-xl-5 p-4">
            <div className="mb-3 text-center">{props?.eyebrow && <Eyebrow text={props?.eyebrow} />}</div>
            {props?.title && (
              <Heading
                titleText={props?.title}
                variation={Types.headingType.title}
                fontSize={Types.size.medium}
                customClass="text-center"
              />
            )}
            {props?.bodyText && (
              <Text
                copyText={props?.bodyText}
                fontSize={isMobileViewPort ? Types.size.small : Types.size.medium}
                element={Types.tags.div}
                customClass="text-center col-12 col-md-9 col-lg-7 pt-2
                 px-4"
              />
            )}
          </div>
        </div>
      </div>
    </StyledOverviewContainer>
  ) : null;
};

export const OverviewSectionConfig = {
  emptyLabel: 'overview',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/overview`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OverviewSectionEditable = (props: any) => (
  <EditableComponent config={OverviewSectionConfig} {...props}>
    <OverviewSection {...props} />
  </EditableComponent>
);
