import { FC } from 'react';
import { Accordion, Eyebrow, Text, Types, baseVariables } from '@marriott/mi-ui-library';
import { getFormattedDate, getFormattedDateObject } from '../../utils/date';
import { formatAmount } from '../../utils/currency';
import { useMediaQuery } from '../../hooks';
import { useQuickGroupStore } from '../../store';
import { EventSpaceSummaryProps } from './EventSpaceSummary.types';
import { StyledAccordionDivider, StyledEventSpaceSummary } from './EventSpaceSummary.styles';

export const EventSpaceSummary: FC<EventSpaceSummaryProps> = props => {
  const { labels, data } = props;
  const filteredData = data.filter(dayEventSpaceSummary => !dayEventSpaceSummary.removed);

  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const { getEventSpaceConfiguration } = useQuickGroupStore();

  const summaryHeader = [
    { label: labels.eventTime, colWidth: 'col-6 col-md-4 event-time-heading' },
    { label: labels.attendees, colWidth: 'col-6 col-md-2 attendees-heading' },
    { label: labels.spaceSetup, colWidth: 'col-6 col-md-3 space-setup-heading' },
    { label: `${labels.total} (${data[0].currency}${labels.perDay})`, colWidth: 'col-6 col-md-3 total-heading' },
  ];

  return (
    <StyledEventSpaceSummary data-component-name="m-groups-EventSpaceSummary" data-testid="groups-EventSpaceSummary">
      <div className="t-subtitle-xl pb-2">{labels.title}</div>
      {filteredData.map((dayEventSpaceSummary, index) => (
        <div key={index}>
          <StyledAccordionDivider />
          <Accordion
            id={`EventSpaceSummary-day${index}`}
            isOpen={true}
            headerChildren={<div>{getFormattedDate(getFormattedDateObject(dayEventSpaceSummary.date))}</div>}
            headerAriaLabel={`${labels.title} ${getFormattedDate(getFormattedDateObject(dayEventSpaceSummary.date))}`}
          >
            <div key={`eventSpace${index}`} className="row">
              {isTabletAndAbove
                ? summaryHeader.map((heading, index) => (
                    <div key={`heading-row-${index}`} className={heading.colWidth}>
                      <Eyebrow text={heading.label} />
                    </div>
                  ))
                : summaryHeader.slice(0, 2).map((heading, index) => (
                    <div key={`heading-row1-${index}`} className={heading.colWidth}>
                      <Eyebrow text={heading.label} />
                    </div>
                  ))}
              <Text
                element={Types.tags.div}
                copyText={dayEventSpaceSummary.time.join(' - ')}
                fontSize={Types.size.medium}
                customClass="col-6 col-md-4 pt-3 t-font-weight-m event-time-value"
              />
              <Text
                element={Types.tags.div}
                copyText={dayEventSpaceSummary.attendees}
                fontSize={Types.size.medium}
                customClass="col-6 col-md-2 pt-3 t-font-weight-m attendees-value"
              />
              {!isTabletAndAbove &&
                summaryHeader.slice(-2).map((heading, index) => (
                  <div key={`heading-row2-${index}`} className={`${heading.colWidth} pt-4`}>
                    <Eyebrow text={heading.label} />
                  </div>
                ))}
              <Text
                element={Types.tags.div}
                copyText={getEventSpaceConfiguration(dayEventSpaceSummary.configuration)}
                fontSize={Types.size.medium}
                customClass="col-6 col-md-3 pt-3 t-font-weight-m space-setup-value"
              />
              <Text
                element={Types.tags.div}
                copyText={formatAmount(+dayEventSpaceSummary.rate)}
                fontSize={Types.size.medium}
                customClass="col-6 col-md-3 pt-3 t-font-weight-m total-value"
              />
            </div>
          </Accordion>
        </div>
      ))}
    </StyledEventSpaceSummary>
  );
};
