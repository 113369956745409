import { PicCollage } from './PicCollage/index';
import { useState, useEffect } from 'react';
import { StyledContainer } from './CollageEditorial.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Text, Types, Button, Icon } from '@marriott/mi-ui-library';
import { trackImpression, useGetBreakpoint } from '../../modules/utils/helpers';
import { CollageEditorialProps } from './index';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const CollageEditorial = (props: CollageEditorialProps) => {
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const { COLLAGE_EDITORIAL, LEARN_MORE_BUTTON, EXTERNAL_LINK } = TRACKING_CONSTANT;

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack)
      trackImpression(props?.trackingProperties || {}, 'Collage Editorial' || '');
  }, [props?.trackingProperties]);

  return (
    <StyledContainer data-testid="editorialcollage" data-component-name="o-rnb-editorialcollage">
      <div className="container px-3 px-lg-0 pt-lg-5 mt-5 mb-5 pb-1 pb-md-5 pt-sm-5">
        {props?.eyebrowText && <h4 className="t-overline-normal mb-0 mt-lg-5 pt-2">{props?.eyebrowText}</h4>}
        {props?.title && (
          <h3
            className={
              isMobileViewPort
                ? 'title t-title-m col-md-8 mb-0 p-0 pt-1'
                : 'title t-title-m col-md-8 mb-0 p-0 pt-1 t-font-weight-m'
            }
          >
            {props?.title}
          </h3>
        )}
        <div className="d-flex flex-row align-items-center pt-3">
          {props?.iconPath && <Icon iconClass={props?.iconPath} />}
          {props?.iconText && (
            <Text
              customClass="t-font-alt-s p-2 "
              fontSize={Types.size.small}
              element={Types.tags.paragraph}
              copyText={props?.iconText}
            />
          )}
        </div>
        <PicCollage {...props} />
        {/* <div className="floatingContainer col-md-7 pl-0">
          <div className="description">
            <Text
              customClass={isMobileViewPort ? 't-font-s desc' : 't-font-m'}
              copyText={props.description}
              fontSize={Types.size.medium}
              element={Types.tags.paragraph}
            />
          </div>

          <Button
            isLink
            href={props?.linkUrl}
            className="linkText custom_click_track m-link-tertiary-button"
            data-custom_click_track_value={`${COLLAGE_EDITORIAL}|${LEARN_MORE_BUTTON}|${EXTERNAL_LINK}`}
            target={props?.openInNewTab === true ? '_blank' : ''}
          >
            {props?.linkText}
          </Button>
        </div> */}
        <div className="floatingContainerNew d-flex flex-column align-items-end">
          <div className="description col-md-7 pl-0 mb-md-4 mt-5 pb-2">
            {props?.description && (
              <Text
                customClass={isMobileViewPort ? 't-font-s desc' : 't-font-m'}
                copyText={props?.description}
                fontSize={Types.size.medium}
                element={Types.tags.paragraph}
              />
            )}
            {props?.linkText && (
              <Button
                isLink
                href={props?.linkUrl}
                className="linkText custom_click_track m-link-tertiary-button"
                custom_click_track_value={`${COLLAGE_EDITORIAL}|${LEARN_MORE_BUTTON}|${EXTERNAL_LINK}`}
                target={props?.openInNewTab === true ? '_blank' : ''}
              >
                {props?.linkText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export const CollageEditorialConfig = {
  emptyLabel: 'editorialcollage',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/editorialcollage`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CollageEditorialEditable = (props: any) => (
  <EditableComponent config={CollageEditorialConfig} {...props}>
    <CollageEditorial {...props} />
  </EditableComponent>
);
