import React, { FC, useEffect, useRef, useState } from 'react';
import { Modal } from '@marriott/mi-ui-library';
import { tags } from '../../modules/utils/enums/enums';
import { modalHorizontalSpacing } from '../../modules/utils/constants';
import { Ppv5ModalProps } from './Ppv5Modal.types';
import { StyledPpv5Modal } from './Ppv5Modal.styles';

export const Ppv5Modal: FC<Ppv5ModalProps> = props => {
  const [showModal, setShowModal] = useState(props?.showModal);
  const [lastElement, setLastElement] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const handleCloseBtnClick = () => {
    setShowModal(false);
    props?.closeBtnHandler();
  };

  const handleDropdownClick = (event: MouseEvent) => {
    if (
      props?.setShowDropdown !== undefined &&
      props?.dropdownRef !== undefined &&
      !props?.dropdownRef?.current?.contains(event?.target as Node) &&
      modalRef?.current?.contains(event?.target as Node)
    ) {
      props?.setShowDropdown(false);
    }
    if (
      props?.setShowDropdownFirstTier !== undefined &&
      props?.dropdownRefFirst !== undefined &&
      !props?.dropdownRefFirst?.current?.contains(event?.target as Node) &&
      modalRef?.current?.contains(event?.target as Node)
    ) {
      props?.setShowDropdownFirstTier(false);
    }
    if (
      props?.setShowDropdownSecondTier !== undefined &&
      props?.dropdownRefSecond !== undefined &&
      !props?.dropdownRefSecond?.current?.contains(event?.target as Node) &&
      modalRef?.current?.contains(event?.target as Node)
    ) {
      props?.setShowDropdownSecondTier(false);
    }
  };

  const handleModalClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (props?.showModal && modalRef?.current) {
      if (!modalRef?.current?.contains(event?.target as Node)) {
        handleCloseBtnClick();
      }
    } else {
      setShowModal(true);
    }
  };

  const focusCloseBtn = () => {
    (modalRef?.current?.querySelector('.ppv5modal__close-btn') as HTMLElement)?.setAttribute('autofocus', 'true');
    (modalRef?.current?.querySelector('.ppv5modal__close-btn') as HTMLElement)?.focus();
  };

  const navigateToLastFocusableElement = (event: KeyboardEvent) => {
    event.preventDefault();
    const focusable =
      modalRef?.current?.querySelectorAll('button:not(:disabled), a, input, select, [tabindex]:not([tabindex="-1"])') ??
      [];
    const lastFocusable = focusable[focusable.length - 1];
    lastFocusable?.setAttribute('autofocus', 'true');
    (lastFocusable as HTMLElement)?.focus();
  };

  const handleModalFocus = (event: KeyboardEvent) => {
    if (event?.code?.toLowerCase() === 'tab') {
      if (lastElement) {
        setLastElement(false);
      } else {
        if (props?.showModal && modalRef?.current) {
          if (!modalRef?.current?.contains(event?.target as Node)) {
            focusCloseBtn();
          }
        }
      }
    }
  };

  const handleShiftTab = (event: KeyboardEvent) => {
    if (event?.code?.toLowerCase() === 'tab') {
      if (event?.shiftKey) {
        if ((document?.activeElement as HTMLElement)?.classList?.contains('ppv5modal__close-btn')) {
          navigateToLastFocusableElement(event);
          setLastElement(true);
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', handleModalClick);
      document.addEventListener('keyup', handleModalFocus);
      document.addEventListener('keydown', handleShiftTab);
    }, 0);

    return () => {
      document.removeEventListener('click', handleModalClick);
      document.removeEventListener('keyup', handleModalFocus);
      document.removeEventListener('keydown', handleShiftTab);
    };
  }, []);

  useEffect(() => {
    if (
      (props?.setShowDropdown !== undefined && props?.dropdownRef !== undefined) ||
      (props?.setShowDropdownFirstTier !== undefined && props?.dropdownRefFirst !== undefined) ||
      (props?.setShowDropdownSecondTier !== undefined && props?.dropdownRefSecond !== undefined)
    ) {
      document.addEventListener('click', handleDropdownClick);
    }

    return () => {
      if (
        (props?.setShowDropdown !== undefined && props?.dropdownRef !== undefined) ||
        (props?.setShowDropdownFirstTier !== undefined && props?.dropdownRefFirst !== undefined) ||
        (props?.setShowDropdownSecondTier !== undefined && props?.dropdownRefSecond !== undefined)
      ) {
        document.removeEventListener('click', handleDropdownClick);
      }
    };
  }, []);

  useEffect(() => {
    if (modalRef?.current?.querySelector('.ppv5modal_heading.ppv5modal__header') as HTMLElement) {
      const modalWidth =
        (modalRef?.current?.querySelector('.ppv5modal_heading.ppv5modal__header') as HTMLElement)?.clientWidth ?? 0;
      const modalTitleMaxWidth = modalWidth - modalHorizontalSpacing;
      if (modalRef?.current?.querySelector('.ppv5modal_heading.ppv5modal__header span')) {
        (
          modalRef?.current?.querySelector('.ppv5modal_heading.ppv5modal__header span') as HTMLElement
        ).style.maxWidth = `${modalTitleMaxWidth}px`;
      }
    }
  });

  useEffect(() => {
    const modalHeaderTitle = document?.querySelector('.header-heading');
    if (modalHeaderTitle) {
      if (props?.diningModal) {
        modalHeaderTitle.classList.value = 't-subtitle-m mx-2 mx-md-0 mx-xl-2 mb-0 d-flex align-items-center';
      } else if (props?.priceModal) {
        modalHeaderTitle.classList.value = 't-subtitle-m ml-2 ml-md-0 ml-xl-2 mb-0 d-flex align-items-center';
      } else if (props?.upgradeModal) {
        modalHeaderTitle.classList.value = 't-subtitle-m ml-2 mb-0 d-flex align-items-center';
      } else {
        modalHeaderTitle.classList.value = 't-subtitle-m ml-2 ml-md-0 ml-xl-2 mb-0 d-flex align-items-center';
      }
    }
  }, []);

  useEffect(() => {
    if (props?.showModal) {
      // document.querySelector('body')?.classList.add('mdc-dialog-scroll-lock');
      document.body.style.overflow = 'hidden';
    } else {
      // document.querySelector('body')?.classList.remove('mdc-dialog-scroll-lock');
      document.body.style.overflow = 'auto';
    }
  }, [props?.showModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    focusCloseBtn();
  }, [modalRef]);

  return (
    <StyledPpv5Modal data-component-name="o-ppv5-Ppv5Modal">
      <div className="mdc-dialog p-0">
        <Modal
          modalId="dialog"
          labelledBy="dialog"
          role="dialog"
          className={`container d-flex align-items-center ${props?.errorModal ? 'px-sm-0' : 'px-3 px-sm-0'} ppv5modal`}
          secondaryClassName={`col-12 col-md-6 col-xl-4 p-0 ${
            props?.diningModal ? 'mx-auto' : 'mx-0 mx-md-auto'
          } my-3 my-md-4 ppv5modal__container ${
            props?.diningModal ? `ppv5modal__container-dining ${props.diningModalClass}` : ''
          }`}
          show={showModal}
          handleBlur={true}
          popupOpenState={showModal}
          allFilterPopUp={true}
        >
          <div ref={modalRef}>
            <Modal.Header
              className={`${
                props?.diningModal ? 'p-4' : 'py-4 px-3'
              } d-flex justify-content-between ppv5modal_heading color-scheme4 ${
                props?.diningModal ? 'ppv5modal__header-dining' : 'ppv5modal__header'
              }`}
              labelText={props?.modalTitle}
              popupHeaderOnCLoseFunc={handleCloseBtnClick}
              popupCloseClassName="m-dialog-close-btn mr-2 mr-md-0 mr-xl-2 ppv5modal__close-btn"
              headingTagElement={tags?.span}
              customHeadingClass={`${
                props?.diningModal ? '' : 't-subtitle-m ml-2 ml-md-0 ml-xl-2 mb-0 d-flex align-items-center'
              }`}
            />
            <Modal.Body
              className={`${
                props?.diningModal || props?.priceModal || props?.upgradeModal ? 'p-0' : 'py-4 px-3 mx-2 my-2'
              } ppv5modal__body`}
            >
              {props?.modalContent}
            </Modal.Body>
          </div>
          {/* <div className="mdc-dialog__scrim ppv5modal__dialog"></div> */}
        </Modal>
      </div>
    </StyledPpv5Modal>
  );
};
