/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { getActiveGolfTab, useGetBreakpoint } from '../../modules/utils/helpers';
import { CreateNavBarOverlay, StyledNavigationTab, StyledNavigationTabMobile } from './NavigationTab.styles';
import { Button, Types } from '@marriott/mi-ui-library';
import { NavBarProps, NavigationTabProps } from './NavigationTab.types';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

const NavBarItems = (props: NavBarProps) => {
  const { tabList, activeTab, listStyleClass, listItemStyleClass, listItemSpanClass } = props;
  return (
    <ul className={listStyleClass} role="tablist">
      {tabList?.map((tabItem, index) => (
        <li
          role="tab"
          onClick={e => {
            e.currentTarget.focus();
          }}
          tabIndex={index}
          className={clsx(listItemStyleClass, activeTab === tabItem?.navbarLabel ? 'active' : 'inactive-tab')}
          aria-expanded={activeTab === tabItem?.navbarLabel ? 'true' : 'false'}
          data-testid={tabItem?.navbarLabel}
        >
          <a
            className={clsx(listItemSpanClass, 'item-heading')}
            href={tabItem?.navbarUrl}
            target={tabItem?.openInNewTab ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {tabItem?.navbarLabel}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const NavigationTab: React.FC<NavigationTabProps> = props => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(getActiveGolfTab(router?.pathname, props?.['navbar-props']));
  const [showNavbar, setShowNavbar] = useState(false);
  let portSize = useGetBreakpoint();
  const [isMobileViewPort, setIsMobileViewPort] = useState(portSize === 'mobile');

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      portSize = useGetBreakpoint();
      setIsMobileViewPort(portSize === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setActiveTab(getActiveGolfTab(router.pathname, props?.['navbar-props']));
  }, [router.pathname]);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return props?.['navbar-props'] && props?.['navbar-props']?.length > 0 ? (
    isMobileViewPort ? (
      <StyledNavigationTabMobile
        showNavbar={showNavbar}
        className={clsx(
          {
            'm-container-fullbleed': props?.styleclass?.includes('fullbleed'),
          },
          props?.styleclass
        )}
      >
        {showNavbar && <CreateNavBarOverlay />}
        <div className="mobile__title-container d-flex p-3 align-items-center">
          <span className="t-subtitle-l flex-grow-1 text-center">{activeTab}</span>

          <Button
            type={Types.ButtonTypeVariation.Button}
            className="p-0 mobile__hamburger-button"
            callback={handleShowNavbar}
            testId={showNavbar ? 'icon-clear' : 'icon-dropdown-down'}
          >
            <span className={clsx('p-0', showNavbar ? 'icon-clear' : 'icon-dropdown-down')}></span>
          </Button>
        </div>
        {showNavbar && (
          <div className="mobile__nav-bar-container p-4">
            <NavBarItems
              tabList={props?.['navbar-props']}
              activeTab=""
              listStyleClass="p-0 m-0 mobile__list-style"
              listItemStyleClass="mobile__list-item-style"
              listItemSpanClass="t-font-m"
            />
          </div>
        )}
      </StyledNavigationTabMobile>
    ) : (
      <StyledNavigationTab
        className={clsx(
          { 'm-container-fullbleed': props?.styleclass?.includes('fullbleed') },
          'pt-3',
          props?.styleclass
        )}
      >
        <nav className="container p-0">
          <NavBarItems
            tabList={props?.['navbar-props']}
            activeTab={activeTab || ''}
            listStyleClass="m-standard-tab-list"
            listItemStyleClass="pb-3 mr-4 m-standard-tab-list-item"
            listItemSpanClass="t-label-s"
          />
        </nav>
      </StyledNavigationTab>
    )
  ) : null;
};

export const NavigationTabConfig = {
  emptyLabel: 'navigationtab',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/navigationtab`,
};

export const NavigationTabEditable = (props: any) => {
  return (
    <EditableComponent config={NavigationTabConfig} {...props}>
      <NavigationTab {...props} />
    </EditableComponent>
  );
};
