export type LookupsByTypeResponse = {
  lookupsByType: {
    lookups: Lookup[];
  };
};

export type Lookup = {
  code: string;
  description: string;
  label: string;
  shortDescription?: string;
};

export enum LookupType {
  CALLING_CODES = 'COUNTRY_DIALING_CODES',
  COUNTRIES = 'COUNTRIES',
  STATES = 'STATE_OPTIONS',
  COST = 'COST_TYPES',
}
