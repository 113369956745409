import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledAuxiliarySection = styled.div`
  row-gap: ${toRem(40)};
  border-top: 1px solid ${baseVariables.color['neutral20']};
  padding-top: ${toRem(40)};
  padding-bottom: ${toRem(36)};

  .hours-content {
    max-width: ${toRem(200)};
  }

  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(40)} ${toRem(54)};
  }

  .auxiliary-item-title {
    column-gap: ${toRem(10)};
    color: ${baseVariables.color.black};
  }

  .auxiliary-item-content {
    text-align: justify;
  }

  .auxiliary-link {
    text-decoration: underline;
  }

  @media ${baseVariables.mediaQuery.md} {
    column-gap: ${toRem(58)};
    .auxiliary-item-first {
      flex-basis: 28%;
    }

    .auxiliary-item {
      flex-basis: 33.3%;
    }
  }
`;
