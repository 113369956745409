export type UpdateEventStateModalProps = {
  labels: (DisableEventLabels | EnableEventLabels) & { apiFailure: string };
  eventData: { eventId: string; eventName: string };
  updateEventStateType: UpdateEventStateType;
  showModal: boolean;
  closeModal: () => void;
  refetchEventList?: () => void;
};

export type DisableEventLabels = {
  heading: string;
  content: string;
  cancelCtaLabel: string;
  yesCtaLabel: string;
  backToReslinkHubPath: string;
};

export type EnableEventLabels = {
  heading: string;
  stepContent: string;
  cancelCtaLabel: string;
  confirmCtaLabel: string;
};

export enum UpdateEventStateType {
  ENABLE,
  DISABLE,
}
