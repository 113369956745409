export type StateCSS = {
  internetBarContent: string;
  internetBarContentContainer: string;
  internetBarContentDescriptionContainer: string;
  internetBarButtonContainer: string;
  internetBarButtonContentContainer: string;
  internetBarFooterContainer: string;
  internetBarIcon: string;
  internetBarNotWelcomeTitle: string;
  internetBarWelcomeText: string;
  internetBarDescription: string;
  internetBarSigninButton: string;
  internetBarJoinNowButton: string;
  internetBarConnectButton: string;
  internetBarTermsAndConditions: string;
  internetBarLoyaltyContainer: string;
  internetBarLoyaltyDescription: string;
  internetBarLoyaltySubDescription: string;
  internetBarLoyaltyButtons: string;
};

const STATE_CSS_INITIAL = {
  internetBarContent: '',
  internetBarContentContainer: '',
  internetBarContentDescriptionContainer: '',
  internetBarButtonContainer: '',
  internetBarButtonContentContainer: '',
  internetBarFooterContainer: '',
  internetBarIcon: '',
  internetBarNotWelcomeTitle: '',
  internetBarWelcomeText: '',
  internetBarDescription: '',
  internetBarSigninButton: '',
  internetBarJoinNowButton: '',
  internetBarConnectButton: '',
  internetBarTermsAndConditions: '',
  internetBarLoyaltyContainer: '',
  internetBarLoyaltyDescription: '',
  internetBarLoyaltySubDescription: '',
  internetBarLoyaltyButtons: '',
};

export const getInternetBarStateSpecificClasses = (
  userSegment: string,
  state: string,
  experienceSegment: string,
  unknownFreeConnection: boolean
): StateCSS => {
  const stateCSS = {
    ...STATE_CSS_INITIAL,
  };
  switch (state) {
    case 'in_stay':
      stateCSS.internetBarContent =
        userSegment === 'NOT Logged In'
          ? `internet-bar-content-${state}-signed-out`
          : `internet-bar-content-${state}-signed-in`;

      stateCSS.internetBarContentContainer =
        userSegment === 'NOT Logged In' ? '' : 'px-4 px-xl-0 pb-4 pb-xl-0 mb-4 mt-4 pt-2 mt-xl-0 pt-xl-1';
      stateCSS.internetBarIcon = 'my-4';
      stateCSS.internetBarNotWelcomeTitle = 'pb-2 mb-1 pb-xl-4 mb-xl-1 px-1 px-xl-0';
      stateCSS.internetBarContentDescriptionContainer =
        userSegment === 'NOT Logged In' ? 'pt-2 pt-xl-0' : 'pt-3 pt-xl-2';
      stateCSS.internetBarWelcomeText = 'mb-4';
      stateCSS.internetBarDescription = 'mb-4 pb-xl-3';
      stateCSS.internetBarButtonContentContainer = userSegment === 'NOT Logged In' ? 'mb-4 pb-xl-2' : '';
      stateCSS.internetBarSigninButton = 'm-button m-button-primary d-flex align-items-center justify-content-center';
      stateCSS.internetBarJoinNowButton = 'm-button-primary d-flex align-items-center justify-content-center';
      stateCSS.internetBarConnectButton = 'px-2 px-xl-0 mb-4 pb-xl-2';
      stateCSS.internetBarTermsAndConditions = 'pb-4 pb-xl-3 mb-xl-1';
      break;

    case 'connected':
      stateCSS.internetBarContentContainer = 'px-0 m-0 pt-xl-1 pt-2 pb-2 pb-xl-0';
      stateCSS.internetBarIcon = 'my-3';
      stateCSS.internetBarNotWelcomeTitle = 'pb-2 pb-xl-4 px-1 px-xl-0 mb-0 pt-xl-1';
      stateCSS.internetBarContentDescriptionContainer = 'pt-3 pt-xl-0 px-xl-4';
      stateCSS.internetBarWelcomeText = 'mb-4 mx-xl-3';
      stateCSS.internetBarDescription = `${experienceSegment.includes('-Unknown') ? 'mx-xl-3' : ''}`;
      stateCSS.internetBarSigninButton =
        experienceSegment === 'Connected-Elite-Tier1-Public-Bonvoy-RememberMe'
          ? 'm-button-secondary d-flex justify-content-center pt-2'
          : 'm-button-secondary d-flex justify-content-center pt-2';
      stateCSS.internetBarTermsAndConditions = 'pb-2 pb-xl-3';
      stateCSS.internetBarJoinNowButton = 'm-button-secondary d-flex justify-content-center';
      stateCSS.internetBarConnectButton = 'mb-2 px-2 px-xl-0 pb-xl-2 pt-2'; //mb-2 mt-3
      break;

    case 'upgrade':
      stateCSS.internetBarContentContainer =
        experienceSegment === 'Upgrade-Basic-UPrice-Public' ||
        experienceSegment === 'Upgrade-Elite-UPrice-Public-Device' ||
        experienceSegment === 'Upgrade-Elite-UPrice-Public-RememberMe' ||
        experienceSegment === 'Upgrade-Unknown-UPrice-Public'
          ? 'pt-xl-1 pt-2 pb-2 pb-xl-0'
          : 'pt-xl-1 pt-2 pb-3';
      stateCSS.internetBarIcon = 'my-3';
      stateCSS.internetBarNotWelcomeTitle = 'pb-4 mb-0 px-1 px-xl-0 pt-xl-1';
      stateCSS.internetBarContentDescriptionContainer = 'px-0 px-xl-4';
      stateCSS.internetBarWelcomeText = 'mb-4 mx-xl-3';
      stateCSS.internetBarDescription =
        experienceSegment.includes('RememberMe') || experienceSegment.includes('Unknown') ? 'mb-4 mx-xl-3' : '';

      stateCSS.internetBarButtonContentContainer =
        experienceSegment === 'Upgrade-Basic-UPrice-Public-Device' ||
        experienceSegment === 'Upgrade-Basic-UPrice-Public-RememberMe' ||
        experienceSegment === 'Upgrade-Elite-UPrice-Public-Device' ||
        experienceSegment === 'Upgrade-Elite-UPrice-Public-RememberMe' ||
        experienceSegment === 'Upgrade-Unknown-UPrice-Public'
          ? 'mb-3'
          : '';
      stateCSS.internetBarSigninButton =
        experienceSegment === 'Upgrade-Basic-UPrice-Public-Device' ||
        experienceSegment === 'Upgrade-Basic-UPrice-Public-RememberMe' ||
        experienceSegment === 'Upgrade-Elite-UPrice-Public-Device' ||
        experienceSegment === 'Upgrade-Elite-UPrice-Public-RememberMe'
          ? 'm-button-secondary mb-5 pt-2'
          : 'm-button-secondary pt-2 mb-3 mb-xl-2';
      stateCSS.internetBarJoinNowButton =
        experienceSegment === 'Upgrade-Unknown-UPrice-Public'
          ? 'm-button-secondary mb-5 pt-2'
          : experienceSegment === 'Upgrade-Unknown-Login' ||
            experienceSegment === 'Upgrade-Unknown-LoginError' ||
            experienceSegment === 'Upgrade-Unknown-UPrice-Guest'
          ? 'm-button-secondary mb-3 mb-xl-2'
          : 'm-button-secondary';
      stateCSS.internetBarConnectButton = 'm-button mb-2';
      stateCSS.internetBarTermsAndConditions = '';
      break;

    case 'upgraded':
      stateCSS.internetBarContentContainer = 'pt-xl-1 pt-2 pb-2 pb-xl-0';
      stateCSS.internetBarNotWelcomeTitle = 'pb-4 mb-0 px-1 px-xl-0 pt-xl-1';
      stateCSS.internetBarIcon = 'my-3';
      stateCSS.internetBarContentDescriptionContainer = 'px-0 px-xl-4';
      stateCSS.internetBarWelcomeText = 'mb-4 mx-xl-3';
      stateCSS.internetBarDescription =
        experienceSegment.includes('RememberMe') || experienceSegment.includes('Unknown') ? 'mb-4 mx-xl-3' : '';
      stateCSS.internetBarButtonContentContainer =
        experienceSegment === 'Upgraded-Basic-Device' ||
        experienceSegment === 'Upgraded-Basic-RememberMe' ||
        experienceSegment === 'Upgraded-Elite-Device' ||
        experienceSegment === 'Upgraded-Unknown'
          ? 'mb-3'
          : '';
      stateCSS.internetBarSigninButton =
        experienceSegment === 'Upgraded-Basic-Device' ||
        experienceSegment === 'Upgraded-Basic-RememberMe' ||
        experienceSegment === 'Upgraded-Elite-Device' ||
        experienceSegment === 'Upgraded-Elite-RememberMe'
          ? 'm-button-secondary mb-5 pt-2'
          : '';
      stateCSS.internetBarJoinNowButton =
        experienceSegment === 'Upgraded-Unknown' ? 'm-button-secondary mb-5 ' : 'm-button-secondary';
      stateCSS.internetBarConnectButton = 'px-2 px-xl-0 mb-4 pb-xl-2';
      stateCSS.internetBarTermsAndConditions = 'pb-4 pb-xl-3 mb-xl-1';
      break;

    case 'preconnect':
      stateCSS.internetBarNotWelcomeTitle = unknownFreeConnection
        ? 'pb-2 pb-md-4'
        : 'pb-2 mb-1 pb-xl-4 mb-xl-1 px-1 px-xl-0';
      stateCSS.internetBarIcon = unknownFreeConnection ? 'mt-5 mb-3' : 'my-4';
      stateCSS.internetBarContentDescriptionContainer = 'mb-3 pb-3 pb-xl-3 mb-xl-1 px-0 mx-xl-4 px-xl-2';
      stateCSS.internetBarButtonContainer = 'px-0';
      stateCSS.internetBarButtonContentContainer = 'pb-3 px-xl-4 mx-xl-1';
      stateCSS.internetBarConnectButton = 'm-button';
      stateCSS.internetBarFooterContainer = userSegment === 'NOT Logged In' ? 'px-0' : 'px-0 pb-4 mb-1 mb-xl-0';
      stateCSS.internetBarTermsAndConditions = unknownFreeConnection
        ? 'pb-3 pb-md-4'
        : 'pb-4 pb-xl-3 mb-xl-1 t-font-xs';
      stateCSS.internetBarDescription =
        userSegment === 'NOT Logged In' &&
        (experienceSegment.includes('RememberMe') || experienceSegment.includes('Unknown'))
          ? 'mb-0'
          : '';
      break;
  }

  return stateCSS;
};
