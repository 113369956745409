import React, { FC } from 'react';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import { MMNComponentProps } from './MMNComponent.types';
import { StyledMMNComponent } from './MMNComponent.styles';

export const MMNComponent: FC<MMNComponentProps> = pageProps => {
  const { bottomspacing, topspacing, posidesktop, posidmobile, dcnid } = { ...pageProps?.model };
  return (
    <StyledMMNComponent data-testid="mmn-container" data-component-name="o-ppv5-mmn-container">
      <div
        className={clsx(
          'mmn-ads-container text-center',
          bottomspacing ? 'mmn-bottom-spacing' : topspacing ? 'mmn-top-spacing' : ''
        )}
        data-pos-id-desktop={posidesktop}
        data-pos-id-mobile={posidmobile}
        data-dcn-id={dcnid}
      ></div>
    </StyledMMNComponent>
  );
};

export const MMNComponentConfig = {
  emptyLabel: 'MMNComponent',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `mi-aem-common-spa/components/content/mmn-container`,
} as Config<MappedComponentProperties>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MMNComponentEditable = (props: any) => {
  return (
    <EditableComponent config={MMNComponentConfig} {...props}>
      <MMNComponent {...props} />
    </EditableComponent>
  );
};
