export enum size {
  small = 's',
  medium = 'm',
  large = 'l',
  extraLarge = 'xl',
  extraSmall = 'xs',
}

export enum headingType {
  title = 'title',
  subtitle = 'subtitle',
}

export enum tags {
  span = 'span',
  div = 'div',
  paragraph = 'p',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

export enum BadgeVariation {
  'Inline' = 'inline',
  'Overlay' = 'overlay',
}

export enum ButtonTypeVariation {
  'Submit' = 'submit',
  'Reset' = 'reset',
  'Button' = 'button',
}

export enum Themes {
  'Standard' = 'standard',
  'Inverse' = 'inverse',
  'Alternate' = 'alternate',
}

export enum ScrimStyle {
  DARK_SCRIM = 'dark-scrim',
  LIGHT_SCRIM = 'light-scrim',
  NO_SCRIM = 'no-scrim',
}

export enum LinkTargetType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum TypographyColorVariations {
  'Alternate' = 'alt',
  'InverseAlt' = 'inverse-alt',
}

export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  isCoBrand?: boolean;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string | undefined;
  location?: string;
  payloadType?: string;
  compName?: string;
}

export interface TrackingDetailsProps {
  text: 'trackingContentPosition' | 'trackingDescription' | 'trackingOfferType' | 'trackingTag';
  pre: string;
}

export interface renditions {
  altText: string;
  assetPath: string;
  dynamic: boolean;
  renditions: Array<{
    renditionPath: string;
    mediaValue: string;
    dynamic: boolean;
    damPath: string;
    mediaQuery: string;
    width: number;
    height: number;
  }>;
}

export enum ButtonStyle {
  PRIMARY_BUTTON = 'm-button-primary',
  PRIMARY_BUTTON_EXTERNAME = 'm-button-primary icon-arrow-right-external',
  SECONDARY_BUTTON = 'm-button-secondary',
  SECONDARY_BUTTON_EXTERNAL = 'm-button-secondary icon-arrow-right-external',
  TERTIARY_BUTTON = 'm-link-tertiary-button',
  TERTIARY_BUTTON_EXTERNAL = 'm-link-tertiary-button-external',
}
