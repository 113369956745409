import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

// remove margin-top at line 7 once herobanner is added

export const StyledHomeEditContainer = styled.div`
  background: ${baseVariables.color.merch30};

  .icon-location {
    visibility: hidden;
  }

  .chef-name {
    white-space: normal;
  }

  .chef-details-container {
    .chef-image {
      aspect-ratio: 16/9;
      @media ${baseVariables.mediaQuery.sm} {
        aspect-ratio: 1/1;
      }
      width: 100%;
      border-radius: 0;
    }
    .chef-address-deatils {
      white-space: break-spaces;
      ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        text-align: center;
      }
    `}
    }

    .chef-description {
      white-space: pre-line;

      ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        text-align: unset;
      }
    `}

      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        text-align: right;
      }
    `}
      }
    }

    .chef-read-more {
      font-size: ${toRem(13)};
      ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        float: unset;
      }
    `}

      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      float: right;
    }
  `}
      }
    }

    .chef-address {
      border-top: ${toRem(1)} solid white;
      border-bottom: ${toRem(1)} solid white;
      margin: ${toRem(16)} ${toRem(30)} ${toRem(16)} ${toRem(30)};
      padding: ${toRem(4)} ${toRem(48)} ${toRem(4)} ${toRem(48)};
    }
  }

  @media ${baseVariables.mediaQuery.allTablets && baseVariables.mediaQuery.largeDesktop} {
    text-align: left;

    .icon-location {
      visibility: visible;
    }

    .chef-map-icon {
      margin-right: ${toRem(12)};

      ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        margin-left: ${toRem(12)};
      }
    `}
    }

    .chef-details-left-container {
      height: ${toRem(554)};
    }

    .chef-details-container {
      column-gap: ${toRem(90)};
      .chef-image {
        aspect-ratio: 1/1;
        min-width: 100%;
        border-radius: ${toRem(12)};
      }

      .chef-details-right-container {
        padding: ${toRem(16)} 0 ${toRem(48)} 0;
        .chef-spotlight {
          ${rtl`
          &:is(${constants.RTL_LANG_LIST}) {
            text-align: right;
          }
        `}
        }
      }

      .chef-name {
        white-space: pre-wrap;
        width: ${toRem(400)};
        ${rtl`
          &:is(${constants.RTL_LANG_LIST}) {
            text-align: right;
          }
        `}
      }
    }
  }
`;
