export type GetEventListAssociateInput = {
  propertyId: string;
  flags: EventListFlags;
  filter: EventListFilter;
};

type EventListFlags = {
  hasOldPlannerUpload: boolean;
  hasFreshPlannerUpload: boolean;
  hasAssociateUpload: boolean;
};

type EventListFilter = {
  type: EventFilterType;
  value: string;
};

export enum EventFilterType {
  TITLE = 'GROUPS_TITLE_SUBSTRING',
  MINIHOTEL_CODE = 'GROUPS_MINIHOTEL_CODE',
}

export type GetEventListAssociateResponse = {
  edges: {
    node: EventNode;
  }[];
  total: number;
};

type EventNode = {
  id: string;
  title: string;
  uploadDetails: UploadDetails;
  miniHotelDetails: MiniHotelDetails;
};

type UploadDetails = {
  id: string;
  status: EventStatus;
  createdOn: number;
  successEntries: null | number;
  failureEntries: null | number;
};

export enum EventStatus {
  NEW = 'new',
  OPENED = 'opened',
  UPLOAD_PENDING = 'uploadPending',
  UPLOAD_SUCCESS = 'uploadSuccess',
  UPLOAD_ERROR = 'uploadError',
}

type MiniHotelDetails = {
  id?: string;
  eventName?: string;
  startDate: string;
  endDate: string;
  cutoffDate: string;
};
