import styled from 'styled-components';
import rtl from 'styled-components-rtl';

export const StyledBonvoyApplicationAd = styled.div`
  ${rtl`
    .bonvoyapplication-ad__section {
    .icon-arrow-right-external {
     :lang(ar-AE), 
      :lang(ar) {
        &::before {
          right: 1rem !important;
        }
      }
      }
    }
  `}
`;
