import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledContainer = styled.div`
  background-color: ${baseVariables.color.merch50};

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}) {
    .m-link-tertiary-button::after
    {
      content: "\\e927";
      font-size: ${toRem(16)};
      padding-right: ${toRem(8)};
      line-height: ${toRem(18)};
      display: inline-block;
    }
   }
`}

  .linkText {
    text-decoration: none;
    margin-top: ${toRem(24)};

    @media ${baseVariables.mediaQuery.mobile} {
      float: left;
      text-decoration: none;

      ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        float: unset;     
      }
    `}
    }

    ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        margin-right: 0;
      }
    `}

    &::after {
      ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      padding-left: 0;
      padding-right: ${toRem(8)};
    }
  `}
    }
  }

  .description {
    height: auto;
    width: 100%;
    margin-top: ${toRem(31.5)};
    text-align: left;
    .desc {
      @media ${baseVariables.mediaQuery.mobile} {
        color: ${baseVariables.color.black};
      }
    }
    @media ${baseVariables.mediaQuery.mobile} {
      height: auto;
      width: 100%;
      text-align: left;
    }
    ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
        margin-top: 5rem !important;
        text-align: right;
    }
  `}
  }
  .floatingContainer {
    margin: ${toRem(28)} 0 0 0;
    text-align: left;
    padding-bottom: ${toRem(0)};

    @media ${baseVariables.mediaQuery.desktop}, @media ${baseVariables.mediaQuery.largeDesktop} {
      margin-left: ${toRem(772)};
      float: right;
      margin-top: -${toRem(50)};
      padding-bottom: ${toRem(120)};
    }
  }

  .floatingContainerNew {
    @media ${baseVariables.mediaQuery.allTablets} {
      margin-top: -${toRem(30)};
    }

    @media ${baseVariables.mediaQuery.desktop} {
      margin-top: -${toRem(50)};
    }

    @media ${baseVariables.mediaQuery.largeDesktop} {
      margin-top: -${toRem(50)};
    }
  }

  .container {
    ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right;
    }
  `}
    @media ${baseVariables.mediaQuery.desktop}, @media ${baseVariables.mediaQuery.largeDesktop} {
      width: 100%;
    }

    @media ${baseVariables.mediaQuery.mobile} {
      width: 100%;
      height: auto;
    }
  }
`;
