import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, mediaQuery } = baseVariables;

export const StyledDropdownContainer = styled.div`
  &.m-input-field {
    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: ${toRem(30)} !important;
    }
    position: relative;
  }
  .icon-arrow-up,
  .icon-arrow-down {
    pointer-events: none;
    font-size: ${toRem(12)};
  }
  @media ${mediaQuery.lg} {
    .icon-arrow-up,
    .icon-arrow-down {
      cursor: pointer;
      pointer-events: unset;
    }
  }
`;

export const StyledOptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: ${toRem(45)};
  left: 0;
  width: 100%;
  max-height: ${toRem(200)};
  overflow-y: auto;
  box-shadow: 0 ${toRem(2)} ${toRem(4)} rgba(0, 0, 0, 0.24);
  z-index: 2;
  box-sizing: border-box;
  border-radius: 0 0 ${toRem(10)} ${toRem(10)};
  background-color: ${color.alert10};
`;

export const StyledOptionItem = styled.li`
  padding: ${toRem(8)} ${toRem(8)} ${toRem(8)} ${toRem(15)};
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  &.active {
    font-weight: bold;
  }
  &.highlighted {
    background-color: ${color.neutral20};
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
