import React, { FC, useEffect, useContext } from 'react';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import { PageParamsContext } from '../../modules/context/PageContext';

import { phoenixPpv5Services } from '../../modules/graphql/index';
import { LocalServicesProps } from './LocalServices.types';
import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';

import { LocalServicesMenu } from './LocalServicesMenu/LocalServicesMenu';
import { LocalServicesCards } from './LocalServicesCards/LocalServicesCards';
import { statesConstant } from '../../modules/utils/constants';
import { LocalServicesCardsProps } from './LocalServicesCards';

import { localServicesMockUXL } from './__mock__/LocalServicesMock';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const LocalServices: FC<LocalServicesProps> = pageProps => {
  const isServer = !(typeof window != 'undefined' && window.document);
  const { preProcessorResponse, sessionID, requestId, lang } = useContext(PageParamsContext);
  const { state = '' } = preProcessorResponse || {};
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);
  let { data, loading } = useQuery(phoenixPpv5Services, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: preProcessorResponse?.marsha ?? '',
    },
    context: {
      headers: {
        'accept-language': lang ?? 'en-US',
        'x-b3-traceId': sessionID ?? 'fallback-token',
        'x-b3-spanId': requestId !== '' ? requestId : `${Date.now()}`,
        'correlation-id': sessionID ?? 'fallback-token',
      },
    },
    skip: pageProps.isAuthorMode,
  });

  if (pageProps.isAuthorMode) {
    loading = false;
    data = localServicesMockUXL.data;
  }

  useEffect(() => {
    if (
      state?.toLowerCase() === statesConstant.connected &&
      sessionStorage.getItem('amenityClicked') &&
      !sessionStorage.getItem('reloadConnectedUrl')
    ) {
      setServiceData(
        {
          serviceClicked: true,
        },
        false,
        false,
        {}
      );
    }
  }, []);

  return (
    <>
      {!loading && data && data?.property ? (
        <>
          <LocalServicesMenu data-component-name="o-ppv5-LocalServices" {...pageProps} {...data} />
          <LocalServicesCards {...pageProps} {...data} loading={loading} />
        </>
      ) : (
        <LocalServicesCards
          data-component-name="o-ppv5-LocalServices"
          {...(pageProps as LocalServicesCardsProps)}
          loading={loading}
        />
      )}{' '}
    </>
  );
};

export const LocalServicesConfig = {
  emptyLabel: 'services_carousel',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/services-carousel`,
} as Config<MappedComponentProperties>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LocalServicesEditable = (props: any) => (
  <EditableComponent config={LocalServicesConfig} {...props}>
    <LocalServices {...props} />
  </EditableComponent>
);
