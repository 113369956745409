import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledOutletAdditionalDetails = styled.div`
  max-width: ${toRem(243)};

  .social-icons {
    font-size: ${toRem(20)};
  }

  @media ${baseVariables.mediaQuery.md} {
    max-width: auto;
  }

  .additional-details {
    &__link {
      text-decoration: underline;
      text-transform: capitalize;
    }
    &__non-link {
      cursor: default;
      text-align: left;
      background: none;
      padding-left: 0px;
    }
    span:first-child {
      font-size: ${toRem(16)};
    }
  }

  .social-icons-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    column-gap: ${toRem(20)};
    width: fit-content;
    row-gap: ${toRem(14)};
  }
`;
