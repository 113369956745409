import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const SearchFormLoaderStyles = styled.div`
  .searchForm {
    position: absolute;
    z-index: 2;
    top: 8rem;
    left: 1rem;
    right: 1rem;
    width: calc(100% - 2rem);
    margin: 0px auto !important;
  }
  .searchFormStyles {
    width: 100%;
    height: 4.5rem;
    padding: 1rem;
    border-radius: 0.75rem;
    margin-top: 1.25rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0.25rem 0.5rem 0px;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      height: 5.75rem;
      padding: 0px 2.5rem;
      margin-top: 2.5rem;
    }
  }
  .searchFormLoaderButton {
    display: flex;
    justify-content: right;
    .react-loading-skeleton {
      border-radius: var(--t-button-border-radius, 2rem);
      position: absolute;
      top: ${toRem(25)};
      right: ${toRem(20)};
    }
  }
`;
