import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const ImageCollageContainer = styled.div`
  .imageBox {
    height: ${toRem(174)};
    position: relative;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    @media ${baseVariables.mediaQuery.mobile} {
      height: ${toRem(190)};
      width: 60%;
    }
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(600)};
      width: 90%;
    }
    @media ${baseVariables.mediaQuery.lg} {
      height: ${toRem(591)};
      width: 80%;
    }
    @media ${baseVariables.mediaQuery.xl} {
      width: 70%;
    }
  }
  .image {
    border-radius: ${toRem(4)};
    object-fit: cover;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      border-radius: ${toRem(14)};
    }
  }
  .roundedBox1 {
    position: absolute;
    box-sizing: border-box;
    background: white;
    z-index: 1;

    top: 0;
    right: 0;
    height: ${toRem(114)};
    width: 32%;
    border: ${toRem(4)} solid ${baseVariables.color.base20};
    border-radius: ${toRem(4)};

    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(8)};
    }

    @media ${baseVariables.mediaQuery.allTablets} {
      height: ${toRem(300)};
      width: 32%;
      border: ${toRem(14)} solid ${baseVariables.color.base20};
      border-radius: ${toRem(14)};
    }
    @media ${baseVariables.mediaQuery.desktop} {
      height: ${toRem(361)};
      width: 32%;
      border: ${toRem(14)} solid ${baseVariables.color.base20};
      border-radius: ${toRem(14)};
    }
  }
  .image1 {
    aspect-ratio: 2/3;
    height: ${toRem(106)};
    width: 100%;
    z-index: 1;
    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(8)};
    }
    @media ${baseVariables.mediaQuery.allTablets} {
      height: ${toRem(300)};
    }
    @media ${baseVariables.mediaQuery.desktop} {
      height: ${toRem(360)};
    }
  }
  .roundedBox2 {
    position: absolute;
    box-sizing: border-box;
    background: white;
    z-index: 1;

    bottom: 0;
    left: 0;
    height: ${toRem(114)};
    width: 32%;
    border: ${toRem(4)} solid ${baseVariables.color.base20};
    border-radius: ${toRem(24)};

    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(8)};
    }

    @media ${baseVariables.mediaQuery.allTablets} {
      height: ${toRem(300)};
      width: 32%;
      border: ${toRem(14)} solid ${baseVariables.color.base20};
      border-radius: ${toRem(14)};
    }
    @media ${baseVariables.mediaQuery.desktop} {
      height: ${toRem(361)};
      width: 32%;
      border: ${toRem(14)} solid ${baseVariables.color.base20};
      border-radius: ${toRem(14)};
    }
  }
  .image2 {
    aspect-ratio: 2/3;
    height: ${toRem(106)};
    width: 100%;
    z-index: 1;
    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(8)};
    }
    @media ${baseVariables.mediaQuery.allTablets} {
      height: ${toRem(300)};
    }
    @media ${baseVariables.mediaQuery.desktop} {
      height: ${toRem(360)};
    }
  }
  .roundedBox3 {
    height: ${toRem(129)};
    width: 52%;
    border: ${toRem(4)} solid ${baseVariables.color.base20};
    border-radius: ${toRem(4)};
    position: absolute;
    box-sizing: border-box;
    background: white;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(8)};
    }

    @media ${baseVariables.mediaQuery.allTablets} {
      height: ${toRem(400)};
      width: 56%;
      border: ${toRem(14)} solid ${baseVariables.color.base20};
      border-radius: ${toRem(14)};
    }
    @media ${baseVariables.mediaQuery.desktop} {
      height: ${toRem(437)};
      width: 56%;
      border: ${toRem(14)} solid ${baseVariables.color.base20};
      border-radius: ${toRem(14)};
    }
  }
  .image3 {
    aspect-ratio: 1/1;
    height: ${toRem(121)};
    width: 100%;
    @media ${baseVariables.mediaQuery.sm} {
      border-radius: ${toRem(8)};
    }
    @media ${baseVariables.mediaQuery.allTablets} {
      height: ${toRem(370)};
    }
    @media ${baseVariables.mediaQuery.desktop} {
      height: ${toRem(409)};
    }
  }
`;
