// Styles for InternetBarModal go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, contextualVariable, toRem } from '@marriott/mi-ui-library';

export const StyledInternetBarModal = styled.div`
  ${rtl`
  .internetbarmodal {
    &__link {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        font-weight: inherit;
        color: inherit;
        cursor: pointer;
      }
    }
    &__error {
      border: ${toRem(1)} solid ${baseVariables.color['alert50']};
      border-radius: ${toRem(8)};
      background-color: ${baseVariables.color['alert10']};
      color: ${baseVariables.color['alert50']};
    }
    &__seperator {
      width: 25%;
      height: ${toRem(1)};
      background-color: ${baseVariables.color['alert20']};
    }
    &-REQSMS,
    &-REQSMSERR,
    &-C_PRICE,
    &-U_PRICE {
      &__dropdownclosebtn {
        position: absolute;
        right: ${toRem(16)};
        cursor: pointer;
      }
      &__dropdownbtn {
        background: none;
        width: 100%;
        border-bottom: ${baseVariables.color['alert20']} solid ${toRem(2)};

        &:focus {
          border-bottom: ${baseVariables.color.brandedFontColor} solid ${toRem(2)};
        }
      }
      &__dropdownlist {
        width: 100%;
        position: absolute;
        max-height: ${toRem(412)};
        z-index: 1;
        border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
        border-radius: ${toRem(14)};
        background-color: ${baseVariables.color['alert10']};
        box-shadow: ${contextualVariable.linkIconFocusBoxShadow};
        overflow-y: auto;
      }
      &__dropdownitems {
        span {
          background-color: ${baseVariables.color['alert10']};
        }
        list-style-type: none;
        margin: 0 ${toRem(24)};
        border-bottom: solid ${toRem(1)} ${baseVariables.color['alert20']};
        min-height: ${toRem(68)};
        cursor: pointer;
        &:hover,
        &:focus {
          outline: solid ${toRem(1)} ${baseVariables.color['alert20']};
          background-color: ${baseVariables.color['memberSolidAlt']};
          border-bottom: none;
          margin: 0;
          padding: ${toRem(16)} ${toRem(24)};

          span {
            background-color: transparent;
          }
        }
        & {
          span[class*='arrow'] {
            visibility: hidden;
          }
        }
        &:hover,
        &:focus {
          span[class*='arrow'] {
            visibility: visible;
          }
        }
      }
      &__dropdownsearch {
        position: sticky;
        border-bottom: solid ${toRem(1)} ${baseVariables.color['alert20']};
        top: 0;
        background: ${baseVariables.color['alert10']};
      }
      &__searchinput {
        width: 100%;
      }
      &__phonenumbercontainer div label {
        margin-bottom: ${toRem(6)};
        font-weight: ${baseVariables.font['fontWeightMedium']};
      }
      &__countrycode {
        width: 100%;
        max-width: ${toRem(50)};
      }
    }
  }
  .mdc-dialog {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 150;
    overflow-y: auto;
    max-height: none;
    display: block;
    min-height: 100%;
    transform: none;
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    &__scrim {
      position: fixed;
      pointer-events: auto;
    }
  }
  .ppv5modal {
    &__container {
      position: relative;
      overflow: visible;
      box-sizing: border-box;
      pointer-events: auto;
      border: 0 solid ${baseVariables.color.base10};
    }
  }
  .m-input-text-field-no-border {
    &:after {
      display: none;
    }
    label {
      text-transform: uppercase;
      letter-spacing: ${toRem(1.296)};
      color: ${baseVariables.color.base10};
    }
    input {
      box-sizing: border-box;
      width: 100%;
      background-color: transparent;
      border-bottom: ${toRem(2)} solid ${baseVariables.color['neutral30']} !important;
    }
    .is-error,
    .is-error:focus {
      border-color: ${contextualVariable.formErrorColor} !important;
    }
  }
  .m-input-text-field-no-border
    input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']) {
    font-size: ${baseVariables.font['fontM']};
  }
  .m-input-text-field-no-border
    input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']).is-error:focus {
    border-bottom: ${toRem(2)} solid ${contextualVariable.formErrorColor} !important;
  }
  .m-input-text-field-no-border
    input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):focus {
    border-bottom: ${toRem(2)} solid ${contextualVariable.inputBorderFocusColor} !important;
  }
  .internet-bar-component {
    &__header {
      border: ${toRem(1)} solid ${baseVariables.color.neutral20};
      border-radius: ${toRem(8)};
      &--free {
        @media ${baseVariables.mediaQuery.xl} {
          margin-bottom: ${toRem(40)};
        }
      }
    }
    &__title {
      display: block;
      width: 100%;
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral20};
      border-radius: ${toRem(8)} ${toRem(8)} 0 0;
      background-color: ${baseVariables.color.memberSolidAlt};
      color: ${baseVariables.color['merch30']};
    }
    .modal-label {
      font-size: ${toRem(40)};
      line-height: ${toRem(32)};
    }
    &-seperator-line {
      width: ${toRem(79)};
      height: ${toRem(1)};
      background-color: ${contextualVariable?.accentColor};
    }
    &-desc {
      text-transform: none;
      font-weight: ${baseVariables.font['fontWeightRegular']};
    }
  }
`}
`;

export const StyledErrorBody = styled.div`
  ${rtl`
  .errorbody__container {
    border: ${toRem(1)} solid ${baseVariables.color['alert50']};
    border-radius: ${toRem(8)};
    background-color: ${baseVariables.color['alert10']};
    color: ${baseVariables.color['alert50']};
    span {
      color: ${baseVariables.color['alert50']};
    }
  }
  `}
`;

export const StyledNoErrorBody = styled.div`
  ${rtl`
  .noerrorbody__container {
    border: ${toRem(1)} solid ${baseVariables.color['alert20']};
    border-radius: ${toRem(8)};
    background-color: ${baseVariables.color['alert10']};
  }
    `}
`;
