import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library-shop';
import { baseVariables } from '@marriott/mi-ui-library';
import { PAGES } from '../../modules/utils/constants/constants';

interface dynamicOffersColor {
  currentPage?: string;
}

export const StyledOffersDynamic = styled.div<dynamicOffersColor>`
  background-color: ${props =>
    props.currentPage === PAGES.HOMEPAGE
      ? `${baseVariables.color.merch30}`
      : props.currentPage === PAGES.CITYPAGE
      ? `${baseVariables.color.merch50}`
      : ''};

  .card-align {
    text-align: center;
  }

  .offers-container {
    height: 100%;
  }

  .glide {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  height: 100%;
  .card-vertical {
    background-color: ${baseVariables.color.base20};
    position: relative;
    box-sizing: border-box;
    border-radius: ${toRem(12)};
    border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-shadow: ${baseVariables.shadows['shadowSubtle01']};
    .image {
      aspect-ratio: 16/9;
      max-width: 100%;
      width: 100%;
    }
    &__body {
      gap: ${toRem(7)};
      height: 100%;
    }
    &__content {
      height: 100%;
    }
    .bonvoy-wheels {
      padding-bottom: ${toRem(14)};
    }

    .sep-lines {
      border-top: 1px solid ${baseVariables.color.neutral30};
      border-bottom: 1px solid ${baseVariables.color.neutral30};
    }

    .description {
      p {
        margin: ${toRem(0)};

        text-align: center;
      }
    }
  }
  .glide__slides {
    white-space: normal;
    text-align: center;
    column-gap: ${toRem(16)};
  }

  .glide .controls_wrapper {
    position: relative;
    bottom: 0;
    padding: 0;
    text-align: -webkit-center;
  }

  .carousal_header .t-overline-normal {
    color: ${props =>
      props.currentPage === PAGES.HOMEPAGE ? `${baseVariables.color.base20}` : `${baseVariables.color.neutral40}`};
  }

  .t-title-s {
    color: ${baseVariables.color.merch50};
  }

  .carousal_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .carousal_header--cta {
      display: flex;
      align-items: flex-end;
    }
  }
  .glide {
    &__track {
      overflow: hidden;
      padding-top: ${toRem(4)};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }

    .center-align {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .glide--swipeable {
    cursor: default;
  }

  .glide__bullet {
    color: ${props =>
      props.currentPage === PAGES.HOMEPAGE
        ? `${baseVariables.color.base20}`
        : props.currentPage === PAGES.CITYPAGE
        ? `${baseVariables.color.merch50}`
        : ''};
  }
`;
