import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { StyledOffersMBEHero } from './OffersMBEHero.styles';
import {
  TextComponent as TextComponentComp,
  size,
  tags,
  Modal,
  Button,
  Text,
  Types,
  Link,
} from '@marriott/mi-ui-library';
import { getHeader, getDescription } from './utils/helper';
import { MBEMessageProps } from './OffersMBEHero.types';
import { useGetBreakpoint } from '../../utils/CommonUtils';
import { INTERNAL_CTA_TYPE } from '../../constants/CommonConstants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MBEMessage = (props: any) => {
  const { model, offersData, variation, isUserLoggedIn } = props;
  const { componentId, ctaLink } = model;
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  //const isMobileViewPort = true;
  const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
  const modalProps = {
    className: 'm-modal d-flex justify-content-center modal-body-offers',
    secondaryClassName: 'terms-modal-content',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleModal = (event: any) => {
    const body = document.querySelector('body');
    if (body) {
      if (!popupOpenState) {
        event.preventDefault();
        body.style.overflow = 'hidden';
        body.style.paddingRight = `15px`;
      } else {
        body.style.overflow = '';
        body.style.paddingRight = '';
      }
    }
    setPopupOpenState(!popupOpenState);
  };

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const header = getHeader(variation, isUserLoggedIn, offersData);
  const descriptionText = getDescription(variation, isUserLoggedIn, offersData);
  const joinNow = !isUserLoggedIn ? (isMobileViewPort ? model?.ctaLinkTextMobile : model?.ctaLinkText) : '';
  const alignment = isUserLoggedIn || isMobileViewPort ? 'left' : variation === 'active' ? 'center' : 'left';
  const eyebrow = isUserLoggedIn
    ? variation === 'active'
      ? 'LIMITED TIME OFFER'
      : ''
    : variation === 'active' || isMobileViewPort
    ? ''
    : '';
  const textMessage: MBEMessageProps = {
    header: header,
    subHeader: '',
    headerTag: tags.h5,
    headerFontSize: size.small,
    descriptionText: descriptionText,
    descriptionFontSize: size.medium,
    componentId: componentId,
    contentAlignment: alignment,
    primaryCtaLabel: '',
    primaryCtaLink: '',
    primaryCtaType: 'primaryButton',
    secondaryCtaLabel: '',
    secondaryCtaLink: '',
    secondaryCtaType: 'secondaryButton',
    eyebrow: eyebrow,
    styleclass: model?.styleclass,
    trackingProperties: model?.trackingProperties,
    openInNewTabPrimary: false,
    openInNewTabSecondary: false,
    showLessDesc: false,
    allowedComponents: {
      components: [
        {
          path: offersData?.allowedComponents?.components[0]?.path || '',
          header: offersData?.allowedComponents?.components[0]?.header || '',
        },
      ],
    },
  };

  return (
    <StyledOffersMBEHero>
      <div
        className={clsx(
          'text-flex',
          !isUserLoggedIn && variation === 'active' && !isMobileViewPort ? '' : 'text-align'
        )}
      >
        <TextComponentComp {...textMessage} />
        <div
          className={clsx(
            !isUserLoggedIn && variation === 'inactive' && !isMobileViewPort
              ? 'flex-row'
              : !isUserLoggedIn && variation === 'active' && !isMobileViewPort
              ? 'anonymous-active-desktop'
              : isMobileViewPort && !isUserLoggedIn
              ? 'anonymous-mobile'
              : ''
          )}
        >
          {!isUserLoggedIn && (
            <Button
              className={clsx(
                variation === 'active' ? 'm-button-secondary' : 'm-button-primary',
                'btn join-btn text-center',
                isMobileViewPort ? 'm-button-s' : 'm-button-m'
              )}
              isLink={true}
              href={ctaLink}
              custom_click_track_value={`${model?.id}|${
                isMobileViewPort ? model?.ctaLinkTextMobile : model.ctaLinkText
              }|${INTERNAL_CTA_TYPE}`}
              trackingProperties={model?.trackingProperties}
            >
              {joinNow}
            </Button>
          )}
          <div
            className={clsx(
              'terms-link',
              isUserLoggedIn ? (isMobileViewPort ? 'link-align-mobile' : 'link-align') : ''
            )}
          >
            <Link
              callback={event => toggleModal(event)}
              text={model?.termsAndConditions ?? ''}
              linkClassName={'m-details-page-TnC m-link custom_click_track'}
              linkHref={'#'}
              linkType="internal"
              target="_self"
              custom_click_track_value={`${model?.id}|${model?.termsAndConditions}|${INTERNAL_CTA_TYPE}`}
              trackingProperties={model?.trackingProperties}
            ></Link>
          </div>
        </div>
        {popupOpenState && (
          <Modal {...modalProps} show={true}>
            <Modal.Header
              labelText={model?.termsAndConditions ?? 'Terms & Conditions'}
              popupHeaderOnCLoseFunc={event => toggleModal(event)}
              className="terms-modal-header"
            />
            <Modal.Body>
              <div className="offers-terms-details">
                <Text
                  copyText={offersData?.hero?.termsAndConditions}
                  fontSize={Types.size.medium}
                  customClass={'offers-additional-terms'}
                  element={Types.tags.paragraph}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </StyledOffersMBEHero>
  );
};
