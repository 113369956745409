/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject } from 'react';
import {
  Constants,
  DEFAULT_LOYALTY_OBJ,
  INTERNETBAR_PRICING_ZONE_OPTIONS,
  INTERNETBAR_PRICING_TIER_OPTIONS,
} from './constant';
import { IDuration } from '../components/InternetBarModal/InternetBarModal.types';
import DOMPurify from 'isomorphic-dompurify';
import {
  FeeDetailsUXLType,
  PricingInfoProps,
  PricingInfoPropsUXLType,
  EnrollmentSourceCodeDivisionMapping,
  EnrollmentSourceCodeMapping,
  EnrollmentSourceCodeTierKey,
  EnrollmentSourceCodeTierMapping,
} from '../InternetBar.types';

export const submitDynamicForm = (body: any, postEndpoint: string): void => {
  const form = document.createElement('form');
  const parsedBody = JSON.parse(body);
  const submitFormEndpoint = postEndpoint.indexOf('://') === -1 ? `https://${postEndpoint}` : postEndpoint;
  form.setAttribute('method', 'POST');
  form.setAttribute('action', submitFormEndpoint);
  form.setAttribute('style', 'display: none');
  DOMPurify.sanitize(form, { RETURN_DOM_FRAGMENT: true });

  for (const property in parsedBody) {
    if (parsedBody[property] !== null) {
      const input = document.createElement('input');
      input.type = 'text';
      input.id = property;
      input.name = property;
      input.value = parsedBody[property];
      form.appendChild(input);
    }
  }

  // add the form inside the body
  document.getElementsByTagName('body')[0].appendChild(form); //pure javascript
  form.submit();
};

// @todo :: Move noLoginInterfaceModal Logic here
// const handleConnectNoLogin = () => {}

export const connectBtnTrigger = (
  isNoLoginInterfaceModal: boolean,
  internetBarDataValues: Record<string, any>
): void => {
  if (internetBarDataValues && internetBarDataValues?.['postEndpoint']) {
    if (isNoLoginInterfaceModal) {
      try {
        const zoneMapping = internetBarDataValues?.['zoneMapping'];
        const Z = internetBarDataValues?.['Z'] ?? '';
        let usage = internetBarDataValues?.['USAGE'] ?? '';
        const usageAttr = internetBarDataValues?.['usageAttr'] ?? '';
        const LR = internetBarDataValues?.['LR'] ?? '';
        const aemUSAGE = Constants.usageAEM[usageAttr] ? usageAttr : Constants.usage.FREE;

        const SP =
          usageAttr === Constants.usage.ELITE &&
          (zoneMapping[Z ?? 'GUEST'] === 'FP' || zoneMapping[Z ?? 'GUEST'] === 'PP')
            ? Constants.plan.PLAN2
            : Constants.plan.PLAN1;

        const sp = SP ?? '';

        if (
          sp === 'PLAN1' &&
          (zoneMapping[Z ?? 'PUBLIC'] === 'SF' ||
            zoneMapping[Z ?? 'GUEST'] === 'SF' ||
            zoneMapping[Z ?? 'MEETING'] === 'SF' ||
            zoneMapping[Z ?? 'CONFERENCE'] === 'SF')
        ) {
          usage = Constants.usage.FREE;
        }

        //newly added for LR TCTC value for FP and PP USAGE should be considered as FREE
        if (LR?.toUpperCase() === 'TCTC' && sp === 'PLAN1' && Z === 'GUEST') {
          usage = Constants.usage.FREE;
        }

        const obj = {
          MARSHA: internetBarDataValues?.['MARSHA'] ? internetBarDataValues?.['MARSHA'] : undefined,
          LR,
          Z,
          ACTION: 'CONNECTED',
          USAGE: usage ? usage : aemUSAGE,
          SP: sp,
          DURATION: internetBarDataValues?.['DURATION'] ? internetBarDataValues?.['DURATION'] : undefined,
          DOMAIN: internetBarDataValues?.['DOMAIN'] ? internetBarDataValues?.['DOMAIN'] : undefined,
          MS:
            internetBarDataValues?.['MS'] || internetBarDataValues?.['MS'] === ''
              ? internetBarDataValues?.['MS']
              : undefined,
          APS: internetBarDataValues?.['APS'],
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, internetBarDataValues?.['postEndpoint'] ?? '');
      } catch (error) {
        console.log('Error in connectBtnTrigger handler', error);
      }
    }
  }
};

export const getEnrolledUserFlag = (loyaltyEnrollmentSourceCode: string): boolean => {
  const dataLayer = window?.dataLayer ?? {
    ...DEFAULT_LOYALTY_OBJ,
  };
  const sessionData = (window?.sessionData &&
    window?.sessionData?.cacheData &&
    window?.sessionData?.cacheData?.data &&
    window?.sessionData?.cacheData?.data?.AriesRewards) ?? {
    ...DEFAULT_LOYALTY_OBJ,
  };
  if (dataLayer && sessionData) {
    return (dataLayer?.['mr_enrolled'] && dataLayer?.['mr_enrollment_source'] === loyaltyEnrollmentSourceCode) ||
      (sessionData?.['mr_enrolled'] && sessionData?.['mr_enrollment_source'] === loyaltyEnrollmentSourceCode)
      ? true
      : false;
  }
  return false;
};

export const handleDurationDropdownKeyDownEvent = (
  event: KeyboardEvent,
  dropdownListRef: MutableRefObject<null>,
  durations: IDuration[] = [],
  item: IDuration
) => {
  if (event?.code?.toLowerCase() === 'arrowdown') {
    event?.stopPropagation();
    setTimeout(() => {
      const dropdownList = dropdownListRef?.current;
      if (dropdownList) {
        const list = (dropdownList as HTMLElement)?.children;
        const dIndex = durations?.findIndex(d => d.value === item.value);
        let dValue: Element | null = null;
        if (dIndex < list?.length - 1) {
          dValue = list[dIndex + 1];
        } else {
          dValue = list[0];
        }
        (dValue as HTMLElement)?.focus();
      }
    }, 20);
  } else if (event?.code?.toLowerCase() === 'arrowup') {
    event?.stopPropagation();
    setTimeout(() => {
      const dropdownList = dropdownListRef?.current;
      if (dropdownList) {
        const list = (dropdownList as HTMLElement)?.children;
        const dIndex = durations?.findIndex(d => d.value === item.value);
        let dValue: Element | null = null;
        if (dIndex - 1 >= 0) {
          dValue = list[dIndex - 1];
        } else {
          dValue = list[list?.length - 1] ?? list[0];
        }
        (dValue as HTMLElement)?.focus();
      }
    }, 20);
  } else {
    event?.stopPropagation();
  }
};

const getTierOneInfo = (
  settlementCurreny: PricingInfoPropsUXLType,
  hasOtherThanHotelQuotingCurrency: boolean,
  defaultCurrency: string
) => {
  const tierOnePricingInfo = {
    hasTierOne: true,
    tierOneId: 12345,
    tierOneName: INTERNETBAR_PRICING_TIER_OPTIONS?.tierOne,
    tierOneFee: '0',
    currencyOne: defaultCurrency,
  };

  tierOnePricingInfo.hasTierOne = true;
  tierOnePricingInfo.tierOneId = settlementCurreny?.externalRecordId;
  tierOnePricingInfo.tierOneName = settlementCurreny?.tierType ?? INTERNETBAR_PRICING_TIER_OPTIONS?.tierOne;

  tierOnePricingInfo.tierOneFee = hasOtherThanHotelQuotingCurrency
    ? (settlementCurreny?.feeDetails?.settlementFee ?? 0)?.toString()
    : (settlementCurreny?.feeDetails?.fee ?? 0)?.toString();

  tierOnePricingInfo.currencyOne = hasOtherThanHotelQuotingCurrency
    ? settlementCurreny?.feeDetails?.settlementCurrency?.code ?? ''
    : defaultCurrency;

  return {
    ...tierOnePricingInfo,
  };
};

const getZonePricingInfo = (
  settlementCurrenyDetails: Array<PricingInfoPropsUXLType>,
  defaultCurrency: string,
  hotelTier: string
) => {
  let pricingInfo: PricingInfoProps = {
    tierTwoFee: '',
    tierOneFee: '',
    hasTierOne: false,
    hasTierTwo: false,
    tierTwoId: 0,
    tierOneId: 0,
  };

  if (settlementCurrenyDetails?.length > 1) {
    const hasOtherThanHotelQuotingCurrencyForZone =
      settlementCurrenyDetails?.every(
        (settlementCurreny: { hasOtherThanHotelQuotingCurrency: boolean }) =>
          settlementCurreny?.hasOtherThanHotelQuotingCurrency
      ) ?? false;

    const showDefaultPricing =
      settlementCurrenyDetails?.every(
        (settlementCurreny: { hasOtherThanHotelQuotingCurrency: boolean }) =>
          !settlementCurreny?.hasOtherThanHotelQuotingCurrency
      ) ?? false;

    if (hasOtherThanHotelQuotingCurrencyForZone || hotelTier === 'FP') {
      settlementCurrenyDetails?.forEach((settlementCurreny: PricingInfoPropsUXLType) => {
        if (settlementCurreny?.tierType === INTERNETBAR_PRICING_TIER_OPTIONS.tierOne) {
          const tierOneInfo = getTierOneInfo(settlementCurreny, true, defaultCurrency);
          pricingInfo = {
            ...pricingInfo,
            ...tierOneInfo,
          };
        } else if (settlementCurreny?.tierType === INTERNETBAR_PRICING_TIER_OPTIONS.tierTwo) {
          pricingInfo.hasTierTwo = true;
          pricingInfo.tierTwoId = settlementCurreny?.externalRecordId;
          pricingInfo.tierTwoName = settlementCurreny?.tierType ?? INTERNETBAR_PRICING_TIER_OPTIONS.tierTwo;

          if (hotelTier === 'FP') {
            pricingInfo.tierTwoFee = settlementCurreny?.hasOtherThanHotelQuotingCurrency
              ? (settlementCurreny?.feeDetails?.settlementFee ?? 0)?.toString()
              : (settlementCurreny?.feeDetails?.fee ?? 0)?.toString();

            pricingInfo.currencyTwo = settlementCurreny?.hasOtherThanHotelQuotingCurrency
              ? settlementCurreny?.feeDetails?.settlementCurrency?.code ?? ''
              : defaultCurrency;
          } else {
            pricingInfo.tierTwoFee = (settlementCurreny?.feeDetails?.settlementFee ?? 0)?.toString();
            pricingInfo.currencyTwo = settlementCurreny?.feeDetails?.settlementCurrency?.code ?? '';
          }
        }
      });
    } else if (showDefaultPricing) {
      settlementCurrenyDetails?.forEach((settlementCurreny: PricingInfoPropsUXLType) => {
        if (settlementCurreny?.tierType === INTERNETBAR_PRICING_TIER_OPTIONS.tierOne) {
          const tierOneInfo = getTierOneInfo(settlementCurreny, false, defaultCurrency);
          pricingInfo = {
            ...pricingInfo,
            ...tierOneInfo,
          };
        } else if (settlementCurreny?.tierType === INTERNETBAR_PRICING_TIER_OPTIONS.tierTwo) {
          pricingInfo.hasTierTwo = true;
          pricingInfo.tierTwoId = settlementCurreny?.externalRecordId;
          pricingInfo.tierTwoName = settlementCurreny?.tierType ?? INTERNETBAR_PRICING_TIER_OPTIONS.tierTwo;
          pricingInfo.tierTwoFee = (settlementCurreny?.feeDetails?.fee ?? 0)?.toString();
          pricingInfo.currencyTwo = defaultCurrency;
        }
      });
    } else {
      console.error('PRICING INFO | PAID-PAID | Settlement Currency NOT ENABLED for all the Tiers');
      return {
        zonePricingInfo: null,
        hasError: true,
      };
    }
  } else {
    const tierOneInfo = getTierOneInfo(
      settlementCurrenyDetails?.[0],
      settlementCurrenyDetails?.[0]?.hasOtherThanHotelQuotingCurrency,
      defaultCurrency
    );
    pricingInfo = {
      ...pricingInfo,
      ...tierOneInfo,
    };
  }

  if (
    (hotelTier === 'PP' && pricingInfo.currencyOne !== pricingInfo.currencyTwo) ||
    (hotelTier === 'PP' && parseFloat(pricingInfo?.tierTwoFee) < parseFloat(pricingInfo?.tierOneFee))
  ) {
    console.error('PRICING INFO | PAID-PAID | Settlement Currency Code Mismatch');
    return {
      zonePricingInfo: null,
      hasError: true,
    };
  }

  return {
    zonePricingInfo: { ...pricingInfo },
    hasError: false,
  };
};

const getPricingTierInfo = (settlementCurrenyDetails: Array<PricingInfoPropsUXLType>) => {
  let pricingTierInfo = '';
  if (settlementCurrenyDetails && settlementCurrenyDetails?.length) {
    if (settlementCurrenyDetails?.length === 1) {
      if (settlementCurrenyDetails?.[0]?.tierType === 'Tier1') {
        if (settlementCurrenyDetails?.[0]?.feeDetails?.feeType === 'C') {
          pricingTierInfo = 'SF';
        } else if (settlementCurrenyDetails?.[0]?.feeDetails?.feeType === 'F') {
          pricingTierInfo = 'SP';
        }
      } else {
        console.error('PRICING INFO | 1 TIER | Missing First Tier');
      }
    } else {
      settlementCurrenyDetails?.forEach(settlementCurreny => {
        if (settlementCurreny?.tierType === 'Tier1' && settlementCurreny?.feeDetails?.feeType === 'C') {
          pricingTierInfo = 'FP';
        } else if (settlementCurreny?.tierType === 'Tier1' && settlementCurreny?.feeDetails?.feeType === 'F') {
          pricingTierInfo = 'PP';
        }
      });
    }
  }

  console.log(`PRICING INFO | HOTEL TIER | ${pricingTierInfo}`);
  return pricingTierInfo;
};

export const settlementCurrencyHandler = (propertyPortalData: any, Z: string, defaultCurrency: string) => {
  const settlementCurrenyDetails: Array<PricingInfoPropsUXLType> =
    propertyPortalData?.property?.internet?.connectionPricingDetails ?? [];

  let pricingInfo: PricingInfoProps = {
    tierTwoFee: '',
    tierOneFee: '',
    tierTwoId: 0,
    tierOneId: 0,
  };

  let pricingInfoHasError: boolean = false;

  try {
    if (!settlementCurrenyDetails?.length) {
      pricingInfoHasError = true;
    } else if (Z?.toUpperCase() === 'PUBLIC' || Z?.toUpperCase() === 'CONFERENCE') {
      const settlementCurrenyPublicDetails = settlementCurrenyDetails?.filter(
        (settlementCurreny: { feeDetails: FeeDetailsUXLType; type: string }) =>
          settlementCurreny?.type === INTERNETBAR_PRICING_ZONE_OPTIONS?.public && settlementCurreny?.feeDetails
      );

      const hotelTier = getPricingTierInfo(settlementCurrenyPublicDetails);

      if (settlementCurrenyPublicDetails && settlementCurrenyPublicDetails?.length) {
        const { zonePricingInfo, hasError = false } =
          getZonePricingInfo(settlementCurrenyPublicDetails, defaultCurrency, hotelTier) ?? {};

        if (!hasError) {
          pricingInfo = { ...(zonePricingInfo as PricingInfoProps) };
        } else {
          pricingInfoHasError = true;
        }
      }
    } else {
      const settlementCurrenyGuestDetails: Array<PricingInfoPropsUXLType> = settlementCurrenyDetails?.filter(
        (settlementCurreny: { feeDetails: FeeDetailsUXLType; type: string }) =>
          settlementCurreny?.type === INTERNETBAR_PRICING_ZONE_OPTIONS?.guest && settlementCurreny?.feeDetails
      );

      const hotelTier = getPricingTierInfo(settlementCurrenyGuestDetails);

      if (settlementCurrenyGuestDetails && settlementCurrenyGuestDetails?.length) {
        const { zonePricingInfo, hasError = false } =
          getZonePricingInfo(settlementCurrenyGuestDetails, defaultCurrency, hotelTier) ?? {};

        if (!hasError) {
          pricingInfo = { ...(zonePricingInfo as PricingInfoProps) };
        } else {
          pricingInfoHasError = true;
        }
      }
    }
    if (pricingInfoHasError) {
      return {
        pricingInfo: null,
        hasError: true,
      };
    } else {
      return {
        pricingInfo: { ...pricingInfo },
      };
    }
  } catch (error) {
    return {
      pricingInfo: null,
      hasError: true,
    };
  }
};

const getPricingTierEnrollmentSourceCode = (
  guestZoneMapping: EnrollmentSourceCodeTierKey,
  pricingTier: Array<EnrollmentSourceCodeTierMapping>
) => {
  if (pricingTier) {
    let enrollmentSourceCode: EnrollmentSourceCodeTierMapping = pricingTier?.find(
      (element: EnrollmentSourceCodeTierMapping) => element.key === guestZoneMapping
    ) as EnrollmentSourceCodeTierMapping;
    enrollmentSourceCode = enrollmentSourceCode?.value
      ? enrollmentSourceCode
      : (pricingTier?.find(
          (element: EnrollmentSourceCodeTierMapping) => element.key === ('*' as unknown as EnrollmentSourceCodeTierKey)
        ) as EnrollmentSourceCodeTierMapping);

    return enrollmentSourceCode?.value ?? '';
  }
  return '';
};

const loyaltyEnrollmentSourceCode = (
  sourceCodeMappingAEM: Array<EnrollmentSourceCodeDivisionMapping>,
  uxlGlobalDivision: string,
  uxlCountryCode: string,
  guestZoneMapping: EnrollmentSourceCodeTierKey
) => {
  let finalEnrollmentSourceCode;

  // Step I :: Filter the enrollment data-list for the Global Division (coming from the UXL) instances
  const matchedGlobalDivision = sourceCodeMappingAEM?.filter((element: EnrollmentSourceCodeDivisionMapping) =>
    element?.globalDivision?.includes(uxlGlobalDivision)
  );

  // Step II :: Filter the country specific enrollments from the filtered Global Division List (matchedGlobalDivision)
  const matchedCountryCode = matchedGlobalDivision?.filter((element: EnrollmentSourceCodeDivisionMapping) =>
    element?.countryCode?.includes(uxlCountryCode)
  );

  // Step III :: Check for the pricing tier specific enrollment source code
  // if the country specific enrollment data is present
  // else fallback to the Global division enrollment source code

  if (matchedCountryCode && matchedCountryCode?.length) {
    finalEnrollmentSourceCode = getPricingTierEnrollmentSourceCode(
      guestZoneMapping,
      matchedCountryCode?.[0]?.pricingTier
    );
  } else {
    const globalDivision: EnrollmentSourceCodeDivisionMapping = matchedGlobalDivision?.find(
      (element: EnrollmentSourceCodeDivisionMapping) => !element?.countryCode?.length
    ) as EnrollmentSourceCodeDivisionMapping;
    finalEnrollmentSourceCode = getPricingTierEnrollmentSourceCode(guestZoneMapping, globalDivision?.pricingTier);
  }

  return finalEnrollmentSourceCode;
};

export const getRegionEnrollmentSourceCode = (
  enrollmentSourceCodeMappingAEM: EnrollmentSourceCodeMapping,
  currentRegionUXL: any,
  loyaltyEnableFlag: boolean,
  guestZoneMapping: EnrollmentSourceCodeTierKey,
  loyaltyEnrollmentSourceCodeValue: string,
  enrollmentSourceCode: string
) => {
  const sourceCodeMappingAEM: EnrollmentSourceCodeMapping = enrollmentSourceCodeMappingAEM
    ? enrollmentSourceCodeMappingAEM
    : { loyalty: [], nonloyalty: [] };
  const uxlGlobalDivision = currentRegionUXL?.basicInformation?.globalDivision;
  const uxlCountryCode = currentRegionUXL?.contactInformation?.address?.country?.code;

  const finalEnrollmentSourceCode = loyaltyEnrollmentSourceCode(
    loyaltyEnableFlag ? sourceCodeMappingAEM?.loyalty : sourceCodeMappingAEM?.nonloyalty,
    uxlGlobalDivision,
    uxlCountryCode,
    guestZoneMapping
  );

  const result = finalEnrollmentSourceCode
    ? finalEnrollmentSourceCode
    : loyaltyEnableFlag
    ? loyaltyEnrollmentSourceCodeValue
    : enrollmentSourceCode;

  console.log(`Final Enrollment Source Code : ${result}`);

  return result;
};
