import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOutletContainer = styled.div`
  background: ${baseVariables.color.base20};
  box-shadow: 0 ${toRem(16)} ${toRem(24)} rgba(0, 0, 0, 0.06), 0 ${toRem(2)} ${toRem(6)} rgba(0, 0, 0, 0.04),
    0 0 ${toRem(1)} rgba(0, 0, 0, 0.04);
  border-radius: ${toRem(8)};
`;

export const OutletMainContainer = styled.div`
  z-index: 7;

  &.tranform-outlet {
    position: relative;
    top: -5rem;

    // @media ${baseVariables.mediaQuery.md} {
    //   position: unset;
    //   transform: translate(0, ${toRem(-76)});
    // }

    // @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    //   position: unset;
    //   transform: translate(0, ${toRem(-64)});
    // }
  }
`;

export const StyledOutletInfo = styled.div`
  padding: ${toRem(0)} ${toRem(16)};
  display: grid;
  grid-template-columns: 100%;

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    grid-template-columns: 62% auto;
  }

  @media ${baseVariables.mediaQuery.md} {
    padding: 0 ${toRem(54)} ${toRem(20)} ${toRem(54)};
  }

  row-gap: ${toRem(32)};

  @media ${baseVariables.mediaQuery.tablet} {
    column-gap: ${toRem(82)};
    margin-bottom: ${toRem(16)};
    flex-basis: 60%;
  }
`;
