import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledContactInfoSummary = styled.div`
  .payment-info {
    text-transform: capitalize;
  }
  .accordion-container {
    border-top: ${toRem(1)} solid ${color.neutral40};
    .additional-info {
      word-wrap: break-word;
    }
  }
`;
