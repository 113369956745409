import { useEffect, useState, useContext, useCallback } from 'react';
import dynamic from 'next/dynamic';

import { geoSearchType, openTextSearchType, otsUrlTypeTerm, otsUrlTypeValue } from '../../modules/utils/constants';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { PAGES } from '../../modules/utils/constants';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { PageParamsContext } from '../../modules/context/PageContext';
import { SearchFormWrapperProps } from './SearchFormWrapper.types';
import { SearchFormSkeletonLoader } from '../../molecules/SearchFormSkeletonLoader';
import { getOffers } from '@marriott/mi-headless-utils';

// Dynamic Imports
const SearchByGeoLocation = dynamic<SearchFormWrapperProps>(() =>
  import('../SearchByGeoLocation').then(mod => mod.SearchByGeoLocation)
);
const SearchByOpenText = dynamic<SearchFormWrapperProps>(() =>
  import('../SearchByOpenText').then(mod => mod.SearchByOpenText)
);

const { SEARCH_RESULTS_PAGE } = PAGES;
const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const SearchFormWrapper = (props: SearchFormWrapperProps) => {
  const { currentPage } = useContext(PageParamsContext);
  const [searchType, setSearchType] = useState('');
  const setSearchTypeMessage = useStore((state: searchResultsState) => state.setSearchTypeMessage);
  const [searchFormData, setSearchFormData] = useState<SearchFormWrapperProps>(() => props);
  const [targetLoading, setTargetLoading] = useState<boolean>(() => (searchFormData?.mboxParameter ? true : false));

  const getOffersData = useCallback(async () => {
    try {
      const response = await getOffers('mbox-rnb-form');
      const searchFormData = { ...props, searchTypes: response?.searchType };
      if (response) {
        setSearchFormData(searchFormData);
      }
    } catch (err) {
      setTargetLoading(false);
      return;
    }
    setTargetLoading(false);
  }, []);

  useEffect(() => {
    if (searchFormData?.mboxParameter) {
      getOffersData();
    }
  }, [getOffersData]);

  useEffect(() => {
    if (currentPage === SEARCH_RESULTS_PAGE) {
      const queryParam = new URLSearchParams(window.location.search);
      let search = '';
      if (queryParam?.has(otsUrlTypeTerm) && queryParam?.get(otsUrlTypeTerm) === otsUrlTypeValue)
        search = openTextSearchType;
      else if (queryParam?.has('term')) search = geoSearchType;

      setSearchType(search);
      if (currentPage === SEARCH_RESULTS_PAGE && search === openTextSearchType)
        setSearchTypeMessage(
          searchFormData?.openTextSortMessage || '',
          searchFormData?.openTextMessage || '',
          searchFormData?.openTextMessage
        );
      else if (currentPage === SEARCH_RESULTS_PAGE && search === geoSearchType)
        setSearchTypeMessage(searchFormData?.geoSearchSortMessage || '', searchFormData?.geoSearchMessage || '');
      else setSearchTypeMessage(searchFormData?.geoSearchSortMessage || '', searchFormData?.geoSearchMessage || '');
    }
  }, [currentPage, searchFormData, setSearchTypeMessage]);

  return searchFormData?.mboxParameter && targetLoading ? (
    <SearchFormSkeletonLoader />
  ) : currentPage === SEARCH_RESULTS_PAGE && searchType === openTextSearchType && !targetLoading ? (
    <SearchByOpenText data-component-name="o-rnb-SearchFormWrapper" {...searchFormData} />
  ) : currentPage === SEARCH_RESULTS_PAGE && searchType === geoSearchType && !targetLoading ? (
    <SearchByGeoLocation data-component-name="o-rnb-SearchFormWrapper" {...searchFormData} />
  ) : currentPage !== SEARCH_RESULTS_PAGE && searchFormData?.searchTypes === openTextSearchType && !targetLoading ? (
    <SearchByOpenText data-component-name="o-rnb-SearchFormWrapper" {...searchFormData} />
  ) : currentPage !== SEARCH_RESULTS_PAGE && searchFormData?.searchTypes === geoSearchType && !targetLoading ? (
    <SearchByGeoLocation data-component-name="o-rnb-SearchFormWrapper" {...searchFormData} />
  ) : (
    <SearchByGeoLocation data-component-name="o-rnb-SearchFormWrapper" {...searchFormData} />
  );
};

export const SearchFormConfig = {
  emptyLabel: 'SearchForm',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/searchform`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchFormEditable = (props: any) => (
  <EditableComponent config={SearchFormConfig} {...props}>
    <SearchFormWrapper {...props} />
  </EditableComponent>
);
