import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledTextBlock = styled.div`
  .item-value {
    word-wrap: break-word;
    &:first-of-type {
      padding-top: ${toRem(8)};
    }
  }
`;
