import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { updateEventActiveStateMutation, UpdateEventActiveStateResponse } from '@marriott/mi-groups-graphql';
import { Button, InlineMessages, InlineMessagesType, Modal, Types } from '@marriott/mi-ui-library';
import {
  DisableEventLabels,
  EnableEventLabels,
  UpdateEventStateModalProps,
  UpdateEventStateType,
} from './UpdateEventStateModal.types';
import { StyledUpdateEventStateModal } from './UpdateEventStateModal.styles';
import { useLocaleStore } from '../../../store';

export const UpdateEventStateModal: FC<UpdateEventStateModalProps> = ({
  labels,
  eventData,
  updateEventStateType,
  showModal,
  closeModal,
  refetchEventList,
}) => {
  const { heading, cancelCtaLabel, apiFailure } = labels;
  const { content, yesCtaLabel, backToReslinkHubPath } = labels as DisableEventLabels;
  const { stepContent, confirmCtaLabel } = labels as EnableEventLabels;
  const { eventId, eventName } = eventData;

  const { locale } = useLocaleStore();
  const router = useRouter();

  const isEnableEvent = updateEventStateType === UpdateEventStateType.ENABLE;

  const [apiError, setApiError] = useState<boolean>(false);

  const [updateEventActiveState, { loading }] = useMutation<{
    groupsReslinkUpdateStatus: UpdateEventActiveStateResponse;
  }>(updateEventActiveStateMutation);

  const updateEventState = () => {
    setApiError(false);
    updateEventActiveState({
      variables: {
        input: {
          eventId,
          isActive: isEnableEvent,
        },
      },
      context: {
        headers: {
          'accept-language': locale,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ groupsReslinkUpdateStatus }) => {
        const { isUpdated } = groupsReslinkUpdateStatus;
        if (isUpdated) {
          closeUpdateEventStateModal();
          if (isEnableEvent) {
            refetchEventList?.();
          } else {
            router.push(backToReslinkHubPath);
          }
        } else {
          setApiError(true);
        }
      },
      onError: () => setApiError(true),
    });
  };

  const closeUpdateEventStateModal = () => {
    setApiError(false);
    closeModal();
  };

  return (
    <StyledUpdateEventStateModal
      modalId="updateEventStateModal"
      role="dialog"
      labelledBy="updateEventStateModal"
      show={showModal}
      popupOpenState={showModal}
      disableScrollOnBody={true}
      handleBlur={true}
      setPopupOpenState={closeUpdateEventStateModal}
      onClose={closeUpdateEventStateModal}
    >
      <Modal.Header
        headingTagElement={Types.tags.div}
        labelText={heading}
        popupHeaderOnCLoseFunc={closeUpdateEventStateModal}
      ></Modal.Header>
      {apiError && (
        <div className="px-5 mt-3">
          <InlineMessages type={InlineMessagesType.Error} title={apiFailure} severity="1" />
        </div>
      )}
      <Modal.Body className="pb-5 pr-5">
        <p>{(isEnableEvent ? stepContent : content)?.replace('{eventName}', eventName)}</p>
      </Modal.Body>
      <Modal.FooterGeneric
        fadeEffect={true}
        actions={
          <>
            <Button buttonCopy={cancelCtaLabel} className="m-button-secondary mr-2" callback={closeModal} />
            <Button
              buttonCopy={isEnableEvent ? confirmCtaLabel : yesCtaLabel}
              className={clsx('m-button-primary confirm-cta', loading && 'm-button skeleton-loader')}
              isDisabled={loading}
              callback={updateEventState}
            />
          </>
        }
      />
    </StyledUpdateEventStateModal>
  );
};
