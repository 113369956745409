/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo, createContext, useContext } from 'react';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
import { lookupsByType, searchRestaurantsByLocation } from '@marriott/mi-rnb-graphql';
import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import { StyledRecommendedOutlets, StyledVerticalSearchCards } from './RecommendedOutlets.styles';
import { Heading, Types, Text, Button } from '@marriott/mi-ui-library';
import { getCurrentLocation, getSubDirectoryLocale, useGetBreakpoint } from '../../modules/utils/helpers';
import searchResultsMockData from '../../organisms/SearchResults/__mocks__/SearchResultsFiltersMock.json';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { PageParamsContext } from '../../modules/context';
import {
  bonvoyMemberType,
  COUNTRYTYPE,
  earnBonvoyPointsFilter,
  earnWithBonvoyPoints,
  payBonvoyPointsFilter,
  payWithBonvoyPoints,
} from '../../modules/utils/constants/constants';
import { CountryDetailsProps, SearchResultData } from './RecommendedOutlets.types';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';
import { getDirection } from '../../modules/utils/helpers';
const {
  EARN_REDEEM_RECOMMENDATIONS,
  EARN_REDEEM_RECOMMENDATIONS_SEARCH_CTA,
  EARN_REDEEM_RECOMMENDATIONS_LOCATION_TRACKING_CTA,
  INTERNAL_LINK,
} = TRACKING_CONSTANT;

// Dynamic Imports
const SearchOutletCard = dynamic<unknown>(() =>
  import('../../organisms/SearchResults/SearchOutletCard').then(mod => mod.SearchOutletCard)
);

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const RecommendedOutlets = (props: any) => {
  let countryCode = '';
  let lat = 0;
  let long = 0;

  if (typeof window !== 'undefined' && window && window.dataLayer) {
    countryCode = window.dataLayer['browser_akamai_loc_country'] as string;
    lat = window.dataLayer['browser_akamai_loc_lat'] as number;
    long = window.dataLayer['browser_akamai_loc_long'] as number;
  }

  const [locationName, setLocationName] = useState('');
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const setData = useStore((state: searchResultsState) => state.setData);
  const searchResultsData = useStore((state: searchResultsState) => state.searchResultData);
  const GOOGLE_MAP_API_KEY: any = process.env['GOOGLE_MAP_API_KEY'];
  const { currentLocale, headersData } = useContext(PageParamsContext);
  const subLocale = getSubDirectoryLocale(currentLocale || 'en-US');
  const isDirectionRTL = getDirection() === 'rtl';

  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();

  const countryDetailsClient = useApolloClient();
  async function getCountryDetails() {
    try {
      const { data } = await countryDetailsClient.query({
        query: lookupsByType,
        errorPolicy: 'all',
        variables: {
          type: COUNTRYTYPE,
        },
        context: {
          headers: {
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
      if (data) {
        const countryDetails = data?.lookupsByType?.lookups?.filter(
          (country: CountryDetailsProps) => country.code === countryCode
        );
        setLocationName(countryDetails[0]?.label);

        const isByCountrySearch = {
          country: countryDetails[0]?.label,
          facets: {
            terms: [
              {
                type: bonvoyMemberType,
                dimensions: [earnBonvoyPointsFilter, payBonvoyPointsFilter],
              },
            ],
            ranges: [],
          },
        };

        getSearchResultsDetails({
          variables: {
            limit: 6,
            search: isByCountrySearch,
          },
        });
      }
    } catch (error) {
      setLocationName('');
    }
  }

  const searchResultDetailsClient = useApolloClient();
  async function getSearchResultsDetails(inputValues: any) {
    try {
      const { data } = await searchResultDetailsClient.query({
        query: searchRestaurantsByLocation,
        errorPolicy: 'all',
        ...inputValues,
        context: {
          headers: {
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
      if (data && data?.searchRestaurantsByLocation?.edges?.length > 0) {
        setData(data, false, false, props?.dynamicMedia);
      }
    } catch (error) {
      setData([], false, true, []);
    }
  }

  useEffect(() => {
    getCountryDetails();
  }, []);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (props?.isAuthorMode) {
      setData(searchResultsMockData?.data, false, false, props?.dynamicMedia);
    }
  }, [props?.isAuthorMode]);

  const makeGeoLocationCall = () => {
    const locationHandler = (data: Record<string, string>): void => {
      if (data) {
        const pageUrl = `https://${headersData['x-host']}${subLocale}/dining/search-results.mi${encodeURI(
          `?term=${data['address']}&lat=${lat}&long=${long}&bonvoyMembers=${earnWithBonvoyPoints},${payWithBonvoyPoints}`
        )}`;

        const target = props?.showNearbyOpenNewTab ? '_blank' : '_self';
        window.open(pageUrl, target);
      }
    };
    getCurrentLocation(locationHandler);
  };

  return searchResultsData?.edges?.length > 0 ? (
    <StyledRecommendedOutlets
      data-component-name="o-rnb-recommendationscardcontainer"
      data-testid="recommendationscardcontainer"
      className={clsx('container my-3', isMobileViewPort ? 'px-3' : 'p-0')}
    >
      {(props?.title || props?.subTitle || props?.showNearbyLabel) && (
        <div
          className={clsx(
            'd-flex justify-content-between recommended-header-section',
            isMobileViewPort
              ? 'flex-column align-items-start'
              : props?.subTitle === ''
              ? 'flex-row align-items-center'
              : 'flex-row align-items-end'
          )}
        >
          <div className={clsx('recommended-header-section__title', isDirectionRTL ? 'text-right' : 'text-left')}>
            <Heading
              customClass="mb-2"
              titleText={props?.title}
              variation={Types.headingType?.title}
              fontSize={Types.size.small}
            />
            {props?.subTitle && (
              <Text
                element={Types.tags.div}
                fontSize={Types.size.medium}
                copyText={props?.subTitle}
                customClass={!isMobileViewPort ? 'my-1' : ''}
              />
            )}
          </div>
          {props?.showNearbyLabel && (
            <div
              className={clsx(
                'recommended-header-section__location-link py-1',
                isDirectionRTL ? 'text-right' : 'text-left'
              )}
            >
              <Button
                className="m-link-tertiary-button show-venue-link"
                custom_click_track_value={`${EARN_REDEEM_RECOMMENDATIONS}|${EARN_REDEEM_RECOMMENDATIONS_LOCATION_TRACKING_CTA}|${INTERNAL_LINK}`}
                trackingProperties={props?.trackingProperties}
                callback={makeGeoLocationCall}
                isLink={true}
              >
                {props?.showNearbyLabel}
              </Button>
            </div>
          )}
        </div>
      )}
      <StyledVerticalSearchCards className="my-3">
        {searchResultsData?.edges?.map((outlet: SearchResultData, index: number) => (
          <div className="recommended-cards" key={index}>
            <SearchOutletCard
              restaurant={outlet}
              {...props}
              isVerticalCard={true}
              showDescriptionAlways={true}
              currentLocale={subLocale}
            />
          </div>
        ))}
      </StyledVerticalSearchCards>

      {props?.searchCtaLabelText && (
        <div className="d-flex justify-content-center">
          <Button
            className="m-button-s m-button-secondary my-3"
            target={props?.searchCtaOpenNewTab ? '_blank' : '_self'}
            isLink={true}
            href={
              `${subLocale}/dining/search-results.mi` +
              encodeURI(
                `?term=${locationName}&term3=${locationName}&bonvoyMembers=${earnWithBonvoyPoints},${payWithBonvoyPoints}&countryDescription=${locationName}`
              )
            }
            custom_click_track_value={`${EARN_REDEEM_RECOMMENDATIONS}|${EARN_REDEEM_RECOMMENDATIONS_SEARCH_CTA}|${INTERNAL_LINK}`}
            trackingProperties={props?.trackingProperties}
          >
            {props?.searchCtaLabelText}
          </Button>
        </div>
      )}
      <Script src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`} strategy="lazyOnload" />
    </StyledRecommendedOutlets>
  ) : null;
};

export const RecommendedOutletsConfig = {
  emptyLabel: 'RecommendedOutlets',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/recommendationscardcontainer`,
};

export const RecommendedOutletsEditable = (props: any) => (
  <EditableComponent config={RecommendedOutletsConfig} {...props}>
    <RecommendedOutlets {...props} />
  </EditableComponent>
);
