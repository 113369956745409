import { FC, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Heading, Types, Text, Button, Modal, InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { EventStatus } from '@marriott/mi-groups-graphql';
import { getFormattedDate } from '../../../utils';
import { EventSummaryCardProps } from './EventSummaryCard.types';
import { StyledEventSummaryCard, StyledModalContainer, StyledEventSummaryContainer } from './EventSummaryCard.styles';
import { DateBlock } from '../DateBlock';
import { downloadTemplate } from '../../utils/roomingList';
import { ROOMING_PLANNER_SPREADSHEET_URL, DEFAULT_PLANNER_SPREADSHEET_NAME } from '../../constants';

export const EventSummaryCard: FC<EventSummaryCardProps> = ({
  eventLabels,
  eventData,
  apiFailure,
  refetchEventList,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState(false);

  const handleCtaClick = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const downloadFile = async () => {
    setLoader(true);
    try {
      setApiError(false);
      await downloadTemplate(
        `${ROOMING_PLANNER_SPREADSHEET_URL}${eventData.uploadId}`,
        DEFAULT_PLANNER_SPREADSHEET_NAME
      );
      refetchEventList();
    } catch (error) {
      setApiError(true);
    } finally {
      setLoader(false);
    }
  };

  return (
    <StyledEventSummaryContainer>
      <StyledEventSummaryCard className={clsx(eventData.status === EventStatus.NEW && 'border-left')}>
        <div className="d-flex justify-content-between flex-column flex-md-row mb-md-3">
          <Heading
            titleText={eventData.eventName}
            variation={Types.headingType.title}
            customClass="event-title t-title-s m-0"
            element={Types.tags.h2}
          />
          <div className="t-font-xs align-self-end align-self-md-start mt-2 mb-2">
            <Text
              copyText={eventLabels.createdDate}
              customClass={'pr-1 text-bold text-uppercase'}
              fontSize={Types.size.extraSmall}
              element={Types.tags.span}
            />
            <Text
              copyText={
                eventData.createdDate
                  ? getFormattedDate(moment(eventData.createdDate), 'dateWithDayMonthYearAndTime')
                  : ''
              }
              customClass={'text-bold'}
              fontSize={Types.size.extraSmall}
              element={Types.tags.span}
            />
          </div>
        </div>

        <div className="mt-2 mb-5 d-flex justify-content-between flex-column flex-md-row flex-wrap event-info">
          <DateBlock
            arrivalDateField={{
              label: eventLabels.arrivalDate,
              value:
                eventData.arrivalDate && getFormattedDate(moment(eventData.arrivalDate), 'dateWithDayMonthAndYear'),
            }}
            departureDateField={{
              label: eventLabels.departureDate,
              value:
                eventData.departureDate && getFormattedDate(moment(eventData.departureDate), 'dateWithDayMonthAndYear'),
            }}
            cutOffDateField={{
              label: eventLabels.cutoffDate,
              value: eventData.cutoffDate && getFormattedDate(moment(eventData.cutoffDate), 'dateWithDayMonthAndYear'),
            }}
          />

          <div>
            <Button
              type={Types.ButtonTypeVariation.Button}
              buttonCopy={eventLabels.ctaLabel}
              className={clsx(
                'm-button m-button-secondary text-uppercase',
                eventData.status === EventStatus.UPLOAD_PENDING && 'disabled'
              )}
              isDisabled={eventData.status === EventStatus.UPLOAD_PENDING}
              callback={handleCtaClick}
            />
          </div>
        </div>

        <div className="border-top pt-4">
          {(eventData.status === EventStatus.NEW || eventData.status === EventStatus.OPENED) && (
            <div className="m-icon-text">
              <span className="icon-blanket icon-s"></span>
              <span className="t-font-m text-bold pr-1">{eventLabels.plannerActionTaken}</span>
              <span className="t-font-m">{eventLabels.submittedRoomingList}</span>
            </div>
          )}

          {eventData.status === EventStatus.UPLOAD_PENDING && (
            <div className="m-icon-text">
              <span className="icon-clock icon-s text-bold"></span>
              <span className="t-font-m text-bold pr-1">{eventLabels.associateNotification}</span>
              <span className="t-font-m">{eventLabels.pendingResults}</span>
            </div>
          )}

          {eventData.status === EventStatus.UPLOAD_SUCCESS && (
            <div className="d-flex justify-content-between flex-column flex-md-row flex-wrap">
              <div className="m-icon-text">
                <span className="icon-check icon-s text-bold"></span>
                <span className="t-font-m text-bold pr-1">{eventLabels.associateNotification}</span>
                <span className="t-font-m">{eventLabels.successResponse}</span>
              </div>
              {eventData.successEntries ? (
                <span className="t-font-s align-self-end">
                  {eventData.successEntries} {eventLabels.successfulEntries}
                </span>
              ) : null}
            </div>
          )}

          {eventData.status === EventStatus.UPLOAD_ERROR && (
            <div className="d-flex justify-content-between flex-column flex-md-row flex-wrap">
              <div className="m-icon-text">
                <span className="icon-clear icon-s text-bold"></span>
                <span className="t-font-m text-bold pr-1">{eventLabels.associateAlert}</span>
                <span className="t-font-m">{eventLabels.errorResponse}</span>
              </div>
              <div className="align-self-end">
                {eventData.successEntries ? (
                  <span className={clsx('t-font-s', eventData.failureEntries && 'border-right mr-2 pr-2')}>
                    {eventData.successEntries} {eventLabels.successfulEntries}
                  </span>
                ) : null}
                {eventData.failureEntries ? (
                  <span className="t-font-s">
                    {eventData.failureEntries} {eventLabels.errors}
                  </span>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </StyledEventSummaryCard>

      <StyledModalContainer>
        {isModalOpen && (
          <Modal
            handleBlur={true}
            role="dialog"
            show={isModalOpen}
            popupOpenState={isModalOpen}
            setPopupOpenState={setIsModalOpen}
            onClose={handleModalClose}
            labelledBy="eventSummaryModal"
            modalId="eventSummaryModal"
            disableScrollOnBody={true}
          >
            <Modal.Header labelText={eventData.eventName} popupHeaderOnCLoseFunc={handleModalClose}></Modal.Header>
            <Modal.Body>
              {apiError ? (
                <div className="px-5 pb-5">
                  <InlineMessages type={InlineMessagesType.Error} title={apiFailure} severity="1" />
                </div>
              ) : (
                <div />
              )}
              <DateBlock
                arrivalDateField={{ label: eventLabels.arrivalDate, value: eventData.arrivalDate }}
                departureDateField={{ label: eventLabels.departureDate, value: eventData.departureDate }}
                cutOffDateField={{ label: eventLabels.cutoffDate, value: eventData.cutoffDate }}
                className="mb-5 mt-3"
              />
              <Text
                copyText={eventLabels.downloadHelperText}
                customClass="mb-3 d-inline-block margin-top"
                fontSize={Types.size.medium}
                element={Types.tags.paragraph}
              />
              <div className="mb-5">
                <Button
                  buttonCopy={eventLabels.downloadCtaLabel}
                  className={clsx('m-button-m m-button-primary mt-1 text-uppercase', loader && 'skeleton-loader')}
                  isDisabled={loader}
                  callback={downloadFile}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
      </StyledModalContainer>
    </StyledEventSummaryContainer>
  );
};
