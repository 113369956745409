import React, { FC } from 'react';
import moment from 'moment';
import { Heading, Types } from '@marriott/mi-ui-library';
import { EventSummaryReviewCardProps } from './EventSummaryReviewCard.types';
import { StyledEventSummaryReviewCard } from './EventSummaryReviewCard.styles';
import { BrandLogo } from '../BrandLogo';
import { DateBlock } from '../DateBlock';
import { formatAmount, getFormattedDate } from '../../../utils';

export const EventSummaryReviewCard: FC<EventSummaryReviewCardProps> = props => {
  const { eventLabels, eventData } = props;
  const { groupRates } = eventLabels;
  const {
    eventName,
    brandCode,
    brandName,
    hotelName,
    addressLine1,
    city,
    stateProvince,
    zipCode,
    countryCode,
    groupRatesList,
  } = eventData;

  return (
    <StyledEventSummaryReviewCard>
      <div>
        <Heading
          titleText={eventName}
          variation={Types.headingType.title}
          customClass="t-title-s m-0"
          element={Types.tags.h2}
        />
        <div className="mt-3 mb-5 d-flex justify-content-between align-items-start flex-column flex-md-row flex-wrap event-summary">
          <DateBlock
            arrivalDateField={{
              label: eventLabels.arrivalDate,
              value:
                eventData.arrivalDate && getFormattedDate(moment(eventData.arrivalDate), 'dateWithDayMonthAndYear'),
            }}
            departureDateField={{
              label: eventLabels.departureDate,
              value:
                eventData.departureDate && getFormattedDate(moment(eventData.departureDate), 'dateWithDayMonthAndYear'),
            }}
            cutOffDateField={{
              label: eventLabels.cutoffDate,
              value: eventData.cutoffDate && getFormattedDate(moment(eventData.cutoffDate), 'dateWithDayMonthAndYear'),
            }}
          />
          <div className="d-flex flex-wrap hotel-info">
            <BrandLogo brandCode={brandCode} ariaLabel={brandName} />
            <div className="hotel-details">
              <span className="t-subtitle-m d-block">{hotelName}</span>
              <span className="t-font-alt-s">{addressLine1} </span>
              <span className="t-font-alt-s">{city} </span>
              <span className="t-font-alt-s">{stateProvince} </span>
              <span className="t-font-alt-s">{zipCode} </span>
              <span className="t-font-alt-s">{countryCode} </span>
            </div>
          </div>
        </div>
      </div>

      <div className="group-rates-container">
        <Heading
          titleText={groupRates.title}
          variation={Types.headingType.title}
          customClass="t-subtitle-xl m-0"
          element={Types.tags.h3}
        />
        <table className="group-rates-table">
          <thead>
            <tr>
              <th className="t-font-s">{groupRates.name}</th>
              <th className="t-font-s">{groupRates.roomType}</th>
              <th className="t-font-s">{groupRates.rate}</th>
              <th className="t-font-s">{groupRates.groupCode}</th>
            </tr>
          </thead>
          <tbody>
            {groupRatesList?.map(groupRatesItem => {
              return (
                <tr key={groupRatesItem.name}>
                  <td className="t-font-alt-s">{groupRatesItem.name}</td>
                  <td className="t-font-alt-s">{groupRatesItem.roomType}</td>
                  <td className="t-font-alt-s">
                    <span>
                      {groupRatesItem.rate && groupRatesItem.rate !== '-' ? formatAmount(+groupRatesItem.rate) : '-'}{' '}
                    </span>
                    {groupRatesItem.rate && groupRatesItem.rate !== '-' ? (
                      <span>
                        {groupRatesItem?.currency}
                        {groupRates.perNight}
                      </span>
                    ) : null}
                  </td>
                  <td className="t-font-alt-s">{groupRatesItem.groupCode}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </StyledEventSummaryReviewCard>
  );
};
