// Styles for OffersMBEHero go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { mediaQuery } from '../../utils/styles';

export const StyledOffersMBEHero = styled.div`
  /* Wrapper for the countdown component */
  .countdownWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${toRem(16)};
    gap: ${toRem(6)};
  }

  /* Tile styles for days, hours, minutes, and seconds */
  .countdownTile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${toRem(56)};
    height: ${toRem(56)};
    border-radius: var(--Border-Radius-cards-border-radius, 14px);
    background: var(--Colors-neutral-50, #303030);
  }

  /* Text styles inside the tile */
  .countdownNumber {
    font-family: var(--Typography-font-family, 'Swiss721 BT');
    font-size: ${toRem(35)};
    font-weight: 700;
    color: ${baseVariables.color.base20};
    line-height: 110%; /* Adjusts vertical spacing for text */
    font-feature-settings: 'liga' off, 'clig' off;
  }

  .countdownLabel {
    color: #000;
    font-family: 'Swiss721 BT';
    font-size: ${toRem(12)};
    font-style: normal;
    font-weight: 700;
    line-height: ${toRem(12)};
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'liga' off, 'clig' off;
    margin-top: ${toRem(6)};
  }

  .countdownLabelsWrapper {
    display: flex;
    align-items: center;
    gap: ${toRem(16)}; /* Space between the timer labels */
  }

  .countdownUnit {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Separator between tiles with the specified size */
  .separator {
    width: ${toRem(4)};
    height: ${toRem(16)};
    display: inline-block;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none"><rect y="12" width="4" height="4" fill="black"/><rect width="4" height="4" fill="black"/></svg>')
      no-repeat center;
    margin: 0 ${toRem(6)}; /* Space between the separator and the tiles */
  }

  .flex-row {
    display: flex;
    align-items: center;
    align-items: flex-start;
    margin-left: ${toRem(7)};
    gap: ${toRem(24)};
    @media ${mediaQuery.tabletOnly} {
      gap: ${toRem(6)};
    }
  }

  .text-flex {
    display: flex;
    flex-direction: column;
  }

  .text-align {
    align-items: flex-start;
  }

  .mbeHero-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  .mbeHero-wrapper-mobile,
  .mbeHero-wrapper-anonymous-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: ${toRem(24)};
  }

  .mbeHero-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .countdown-wrapper {
    display: flex;
    align-items: center;
  }

  .vertical-divider {
    width: 1px;
    height: ${toRem(128)};
    background: ${baseVariables.color['neutral30']};
    margin: 0 30px;
  }

  .mbeMessage-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--General-spacer-04, ${toRem(24)});
    margin-block: ${toRem(24)};
  }

  .mbeMessage-wrapper-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-large, ${toRem(16)});
    align-self: stretch;
  }

  .link-align {
    margin-left: ${toRem(7)};
  }

  .link-align-mobile {
    margin-left: ${toRem(14)};
  }

  a.m-button-s {
    padding-top: var(--t-button-s-padding-y, ${toRem(8)});
  }

  .timer-align {
    margin-top: ${toRem(20)};
  }

  .terms-link {
    font-size: ${toRem(14)};
    @media ${mediaQuery.tabletOnly} {
      font-size: x-small;
    }
  }

  .m-link {
    padding-bottom: ${toRem(2)};
    font-weight: 400;
  }

  .countdown-wrapper {
    display: flex;
    align-items: center;
  }

  .icon-with-lines {
    display: flex;
    align-items: center;
    margin-bottom: ${toRem(16)};
  }

  .icon-with-lines-mobile {
    margin-left: ${toRem(16)};
    margin-bottom: ${toRem(4)};
  }

  .icon-line {
    width: ${toRem(64)};
    height: ${toRem(1)};
    background: var(--Color-Sets-non-interactive-accent-color, #1c1c1c);
    margin-top: ${toRem(6)};
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${toRem(24)};
  }

  .icon-wrapper .icon {
    color: #ff8d6b;
  }

  .anonymous-active-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--General-spacer-01, ${toRem(24)});
  }

  .anonymous-mobile {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: ${toRem(16)};
    gap: var(--General-spacer-01, ${toRem(8)});
  }

  .content-wrapper {
    display: flex;
    align-items: center; /* Vertically center align content */
  }

  .desktop-layout {
    flex-direction: row; /* Align MBEMessage and CountdownTimer horizontally for desktop */
    justify-content: space-between;
  }

  .mobile-layout {
    flex-direction: column-reverse; /* Stack MBEMessage and CountdownTimer for mobile */
    align-items: flex-start; /* Left align the content */
    gap: 24px; /* Add gap between the elements */
  }

  .authenticated-mobile {
    margin-left: ${toRem(6)};
  }
`;
