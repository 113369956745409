/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
  NearByDestinationsInput,
  DistanceMatrixResponseType,
  NearByDestinationsInfoProps,
  CardArrayType,
  LocationCoords,
} from './NearByDestinations.types';
import { getLocation } from '../../lib/helper';
import {
  constructOfferSearchURL,
  destinationListResponse,
  filterDestinations,
  generateCardIndexArray,
  getDistanceMatrixForDestinations,
  getHoursAwayText,
  getNearByDestinationsVariables,
} from './utils/helper';
import { useLazyQuery } from '@apollo/client';
import { phoenixNearbyDestinations } from '@marriott/mi-offers-graphql';
import { UXL_ERROR_POLICY } from '../../api/apiConstants';
import { processAcceptLang } from '../../utils/OfferUtils';
import { shouldUseMockData } from '../../utils/CommonUtils';
import { MiniCardCarouselContainer } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

import { INTERNAL_CTA_TYPE, constants } from '../../constants/CommonConstants';

export const NearByDestinationsContainer: React.FC<NearByDestinationsInput> = props => {
  const { model, requestId, isAuthorMode } = props;
  let lat = 0;
  let long = 0;
  if (typeof window !== 'undefined' && window && window.dataLayer) {
    lat = window.dataLayer['browser_akamai_loc_lat'] as number;
    long = window.dataLayer['browser_akamai_loc_long'] as number;
  }
  const [browserLat, setLatitude] = useState(lat);
  const [browserLong, setLongitude] = useState(long);

  const acceptLanguage = processAcceptLang(props?.acceptLanguage ?? '');
  const [nearByDestData, setNearByDestData] = useState<NearByDestinationsInfoProps>({
    destinationList: [],
  });
  const [destinationsResult, setDestinationsResult] = useState<any>([]);
  const { envVarsObject } = useClientEnvVarsStore();

  // Load mock data dynamically for dev and author mode
  const GetMockData = async (isAuthorMode: boolean) => {
    if (shouldUseMockData(isAuthorMode)) {
      const mockData = await import('./__mock__/NearByDestinationsLocations.mock');
      return mockData.default;
    } else {
      return {} as NearByDestinationsInfoProps;
    }
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const { latitude, longitude } = await getLocation();
        setLatitude(latitude);
        setLongitude(longitude);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    if (!isAuthorMode) {
      fetchLocation();
    }
  }, []);

  useEffect(() => {
    const inputVariable = getNearByDestinationsVariables(
      browserLat,
      browserLong,
      model?.minDistance ?? '1',
      model?.maxDistance ?? '1'
    );
    const payLoad = {
      variables: {
        ...inputVariable,
      },
    };
    if (isAuthorMode) {
      const fetchMockData = async () => {
        const mockDestinationsData = await GetMockData(isAuthorMode || false);
        setNearByDestData(mockDestinationsData as NearByDestinationsInfoProps);
        mapNearByDestinationsItems(mockDestinationsData as NearByDestinationsInfoProps);
      };

      fetchMockData();
    } else {
      getNearByDestinationsData(payLoad);
    }
  }, [browserLat, browserLong]);

  const mapNearByDestinationsItems = (destinationList: NearByDestinationsInfoProps) => {
    const destinationsMap = destinationList?.destinationList?.map((card: any, index: number) => {
      const cardLocDetails = model?.trackingProperties?.location
        ? `${model.trackingProperties.location}-card-${index}`
        : `card-${index}`;

      return {
        ':items': {
          [`cardhorizontalmini_copy-${index}`]: {
            trackingProperties: {
              atCCeVar48: null,
              additionalTrackingVariables: null,
              trackingDescription: card?.header,
              description: card?.header,
              clickTrack: model?.trackingProperties?.clickTrack ? true : false,
              impressionTrack: false,
              location: cardLocDetails,
              trackingContentPosition: cardLocDetails,
              merchandisingCategory: model?.trackingProperties?.merchandisingCategory
                ? model.trackingProperties.merchandisingCategory
                : 'nonCobrand',
              impressionCount: false,
              trackingTag: model?.trackingProperties?.trackingTag,
              trackingOfferType: null,
            },
            headerTag: 'h2',
            iconPath: model?.fontIcon,
            clickTrack: model?.trackingProperties?.clickTrack ? true : false,
            header: card.city,
            ctaLink: constructOfferSearchURL(envVarsObject?.['NEXT_PUBLIC_SUBMIT_SEARCH_URL'], card),
            assetVariation: constants.ASSET_VARIATION_ICON,
            componentId: model?.componentId + cardLocDetails,
            descriptionText: getHoursAwayText(card.duration, model?.hour ?? 'hour', model?.hours ?? 'hours'),
            isAuthor: false,
            hideEllipsis: false,
            path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardhorizontalmini',
            ':type': 'mi-aem-common-spa/components/content/cardhorizontalmini',
            openInaNewTab: false,
            renderType: 'manual',
            hideDescriptionOnMobile: false,
            wcmMode: model?.wcmMode,
            hideImage: false,
            custom_click_track_value: `${model?.componentId}|${cardLocDetails}-${card.destination}|${INTERNAL_CTA_TYPE}`,
          },
        },
      };
    });

    // Modifying index names to map it with card horizontal mini container cqItems
    const modifiedDestinationArr: CardArrayType = {};
    destinationsMap.forEach((value, index) => {
      modifiedDestinationArr['card-' + index] = value;
    });
    setDestinationsResult(modifiedDestinationArr);
  };

  const processNearByData = async (nearByData: any) => {
    if (nearByData?.offersSearch?.edges?.length) {
      const origins = { lat: browserLat, lng: browserLong };
      const destinations = filterDestinations(
        nearByData.offersSearch.edges,
        model?.minOffersPerCity ?? 1,
        model?.numCitiesToDisplay ?? 1
      );
      const destinationCoordinates: Array<string> = [];
      const destinationCoordinatesArr: Array<LocationCoords> = [];
      destinations.forEach(dest => {
        const destLatitude = dest.participatingProperties.properties[0].basicInformation.latitude;
        const destLongitude = dest.participatingProperties.properties[0].basicInformation.longitude;
        destinationCoordinates.push(`${destLatitude},${destLongitude}`);
        destinationCoordinatesArr.push({ lat: destLatitude, lng: destLongitude });
      });
      return await getDistanceMatrixForDestinations(origins, destinationCoordinatesArr)
        .then((resp: DistanceMatrixResponseType) => {
          const destinationsList = destinationListResponse(resp, destinations);
          setNearByDestData(destinationsList);
          mapNearByDestinationsItems(destinationsList);
        })
        .catch(error => {
          console.log(`[NearByDestinations] getNearByDestinationsData graphql error: ${error}`);
        });
    } else {
      return { destinationList: [] };
    }
  };

  const handleNearByDataError = (error: any) => {
    console.log('handleNearByDataError', error);
  };

  const [getNearByDestinationsData, { data: _nearByData }] = useLazyQuery(phoenixNearbyDestinations, {
    fetchPolicy: 'network-only',
    errorPolicy: UXL_ERROR_POLICY,
    context: {
      headers: {
        'x-request-id': requestId,
        'accept-language': acceptLanguage,
      },
    },
    onCompleted: processNearByData,
    onError: handleNearByDataError,
  });

  return (
    <div>
      {nearByDestData?.destinationList?.length > 0 && (
        <MiniCardCarouselContainer
          titleText={model?.title ?? ''}
          subTitleText={''}
          ctaLabel={''}
          ctaLink={''}
          eyebrow={''}
          ctaType={''}
          cardsCount={nearByDestData.destinationList.length}
          openInaNewTab={false}
          totalNumberOfCards={generateCardIndexArray(nearByDestData.destinationList.length)}
          trackingProperties={model?.trackingProperties ?? {}}
          componentId={model?.componentId ?? 'componentId'}
          pagePath={''}
          itemPath={''}
          wcmMode={model?.wcmMode ?? 'edit'}
          isAuthorMode={isAuthorMode ?? false}
          cqItems={destinationsResult}
          isFullWidthCards={true}
        />
      )}
    </div>
  );
};
