/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState, useContext, createContext, useRef } from 'react';
import { searchResultsPageUrl, TRACKING_CONSTANT, PAGES } from '../../modules/utils/constants';
import Script from 'next/script';
import {
  SearchFormParent,
  SearchFormContainer,
  SearchInputField,
  TextInputField,
  GlobalStyledFullForm,
} from '../SearchFormWrapper/SearchFormWrapper.styles';
import { SearchFieldContainer } from './SearchByGeoLocation.styles';
import clsx from 'clsx';
import {
  getCurrentLocation,
  useGetBreakpoint,
  getHttpStatusCode,
  getErrorMessage,
  trackImpression,
  getTrackingProperties,
  getSubDirectoryLocale,
} from '../../modules/utils/helpers';
import { useRouter } from 'next/router';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { suggestedPlaces, suggestedPlacesDetails } from '@marriott/mi-rnb-graphql';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { autoCompleteState, useAutoCompleteStore } from '../../modules/store/autoCompleteDetailsStore';
import searchFilterStore from '../../modules/store/store';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { rnbErrorState, rnbErrorStore } from '../../modules/store/rnbErrorStore';
import { PageParamsContext } from '../../modules/context';
import { SearchFormWrapperProps } from '../../organisms/SearchFormWrapper';
import { useApolloClient } from '@apollo/client';
import { Button, Icon } from '@marriott/mi-ui-library';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';

const RnBErrorModal = dynamic<any>(() => import('../../molecules/RnBErrorModal').then(mod => mod.RnBErrorModal));
const { HOMEPAGE, SEARCH_RESULTS_PAGE } = PAGES;

declare global {
  interface Window {
    google: any;
  }
}

export const SearchByGeoLocation = (props: SearchFormWrapperProps) => {
  const { currentPage, userId } = useContext(PageParamsContext);
  const CURRENT_LOCATION = props?.currentLocationLabel;
  const [isDestDropdownOpen, setIsDestDropdownOpen] = useState(false);
  const [destinationList, setDestinationList] = useState([
    {
      description: CURRENT_LOCATION,
      placeId: '',
      primaryDescription: CURRENT_LOCATION,
      secondaryDescription: '',
      category: '',
    },
  ]);
  const [selectedDestValue, setSelectedDestValue] = useState({
    description: '',
    placeId: '',
    primaryDescription: '',
    secondaryDescription: '',
    category: '',
  });
  const [destFieldDisplayValue, setDestFieldDisplayValue] = useState('');
  const [initialOptionsList] = useState(destinationList);
  let portSize = useGetBreakpoint();
  const [isMobileViewPort, setIsMobileViewPort] = useState(portSize === 'mobile');
  const GOOGLE_MAP_API_KEY: any = process.env['GOOGLE_MAP_API_KEY'];
  const router = useRouter();
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const setSuggestionsData = useAutoCompleteStore((state: autoCompleteState) => state.setSuggestionsData);
  const setSelectedPlaceData = useAutoCompleteStore((state: autoCompleteState) => state.setSelectedPlaceData);
  const suggestedPlacesError = useAutoCompleteStore((state: autoCompleteState) => state.suggestedPlacesError);
  const setErrorState = rnbErrorStore((state: rnbErrorState) => state.setErrorState);
  const [isSelectedFromList, setIsSelectedFromList] = useState(false);
  const setIsLoaderVisible = useStore((state: searchResultsState) => state.setIsLoaderVisible);
  const setQueryParam = useStore((state: searchResultsState) => state.setQueryParam);
  const setPaginationChange = useStore((state: searchResultsState) => state.setPaginationChange);
  const setPageNumber = useStore((state: searchResultsState) => state.setPageNumber);
  const { setFilter } = searchFilterStore();
  const [getDestinationFieldValue, setGetDestinationFieldValue] = useState(false);
  const setIsComponentLoading = useStore((state: searchResultsState) => state.setIsComponentLoading);
  const isComponentLoading = useStore((state: searchResultsState) => state.isComponentLoading);
  const shouldComponentReload = useStore((state: searchResultsState) => state.shouldComponentReload);
  const setShouldComponentReload = useStore((state: searchResultsState) => state.setShouldComponentReload);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const searchResultDataLoading = useStore((state: searchResultsState) => state.searchResultDataLoading);
  const searchResultDataError = useStore((state: searchResultsState) => state.searchResultDataError);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const { currentLocale } = useContext(PageParamsContext);
  const subLocale = getSubDirectoryLocale(currentLocale || 'en-US');

  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext?.requestId ? pageContext?.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');

  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();

  const { HOME_PAGE_SEARCH_FORM, SEARCH_RESULTS_SEARCH_FORM, SEARCH_BUTTON, UPDATE_SEARCH_BUTTON, INTERNAL_LINK } =
    TRACKING_CONSTANT;

  // UXL queries
  const suggestionClient = useApolloClient();
  async function getSuggestionList(inputValues: any) {
    await suggestionClient
      .query({
        query: suggestedPlaces,
        ...inputValues,
      })
      .then(data => {
        if (data?.data?.suggestedPlaces?.edges?.length && !data?.loading) {
          const completeList = [
            ...initialOptionsList,
            ...data.data.suggestedPlaces.edges.map((item: any) => {
              return {
                description: item?.node?.description,
                placeId: item?.node?.placeId,
                primaryDescription: item?.node?.primaryDescription,
                secondaryDescription: item?.node?.secondaryDescription,
                category: props?.locationLabel,
              };
            }),
          ];
          setDestinationList(completeList);
          setSuggestionsData(false, false);
          if (!searchResultDataLoading && !searchResultDataError) setErrorState(0, '');
        }
      })
      .catch(error => {
        const errorCode = Number(getHttpStatusCode(error));
        const errorMessage = getErrorMessage(errorCode);
        if (errorCode) setErrorState(errorCode, errorMessage);
        else setErrorState(0, 'apiError');
        setSuggestionsData(false, true);
        clearSearchField();
      });
  }

  const selectedDetailsClient = useApolloClient();
  async function getSelectedPlaceDetails(inputValues: any, location = '') {
    setIsLoaderVisible(true);

    await selectedDetailsClient
      .query({
        query: suggestedPlacesDetails,
        ...inputValues,
      })
      .then(data => {
        if (data?.data?.suggestedPlaceDetails && !data?.loading) {
          const selectedPlaceData = {
            // eslint-disable-next-line no-extra-boolean-cast
            selectedPlace: !!location ? location : selectedDestValue,
            selectedPlaceDetails: data,
          };
          setSelectedPlaceData(selectedPlaceData, false, false);
          setErrorState(0, '');
          setIsLoaderVisible(false);

          if (isRedirectionRequired(props?.homePage)) {
            const searchPageUrl = getSearchResultsRedirectUrl(selectedPlaceData);
            window.location.href = searchPageUrl;
            setIsLoaderVisible(false);
            // router.push(searchPageUrl).then(() => setIsLoaderVisible(false));
          } else {
            updateSearch(selectedPlaceData);
          }
        } else if (!data?.data?.suggestedPlaceDetails) {
          setErrorState(0, 'apiError');
        }
      })
      .catch(error => {
        const errorCode = Number(getHttpStatusCode(error));
        const errorMessage = getErrorMessage(errorCode);
        if (errorCode) setErrorState(errorCode, errorMessage);
        else setErrorState(0, 'apiError');
        setIsLoaderVisible(false);
        setIsComponentLoading(false);
        setSelectedPlaceData(null, false, true);
        clearSearchField();
      });
  }

  const isRedirectionRequired = (isHomePage: boolean) => {
    return isHomePage ||
      router?.pathname?.includes('/dining/404') ||
      router?.pathname?.includes('/dining/restaurant-bar')
      ? true
      : false;
  };

  const getSearchResultsRedirectUrl = (selectedPlaceData: any): string => {
    let searchPageUrl = '';
    const lat = selectedPlaceData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.latitude;
    const long = selectedPlaceData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.longitude;
    const stateName = selectedPlaceData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.state;
    const countryName = selectedPlaceData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.countryName;
    const cityName = selectedPlaceData?.selectedPlaceDetails?.data?.suggestedPlaceDetails?.location?.city;
    const displayTerm = selectedPlaceData?.selectedPlace?.description
      ? selectedPlaceData?.selectedPlace?.description
      : destFieldDisplayValue;
    setFilter([]);
    if (displayTerm && lat && long) {
      searchPageUrl =
        subLocale +
        searchResultsPageUrl +
        '?term=' +
        displayTerm +
        '&lat=' +
        lat +
        '&long=' +
        long +
        (cityName ? '&term1=' + cityName : '') +
        (stateName ? '&term2=' + stateName : '') +
        (countryName ? '&term3=' + countryName : '');
    } else if (displayTerm && !(lat && long) && stateName && countryName) {
      searchPageUrl =
        subLocale + searchResultsPageUrl + '?term=' + displayTerm + '&term2=' + stateName + '&term3=' + countryName;
    } else if (displayTerm && !(lat && long) && !stateName && countryName) {
      searchPageUrl = subLocale + searchResultsPageUrl + '?term=' + displayTerm + '&term3=' + countryName;
    } else if (displayTerm && !(lat && long) && stateName && !countryName) {
      searchPageUrl = subLocale + searchResultsPageUrl + '?term=' + displayTerm + '&term3=' + stateName;
    }
    return searchPageUrl;
  };

  useEffect(() => {
    const searchBar: React.RefObject<HTMLDivElement | null> = searchContainerRef;
    const searchBarOffsetTop: number = searchBar?.current?.offsetTop ?? 53; // Header height is 53
    let prevScrollY = window?.scrollY;

    function handleScroll() {
      const currentScrollY = window?.scrollY;
      if (currentScrollY < prevScrollY) {
        setIsScrolledUp(true);
      } else {
        setIsScrolledUp(false);
      }

      if (window?.scrollY >= searchBarOffsetTop) {
        setIsScrolled(true);
      } else if (window?.scrollY === 0) {
        setIsScrolled(false);
      }
      prevScrollY = currentScrollY;
    }
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (destFieldDisplayValue !== '') {
        getSuggestionList({
          variables: {
            query: destFieldDisplayValue,
          },
          context: {
            headers: {
              // Any header, including x-request-id, can be passed ui-libraryin args with query.
              // If you don't pass it in the authLink will generate a random ID.
              'x-request-id': requestId,
              'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
              'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
              'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
              'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            },
          },
        });
      } else setDestinationList(initialOptionsList);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [destFieldDisplayValue]);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      portSize = useGetBreakpoint();
      setIsMobileViewPort(portSize === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack) trackImpression(props?.trackingProperties || {}, 'Search Form');
  }, [props?.trackingProperties]);

  useEffect(() => {
    let queryParams = [];
    if (router?.query) {
      queryParams = Object.keys(router?.query);
      queryParams.forEach(queryParam => {
        if (queryParam === 'term') {
          setDestFieldDisplayValue(router?.query?.['term']?.toString() ?? '');
        }
      });
    }
  }, [getDestinationFieldValue, router?.query]);

  const destinationDropdownOptions = (option: any) => (
    <p className="t-font-m rnb-destination-option">
      {option?.description === CURRENT_LOCATION ? (
        <>
          <Icon iconClass="icon-nearby icon-decorative rnb-current-location-icon" />
          <span data-testid="current-location">{option?.description}</span>
        </>
      ) : (
        <span>
          <b data-testid={option?.primaryDescription}>{option?.primaryDescription}</b>
          {!!option?.secondaryDescription && ', '}
          {option?.secondaryDescription}
        </span>
      )}
    </p>
  );

  const destinationSearchField = (params: any) => (
    <SearchInputField isDropDownOpen={isDestDropdownOpen} isHomePage={props?.homePage}>
      <div className={clsx('rnb-dest-search-input')}>
        <TextInputField
          {...params}
          id="destination"
          name="destination"
          placeholder={
            !props?.homePage || (!props?.mboxParameter && props?.homePage) ? props?.searchLabelExpB : props?.searchLabel
          }
          InputProps={{
            ...params.InputProps,
          }}
          variant="standard"
          className="t-subtitle-xl"
        />
        {isDestDropdownOpen && destFieldDisplayValue && (
          <Button
            isLink={false}
            className="rnb-dest-field-clear-btn"
            callback={clearSearchField}
            aria-label="clear-field"
            testId="clear-field"
          >
            <Icon iconClass={isMobileViewPort ? 'icon-cancel icon-inverse' : 'icon-cancel'} />
          </Button>
        )}
      </div>
    </SearchInputField>
  );

  const updateSearchFieldValue = (newValue: any) => {
    setSelectedDestValue(newValue);
    if (newValue.description === CURRENT_LOCATION) getLocationDetails();
    setIsCurrentLocation(false);
    setIsSelectedFromList(true);
  };

  const updateSearchFieldDisplayValue = (newInputValue: any) => {
    setDestFieldDisplayValue(newInputValue);
  };

  const getLocationDetails = () => {
    const locationHandler = (data: Record<string, string>): void => {
      if (data) {
        setIsCurrentLocation(false);
        const currentLocationInfo = {
          description: data['address'],
          placeId: data['placeId'],
          primaryDescription: '',
          secondaryDescription: '',
          category: '',
        };
        setSelectedDestValue(currentLocationInfo);
      } else {
        setIsCurrentLocation(true);
        clearSearchField();
      }
    };
    getCurrentLocation(locationHandler);
  };

  const updateSearch = (selectedPlace: any) => {
    setIsComponentLoading(false);
    const searchPageUrl = getSearchResultsRedirectUrl(selectedPlace);
    const queryString = searchPageUrl?.split('?')[1];
    window.history.pushState(searchPageUrl?.split('?')[0], '', `?${queryString}`);
    setQueryParam(queryString);
    setPaginationChange(false);
    setPageNumber(1);
    setIsLoaderVisible(false);
    setShouldComponentReload(!shouldComponentReload);
  };

  const updateSearchResults = () => {
    setIsComponentLoading(true);
    if (props?.trackingProperties?.impressionTrack) {
      const tracking = getTrackingProperties(props?.trackingProperties || {}, ',', '');
      if (window?.impressionArr?.includes(`${tracking?.trackingString}${'Search Form'}`)) {
        window.impressionArr = [];
      }
      trackImpression(props?.trackingProperties || {}, 'Search Form');
    }
    const queryParam = new URLSearchParams(window?.location?.search);
    const latitude = parseFloat(queryParam?.get('lat') ?? '0');
    const longitude = parseFloat(queryParam?.get('long') ?? '0');
    const city = queryParam?.get('term1') ?? '';
    const state = queryParam?.get('term2') ?? '';
    const countryName = queryParam?.get('term3') ?? '';

    if (!isSelectedFromList && destFieldDisplayValue !== '') {
      if (
        destFieldDisplayValue === queryParam?.get('term') &&
        ((latitude && longitude) || (state && countryName) || countryName)
      ) {
        const placeData = {
          selectedPlaceDetails: {
            data: {
              suggestedPlaceDetails: {
                location: {
                  latitude,
                  longitude,
                  city,
                  state,
                  countryName,
                },
              },
            },
          },
        };
        setGetDestinationFieldValue(!getDestinationFieldValue);
        setSelectedDestValue({
          description: '',
          placeId: '',
          primaryDescription: '',
          secondaryDescription: '',
          category: '',
        });
        setErrorState(0, '');
        updateSearch(placeData);
      } else {
        setIsComponentLoading(false);
        setErrorState(0, 'destinationError');
      }
    }
    if (destFieldDisplayValue === '') {
      setSuggestionsData(false, true);
      setIsComponentLoading(false);
      setErrorState(0, 'destinationError');
    } else if ((destFieldDisplayValue !== '' || !suggestedPlacesError) && isSelectedFromList) {
      if (destFieldDisplayValue === selectedDestValue?.description) {
        setErrorState(0, '');
        getSelectedPlaceDetails({
          variables: {
            placeId: selectedDestValue?.placeId,
          },
          context: {
            headers: {
              // Any header, including x-request-id, can be passed ui-libraryin args with query.
              // If you don't pass it in the authLink will generate a random ID.
              'x-request-id': requestId,
              'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
              'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
              'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
              'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            },
          },
        });
      } else {
        setIsComponentLoading(false);
        setErrorState(0, 'destinationError');
      }
    }
  };

  const clearSearchField = () => {
    setSelectedDestValue({
      description: '',
      placeId: '',
      primaryDescription: '',
      secondaryDescription: '',
      category: '',
    });
    setDestFieldDisplayValue('');
    setDestinationList(initialOptionsList);
  };

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    updateSearchResults();
  };

  const handleBackButton = () => {
    setIsDestDropdownOpen(false);
  };

  return (
    <SearchFormParent
      data-component-name="o-rnb-SearchByGeoLocation"
      currentPage={currentPage}
      onSubmit={e => onFormSubmit(e)}
      isScrolledUp={isScrolledUp}
    >
      <div
        className={clsx(
          'seacrh-contaner-main',
          props?.homePage ? 'rnb-additional-container' : 'rnb-search-form ',
          (currentPage === HOMEPAGE || SEARCH_RESULTS_PAGE) && isScrolled ? 'sticky-search-form back-color' : ''
        )}
        ref={searchContainerRef}
      >
        {isDestDropdownOpen && isMobileViewPort && <GlobalStyledFullForm />}
        <SearchFormContainer
          className="container"
          isHomePage={props?.homePage}
          isDropDownOpen={isDestDropdownOpen}
          currentPage={currentPage}
          isScrolled={isScrolled}
          userId={userId}
        >
          {isDestDropdownOpen && isMobileViewPort && (
            <Button isLink={false} className="back-button" callback={handleBackButton} aria-label="back-button">
              <Icon iconClass="icon-back-arrow-cropped icon-inverse" />
            </Button>
          )}
          <SearchFieldContainer
            isDropDownOpen={isDestDropdownOpen}
            isHomePage={props?.homePage}
            className={clsx('search-field-container', !isMobileViewPort ? 'col-md-6 col-lg-5 col-xl-4' : '')}
          >
            {props?.searchBarLabel && (
              <label htmlFor="destination" className="rnb-destination-label">
                <Icon iconClass="icon-dining rnb-label-dining-icon icon-decorative" />
                <span
                  className={clsx(
                    't-overline-normal',
                    isDestDropdownOpen && isMobileViewPort ? 't-overline-inverse-normal' : ''
                  )}
                >
                  {props?.searchBarLabel}
                </span>
              </label>
            )}

            <Autocomplete
              id="destination"
              aria-label="destination"
              value={selectedDestValue}
              open={isDestDropdownOpen}
              onChange={(_event, newValue) => {
                updateSearchFieldValue(newValue);
              }}
              inputValue={destFieldDisplayValue}
              onInputChange={(_event, newInputValue) => {
                updateSearchFieldDisplayValue(newInputValue);
              }}
              onOpen={(): void => {
                setIsDestDropdownOpen(true);
              }}
              onClose={(): void => {
                setIsDestDropdownOpen(false);
              }}
              options={destinationList}
              groupBy={(option: any) => option?.category}
              getOptionLabel={(option: any) => option?.description}
              renderOption={option => destinationDropdownOptions(option)}
              renderInput={params => destinationSearchField(params)}
              disablePortal={true}
              openOnFocus={true}
              clearOnBlur={false}
              forcePopupIcon={false}
              blurOnSelect={!isMobileViewPort}
              selectOnFocus={!isMobileViewPort}
              disableClearable={true}
              filterOptions={(x): any => x}
              getOptionSelected={(option: any, value: any) => option?.placeId === value?.placeId}
            />
          </SearchFieldContainer>

          {isMobileViewPort && props?.homePage && isComponentLoading && <div className="m-spinner-m m-auto"></div>}
          {((props?.homePage && props?.searchBarCtaLabel) || (!props?.homePage && props?.updateSearchBarCtaLabel)) && (
            <Button
              isLink={false}
              className={clsx(
                'rnb-additional-container__form-cta',
                props?.homePage ? 'm-button-l m-button-primary' : 'm-button-m m-button-secondary',
                'custom_click_track'
              )}
              callback={updateSearchResults}
              custom_click_track_value={
                props?.homePage
                  ? `${HOME_PAGE_SEARCH_FORM}|${SEARCH_BUTTON}|${INTERNAL_LINK}`
                  : `${SEARCH_RESULTS_SEARCH_FORM}|${UPDATE_SEARCH_BUTTON}|${INTERNAL_LINK}`
              }
            >
              {props?.homePage && isComponentLoading && <div className="m-spinner-m ml-0"></div>}
              {props?.homePage ? props?.searchBarCtaLabel : props?.updateSearchBarCtaLabel}
            </Button>
          )}
        </SearchFormContainer>
        {isCurrentLocation && <RnBErrorModal />}
      </div>
      <Script src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`} strategy="lazyOnload" />
    </SearchFormParent>
  );
};
