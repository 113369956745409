import styled from 'styled-components';

export const StyledPaymentInfoIframe = styled.div`
  position: relative;
  .loader {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .container {
    position: relative;
    z-index: 1;
  }
`;
