import React, { FC, useState } from 'react';
import { Button, Icon, Image, Modal, baseVariables } from '@marriott/mi-ui-library';
import { useMediaQuery } from '../../hooks';
import { SignInModalProps } from './SignInModal.types';
import { StyledSignInModal } from './SignInModal.styles';
import { SignInForm } from './SignInForm';

export const SignInModal: FC<SignInModalProps> = props => {
  const { signIn, joinNow, ctas, show, onClose } = props;

  const [showModal, setShowModal] = useState<boolean>(show);
  const isTabletAndAbove = useMediaQuery(baseVariables.mediaQuery.md);

  const onCloseModal = () => {
    setShowModal(false);
    onClose?.();
  };

  return (
    <StyledSignInModal data-component-name="m-groups-SignInModal" data-testid="groups-SignInModal">
      <Modal
        modalId="signInModal"
        role="dialog"
        labelledBy="signInModal"
        secondaryClassName="sign-in-modal"
        show={showModal}
        popupOpenState={showModal}
        handleBlur={true}
        disableScrollOnBody={true}
        setPopupOpenState={onCloseModal}
        onClose={onCloseModal}
      >
        <Modal.Header
          labelText={isTabletAndAbove ? '' : signIn.title}
          popupHeaderOnCLoseFunc={onCloseModal}
          className="modal-header"
          closeIconClickTrackValue={
            !ctas.closeButtonLocation && !ctas.closeButtonDescription
              ? `no tracking`
              : `${ctas.closeButtonLocation || ''} | ${ctas.closeButtonDescription || ''} | internal`
          }
        />
        <Modal.Body className="modal-body">
          <div className="sign-in">
            <SignInForm {...signIn} signInCta={ctas.signIn} signInCtaAriaLabel={ctas.signInAriaLabel} />
            <div className="divider"></div>
            <Button
              className="m-button-secondary button"
              ariaLabel={ctas.continueAsGuestAriaLabel}
              callback={onCloseModal}
            >
              {ctas.continueAsGuest}
            </Button>
          </div>
          <div className="join-now">
            <div className="mt-5 mt-md-0 join-now-title">{joinNow.title}</div>
            <Image defaultImageURL={joinNow.image.path} altText={joinNow.image.altText} customClass="join-now-image" />
            {joinNow.benefits?.map((benefit, index) => (
              <div key={`joinNowBenefit${index}`} className="d-flex">
                <Icon iconClass="icon-check" />
                <p className="t-font-s pl-2">{benefit}</p>
              </div>
            ))}
            <Button
              isLink={true}
              className="m-button-secondary pt-2 button"
              ariaLabel={ctas.joinNowAriaLabel}
              href={ctas.joinNowTargetUrl}
            >
              {ctas.joinNow}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </StyledSignInModal>
  );
};
