import { baseVariables } from '@marriott/mi-ui-library';

const isAndroidTabletOrMobile = () => {
  const lgWidth = parseInt(baseVariables.mediaQuery.lg.match(/\d+/)?.[0] as string);
  const mdWidth = parseInt(baseVariables.mediaQuery.md.match(/\d+/)?.[0] as string);
  const isMinLgWidth = window.innerWidth >= lgWidth;
  const isMaxMdWidth = window.innerWidth <= mdWidth;

  const isCoarsePointer = window.matchMedia('(pointer: coarse)').matches;
  const isLandscapeOrientation = window.matchMedia('(orientation: landscape)').matches;

  const isNotIphone = !/iPhone/i.test(navigator.userAgent);

  const isLandscapeTablet = isMinLgWidth && (isCoarsePointer || isLandscapeOrientation);

  return (isMaxMdWidth || isLandscapeTablet) && isNotIphone;
};

export function print(target: string, heading?: string) {
  const printContent = document.querySelector('.print-content');
  printContent && printContent.remove();

  const printContainer = document.createElement('div');
  printContainer.classList.add('print-content');

  if (heading) {
    const header = document.createElement('h1');
    header.textContent = heading;
    printContainer.appendChild(header);
  }

  const clonedTarget = (document.getElementById(target) as HTMLElement).cloneNode(true);
  printContainer.appendChild(clonedTarget);

  document.body.appendChild(printContainer);

  const isAndroidDevice = isAndroidTabletOrMobile();

  if (!isAndroidDevice) {
    window.onafterprint = () => {
      document.body.removeChild(printContainer);
      window.onafterprint = null;
    };
  }

  window.print();

  if (isAndroidDevice) {
    window.onfocus = () => {
      document.body.removeChild(printContainer);
      window.onfocus = null;
    };
  }
}
