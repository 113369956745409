import { Types } from '@marriott/mi-ui-library';
import { renditions } from '../../modules/utils/constants/constants';

export enum CardLayeredVariations {
  'Square' = 'square',
  'Wide' = 'wide',
  'Tall' = 'Tall2:3',
  'TallOneByTwo' = 'Tall1:2',
}
export interface CardLayeredProps {
  cardLayeredVariations:
    | CardLayeredVariations.Square
    | CardLayeredVariations.Wide
    | CardLayeredVariations.Tall
    | CardLayeredVariations.TallOneByTwo;
  badgeText: string;
  openLinkInNewTab?: boolean;
  rel?: string;
  socialMediaIconName?: string;
  eyebrow?: string | undefined;
  ctaLink: string;
  titleText?: string;
  fileReferenceImage?: string;
  badgeIcon?: string;
  icon?: string;
  headerTag?: Types.tags.h2 | Types.tags.h3 | Types.tags.h4 | Types.tags.h5 | Types.tags.h6;
  header?: string;
  fontSize: Types.size.large | Types.size.extraLarge;
  openInNewTab: boolean;
  trackingProperties?: Types.TrackingPropsInterface;
  dynamicMedia?: renditions;
  dynamicMediaTall?: renditions;
  dynamicMediaTallOneByTwo?: renditions;
  dynamicMediaWide?: renditions;
  styleclass?: string;
}
