export type FileUploadProps = {
  ctaLabel: string;
  ctaClass?: string;
  disabled?: boolean;
  allowedFileTypes: string[];
  maxSize: number;
  onFileSelected: (file: File) => void;
  onError: (errorType: FileErrorType) => void;
};

export enum FileErrorType {
  SIZE_ERROR,
  FORMAT_ERROR,
  UPLOAD_VIRUS_ERROR,
  UPLOAD_FILE_ERROR,
  DELETE_FILE_ERROR,
  NO_ERROR,
}
