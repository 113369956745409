import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledFaqModalWrapper = styled.div`
  .accordion-body {
    padding: ${toRem(24)} 0 0 ${toRem(8)};
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)} ${toRem(11)} 0 ${toRem(24)};
    }

    .m-accordion__title {
      -webkit-line-clamp: unset;
    }
  }

  .modal-accordion-header {
    padding: ${toRem(28)} ${toRem(24)};
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)};
    }
  }

  .accordion__container .seperator {
    display: block;
    height: unset;
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }

  .t-horizontal-vertical-rule {
    color: unset;
    background-color: unset;
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
      .accordion-body {
        padding-left: ${toRem(12)};
        padding-right: ${toRem(24)};
      }

      .accordion__container .accordion__heading .accordion__title {
        margin-left: auto;
        margin-right: unset;
      }

      .accordion__list {
        text-align: right;
      }
   }`}
`;
