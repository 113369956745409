import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import { Button, Eyebrow, Heading, Types, Image } from '@marriott/mi-ui-library';
import { CardVerticalProps } from './CardVertical.types';
import { StyledCardVertical } from './CardVertical.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const CardVertical: React.FC<CardVerticalProps> = props => {
  const {
    openInNewTab = false,
    eyebrow,
    ctaLink,
    header,
    subheader,
    headerTag,
    description,
    ctaLinkText,
    cardVerticalVariations,
    dynamicMedia,
    trackingProperties,
    styleclass,
  } = props;

  const renditions = dynamicMedia?.renditions;

  const CardVerticalRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;

  return (
    <StyledCardVertical className="col-12 p-0" data-component-name="m-rnb-CardVertical" data-testid="rnb-CardVertical">
      {(cardVerticalVariations === 'Tall1:1' || cardVerticalVariations === 'tall16x9') && (
        <div
          className={`card-vertical ${
            styleclass && cardVerticalVariations !== 'tall16x9' ? styleclass : ''
          } d-flex flex-column`}
          data-variation={cardVerticalVariations}
          data-testid="card-vertical"
        >
          <Image
            title={header}
            altText={dynamicMedia?.altText || header}
            renditions={renditions}
            dynamic={dynamicMedia?.dynamic}
            defaultImageURL={dynamicMedia?.assetPath}
            loading="eager"
          />
          <div
            className={`card-vertical__body overlay ${
              cardVerticalVariations === 'Tall1:1' || cardVerticalVariations === 'tall16x9' ? 'align-items-center' : ''
            }`}
          >
            <div className={`d-flex flex-column align-items-center justify-content-between card-vertical__content`}>
              <div>
                {eyebrow && <Eyebrow text={eyebrow} />}
                {header && (
                  <Heading
                    element={headerTag}
                    variation={Types.headingType.subtitle}
                    fontSize={Types.size.extraLarge}
                    titleText={header || ''}
                    customClass={' t-subtitle-xl heading m-0 bonvoy-wheels '}
                  />
                )}
                {subheader && (
                  <>
                    {/* <span
                    className={`${
                      cardVerticalVariations === 'Tall1:1' || cardVerticalVariations === 'tall16x9'
                        ? 'seperator t-horizontal-vertical-rule'
                        : ''
                    }`}
                  ></span> */}
                    <div
                      className={clsx(
                        't-label-s points',
                        `${
                          cardVerticalVariations === 'Tall1:1' || cardVerticalVariations === 'tall16x9'
                            ? 'sep-lines '
                            : ''
                        }`
                      )}
                    >
                      {subheader}
                    </div>
                    {/* <span
                    className={`${
                      cardVerticalVariations === 'Tall1:1' || cardVerticalVariations === 'tall16x9'
                        ? 'seperator  t-horizontal-vertical-rule'
                        : ''
                    }`}
                  ></span> */}
                  </>
                )}
                {description && (
                  <div className="description-height">
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                      className={clsx('description', 't-font-s')}
                    ></div>
                  </div>
                )}
              </div>

              {ctaLinkText && (
                <Button
                  href={ctaLink}
                  isLink={true}
                  className={`${openInNewTab ? 'm-button-s m-button-secondary' : 'm-button-s m-button-secondary'}`}
                  target={`${openInNewTab ? '_blank' : '_self'}`}
                  rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
                  ref={CardVerticalRef}
                  trackingProperties={trackingProperties}
                  custom_click_track_value={`${TRACKING_CONSTANT.OFFER_SECTION}|${TRACKING_CONSTANT.OFFERS_VIEW_DETAILS}|${TRACKING_CONSTANT.EXTERNAL_LINK}`}
                >
                  {ctaLinkText}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </StyledCardVertical>
  );
};

export const CardVerticalConfig = {
  emptyLabel: 'cardvertical',
  isEmpty: true,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/cardvertical`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CardVerticalEditable: FC<any> = props => (
  <EditableComponent config={CardVerticalConfig} {...props}>
    <CardVertical {...props} />
  </EditableComponent>
);
