import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledCardLayered = styled.div`
  display: flex;
  justify-content: space-between;

  .card-layered {
    position: relative;
    border-radius: ${toRem(15)};
    box-sizing: border-box;
    overflow: hidden;
    width: fit-content;
    height: fit-content;
    .image {
      max-width: 100%;
    }
    &__content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .overlay {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;
      }
      .socialIcons {
        padding: 0;
        span {
          &::before {
            padding: ${toRem(6)} ${toRem(6)} ${toRem(8)};
          }
        }
      }
      .card-texts {
        background: ${baseVariables.gradient['baseGradient00']};
        height: auto;
        padding-top: 3.125rem !important;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .cardTitle {
          display: -webkit-box; /* stylelint-disable-line */
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; /* stylelint-disable-line */
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0;
          margin: 0;
        }
      }
    }
    &.inverse {
      .card-layered__content .card-texts {
        background: ${baseVariables.gradient['baseGradient04']};
      }
    }
    .chevronContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      .icon-m {
        display: flex;
        height: 100%;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 1.5rem 0;
      }
      .icon-arrow-right {
        position: absolute;
        right: 1.5rem;
      }
      .icon-arrow-right-external:before {
        font-size: 1.25rem;
        right: 1.5rem;
      }
      [class*='icon-arrow-']:before {
        padding-bottom: ${toRem(2)};
      }
      span {
        height: 100%;
      }
    }
  }
  .glide__slides & {
    white-space: normal;
  }
`;
