/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

export interface campaignDetailsStore {
  campaignId: string;
  campaignData: any;
  campaignDataError: boolean;
  campaignOffersList: any;
  campaignOffersListError: boolean;
  campaignOfferByIdDetailsError: boolean;
  campaignsLoading: boolean;
  terms: any;
  scrollPosition: number;
  setCampaignId: (id: string) => void;
  setCampaignData: (data: any) => void;
  setCampaignDataError: (error: boolean) => void;
  setCampaignOffersList: (data: any, error: boolean, loading: boolean) => void;
  setCampaignOfferByIdDetailsError: (error: boolean) => void;
  setTerms: (terms: any) => void;
  setScrollPosition: (position: number) => void;
}

export const initialState: campaignDetailsStore = {
  campaignId: '',
  campaignData: null,
  campaignDataError: false,
  campaignOffersList: null,
  campaignOffersListError: false,
  campaignOfferByIdDetailsError: false,
  campaignsLoading: false,
  terms: [],
  scrollPosition: 0,
  setCampaignId: () => {},
  setCampaignData: () => {},
  setCampaignDataError: () => {},
  setCampaignOffersList: () => {},
  setCampaignOfferByIdDetailsError: () => {},
  setTerms: () => {},
  setScrollPosition: () => {},
};

export const useStore: StateCreator<campaignDetailsStore> = set => {
  return {
    ...initialState,

    setCampaignId: id => {
      set({ campaignId: id });
    },
    setCampaignData: data => {
      set({ campaignData: data });
    },
    setCampaignDataError: error => {
      set({ campaignDataError: error });
    },
    setCampaignOffersList: (data: any, error: any, loading: boolean) => {
      set({ campaignOffersList: data, campaignOffersListError: error, campaignsLoading: loading });
    },
    setCampaignOfferByIdDetailsError: error => {
      set({ campaignOfferByIdDetailsError: error });
    },
    setTerms: terms => {
      set({ terms });
    },
    setScrollPosition: position => {
      set({ scrollPosition: position });
    },
  };
};

export const campaignDetailsStore = createAppStore(useStore, {
  usePersistentStore: false,
});
