import { StyledSkeletonLoaderDiv, StyledSkeletonLoaderContainer } from './SkeletonOutletCardLoader.styles';

export const SkeletonOutletCardLoader = () => {
  return (
    <StyledSkeletonLoaderContainer
      className="container skeleton-loader-container"
      data-testid="skeleton-loader-container"
    >
      {[...Array(10)].map((key, ind) => (
        <StyledSkeletonLoaderDiv key={ind}>
          <div className={`left-side-loader ${key ?? ''}`}>
            <div
              data-testid={`skeleton-loader-composite-${ind}`}
              className="skeleton-loader-composite image-place-loader"
              style={{
                width: '100%',
                height: '100%',
              }}
            ></div>
          </div>
          <div className="right-side-loader">
            <div className="top-right-side-loader">
              <div
                className="skeleton-loader-composite title-place-loader mb-2"
                style={{
                  width: '30%',
                  height: '20px',
                }}
              ></div>
              <div
                className="skeleton-loader-composite review-place-loader mb-3"
                style={{
                  width: '30%',
                  height: '34px',
                }}
              ></div>
              <div
                className="skeleton-loader-composite desc-place-loader mb-4"
                style={{
                  width: '100%',
                  height: '90px',
                }}
              ></div>
              <div
                className="skeleton-loader-composite view-place-loader mb-2"
                style={{
                  width: '30%',
                  height: '16px',
                }}
              ></div>
            </div>
            <div className="bottom-rate-place-loader-container">
              <div
                className="skeleton-loader-composite rate-place-loader mr-4"
                style={{
                  width: '25%',
                  height: '42px',
                }}
              ></div>
            </div>
          </div>
        </StyledSkeletonLoaderDiv>
      ))}
    </StyledSkeletonLoaderContainer>
  );
};
