import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersMBEHeroProps } from './OffersMBEHero.types';
import { StyledOffersMBEHero } from './OffersMBEHero.styles';
import { CountDownTimer } from './CountDownTimer';
import { Icon } from '@marriott/mi-ui-library';
import { getLocalTimeEST, calculateTimeLeft, checkCampaignStatus } from '../../utils/OfferUtils';
import { MBEMessage } from './MBEMessage';
import { useGetBreakpoint, shouldUseMockData } from '../../utils/CommonUtils';
import { getTextAlignClass, getWrapperClass, getMBEWrapperClass } from './utils/helper';

export const OffersMBEHeroConfig = {
  emptyLabel: 'OffersMBEHero',
  isEmpty: () => true,
  resourceType: `mi-aem-offers-spa/components/mbehero`,
};

export const OffersMBEHero: FC<OffersMBEHeroProps> = props => {
  const { isAuthorMode } = props;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [mockProps, setMockProps] = useState<any>({});

  const GetMockData = async (isAuthorMode: boolean) => {
    if (shouldUseMockData(isAuthorMode)) {
      const mockData = await import('./__mock__/OffersMBEHero.mock');
      return mockData.mockProps;
    }
    return {};
  };

  useEffect(() => {
    const fetchMockData = async () => {
      const mockData = await GetMockData(isAuthorMode);
      setMockProps(mockData);
    };

    fetchMockData();
  }, [isAuthorMode]);
  console.log('mockProps', mockProps);
  const { offersData, model } = !isAuthorMode ? props : mockProps;
  const { campaignStartDate = '', campaignEndDate = '' } = offersData || {};

  const [localTimezone, setLocalTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [utilityState, setUtilityState] = useState<string>('inactive');

  const [timeLeft, setTimeLeft] = useState<{ days: number }>({ days: 0 });
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');

  const updateStatus = () => {
    const currentLocalTimeEST = getLocalTimeEST();

    setTimeLeft(calculateTimeLeft(campaignStartDate, isAuthorMode));
    setUtilityState(checkCampaignStatus(currentLocalTimeEST, campaignStartDate, campaignEndDate));
  };
  useEffect(() => {
    updateStatus();

    if (timeLeft.days > 1) return;
    const interval = setInterval(() => {
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (currentTimezone !== localTimezone) {
        setLocalTimezone(currentTimezone);
        updateStatus();
      }
    }, 10000); // Check every 10 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps
  }, [localTimezone, campaignStartDate, campaignEndDate, timeLeft?.days]);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <EditableComponent config={OffersMBEHeroConfig} {...props}>
      <StyledOffersMBEHero data-testid="offers-OffersMBEHero" data-component-name="o-offers-OffersMBEHero">
        <div className={clsx('text-flex', getTextAlignClass(props?.isUserLoggedIn, utilityState, isMobileViewPort))}>
          {(!props?.isUserLoggedIn && utilityState === 'active') ||
          (!props?.isUserLoggedIn && utilityState === 'inactive' && isMobileViewPort) ? (
            <div
              className={clsx('icon-with-lines icon-wrapper', {
                'icon-with-lines-mobile': isMobileViewPort,
              })}
            >
              <div className="icon-line"></div>
              <Icon iconClass={model?.iconFontPath + ' icon'}></Icon>
              <div className="icon-line"></div>
            </div>
          ) : null}
        </div>
        <div className={clsx(getWrapperClass(props?.isUserLoggedIn, isMobileViewPort))}>
          {/* Flex container to handle layout of MBEMessage and CountdownTimer */}
          <div className={clsx(getMBEWrapperClass(props?.isUserLoggedIn, utilityState, isMobileViewPort))}>
            {/* Flex wrapper to manage both components in one row for desktop, column for mobile */}
            <div
              className={clsx(
                'content-wrapper',
                isMobileViewPort ? 'mobile-layout' : 'desktop-layout' // Use this to control layout based on viewport
              )}
            >
              <MBEMessage variation={utilityState} {...props} />

              {utilityState === 'inactive' && model?.countdownTimer && (
                <>
                  {/* Conditionally render countdown timer */}
                  {!isMobileViewPort && <div className="vertical-divider"></div>}
                  <div className="timer-align">
                    <CountDownTimer
                      startDate={offersData?.campaignStartDate}
                      model={model}
                      isAuthorMode={isAuthorMode}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </StyledOffersMBEHero>
    </EditableComponent>
  );
};
