export const MBOPOfferDetailsMock = {
  data: {
    offersSearch: {
      edges: [
        {
          node: {
            associatedOutlets: [
              {
                outlets: [
                  {
                    cuisines: ['English'],
                    id: '6908006',
                    name: 'Afternoon Tea at Cast Iron',
                    reservationURL:
                      'https://www.tableresmarriott.com/cast-iron-bar-grill-chepstow-restaurant-chepstow-st-pierre-park',
                    media: null,
                  },
                ],
                property: {
                  basicInformation: {
                    displayName: null,
                  },
                  contactInformation: {
                    contactNumbers: [
                      {
                        phoneNumber: {
                          areaCityCode: null,
                          countryCode: '+44',
                          display: '+44 1291 625261',
                          extension: null,
                          international: '+44 1291 625261',
                          national: '01291 625261',
                          number: null,
                          original: '+441291625261',
                        },
                      },
                      {
                        phoneNumber: {
                          areaCityCode: null,
                          countryCode: '+44',
                          display: '+44 1291 625261',
                          extension: null,
                          international: '+44 1291 625261',
                          national: '01291 625261',
                          number: null,
                          original: '+441291625261',
                        },
                      },
                    ],
                  },
                },
              },
            ],
            bookingEndDate: '2024-12-15',
            bookingStartDate: '2023-11-22',
            description:
              'Surrounded by the grandeur of country landscapes and even an 11th century church, our South Wales location creates an impressive setting for a truly traditional gathering.<br><br>Served in Cast Iron Grill, you&#39;ll be surrounded by intricately panelled walls, a fireplace, period architecture and furnishings that set the stage for memorable dining. Indulge in a traditional afternoon tea menu featuring a tempting range of sandwiches and sweet things, plus a choice of teas from our Twinings selection. You can also treat yourself and guests to a lovely glass of Prosecco, Champagne or a refreshing Pink Gin Spritz.<br><br>Available 1pm to 3pm, Friday through Saturday from £28.95 per person. Upgrade to Prosecco for £36.95 per person.<br><br><a href=&quot;https://delta-hotel-st-pierre-country-club.skchase.com/vouchers&quot; target=&quot;_blank&quot;>Purchase gift vouchers</a><br>',
            id: 'OFF-75174',
            termsAndConditions:
              'Offer must fall within valid dates and is only valid through the stay window end date. Limited number of rooms are available for this promotion. Tax is additional. Offer does not apply to groups of 10 or more rooms. Offer cannot be combined with any other promotions. Blackout dates may apply. Advance reservations required. Other restrictions apply. Rates are per room, per night and based on availability at the time of reservations.',
            needToKnow: ['Aruba Aloe products room amenity', 'Yoga mat delivered to room', 'A massage for two'],
            title: 'Afternoon Tea at St. Pierre',
            associatedProperties: [
              {
                property: {
                  basicInformation: {
                    latitude: 51.611674,
                    name: 'Delta Hotels St. Pierre Country Club',
                    longitude: -2.702492,
                  },
                  id: 'BRSSP',
                },
              },
            ],
          },
        },
      ],
    },
  },
};

export const CHUBOfferDetailsMock = {
  data: {
    diningOfferbyId: {
      offerId: '3130',
      name: 'Candlelit Romantic Night',
      description: 'Candlelit Romantic Night',
      needToKnow: ['Aruba Aloe products room amenity', 'Yoga mat delivered to room', 'A massage for two'],
      termsAndConditions:
        'Offer must fall within valid dates and is only valid through the stay window end date. Limited number of rooms are available for this promotion. Tax is additional. Offer does not apply to groups of 10 or more rooms. Offer cannot be combined with any other promotions. Blackout dates may apply. Advance reservations required. Other restrictions apply. Rates are per room, per night and based on availability at the time of reservations.',
      image: {
        alternateDescription: 'skyline',
        title: 'ievds-attraction-skyline-5334',
        imageUrls: {
          classicHorizontal: '/content/dam/marriott-renditions/IEVDS/ievds-attraction-skyline-5334-hor-clsc.jpg',
          panoramicHorizontal: '/content/dam/marriott-renditions/IEVDS/ievds-attraction-skyline-5334-hor-pano.jpg',
          __typename: 'ImageRendition',
        },
        __typename: 'OfferImage',
      },
      schedule: {
        expiryDate: '2024-09-25',
        recurrence: 'none',
        startDate: '2024-07-09',
        endDate: '2024-09-25',
        __typename: 'DiningOfferSchedule',
      },
      participatingOutlets: [
        {
          property: {
            id: 'BERZT',
            __typename: 'Hotel',
            basicInformation: {
              displayName: null,
              __typename: 'HotelBasicInformation',
              latitude: 52.510052,
              longitude: 13.375555,
            },
            contactInformation: {
              contactNumbers: [
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337775300',
                    international: '+49 30 337775300',
                    national: '030 337775300',
                    number: null,
                    original: '+4930337775300',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337777',
                    international: '+49 30 337777',
                    national: '030 337777',
                    number: null,
                    original: '+4930337777',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337772100',
                    international: '+49 30 337772100',
                    national: '030 337772100',
                    number: null,
                    original: '+4930337772100',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: null,
                    extension: null,
                    display: '+4930337777; ext= 2200',
                    international: null,
                    national: null,
                    number: null,
                    original: '+4930337777; ext= 2200',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
                {
                  phoneNumber: {
                    areaCityCode: null,
                    countryCode: '+49',
                    extension: null,
                    display: '+49 30 337775555',
                    international: '+49 30 337775555',
                    national: '030 337775555',
                    number: null,
                    original: '+4930337775555',
                    __typename: 'PhoneNumber',
                  },
                  __typename: 'ContactNumber',
                },
              ],
              __typename: 'PropertyContactInformation',
            },
          },
          restaurantDetails: [
            {
              cuisine: 'German',
              restaurant: {
                id: 'BERZT.6557748',
                primaryType: null,
                basicInformation: {
                  name: 'POTS',
                  primaryType: 'Restaurant',
                  reservationURL: 'https://www.sevenrooms.com/landing/potsberlin',
                  __typename: 'RestaurantBasicInformation',
                },
                diningExperience: {
                  cuisines: ['German'],
                  __typename: 'RestaurantDiningExperience',
                },
                __typename: 'Restaurant',
              },
              __typename: 'RestaurantDetail',
            },
          ],
          __typename: 'DiningParticipatingOutlets',
        },
      ],
      __typename: 'DiningOffer',
    },
  },
};
