//Basic Search Form skeleton loader to serve target puropose.

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SearchFormLoaderStyles } from './SearchFormSkeletonLoader.styles';
import { useEffect, useState } from 'react';
import { useGetBreakpoint } from '../../modules/utils/helpers';
import clsx from 'clsx';

export const SearchFormSkeletonLoader = () => {
  let portSize = useGetBreakpoint();
  const [isMobileViewPort, setIsMobileViewPort] = useState(portSize === 'mobile');

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      portSize = useGetBreakpoint();
      setIsMobileViewPort(portSize === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <SearchFormLoaderStyles
      data-component-name="m-rnb-SearchFormSkeletonLoader"
      data-testid="rnb-SearchFormSkeletonLoader"
    >
      <div className="rnb-additional-container">
        <div className="container">
          <div className="row">
            <div className="searchFormStyles col-12">
              <Skeleton
                className={clsx('skeleton-loader', !isMobileViewPort ? 'mt-3' : '')}
                width={isMobileViewPort ? '30%' : '20%'}
                height={20}
                containerTestId="search-loader-label"
              />
              <Skeleton
                className="skeleton-loader"
                width={!isMobileViewPort ? '50%' : '100%'}
                height={!isMobileViewPort ? 40 : 20}
                containerTestId="search-loader-textbox"
              />
              {!isMobileViewPort && (
                <div className="searchFormLoaderButton" data-testid="search-loader-cta">
                  <Skeleton className="skeleton-loader" width={'17.1875rem'} height={50} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SearchFormLoaderStyles>
  );
};
