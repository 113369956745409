import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { font, color } = baseVariables;

export const StyledEventSummaryContainer = styled.div`
  .text-uppercase {
    text-transform: uppercase;
  }
`;

export const StyledEventSummaryCard = styled.div`
  padding: ${toRem(30)} ${toRem(40)} ${toRem(24)} ${toRem(40)};
  background-color: ${color.base20};
  &.border-left {
    border-left: ${toRem(5)} solid;
  }
  .event-title {
    flex: 0 0 60%;
  }
  .text-bold {
    font-weight: ${font.fontWeightBold} !important;
  }
  .border-top {
    border-top: ${toRem(1)} solid;
  }
  .border-right {
    border-right: ${toRem(1)} solid;
  }
  .icon-check {
    color: ${color.alert30};
  }
  .icon-clear {
    color: ${color.alert50};
  }
  .icon-blanket {
    &::before {
      transform: rotate(-135deg);
    }
  }
  .m-icon-text {
    display: block;
  }

  .event-info {
    row-gap: ${toRem(24)};
  }
`;

export const StyledModalContainer = styled.div`
  .margin-top {
    margin-top: ${toRem(60)} !important;
  }
`;
