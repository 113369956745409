import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledStaticArticles = styled.div`
  img {
    border-radius: ${toRem(14)};
    width: 100%;
  }

  .separator-line {
    border-bottom: 1px solid ${baseVariables.color.neutral30};
    width: 100%;
    display: block;
    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      display: none;
    }
  }
`;
