import { CreateOrderForGroupBookingUsingEmailLinkInput } from '@marriott/mi-groups-graphql';
import { PROCESS_CREDIT_CARD_URL, PAYMENT_METHOD_TYPE_CODE } from '../constants';
import { PaymentInfoFormData } from '../organisms/PaymentInfo/PaymentInfo.types';
import { PreferredPaymentFormData } from '../organisms/PreferredPaymentInfo/PreferredPaymentInfo.types';
import { getFormattedDateString } from './date';

export const getPaymentMethodId = async (data: PaymentInfoFormData, marshaCode: string) => {
  let paymentMethodId = '';
  let errors: string[] = [];
  const expirationMonth = data.paymentInfo.expirationMonth.value.padStart(2, '0');
  const expirationYear = data.paymentInfo.expirationYear.value.slice(-2);

  if (data.paymentInfo.isSavedCard) {
    const paymentdata = `${data.paymentInfo.tokenizedPAN}|${expirationMonth}${expirationYear}|${data.paymentInfo.cardType}|${data.paymentInfo.panLast4}`;
    paymentMethodId = Buffer.from(paymentdata).toString('base64');
  } else {
    const payload = {
      marshaCode,
      creditCardInfo: {
        creditCardType: data.paymentInfo.cardType,
        nameOnCreditCard: data.paymentInfo.cardHolderName,
        creditCardNumber: data.paymentInfo.cardNumber,
        expMonth: expirationMonth,
        expYear: data.paymentInfo.expirationYear.value,
        creditCardCVV: data.paymentInfo.cvvNumber,
        cvvApplicable: true,
        paymentMethodTypeCode: PAYMENT_METHOD_TYPE_CODE,
      },
      legacyV2: true,
    };

    try {
      const response = await fetch(PROCESS_CREDIT_CARD_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error();
      }

      const responseData = await response.json();
      paymentMethodId = responseData?.creditCardInfo?.paymentMethodId as string;
      const errorMessageKeys = responseData?.errorMessages?.guestInfoFormErrorMessages?.errorMessageKeys as string[];
      errors = errorMessageKeys.length ? errorMessageKeys : [];
    } catch (error) {
      paymentMethodId = '';
    }
  }

  return { paymentMethodId, errors };
};

export const getPreferredPaymentInput = (
  data: PreferredPaymentFormData,
  queryParams: Record<string, string>,
  paymentId: string
): CreateOrderForGroupBookingUsingEmailLinkInput => {
  const { userInfo, addressInfo } = data;
  let stateProvince = '';

  try {
    const stateOption = JSON.parse(addressInfo.state);
    stateProvince = stateOption.code;
  } catch (error) {
    stateProvince = addressInfo.state;
  }

  const contactInformation = {
    emailAddress: userInfo.email,
    phone: {
      type: addressInfo.addressType,
      number: userInfo.phoneNumber,
    },
    address: {
      line1: addressInfo.addressLine1,
      line2: addressInfo.addressLine2,
      countryCode: addressInfo.country.code,
      city: addressInfo.city,
      stateProvince,
      postalCode: addressInfo.zipcode,
    },
  };

  return {
    propertyId: queryParams['PropCode'],
    opportunityId: queryParams['OptyId'],
    quoteId: queryParams['QuoteId'],
    eventName: queryParams['Event'],
    eventStartDate: getFormattedDateString(queryParams['ADate']),
    eventEndDate: getFormattedDateString(queryParams['DDate']),
    hasGuestRoom: false,
    agentEmail: queryParams['SfaEmAdd'],
    givenName: userInfo.firstName,
    surName: userInfo.lastName,
    companyName: addressInfo.companyName,
    contactInformation,
    paymentId,
  };
};
