import { useCallback, useEffect, useRef } from 'react';
import { USER_SESSION_TIMEOUT } from '../constants';

export const useIdleTimer = (redirectUrl: string) => {
  const timeOutAction = useRef<number | null>(null);

  const resetTimer = useCallback(() => {
    if (timeOutAction.current) {
      clearTimeout(timeOutAction.current);
    }

    timeOutAction.current = window.setTimeout(() => {
      window.location.assign(redirectUrl);
    }, USER_SESSION_TIMEOUT);
  }, [redirectUrl]);

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

    resetTimer();
    events.forEach(event => window.addEventListener(event, resetTimer));

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimer));
      if (timeOutAction.current) {
        clearTimeout(timeOutAction.current);
      }
    };
  }, [resetTimer]);

  return resetTimer;
};
