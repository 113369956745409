import { FC, useState } from 'react';
import { StyledErrorModal } from './RnBErrorModal.styles';
import { Modal } from '@marriott/mi-ui-library';
import { rnbErrorState, rnbErrorStore } from '../../modules/store/rnbErrorStore';

export const RnBErrorModal: FC = () => {
  const [popupOpenState, setPopupOpenState] = useState<boolean>(true);
  const genericError = rnbErrorStore((state: rnbErrorState) => state.genericError);

  return popupOpenState ? (
    <StyledErrorModal data-component-name="m-rnb-RnBErrorModal" data-testid="rnb-RnBErrorModal">
      <Modal
        className={`custom-scrollbar modal-main-container rnb-error-modal ${
          popupOpenState ? '' : 'close-popup-header'
        }`}
        show={true}
        handleBlur={true}
        popupOpenState={popupOpenState}
        setPopupOpenState={setPopupOpenState}
        allFilterPopUp={true}
      >
        <Modal.Header
          className="modal-header t-title-s"
          labelText={genericError.locationErrorTitle}
          popupHeaderOnCLoseFunc={setPopupOpenState}
        />
        <Modal.Body>
          <div className="t-font-m">{genericError.locationErrorDescription}</div>
        </Modal.Body>
      </Modal>
    </StyledErrorModal>
  ) : null;
};
