/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useContext, FormEvent, useRef } from 'react';
import {
  OTSPlaceHolderLimit,
  otsUrlTypeTerm,
  otsUrlTypeValue,
  searchResultsPageUrl,
  TRACKING_CONSTANT,
  PAGES,
} from '../../modules/utils/constants';
import {
  SearchFormParent,
  SearchFormContainer,
  SearchInputField,
  TextInputField,
  GlobalStyledFullForm,
} from '../SearchFormWrapper/SearchFormWrapper.styles';
import { SearchFieldContainer } from './SearchByOpenText.styles';
import clsx from 'clsx';
import {
  useGetBreakpoint,
  trackImpression,
  getTrackingProperties,
  getDirection,
  getSubDirectoryLocale,
} from '../../modules/utils/helpers';
import { useRouter } from 'next/router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import searchFilterStore from '../../modules/store/store';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { SearchFormWrapperProps } from '../SearchFormWrapper/SearchFormWrapper.types';
import { Button, Icon } from '@marriott/mi-ui-library';
import { rnbErrorState, rnbErrorStore } from '../../modules/store/rnbErrorStore';
import { PageParamsContext } from '../../modules/context/PageContext';
const { HOMEPAGE, SEARCH_RESULTS_PAGE } = PAGES;
declare global {
  interface Window {
    google: any;
  }
}

export const SearchByOpenText = (props: SearchFormWrapperProps) => {
  const { currentPage, userId } = useContext(PageParamsContext);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  let portSize = useGetBreakpoint();
  const [isMobileViewPort, setIsMobileViewPort] = useState(portSize === 'mobile');
  const router = useRouter();
  const setIsLoaderVisible = useStore((state: searchResultsState) => state.setIsLoaderVisible);
  const setQueryParam = useStore((state: searchResultsState) => state.setQueryParam);
  const setPaginationChange = useStore((state: searchResultsState) => state.setPaginationChange);
  const setPageNumber = useStore((state: searchResultsState) => state.setPageNumber);
  const { setFilter } = searchFilterStore();
  const [getFieldValue, setGetFieldValue] = useState(false);
  const setIsComponentLoading = useStore((state: searchResultsState) => state.setIsComponentLoading);
  const isComponentLoading = useStore((state: searchResultsState) => state.isComponentLoading);
  const shouldComponentReload = useStore((state: searchResultsState) => state.shouldComponentReload);
  const setShouldComponentReload = useStore((state: searchResultsState) => state.setShouldComponentReload);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [userInputSearchTerm, setUserInputSearchTerm] = useState('');
  const setErrorState = rnbErrorStore((state: rnbErrorState) => state.setErrorState);
  const [animationTriggerFlag, setAnimationTriggerFlag] = useState(true);
  const placeholdersProps = [props?.searchLabel, props?.searchPlaceholder, props?.searchBarPlaceholder];
  const animationTime = props?.animationTime ?? 4;
  const placeholders: any[] = placeholdersProps?.filter(value => value);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('');
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const isDirectionRTL = getDirection() === 'rtl';
  const { currentLocale } = useContext(PageParamsContext);
  const subLocale = getSubDirectoryLocale(currentLocale || 'en-US');

  useEffect(() => {
    const animationInterval = setInterval(() => {
      setAnimationClass('animate');
      setTimeout(() => {
        setAnimationClass('');
        setCurrentIndex(prevIndex => (prevIndex + 1) % placeholders?.length);
      }, (animationTime * 1000) / 2); // Pause in the middle of the animation
    }, (animationTime - 0.5) * 1000); // Change the placeholder every 3 seconds

    return () => {
      clearInterval(animationInterval);
    };
  }, [placeholders, animationTriggerFlag]);

  const { HOME_PAGE_SEARCH_FORM, SEARCH_RESULTS_SEARCH_FORM, SEARCH_BUTTON, UPDATE_SEARCH_BUTTON, INTERNAL_LINK } =
    TRACKING_CONSTANT;

  const isRedirectionRequired = (isHomePage: boolean) => {
    return (
      isHomePage || router?.pathname?.includes('/dining/404') || router?.pathname?.includes('/dining/restaurant-bar')
    );
  };

  const getSearchResultsRedirectUrl = (): string => {
    setFilter([]);
    const searchPageUrl =
      subLocale +
      searchResultsPageUrl +
      '?term=' +
      encodeURIComponent(userInputSearchTerm) +
      '&' +
      otsUrlTypeTerm +
      '=' +
      otsUrlTypeValue;

    return searchPageUrl;
  };

  useEffect(() => {
    const searchBar: React.RefObject<HTMLDivElement | null> = searchContainerRef;
    const searchBarOffsetTop: number = searchBar?.current?.offsetTop ?? 53; // Header height is 53
    let prevScrollY = window?.scrollY;

    function handleScroll() {
      const currentScrollY = window?.scrollY;
      if (currentScrollY < prevScrollY) {
        setIsScrolledUp(true);
      } else {
        setIsScrolledUp(false);
      }

      if (window?.scrollY >= searchBarOffsetTop) {
        setIsScrolled(true);
      } else if (window?.scrollY === 0) {
        setIsScrolled(false);
      }
      prevScrollY = currentScrollY;
    }
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      portSize = useGetBreakpoint();
      setIsMobileViewPort(portSize === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack) trackImpression(props?.trackingProperties || {}, 'Search Form');
  }, [props?.trackingProperties]);

  useEffect(() => {
    let queryParams = [];
    if (router?.query) {
      queryParams = Object.keys(router?.query);
      queryParams.forEach(queryParam => {
        if (queryParam === 'term') {
          setUserInputSearchTerm(router?.query?.['term']?.toString() ?? '');
        }
      });
    }
  }, [getFieldValue, router?.query]);

  const openTextSearchField = (params: any) => (
    <SearchInputField isDropDownOpen={isPopUpOpen} isHomePage={props?.homePage}>
      <div className={clsx('rnb-dest-search-input d-flex align-items-center')}>
        <TextInputField
          {...params}
          id="openTextSearch-input"
          name="openTextSearch"
          InputProps={{
            ...params.InputProps,
          }}
          variant="standard"
          onFocus={() => setAnimationTriggerFlag(false)}
          onBlur={() =>
            userInputSearchTerm?.length > 0 ? setAnimationTriggerFlag(false) : setAnimationTriggerFlag(true)
          }
          placeholder={
            !isPopUpOpen
              ? placeholders[currentIndex]?.length <= OTSPlaceHolderLimit
                ? placeholders[currentIndex]
                : placeholders[currentIndex]?.slice(0, OTSPlaceHolderLimit) + '...'
              : ''
          }
          className={`t-subtitle-xl ${
            animationTriggerFlag && !userInputSearchTerm && placeholders?.length > 1 ? animationClass : ''
          }`}
          data-testid="search-placeholder"
        />
        {isPopUpOpen && userInputSearchTerm && (
          <Button
            isLink={false}
            className="rnb-dest-field-clear-btn"
            callback={clearSearchField}
            aria-label="clear-field"
            testId="clear-field"
          >
            <Icon iconClass={isMobileViewPort ? 'icon-cancel icon-inverse' : 'icon-cancel'} />
          </Button>
        )}
      </div>
    </SearchInputField>
  );

  const updateSearchFieldDisplayValue = (newInputValue: string) => {
    setUserInputSearchTerm(newInputValue);
  };

  const updateSearch = () => {
    const searchPageUrl = getSearchResultsRedirectUrl();
    const queryString = searchPageUrl?.split('?')[1];
    window.history.pushState(searchPageUrl?.split('?')[0], '', `?${queryString}`);
    setQueryParam(queryString);
    setPaginationChange(false);
    setPageNumber(1);
    setIsLoaderVisible(false);
    setShouldComponentReload(!shouldComponentReload);
  };

  const updateSearchResults = () => {
    if (props?.trackingProperties?.impressionTrack) {
      const tracking = getTrackingProperties(props?.trackingProperties || {}, ',', '');
      if (window?.impressionArr?.includes(`${tracking.trackingString}${'Search Form'}`)) {
        window.impressionArr = [];
      }
      trackImpression(props?.trackingProperties || {}, 'Search Form');
    }
    if (userInputSearchTerm === '') setErrorState(0, 'errorText');
    else {
      if (isRedirectionRequired(props?.homePage)) {
        const searchPageUrl = getSearchResultsRedirectUrl();
        window.location.href = searchPageUrl;
        setIsLoaderVisible(false);
        // router.push(searchPageUrl).then(() => setIsLoaderVisible(false));
      } else {
        updateSearch();
      }
    }
    setIsComponentLoading(false);
  };

  const clearSearchField = () => {
    setUserInputSearchTerm('');
  };

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsPopUpOpen(false);
    updateSearchResults();
  };

  const handleBackButton = () => {
    setGetFieldValue(!getFieldValue);
    setIsPopUpOpen(false);
  };

  return (
    <SearchFormParent
      data-component-name="o-rnb-SearchByOpenText"
      currentPage={currentPage}
      onSubmit={e => onFormSubmit(e)}
      isScrolledUp={isScrolledUp}
    >
      <div
        className={clsx(
          'seacrh-contaner-main',
          props?.homePage ? 'rnb-additional-container' : 'rnb-search-form ',
          (currentPage === HOMEPAGE || SEARCH_RESULTS_PAGE) && isScrolled ? 'sticky-search-form back-color' : ''
        )}
        ref={searchContainerRef}
      >
        {isPopUpOpen && isMobileViewPort && <GlobalStyledFullForm />}
        <SearchFormContainer
          className="container"
          isDropDownOpen={isPopUpOpen}
          isHomePage={props?.homePage}
          currentPage={currentPage}
          isScrolled={isScrolled}
          userId={userId}
        >
          {isPopUpOpen && isMobileViewPort && (
            <Button isLink={false} className="back-button" callback={handleBackButton} aria-label="back-button">
              <Icon iconClass="icon-back-arrow-cropped icon-inverse" />
            </Button>
          )}
          <SearchFieldContainer
            isDropDownOpen={isPopUpOpen}
            isHomePage={props?.homePage}
            animationSpeed={animationTime}
            className={clsx(!isMobileViewPort ? 'col-8' : ' ')}
          >
            {(props?.searchBarLabelExpB || props?.searchBarLabel) && (
              <label htmlFor="openTextSearch" className="rnb-destination-label">
                <Icon iconClass="icon-dining rnb-label-dining-icon icon-decorative" />
                <span
                  className={clsx(
                    't-overline-normal',
                    isPopUpOpen && isMobileViewPort ? 't-overline-inverse-normal' : ''
                  )}
                >
                  {props?.searchBarLabelExpB || props?.searchBarLabel}
                </span>
              </label>
            )}

            <Autocomplete
              id="openTextSearch"
              aria-label="openTextSearch"
              aria-controls="openTextSearch"
              value={userInputSearchTerm}
              open={isPopUpOpen}
              inputValue={userInputSearchTerm}
              onInputChange={(_event, newInputValue) => {
                updateSearchFieldDisplayValue(newInputValue);
              }}
              onOpen={(): void => {
                setIsPopUpOpen(true);
              }}
              onClose={(): void => {
                setIsPopUpOpen(false);
              }}
              options={[]}
              noOptionsText={
                <>
                  <span className={clsx('icon-information icon-s', isDirectionRTL ? 'ml-2' : 'mr-2')}></span>
                  <span className="t-font-s">{props?.searchBarInfoMessage}</span>
                </>
              }
              renderInput={params => openTextSearchField(params)}
              disablePortal={true}
              openOnFocus={true}
              clearOnBlur={false}
              forcePopupIcon={false}
              disableClearable={true}
            />
          </SearchFieldContainer>

          {isMobileViewPort && props?.homePage && isComponentLoading && <div className="m-spinner-m m-auto"></div>}
          {((props?.homePage && (props?.searchBarCtaLabelExpB || props?.searchBarCtaLabel)) ||
            (!props?.homePage && (props?.updateSearchBarCtaLabelExpB || props?.updateSearchBarCtaLabel))) && (
            <Button
              isLink={false}
              className={clsx(
                'rnb-additional-container__form-cta',
                props?.homePage ? 'm-button-l m-button-primary' : 'm-button-m m-button-secondary',
                'custom_click_track'
              )}
              callback={updateSearchResults}
              custom_click_track_value={
                props?.homePage
                  ? `${HOME_PAGE_SEARCH_FORM}|${SEARCH_BUTTON}|${INTERNAL_LINK}`
                  : `${SEARCH_RESULTS_SEARCH_FORM}|${UPDATE_SEARCH_BUTTON}|${INTERNAL_LINK}`
              }
            >
              {props?.homePage && isComponentLoading && <div className="m-spinner-m ml-0"></div>}
              {props?.homePage
                ? props?.searchBarCtaLabelExpB || props?.searchBarCtaLabel
                : props?.updateSearchBarCtaLabelExpB || props?.updateSearchBarCtaLabel}
            </Button>
          )}
        </SearchFormContainer>
      </div>
    </SearchFormParent>
  );
};
