import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { theme } from '@marriott/mi-ui-library-shop';

export const StyledCalendarWrapperDiv = styled.div`
.DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--outside {
visibility:visible !important;
}
.DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--selected .DayPicker-Day-Cell-Wrapper div  {
.low-label-adf {
   color: ${baseVariables.color.merch20} !important;
   border: ${toRem(1.8)} solid ${baseVariables.color.base10};
   border-top: none;
   border-radius: 0 0 ${toRem(7)} ${toRem(7)};
  }
}
.mandatory-fees-text-selected {
    color: ${baseVariables.color.neutral40};
   @media only ${baseVariables.mediaQuery.md} {
   display:flex;
  justify-content:end;
  margin-right:${toRem(32)};
  }

}

.adf-error-messages {
  width: 100%;
  margin-right: ${toRem(12)};
  .m-message-inline {
    padding: ${toRem(13)} ${toRem(16)} ${toRem(13)} ${toRem(19)};
    margin: ${toRem(16)} 0 ${toRem(16)} ${toRem(16)};
    @media only ${baseVariables.mediaQuery.md} {
      padding: ${toRem(16)};
      margin: 0 0 ${toRem(24)} 0;
      width: fit-content;
    }
  }
} 

.adf-currency-value {
  margin-left:${toRem(4)};
}
  .DayPicker-Day--today {
    color: inherit;
  }
  .disable-continue {
    pointer-events: none;
    opacity: 0.1 !important;
    
  }
  .a-text-body {
    visibility: visible;
    @media ${baseVariables.mediaQuery.md} {
      display: none;
    }
  }

  .low-placeholder{
    @media ${theme.mediaQuery.allMobiles} {
      height: ${toRem(32)};
    }
  }
  
  .skeleton-loader-composite {
    @media ${theme.mediaQuery.allMobiles} {
      height: ${toRem(18)};
      width: ${toRem(20)};
    }
    height: ${toRem(24)};
    width: ${toRem(56)};
    background-color: ${theme.color['light-gray-1']};
    color: white;
    -webkit-animation: fadein 2s ease-in alternate infinite;
    -moz-animation: fadein 2s ease-in alternate infinite;
    animation: fadein 2s ease-in alternate infinite;
    margin:auto;
  }
  @-webkit-keyframes fadein {
    10% {
      opacity: 0.3;
    }
    25% {
      opacity: 0.35;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.6;
    }
  }
  @-moz-keyframes fadein {
    10% {
      opacity: 0.3;
    }
    25% {
      opacity: 0.35;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.6;
    }
  }
  @keyframes fadein {
    10% {
      opacity: 0.3;
    }
    25% {
      opacity: 0.35;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.6;
    }
  }
  .hide-continue {
    visibility: hidden;
    @media ${baseVariables.mediaQuery.md} {
      visibility: visible;
    }
  }
  .daypicker-cell-custom {
    width: 100%;
    height: 100%;

  }
  .lower-cell {
    .price-section {
      color: ${baseVariables.color.alert30};
    }
  }
  .price-section-wrapper {
    height:auto;
    @media ${theme.mediaQuery.allMobiles} {
      box-shadow:0 ${toRem(4)} ${toRem(20)} rgba(0, 0, 0, 0.07);
    }
    @media ${baseVariables.mediaQuery.md} {
      height:${toRem(100)};
    }
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${baseVariables.color.alert10};
    z-index: 5;
    padding-right: ${toRem(29)} !important;
    padding-top: ${toRem(24)};
    padding-bottom: ${toRem(24)};
    @media ${baseVariables.mediaQuery.md} {
      position: sticky;
      z-index:5;
      border-top:${toRem(1)} solid ${baseVariables.color.neutral30};
    }
  }
  .mobile-price-low-rates{
    color: ${baseVariables.color.alert30};
    font-size: ${toRem(12)};
  }
  .DayPicker.DayPicker-wrapper-react-el {
    width: 100%;
  }
  .DayPicker .DayPicker-wrapper {
    border-radius: ${toRem(12)} !important;
  }
  .DayPicker-wrapper {
    padding-bottom: 0;
    .DayPicker-NavBar {
      .DayPicker-NavButton .DayPicker-NavButton--next {
        margin-right: 0 !important;
      }
      .DayPicker-NavButton {
        &:before {
          color: ${baseVariables.color.neutral100};
        }
        margin-top: ${toRem(16)};
        margin-left:${toRem(34)};
        margin-right:${toRem(34)};
        @media ${baseVariables.mediaQuery.md}{
          margin-left:29%;
          margin-right:29%;
         }
         @media ${baseVariables.mediaQuery.xl}{
          margin-left:${toRem(420)};
          margin-right:${toRem(420)};
        }
        &.DayPicker-NavButton--interactionDisabled {
          display: block;
          opacity: 0.5;
          cursor: auto;
        }
      }
    }
    .DayPicker-Months {
      width: 100%;
      padding: 0;
      padding-left:${toRem(16)} !important;
      padding-right:${toRem(16)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding-left:0 !important;
        padding-right:0 !important;
      }
      .DayPicker-Month {
        margin: 0 !important;
        width: inherit !important;
        @media ${baseVariables.mediaQuery.md} {
          width: inherit !important;
        }
        .DayPicker-Caption {
          div {
            font-size: ${toRem(22)} !important;
            line-height: ${toRem(20)};
            padding-top: ${toRem(32)};
            padding-bottom: ${toRem(32)};
            color: ${baseVariables.color.bvyBase10};
            font-weight: 700;
          }
          @media ${baseVariables.mediaQuery.lg} {
            margin-bottom:0;
          }
        }
        .DayPicker-Weekdays {
          .DayPicker-Weekday {
            padding-bottom: ${toRem(12)} !important;
            abbr {
              font-size: ${toRem(14)};
              line-height: ${toRem(20)};
              font-weight: 700;
              color: ${baseVariables.color.merch30};
              pointer-events:none;
            }
          }
        }
        .DayPicker-Body {
          .DayPicker-Day {
            border-radius: ${toRem(8)} !important;
            &:after {
              display: none !important;
            }
            &.DayPicker-Day--outside {
              cursor: pointer !important;
              pointer-events: auto !important;
              color:inherit;
            }
            &.DayPicker-Day--outside.DayPicker-Day--disabled{
              pointer-events:none !important;
            }
            &:not(.DayPicker-Day--disabled, .DayPicker-Day--selected):hover {
              background-color: ${baseVariables.color.neutral10} !important;
            }
            &.DayPicker-Day--selected {
              background-color: rgb(112 112 112 / 8%) !important;
              &:before {
                background-color: transparent !important;
              }
              .price-section {
                visibility: hidden;
              }
              &.DayPicker-Day--end {
                .price-section {
                  visibility: hidden !important;
                }
              }
              &.DayPicker-Day--start,
              &.DayPicker-Day--select,
              &.DayPicker-Day--end {
                background-color: ${baseVariables.color.base10} !important;
                .price-section {
                  visibility: visible;
                }
                .DayPicker-Day-Cell-Wrapper {
                  .DayPicker-Day-value,
                  div {
                    color: ${baseVariables.color.alert10} !important;
                  }
                }
              }
              .DayPicker-Day-value {
                background-color: transparent !important;
              }
            }
            .DayPicker-Day-value {
              font-weight: 500;
              font-size: ${toRem(14)};
              @media ${baseVariables.mediaQuery.md} {
                font-size: ${toRem(16)};
              }
              @media ${baseVariables.mediaQuery.lg} {
                font-size: ${toRem(18)};
                font-weight: 700;
              }
              background-color: none !important;
            }
            &.DayPicker-Day--disabled {
              color: ${baseVariables.color['neutral30']};
              cursor:default !important;
              pointer-events:none;
              @media ${baseVariables.mediaQuery.sm} {
                .DayPicker-Day-value {
                  color: ${baseVariables.color.alert20};
                }
              }
            }
            width: ${toRem(44)} !important;
            height: ${toRem(70)} !important;
            border-top: ${toRem(1)} solid ${baseVariables.color.neutral20};
            @media ${baseVariables.mediaQuery.sm} {
              width: ${toRem(161)} !important;
              height: ${toRem(102)} !important;
              border: ${toRem(1)} solid ${baseVariables.color.neutral30};
            }
            @media ${baseVariables.mediaQuery.md} {
              width: ${toRem(101)} !important;
              height: ${toRem(77)} !important;
              border: ${toRem(1)} solid ${baseVariables.color.neutral20};
            }
            @media ${baseVariables.mediaQuery.lg} {
              width: ${toRem(161)} !important;
              height: ${toRem(102)} !important;
              border: ${toRem(1)} solid ${baseVariables.color.neutral20};
            }
            &:nth-child(7) {
              border-right: 0;
            }
            &:nth-child(1) {
              border-left: 0;
            }
            .DayPicker-Day-value {
              height: auto;
            }
            .DayPicker-Day-Cell-Wrapper {
              &.rate-unavailable{
                padding-bottom:${toRem(7)}
              }
                .DayPicker-Day-custom-data {
                @media ${baseVariables.mediaQuery.md} {
                  max-width: ${toRem(86)};
                }
                .price-section {
                  font-weight: 500 !important;
                }
                .de-related-font {
                  font-size: ${toRem(8)};
                  @media only ${baseVariables.mediaQuery.md} {
                    font-size: ${toRem(12)};
                  }
                }
                .low-label-adf {
                  position: absolute;
                  color: ${baseVariables.color.merch20};
                  background:${baseVariables.color.merch70};
                  left: 0;
                  right: 0;
                  bottom: 0;
                  padding-top: ${toRem(4)};
                  padding-bottom: ${toRem(4)};
                  text-transform: uppercase;
                  font-weight: 700 !important;
                  letter-spacing: ${toRem(1.3)};
                }
          }
        }
      }
    }
  }
  .DayPicker-Day--selected: not(.DayPicker-Day--start) 
    {
      .low-label-adf{
      display:none;
    }
  }  
`;
