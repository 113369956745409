/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Config,
  EditableComponent,
  MappedComponentProperties,
  ResponsiveGrid,
} from '@adobe/aem-react-editable-components';
import { HeroBannerProps, RenditionsProps } from './HeroBanner.types';
import { StyledHeroBanner } from './HeroBanner.styles';
import { Image } from '@marriott/mi-ui-library';
import { constants, internetBarHeight } from '../../modules/utils/constants/constants';
import { PageParamsContext } from '../../modules/context/PageContext';
import { InternetBar } from '../InternetBar';
import { imageQueryParam } from '../LocalServices/LocalServices.types';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const HeroBanner: FC<HeroBannerProps> = pageProps => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const { disableImageModifier = false } = preProcessorResponse || {};
  const [updatedRenditions, setUpdatedRenditions] = useState<Array<RenditionsProps> | undefined>([]);
  const internetBarContainerRef = useRef(null);
  const heroBannerContainerRef = useRef(null);
  //Method for returning Responsive Grid for showing parsys in Author mode
  const InternetBarContainer = () => {
    return (
      <div data-testid="brand-container-component">
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pageProps?.pagePath}
          itemPath={`${pageProps?.itemPath}/container-0`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const InternetBarContainerWrapper = (cardName: string, jsonData: any) => {
    const returnable = [];
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          returnable.push(<InternetBar {...item} key={itemKey} />);
        }
      }
      return <div data-testid="brand-container-component-wrapper">{returnable}</div>;
    }
    return null;
  };

  const showInternetBarContainer = pageProps.isAuthorMode ? (
    <div>{Array.from({ length: 1 }, _ => InternetBarContainer())}</div>
  ) : (
    <div>
      {pageProps?.cqItemsOrder && InternetBarContainerWrapper(pageProps?.cqItemsOrder?.[0], pageProps?.cqItems)}
    </div>
  );

  useLayoutEffect(() => {
    const internetBarContainerHeight =
      (internetBarContainerRef?.current as unknown as HTMLElement)?.querySelector('.internet-bar-container')
        ?.clientHeight ?? 0;
    const heroBannerContainerHeight = (heroBannerContainerRef?.current as unknown as HTMLElement)?.clientHeight ?? 0;
    const heroBannerImage = (heroBannerContainerRef?.current as unknown as HTMLElement)?.querySelector('picture img');
    if (heroBannerContainerHeight < internetBarContainerHeight + internetBarHeight / 2) {
      (heroBannerContainerRef?.current as unknown as HTMLElement).style.height = `${
        internetBarContainerHeight + internetBarHeight
      }px`;

      if (heroBannerImage) {
        (heroBannerImage as HTMLElement).style.height = `${internetBarContainerHeight + internetBarHeight}px`;
      }
    }
  }, []);

  useEffect(() => {
    if (!disableImageModifier && !pageProps?.dynamicImageFileReference?.renditions?.length) {
      const imageQueryParamsHeroBanner: Array<imageQueryParam> = pageProps?.imageQueryParamsHeroBanner ?? [];
      const imagePath = pageProps?.dynamicImageFileReference?.assetPath ?? constants.BASE_IMG_URL ?? '';
      const renditions: Array<RenditionsProps> = [];
      imageQueryParamsHeroBanner.forEach(imageQueryParamHeroBanner => {
        renditions.push({
          mediaQuery: imageQueryParamHeroBanner?.viewPortName?.split(':')[0],
          mediaValue: imageQueryParamHeroBanner?.viewPortName?.split(':')[1],
          dynamic: true,
          renditionPath: `${imagePath}?${imageQueryParamHeroBanner?.nonRetinaDisplayParams}, ${imagePath}?${imageQueryParamHeroBanner?.retinaDisplayParams}`,
        });
      });
      setUpdatedRenditions([...renditions]);
    }
  }, []);

  return (
    <StyledHeroBanner data-testid="hero-banner" data-component-name="o-ppv5-hero-banner">
      <div className="hero-component">
        <div className="hero-component-container" ref={heroBannerContainerRef}>
          <Image
            defaultImageURL={constants.BASE_IMG_URL}
            altText={pageProps?.dynamicImageFileReference?.altText}
            dynamic={true}
            renditions={pageProps?.dynamicImageFileReference?.renditions ?? updatedRenditions ?? []}
            loading="eager"
            customClass="hero-component-img col-12 p-0 loaded"
          ></Image>
          <div className="hero-component__internetbar" ref={internetBarContainerRef}>
            {showInternetBarContainer}
          </div>
        </div>
      </div>
    </StyledHeroBanner>
  );
};

export const HeroBannerConfig = {
  emptyLabel: 'HeroBanner',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/hero-banner`,
} as Config<MappedComponentProperties>;

export const HeroBannerEditable = (props: HeroBannerProps) => (
  <EditableComponent config={HeroBannerConfig} {...props}>
    <HeroBanner {...props} />
  </EditableComponent>
);
