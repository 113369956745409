import { FC } from 'react';
import clsx from 'clsx';
import { ModalHeaderGenericProps } from './ModalHeaderGeneric.types';
import { StyledPopupHeaderDiv } from './ModalHeaderGeneric.styles';

// This modal is created to take children as input prop
export const ModalHeaderGeneric: FC<ModalHeaderGenericProps> = ({
  className,
  children,
  popupHeaderOnCLoseFunc,
  popupCloseClassName,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (popupHeaderOnCLoseFunc) {
        popupHeaderOnCLoseFunc(false);
      }
    }
  };
  const clickTrackingLoc = 'RnB Modal Header Generic';

  return (
    <StyledPopupHeaderDiv
      data-component-name="m-rnb-ModalHeaderGeneric"
      data-testid="rnb-ModalHeaderGeneric"
      className={clsx(className, 'd-flex align-items-start justify-content-between')}
    >
      <>
        {children}
        <div
          className={clsx(
            'popup-close d-flex align-items-center justify-content-center',
            'custom_click_track',
            popupCloseClassName
          )}
          tabIndex={0}
          role="button"
          onClick={(e): void => {
            handleClick(e);
          }}
          onKeyDown={(e): void => {
            handleClick(e);
          }}
          {...{ custom_click_track_value: `${clickTrackingLoc}| Close Button |internal` }}
          aria-label="Close pop up"
        >
          <div className="icon-clear"></div>
        </div>
      </>
    </StyledPopupHeaderDiv>
  );
};
