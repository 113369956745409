import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledEventSpaceConfigurationPicker = styled.div`
  overflow: hidden;
  width: 100%;
  .carousel__slide-focus-ring {
    outline: none;
  }
  label {
    width: ${toRem(94)};
    text-transform: capitalize;
    &:focus-visible {
      outline: none;
    }
    .option-container {
      border: ${toRem(1)} solid ${color.neutral30};
      border-radius: ${toRem(6)};
      background-color: ${color.alert10};
      width: ${toRem(80)};
      height: ${toRem(80)};
      padding: ${toRem(10)};
      cursor: pointer;
      span {
        color: ${color.base10};
        font-size: ${toRem(55)};
        line-height: ${toRem(60)};
      }
    }
    input[type='radio']:checked + .option-label {
      &:before {
        background-color: ${color.base10};
      }
      &:after {
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: ${color.alert10};
        width: ${toRem(4)};
        height: ${toRem(4)};
        position: absolute;
        top: ${toRem(5)};
        left: ${toRem(4)};
      }
    }
    .option-label {
      display: inline-flex;
      position: relative;
      color: ${color.base10};
      &:before {
        display: inline-block;
        content: '';
        margin-top: ${toRem(1)};
        margin-right: ${toRem(4)};
        background-color: ${color.alert10};
        border-radius: 50%;
        border: ${toRem(0.5)} solid ${color.base10};
        height: ${toRem(12)};
        width: ${toRem(12)};
        min-width: ${toRem(12)};
      }
    }
    &.is-disabled {
      opacity: 25%;
    }
    &:focus-within {
      .option-container {
        outline: -webkit-focus-ring-color auto ${toRem(1)};
      }
    }
  }
  .right-arrow,
  .left-arrow {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }
  .error-message {
    color: ${color.alert50};
  }
`;
