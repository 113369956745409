import { useEffect, useCallback } from 'react';

export const usePopState = (url: string) => {
  const handleBrowserBack = useCallback(
    (event: PopStateEvent) => {
      event.preventDefault();
      if (event.state) {
        window.location.href = url;
      }
    },
    [url]
  );

  useEffect(() => {
    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBrowserBack]);

  return handleBrowserBack;
};
