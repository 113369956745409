import { SignInModalLabels } from '../../molecules/SignInModal/SignInModal.types';
import { EventSummary } from './EventSummaryModal/EventSummaryModal.types';

export type RfpHeaderProps = {
  pageTitle: string;
  eventProfile: string;
  eventDetails: string;
  contactInformation: string;
  viewEventSummary: string;
  currentPageNo: string;
  currentStep: string;
  eventSummary: EventSummary;
  signInModal: SignInModalLabels;
  appliedCssClassNames: string;
};

export enum RfpPageIdentifier {
  EVENT_PROFILE = 'event-profile',
  EVENT_DETAILS = 'event-details',
  EVENT_CONTACT = 'event-contact',
}
