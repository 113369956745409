import { FC, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { EventCardProps } from './EventCard.types';
import { Icon } from '@marriott/mi-ui-library';
import { StyledEventCard } from './EventCard.styles';
import { UpdateEventStateModal } from '../UpdateEventStateModal';
import { UpdateEventStateType } from '../UpdateEventStateModal/UpdateEventStateModal.types';

export const EventCard: FC<EventCardProps> = ({
  eventCardLabels,
  eventCardData,
  enableEventModal,
  apiFailure,
  refetchEventList,
}) => {
  const {
    eventId,
    image,
    title,
    startDate,
    endDate,
    reslinkGroupType,
    propertyCount,
    isActive,
    previewUrl,
    modifyUrl,
  } = eventCardData;
  const { noImageText, group, corporate, property, properties, preview, previewAriaLabel, modify, enable } =
    eventCardLabels;

  const [showEnableEventModal, setShowEnableEventModal] = useState<boolean>(false);

  const enableEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isActive) {
      event.preventDefault();
      setShowEnableEventModal(true);
    }
  };

  return (
    <StyledEventCard>
      <div className={clsx('image-container', !isActive && 'disabled')}>
        {image?.link ? (
          <div className="event-image">
            <img src={image.link} alt={image.name} />
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center no-image">
            <Icon iconClass="icon-photo-gallery icon-alt" />
            <span className="t-font-alt-m">{noImageText}</span>
          </div>
        )}
      </div>
      <div className="p-3 d-flex flex-column justify-content-between event-details">
        <div className={clsx(!isActive && 'disabled')}>
          <div className="d-flex justify-content-between mb-1">
            <span className="t-font-xs">{reslinkGroupType ? group : corporate}</span>
            <span className="t-font-xs">
              {propertyCount} {propertyCount > 1 ? properties : property}
            </span>
          </div>
          <h2 className="t-subtitle-xl mb-1 event-title">{title || ' '}</h2>
          {reslinkGroupType && startDate && endDate && (
            <p className="event-date">
              <span className="t-font-s">{startDate} - </span>
              <span className="t-font-s">{endDate}</span>
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between event-ctas">
          <a
            href={previewUrl}
            aria-label={`${title} ${previewAriaLabel}`}
            className="d-flex align-items-center t-font-xs"
            target="_blank"
            rel="noreferrer"
          >
            {preview}
            <Icon iconClass="icon-external-arrow" />
          </a>
          <Link
            href={isActive ? modifyUrl : ''}
            aria-label={`${title} ${isActive ? modify : enable} `}
            className="t-font-xs"
            onClick={enableEvent}
          >
            {isActive ? modify : enable}
          </Link>
        </div>
      </div>
      {showEnableEventModal && (
        <UpdateEventStateModal
          labels={{ ...enableEventModal, apiFailure }}
          eventData={{ eventId: eventId, eventName: title }}
          updateEventStateType={UpdateEventStateType.ENABLE}
          showModal={showEnableEventModal}
          closeModal={() => setShowEnableEventModal(false)}
          refetchEventList={refetchEventList}
        />
      )}
    </StyledEventCard>
  );
};
