// Styles for PropertyPortalContainer go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';

export const StyledPropertyPortalContainer = styled.div`
  ${rtl`
  .carousal_header {
    h3 {
      margin-bottom: ${toRem(16)};
    }
  }
  .glide__track__pt3 {
    padding-top: 0;
  }
  `}
`;
