import { FC } from 'react';
import clsx from 'clsx';
import { Eyebrow, Text, Types } from '@marriott/mi-ui-library';
import { TextBlockProps } from './TextBlock.types';
import { StyledTextBlock } from './TextBlock.styles';

export const TextBlock: FC<TextBlockProps> = ({ label, value, customClass }) => {
  return (
    <StyledTextBlock
      data-component-name="m-groups-TextBlock"
      data-testid="groups-TextBlock"
      className={clsx('pt-4 pt-md-5', customClass)}
    >
      <Eyebrow text={label} />
      {Array.isArray(value) ? (
        value.map(item => (
          <Text element={Types.tags.div} copyText={item} fontSize={Types.size.medium} customClass="item-value" />
        ))
      ) : (
        <Text element={Types.tags.div} copyText={value} fontSize={Types.size.medium} customClass="item-value" />
      )}
    </StyledTextBlock>
  );
};
