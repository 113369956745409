import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, spacers } = baseVariables;

export const StyledEventSpaceSummary = styled.div`
  @media print {
    .event-time-heading {
      order: 1;
    }
    .attendees-heading {
      order: 2;
    }
    .event-time-value {
      order: 3;
    }
    .attendees-value {
      order: 4;
    }
    .space-setup-heading {
      order: 5;
      padding-top: ${spacers?.[spacers[3]]};
    }
    .total-heading {
      order: 6;
      padding-top: ${spacers?.[spacers[3]]};
    }
    .space-setup-value {
      order: 7;
    }
    .total-value {
      order: 8;
    }
  }
`;

export const StyledAccordionDivider = styled.div`
  border-top: ${toRem(1)} solid ${color.neutral40};
  margin: ${toRem(16)} 0;
`;
