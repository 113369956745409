import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { earnRedeemPageType } from '../../modules/utils/constants/constants';

export const StyledOverviewContainer = styled.div`
  background-color: ${baseVariables.color.memberSolidAlt};
  .text-center {
    color: ${baseVariables.color.base10};
  }
`;

export const StyledCampaignContainer = styled.div<{ pageType: string }>`
  background-color: ${props =>
    props?.pageType === earnRedeemPageType ? 'inherit' : `${baseVariables.color.base20} !important`};

  .campaign-overview-container {
    padding: ${toRem(24)} 0;
    @media ${baseVariables.mediaQuery.lg} {
      padding: ${toRem(48)} 0;
    }
  }

  .earn-redeem-description {
    @media ${baseVariables.mediaQuery.lg} {
      padding: 0 ${toRem(72)};
    }
    @media ${baseVariables.mediaQuery.xl} {
      padding: 0 ${toRem(145)};
    }
  }
`;
