export const mockInternetBar = {
  isAuthorMode: false,
  upgradeButtonLabel: 'Upgrade',
  welcomeTitle: 'Welcome',
  signInButtonLink: '/sign-in.mi?returnTo=_PPV5URL_',
  memberUpgradedDescription:
    'Welcome back! Please <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  wifiUpgradePmsFieldsBean: {
    errorMessagePms: "We can't connect you to the internet",
    descriptionPms: "Please try again. If you're still having trouble, contact the front desk",
    closingLabelPms: 'Close Dialog',
    headingtextPms: "We're Sorry",
  },
  wifiUpgradeSuccessNaFieldsBean: {
    descriptionSna: 'Please contact the front desk to see if an upgrade from your current plan is available.',
    upgradeMessageSna: 'Upgrade Not Available',
    closingLabelSna: 'Close Dialog',
    headingtextSna: "We're Sorry",
  },
  eliteMACDescription:
    "<p>As an elite Marriott Bonvoy™ member, you mean the world to us. You'll receive our fastest internet option at no charge.</p>\r\n",
  uknownUpgradedDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free!',
  accessCodeModel: {
    returnLabel: 'Return to Login',
    accessCodePlaceholder: null,
    requiredLabel: 'All fields are required',
    accessCodeLabel: 'ACCESS CODE',
    acErrorMessage: 'Invalid Access Code: Please Try Again',
    acTitle: 'Connect to the Internet',
    acClosingLabel: 'Close',
    acSeparatorLabel: 'OR',
    accessCodeTitle: 'Please Enter Your Access Code',
    continueButtonLabel: 'Continue',
  },
  ':type': 'mi-aem-ppv5-spa/components/content/internet-box',
  loginModel: {
    roomNumberPlaceHolder: 'Enter Room Number',
    requestAccessCodeLabel: 'Request Access Code via SMS',
    errorMessage: 'Room Number/Last Name Not Found:  Please Try Again',
    loginButtonLabel: 'Submit',
    title: 'Connect to the Internet',
    connectNowLabel: 'Connect for Free',
    lastNameLabel: 'LAST NAME',
    userAccessCodeLabel: 'Use an Access Code ',
    roomNumberLabel: 'ROOM NUMBER',
    closingLabel: 'Close Dialog',
    guestLoginLabel: 'Please Enter Your Information Below',
    requiredLabel: 'All fields are required',
    lastNamePlaceHolder: 'Enter Last Name',
    separatorLabel: 'OR',
  },
  upgradeButtonLinkInNewTab: '_self',
  signInButtonLabel: 'Sign In',
  memberNSDescription:
    'Welcome back! Please <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  memberInStayDescription:
    'Welcome back! Please <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  internetPricingModel: {
    connectNowLabel2: 'Connect Now',
    internetPricingClosingLabel: 'Close Dialog',
    choosedurationlabel: 'Choose Duration',
    pricelabel1: 'Price',
    resortDescription: 'Free for Marriott Bonvoy Members. Included with Resort Fee.',
    pricelabel2: 'Price',
    description2:
      'Free for Marriott Bonvoy Gold and Higher Status Members. Best for: Streaming, Video Conferencing. On-line video games',
    pricingInfo: {
      GuestRoom: {
        tierTwoFee: '19.95',
        tierOneFee: '16.95',
        tierOneName: 'Tier 1 (basic)',
        tierTwoName: 'Tier 2',
        tierTwoId: 5546937,
        tierOneId: 5439670,
        hasTierTwo: true,
        hasTierOne: true,
      },
      PublicArea: {
        tierTwoId: 0,
        tierOneId: 0,
        hasTierTwo: false,
        hasTierOne: true,
      },
    },
    intUpgResortDescription:
      'Added to your current connection plan.  Enhanced Internet is included with the Resort Fee and is always free for Marriott Bonvoy Gold and higher Elite Members.',
    durationlabel2: 'Choose Duration',
    destinationDescription2: 'Free for Marriott Bonvoy Gold and Higher Status Members. Included with Destination Fee.',
    intUpgConnectLabel: 'Upgrade your Connection',
    intUpgDestinationDescription:
      'Added to your current connection plan.  Enhanced Internet is included with the Destination Fee and is always free for Marriott Bonvoy Gold and higher Elite Members.',
    currency: 'USD',
    intUpgDurationLabel: 'CHOOSE DURATION',
    intUpgClosingLabel: 'Close',
    internetPricingConnectNowLabel: 'Connect Now',
    intUpgFreeLabel: 'Free',
    intUpgNoThanksLink: 'No thanks, Keep my current connection.',
    description2label: 'Description',
    intUpgTierTitle: 'Enhanced Internet',
    freeDescription: 'Connect to free internet',
    resortDescription2: 'Free for Marriott Bonvoy Gold and Higher Status Members. Included with Resort Fee.',
    intUpgDescription:
      'Added to your current connection plan.  Marriott Bonvoy Members receive free internet as a benefit. Gold and higher Elite Members receive free Enhanced Internet service as an Elite benefit.',
    title2: 'Enhanced Internet',
    dayLabel: '/day',
    intUpgConnectionLabel: 'Upgrade Connection',
    internetPricingDescription: 'Free for Marriott Bonvoy Members. Best for: Web browsing and Email',
    internetPricingTitle: 'Internet',
    destinationDescription: 'Free for Marriott Bonvoy Members. Included with Destination Fee.',
    description1label: 'Description',
    connectlabelTier1: 'Connect  to the Internet',
    freeLabel: 'Free',
  },
  wifiUpgradeLrFieldsBean: {
    headingtextLr: "We're Sorry",
    errorMessageLr: "We can't connect you to the internet",
    closingLabelLr: 'Close Dialog',
    descriptionLr: "Please try again. If you're still having trouble, contact the front desk",
  },
  basicInStayMACDescription: 'Welcome back! And thank you for being a Marriott Bonvoy™ member.',
  joinButtonLinkInNewTab: '_blank',
  upgradeTitle: 'Upgrade Your Connection',
  eliteInStayMACDescription: 'Welcome back! And thank you for being an elite Marriott Bonvoy™ member.',
  tier1Title: 'Connected to the Internet',
  unknownConnectedDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free!',
  connectButtonLabel: 'Connect',
  eliteMACUgradedDescription: '<p>Welcome back! And thank you for being an elite Marriott Bonvoy™ member.</p>\r\n',
  memberTierUnknownDescription: '<p>Thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  basicUpgradeDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  eliteUpgradedDescription: '<p>Welcome back! And thank you for being an elite Marriott Bonvoy™ member.</p>\r\n',
  unknownDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">Join now</a> - it\'s free!',
  eliteDescription:
    "<p>As an elite Marriott Bonvoy™ member, you mean the world to us. You'll receive our fastest internet option at no charge.</p>\r\n",
  eliteInStayDescription: 'Welcome back! And thank you for being an elite Marriott Bonvoy™ member.',
  basicDescription:
    "<p>Welcome back! As a valued Marriott Bonvoy™ member, you'll receive a complimentary internet connection.</p>\r\n",
  signInButtonLinkInNewTab: '_blank',
  deviceTitle: 'Device Added to Your Internet Plan',
  title: 'Connect to the Internet',
  requestAccessCodeModel: {
    frequentCountry: [],
    racSeparatorLabel: 'OR',
    searchCountryRegionLabel: 'Search Country/Region',
    racTitle: 'Connect to the Internet',
    racContinueButtonLabel: 'Continue',
    phoneNumberPlaceholder: null,
    defaultOption: 'Select Country/Region Code',
    racReturnLabel: 'Return to Login',
    enterYourPhoneNumber: 'ENTER YOUR PHONE NUMBER',
    racClosingLabel: 'Close',
    guidLinks: [
      {
        guidAltDecode: 'AF',
        guidDecode: 'Afghanistan',
        guidCode: '93',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AX',
        guidDecode: 'Aland Islands',
        guidCode: '358',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AL',
        guidDecode: 'Albania',
        guidCode: '355',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'DZ',
        guidDecode: 'Algeria',
        guidCode: '213',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AD',
        guidDecode: 'Andorra',
        guidCode: '376',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AO',
        guidDecode: 'Angola',
        guidCode: '244',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AI',
        guidDecode: 'Anguilla',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AQ',
        guidDecode: 'Antarctica',
        guidCode: '672',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AG',
        guidDecode: 'Antigua',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AR',
        guidDecode: 'Argentina',
        guidCode: '54',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AM',
        guidDecode: 'Armenia',
        guidCode: '374',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AW',
        guidDecode: 'Aruba',
        guidCode: '297',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AU',
        guidDecode: 'Australia',
        guidCode: '61',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AT',
        guidDecode: 'Austria',
        guidCode: '43',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AZ',
        guidDecode: 'Azerbaijan',
        guidCode: '994',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BS',
        guidDecode: 'Bahamas',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BH',
        guidDecode: 'Bahrain',
        guidCode: '973',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BD',
        guidDecode: 'Bangladesh',
        guidCode: '880',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BB',
        guidDecode: 'Barbados',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BY',
        guidDecode: 'Belarus',
        guidCode: '375',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BE',
        guidDecode: 'Belgium',
        guidCode: '32',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BZ',
        guidDecode: 'Belize',
        guidCode: '501',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BJ',
        guidDecode: 'Benin',
        guidCode: '229',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BM',
        guidDecode: 'Bermuda',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BT',
        guidDecode: 'Bhutan',
        guidCode: '975',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BO',
        guidDecode: 'Bolivia',
        guidCode: '591',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BQ',
        guidDecode: 'Bonaire',
        guidCode: '599',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BA',
        guidDecode: 'Bosnia Hercegovina',
        guidCode: '387',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BW',
        guidDecode: 'Botswana',
        guidCode: '267',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BV',
        guidDecode: 'Bouvet Island',
        guidCode: '55',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IO',
        guidDecode: 'Br Indian Ocean Terr',
        guidCode: '246',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BR',
        guidDecode: 'Brazil',
        guidCode: '55',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'VG',
        guidDecode: 'British Virgin Islands',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BN',
        guidDecode: 'Brunei Darussalam',
        guidCode: '673',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BG',
        guidDecode: 'Bulgaria',
        guidCode: '359',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BF',
        guidDecode: 'Burkina Faso',
        guidCode: '226',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BI',
        guidDecode: 'Burundi',
        guidCode: '257',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CB',
        guidDecode: 'Cambodia',
        guidCode: '855',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CM',
        guidDecode: 'Cameroon',
        guidCode: '237',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CA',
        guidDecode: 'Canada',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CV',
        guidDecode: 'Cape Verde',
        guidCode: '238',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KY',
        guidDecode: 'Cayman Islands',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CF',
        guidDecode: 'Cent Africa Republic',
        guidCode: '236',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TD',
        guidDecode: 'Chad',
        guidCode: '235',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CL',
        guidDecode: 'Chile',
        guidCode: '56',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CN',
        guidDecode: 'China',
        guidCode: '86',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CX',
        guidDecode: 'Christmas Island',
        guidCode: '61',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CC',
        guidDecode: 'Cocos Islands',
        guidCode: '891',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CO',
        guidDecode: 'Colombia',
        guidCode: '57',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KM',
        guidDecode: 'Comoro Islands',
        guidCode: '269',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CG',
        guidDecode: 'Congo',
        guidCode: '242',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CD',
        guidDecode: 'Congo, Dem. Republic',
        guidCode: '243',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CK',
        guidDecode: 'Cook Islands',
        guidCode: '682',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CR',
        guidDecode: 'Costa Rica',
        guidCode: '506',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'HR',
        guidDecode: 'Croatia',
        guidCode: '385',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CU',
        guidDecode: 'Cuba',
        guidCode: '53',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CW',
        guidDecode: 'Curacao',
        guidCode: '599',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CY',
        guidDecode: 'Cyprus',
        guidCode: '357',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CZ',
        guidDecode: 'Czech Republic',
        guidCode: '420',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'DK',
        guidDecode: 'Denmark',
        guidCode: '45',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'DJ',
        guidDecode: 'Djibouti',
        guidCode: '253',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'DM',
        guidDecode: 'Dominica',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'DO',
        guidDecode: 'Dominican Republic',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'EC',
        guidDecode: 'Ecuador',
        guidCode: '593',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'EG',
        guidDecode: 'Egypt',
        guidCode: '20',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SV',
        guidDecode: 'El Salvador',
        guidCode: '503',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GQ',
        guidDecode: 'Equatorial Guinea',
        guidCode: '240',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ER',
        guidDecode: 'Eritrea',
        guidCode: '291',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'EE',
        guidDecode: 'Estonia',
        guidCode: '372',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ET',
        guidDecode: 'Ethiopia',
        guidCode: '251',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'FO',
        guidDecode: 'Faeroe Islands',
        guidCode: '298',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'FK',
        guidDecode: 'Falkland Islands',
        guidCode: '500',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'FJ',
        guidDecode: 'Fiji',
        guidCode: '679',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'FI',
        guidDecode: 'Finland',
        guidCode: '358',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'FR',
        guidDecode: 'France',
        guidCode: '33',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GF',
        guidDecode: 'French Guiana',
        guidCode: '594',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PF',
        guidDecode: 'French Polynesia',
        guidCode: '689',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TF',
        guidDecode: 'French Southern Terr',
        guidCode: '262',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GA',
        guidDecode: 'Gabon',
        guidCode: '241',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GM',
        guidDecode: 'Gambia',
        guidCode: '220',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'XA',
        guidDecode: 'Gaza',
        guidCode: '970',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GE',
        guidDecode: 'Georgia',
        guidCode: '995',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'DE',
        guidDecode: 'Germany',
        guidCode: '49',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GH',
        guidDecode: 'Ghana',
        guidCode: '233',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GI',
        guidDecode: 'Gibraltar',
        guidCode: '350',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GR',
        guidDecode: 'Greece',
        guidCode: '30',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GL',
        guidDecode: 'Greenland',
        guidCode: '299',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GD',
        guidDecode: 'Grenada',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GP',
        guidDecode: 'Guadeloupe',
        guidCode: '33',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GT',
        guidDecode: 'Guatemala',
        guidCode: '502',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GG',
        guidDecode: 'Guernsey',
        guidCode: '44',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GN',
        guidDecode: 'Guinea',
        guidCode: '224',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GW',
        guidDecode: 'Guinea-Bissau',
        guidCode: '245',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GY',
        guidDecode: 'Guyana',
        guidCode: '592',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'HT',
        guidDecode: 'Haiti',
        guidCode: '509',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'HM',
        guidDecode: 'Heard & McDonald Island',
        guidCode: '+0',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'HN',
        guidDecode: 'Honduras',
        guidCode: '504',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'HK',
        guidDecode: 'Hong Kong',
        guidCode: '852',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'HU',
        guidDecode: 'Hungary',
        guidCode: '36',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IS',
        guidDecode: 'Iceland',
        guidCode: '354',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IN',
        guidDecode: 'India',
        guidCode: '91',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'XI',
        guidDecode: 'Indian Ocean Islands',
        guidCode: '61',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ID',
        guidDecode: 'Indonesia',
        guidCode: '62',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IR',
        guidDecode: 'Iran',
        guidCode: '98',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IQ',
        guidDecode: 'Iraq',
        guidCode: '964',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IE',
        guidDecode: 'Ireland',
        guidCode: '353',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IM',
        guidDecode: 'Isle of Man',
        guidCode: '44',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IL',
        guidDecode: 'Israel',
        guidCode: '972',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'IT',
        guidDecode: 'Italy',
        guidCode: '39',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CI',
        guidDecode: 'Ivory Coast',
        guidCode: '225',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'JM',
        guidDecode: 'Jamaica',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'JP',
        guidDecode: 'Japan',
        guidCode: '81',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'JE',
        guidDecode: 'Jersey',
        guidCode: '44',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'JO',
        guidDecode: 'Jordan',
        guidCode: '962',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KZ',
        guidDecode: 'Kazakhstan',
        guidCode: '7',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KE',
        guidDecode: 'Kenya',
        guidCode: '254',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KI',
        guidDecode: 'Kiribati',
        guidCode: '686',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KP',
        guidDecode: 'Korea, Dem. Peoples',
        guidCode: '850',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KR',
        guidDecode: 'Korea, Republic of',
        guidCode: '82',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CS',
        guidDecode: 'Kosovo',
        guidCode: '383',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KW',
        guidDecode: 'Kuwait',
        guidCode: '965',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KG',
        guidDecode: 'Kyrgyzstan',
        guidCode: '996',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LP',
        guidDecode: 'Lao P D R',
        guidCode: '856',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LA',
        guidDecode: 'Laos',
        guidCode: '856',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LV',
        guidDecode: 'Latvia',
        guidCode: '371',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LB',
        guidDecode: 'Lebanon',
        guidCode: '961',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LW',
        guidDecode: 'Leeward Islands',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LS',
        guidDecode: 'Lesotho',
        guidCode: '266',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LR',
        guidDecode: 'Liberia',
        guidCode: '231',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LY',
        guidDecode: 'Libya',
        guidCode: '218',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LI',
        guidDecode: 'Liechtenstein',
        guidCode: '423',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LT',
        guidDecode: 'Lithuania',
        guidCode: '370',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LU',
        guidDecode: 'Luxembourg',
        guidCode: '352',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MO',
        guidDecode: 'Macau',
        guidCode: '853',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MK',
        guidDecode: 'Macedonia',
        guidCode: '389',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MG',
        guidDecode: 'Madagascar',
        guidCode: '261',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MW',
        guidDecode: 'Malawi',
        guidCode: '265',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MY',
        guidDecode: 'Malaysia',
        guidCode: '60',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MV',
        guidDecode: 'Maldives',
        guidCode: '960',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ML',
        guidDecode: 'Mali',
        guidCode: '223',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MT',
        guidDecode: 'Malta',
        guidCode: '356',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MQ',
        guidDecode: 'Martinique',
        guidCode: '596',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MR',
        guidDecode: 'Mauritania',
        guidCode: '222',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MU',
        guidDecode: 'Mauritius',
        guidCode: '230',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'YT',
        guidDecode: 'Mayotte',
        guidCode: '33',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MX',
        guidDecode: 'Mexico',
        guidCode: '52',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MD',
        guidDecode: 'Moldova, Republic of',
        guidCode: '373',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MC',
        guidDecode: 'Monaco',
        guidCode: '377',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MN',
        guidDecode: 'Mongolia',
        guidCode: '976',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ME',
        guidDecode: 'Montenegro',
        guidCode: '382',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MS',
        guidDecode: 'Montserrat',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MA',
        guidDecode: 'Morocco',
        guidCode: '212',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MZ',
        guidDecode: 'Mozambique',
        guidCode: '258',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MM',
        guidDecode: 'Myanmar',
        guidCode: '95',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NA',
        guidDecode: 'Namibia',
        guidCode: '264',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NR',
        guidDecode: 'Nauru',
        guidCode: '674',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NP',
        guidDecode: 'Nepal',
        guidCode: '977',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AN',
        guidDecode: 'Netherland Antilles',
        guidCode: '599',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NL',
        guidDecode: 'Netherlands',
        guidCode: '31',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NC',
        guidDecode: 'New Caledonia',
        guidCode: '687',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NZ',
        guidDecode: 'New Zealand',
        guidCode: '64',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NI',
        guidDecode: 'Nicaragua',
        guidCode: '505',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NE',
        guidDecode: 'Niger',
        guidCode: '227',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NG',
        guidDecode: 'Nigeria',
        guidCode: '234',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NU',
        guidDecode: 'Niue',
        guidCode: '683',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NF',
        guidDecode: 'Norfolk Island',
        guidCode: '672',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'NO',
        guidDecode: 'Norway',
        guidCode: '47',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'OM',
        guidDecode: 'Oman',
        guidCode: '968',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PK',
        guidDecode: 'Pakistan',
        guidCode: '92',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PA',
        guidDecode: 'Panama',
        guidCode: '507',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PG',
        guidDecode: 'Papua New Guinea',
        guidCode: '675',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PY',
        guidDecode: 'Paraguay',
        guidCode: '595',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PE',
        guidDecode: 'Peru',
        guidCode: '51',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PH',
        guidDecode: 'Philippines',
        guidCode: '63',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PN',
        guidDecode: 'Pitcairn Island',
        guidCode: '64',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PL',
        guidDecode: 'Poland',
        guidCode: '48',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PT',
        guidDecode: 'Portugal',
        guidCode: '351',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'QA',
        guidDecode: 'Qatar',
        guidCode: '974',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'RE',
        guidDecode: 'Reunion',
        guidCode: '262',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'RO',
        guidDecode: 'Romania',
        guidCode: '40',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'RU',
        guidDecode: 'Russia',
        guidCode: '7',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'RW',
        guidDecode: 'Rwanda',
        guidCode: '250',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GS',
        guidDecode: 'S Georgia-S Sandwich',
        guidCode: '500',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'BL',
        guidDecode: 'Saint Barthelemy',
        guidCode: '590',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LC',
        guidDecode: 'Saint Lucia',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'MF',
        guidDecode: 'Saint Martin',
        guidCode: '590',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'WS',
        guidDecode: 'Samoa, Ind. State of',
        guidCode: '685',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SM',
        guidDecode: 'San Marino',
        guidCode: '378',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ST',
        guidDecode: 'Sao Tome-Principe',
        guidCode: '239',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SA',
        guidDecode: 'Saudi Arabia',
        guidCode: '966',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SN',
        guidDecode: 'Senegal',
        guidCode: '221',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'RS',
        guidDecode: 'Serbia',
        guidCode: '381',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SC',
        guidDecode: 'Seychelles',
        guidCode: '248',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SL',
        guidDecode: 'Sierra Leone',
        guidCode: '232',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SG',
        guidDecode: 'Singapore',
        guidCode: '65',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SK',
        guidDecode: 'Slovakia',
        guidCode: '421',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SI',
        guidDecode: 'Slovenia',
        guidCode: '386',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SB',
        guidDecode: 'Solomon Islands',
        guidCode: '677',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SO',
        guidDecode: 'Somalia',
        guidCode: '252',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ZA',
        guidDecode: 'South Africa',
        guidCode: '27',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SS',
        guidDecode: 'South Sudan',
        guidCode: '211',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ES',
        guidDecode: 'Spain',
        guidCode: '34',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'LK',
        guidDecode: 'Sri Lanka',
        guidCode: '94',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SH',
        guidDecode: 'St Helena',
        guidCode: '247',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'KN',
        guidDecode: 'St Kitts-Nevis',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SX',
        guidDecode: 'St Maarten',
        guidCode: '599',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PM',
        guidDecode: 'St Pierre & Miquelon',
        guidCode: '508',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'VC',
        guidDecode: 'St Vincent & The Gre',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SD',
        guidDecode: 'Sudan',
        guidCode: '249',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SR',
        guidDecode: 'Suriname',
        guidCode: '597',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SJ',
        guidDecode: 'Svalbard & Jan Mayen',
        guidCode: '47',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SZ',
        guidDecode: 'Swaziland',
        guidCode: '268',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SE',
        guidDecode: 'Sweden',
        guidCode: '46',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'CH',
        guidDecode: 'Switzerland ',
        guidCode: '41',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'SY',
        guidDecode: 'Syrian Arab Republic',
        guidCode: '963',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TW',
        guidDecode: 'Taiwan',
        guidCode: '886',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TJ',
        guidDecode: 'Tajikstan',
        guidCode: '992',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TZ',
        guidDecode: 'Tanzania',
        guidCode: '255',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TH',
        guidDecode: 'Thailand',
        guidCode: '66',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TL',
        guidDecode: 'Timor-Leste',
        guidCode: '670',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TG',
        guidDecode: 'Togo',
        guidCode: '228',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TK',
        guidDecode: 'Tokelau',
        guidCode: '690',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TO',
        guidDecode: 'Tonga',
        guidCode: '676',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TT',
        guidDecode: 'Trinidad & Tobago',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TN',
        guidDecode: 'Tunisia',
        guidCode: '216',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TR',
        guidDecode: 'Turkey',
        guidCode: '90',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TM',
        guidDecode: 'Turkmenistan',
        guidCode: '993',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TC',
        guidDecode: 'Turks & Caicos',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'TV',
        guidDecode: 'Tuvalu',
        guidCode: '688',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'US',
        guidDecode: 'USA',
        guidCode: '1',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'UG',
        guidDecode: 'Uganda',
        guidCode: '256',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'UA',
        guidDecode: 'Ukraine',
        guidCode: '380',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'AE',
        guidDecode: 'United Arab Emirates',
        guidCode: '971',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'GB',
        guidDecode: 'United Kingdom',
        guidCode: '44',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'UV',
        guidDecode: 'Upper Volta',
        guidCode: '226',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'UY',
        guidDecode: 'Uruguay',
        guidCode: '598',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'UZ',
        guidDecode: 'Uzbekistan',
        guidCode: '998',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'VU',
        guidDecode: 'Vanuatu',
        guidCode: '678',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'VA',
        guidDecode: 'Vatican City',
        guidCode: '379',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'VE',
        guidDecode: 'Venezuela',
        guidCode: '58',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'VN',
        guidDecode: 'Vietnam',
        guidCode: '84',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'WF',
        guidDecode: 'Wallis & Futuna',
        guidCode: '681',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'PS',
        guidDecode: 'West Bank',
        guidCode: '970',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'EH',
        guidDecode: 'Western Sahara',
        guidCode: '212',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'YE',
        guidDecode: 'Yemen, Republic of',
        guidCode: '967',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ZM',
        guidDecode: 'Zambia',
        guidCode: '260',
        'jcr:primaryType': 'nt:unstructured',
      },
      {
        guidAltDecode: 'ZW',
        guidDecode: 'Zimbabwe',
        guidCode: '263',
        'jcr:primaryType': 'nt:unstructured',
      },
    ],
    requiredLabel: 'All fields are required',
    countryCode: 'SELECT YOUR COUNTRY/REGION CODE',
    priorityCountries: [],
    requestAccessCodeTitle: null,
    racErrorMessage: 'Invalid Phone Number: Please Try Again',
  },
  dataLists: {
    lrParamList: 'AF_AC,AF_MO,STRICT_AS,STRICT_A,STRICT_N,TCTC',
    mSParamList: 'E,N',
    debounce: '5000',
    mVParamList: 'LOGIN,LOGERR,C_PRICE,REQSMS,REQSMSERR,ACCESS,ACCESSERR,U_PRICE,U_NA,U_MAX,U_CONF,INT_ERR',
    zoneValues: {
      CONFERENCE: 'SF',
      GUEST: 'PP',
      PUBLIC: 'SF',
    },
    zParamList: 'PUBLIC,GUEST,MEETING,LOBBY,CONFERENCE',
    enrollmentSourceCode: 'MRPI',
    segmentId: 'elite.nonrewards',
  },
  termsAndConditions:
    'By connecting, you accept our <a href="/marriott/internet-access/termsofuse.mi" target="_blank">Terms of Use</a>',
  basicInStayDescription: 'Welcome back! And thank you for being a Marriott Bonvoy™ member.',
  signInText: 'Already a member? <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">Sign in</a>',
  wifiUpgradeMaxFieldsBean: {
    errorMessageMax: "We can't connect you to the internet",
    descriptionMax: "Please try again. If you're still having trouble, contact the front desk",
    closingLabelMax: 'Close Dialog',
    headingtextMax: "We're Sorry",
  },
  joinButtonLabel: 'Join Now',
  eliteTier1Description:
    '<p>Your internet connection is complimentary, in recognition of your Marriott Bonvoy™ membership.</p>\r\n',
  eliteTier2Description:
    '<p>Your Enhanced Internet connection is complimentary, in recognition of your Elite Marriott Bonvoy™ membership.</p>\r\n',
  wifiUpgradeSuccessMaxFieldsBean: {
    upgradeMessageSmax: "You're Already Connected at our Highest Speed",
    closingLabelSmax: 'Close Dialog',
    headingtextSmax: "We're Sorry",
    descriptionSmax: 'Your device is already connected at our highest-level internet option.',
  },
  joinButtonLink: '/loyalty/createAccount/createAccountPage1.mi',
  uknownInStayDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free! ',
  eliteMACUgradeDescription: '<p>Welcome back! And thank you for being an elite Marriott Bonvoy™ member.</p>\r\n',
  basicMACUpgradeDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  eliteUpgradeDescription: '<p>Welcome back! And thank you for being an elite Marriott Bonvoy™ member.</p>\r\n',
  welcomeText:
    'Your stay starts here. Browse our services below and explore all you can enjoy during your time with us.',
  wifiUpgradeCreditFieldsBean: {
    headingtext1: "We're Sorry",
    upgradeMessage: "We can't complete your request",
    closingLabel1: 'Close Dialog',
    description1:
      'We do not have a valid credit card on file to enable your internet access. Please contact the front desk to make payment arrangements.',
  },
  basicMACUpgradedDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  basicMACDescription:
    "<p>Welcome back! As a valued Marriott Bonvoy™ member, you'll receive a complimentary internet connection.</p>\r\n",
  uknownUpgradeDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free!',
  upgradedTitle: 'Connection Upgraded',
  basicUpgradedDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  tier2Title: 'Connected to Enhanced Internet',
  wifiUpgradeErrorFieldsBean: {
    upgradeErrorMessage: "We can't connect you to the internet",
    headingtext: "We're Sorry",
    upgradeErrorClosingLabel: 'Close Dialog',
    upgradeErrorDescription: "Please try again. If you're still having trouble, contact the front desk",
  },
  upgradeButtonLink: 'https://internetupgrade.marriott.com/',
  memberMACDescription: '<p>Thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  memberUpgradeDescription:
    'Welcome back! Please <a href="/sign-in.mi?returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  basicTier1Description:
    '<p>Your internet connection is complimentary, in recognition of your Marriott Bonvoy™ membership.</p>\r\n',
};

export const mockPageParamContext = {
  preProcessorResponse: {
    userSegment: 'NOT Logged In',
    validMarsha: true,
    status: 'SUCCESS',
    message: '',
    statusCode: '200',
    brandCode: 'MC',
    experienceSegment: 'In_Stay-Unknown',
    locale: 'en-US',
    subDirectory: 'en-US',
    currentStateMapping: {
      title: 'welcomeTitle',
      welcomeText: 'welcomeText',
      description: 'uknownInStayDescription',
      isConnectState: false,
      connectUpgradeBtn: '',
      signInButton: {
        signInButtonLink: '',
        signInButtonLabel: '',
        signInButtonLinkInNewTab: '',
      },
      joinNowButton: {
        joinButtonLabel: 'joinButtonLabel',
        joinButtonLink: 'joinButtonLink',
        joinButtonLinkInNewTab: 'joinButtonLinkInNewTab',
      },
      termsAndConditions: '',
      signInText: '',
      noLoginInterfaceModal: true,
      showLoginModal: false,
      loginModal: {
        default: false,
        separatorLabel: false,
        userAccessCodeLabel: false,
        requestAccessCodeLabel: false,
        errorMessage: false,
        connectNowLabel: false,
      },
      showAccessModal: false,
      accessModal: {
        default: false,
        acErrorMessage: false,
      },
      showReqAccessModal: false,
      reqAccessModal: {
        default: false,
        racErrorMessage: false,
      },
      showUpgradeGenErr: false,
      showUpgradeLRErr: false,
      showUpgradeMaxErr: false,
      showUpgradeNoCCErr: false,
      showUpgradePMSErr: false,
      showUpgradeSuccessNAInfo: false,
      showUpgradeSuccessMAXInfo: false,
      showPricingModal: false,
      showUpgradeModal: false,
    },
    mac: false,
    cna: false,
    sameTab: false,
    marsha: 'nycmq',
    state: 'in_stay',
    userState: 'unknown',
    cookie: 'sessionID=5D1BCBAF-D0F1-57EA-9CB1-849694666C83',
  },
};

export const inStayMockResponse = {
  title: 'welcomeTitle',
  welcomeText: 'welcomeText',
  description: 'uknownInStayDescription',
  isConnectState: false,
  connectUpgradeBtn: '',
  signInButton: {
    signInButtonLink: '',
    signInButtonLabel: '',
    signInButtonLinkInNewTab: '',
  },
  joinNowButton: {
    joinButtonLabel: 'joinButtonLabel',
    joinButtonLink: 'joinButtonLink',
    joinButtonLinkInNewTab: 'joinButtonLinkInNewTab',
  },
  termsAndConditions: '',
  signInText: '',
  noLoginInterfaceModal: true,
  showLoginModal: false,
  loginModal: {
    default: false,
    separatorLabel: false,
    userAccessCodeLabel: false,
    requestAccessCodeLabel: false,
    errorMessage: false,
    connectNowLabel: false,
  },
  showAccessModal: false,
  accessModal: {
    default: false,
    acErrorMessage: false,
  },
  showReqAccessModal: false,
  reqAccessModal: {
    default: false,
    racErrorMessage: false,
  },
  showUpgradeGenErr: false,
  showUpgradeLRErr: false,
  showUpgradeMaxErr: false,
  showUpgradeNoCCErr: false,
  showUpgradePMSErr: false,
  showUpgradeSuccessNAInfo: false,
  showUpgradeSuccessMAXInfo: false,
  showPricingModal: false,
  showUpgradeModal: false,
};

export const mockPageParamConnectNoLogin = {
  preProcessorResponse: {
    userSegment: 'NOT Logged In',
    validMarsha: true,
    status: 'SUCCESS',
    message: '',
    statusCode: '200',
    brandCode: 'MC',
    experienceSegment: 'PreConnect-Basic-Login',
    locale: 'en-US',
    subDirectory: 'en-US',
    currentStateMapping: {
      title: 'title',
      welcomeText: '',
      description: 'unknownDescription',
      isConnectState: true,
      connectUpgradeBtn: 'connectButtonLabel',
      signInButton: {
        signInButtonLink: '',
        signInButtonLabel: '',
        signInButtonLinkInNewTab: '',
      },
      joinNowButton: {
        joinButtonLabel: '',
        joinButtonLink: '',
        joinButtonLinkInNewTab: '',
      },
      termsAndConditions: 'termsAndConditions',
      signInText: 'signInText',
      noLoginInterfaceModal: false,
      showLoginModal: true,
      loginModal: {
        default: true,
      },
      showAccessModal: false,
      accessModal: {
        default: false,
        acErrorMessage: false,
      },
      showReqAccessModal: false,
      reqAccessModal: {
        default: false,
        racErrorMessage: false,
      },
      showUpgradeGenErr: false,
      showUpgradeLRErr: false,
      showUpgradeMaxErr: false,
      showUpgradeNoCCErr: false,
      showUpgradePMSErr: false,
      showUpgradeSuccessNAInfo: false,
      showUpgradeSuccessMAXInfo: false,
      showPricingModal: false,
      showUpgradeModal: false,
    },
    mac: false,
    cna: false,
    sameTab: true,
    marsha: 'nycmq',
    state: 'preconnect',
    userState: 'unknown',
    cookie: 'sessionID=3005DD4B-E88F-55E9-8121-11000350065E',
  },
};

export const mockPageParamUpgrade = {
  preProcessorResponse: {
    userSegment: 'NOT Logged In',
    validMarsha: true,
    status: 'SUCCESS',
    message: '',
    statusCode: '200',
    brandCode: 'JW',
    experienceSegment: 'Upgrade-Elite-UPrice-Guest',
    locale: 'en-US',
    subDirectory: 'en-US',
    currentStateMapping: {
      title: 'upgradeTitle',
      welcomeText: 'welcomeText',
      description: 'uknownUpgradeDescription',
      isConnectState: false,
      connectUpgradeBtn: 'upgradeButtonLabel',
      signInButton: {
        signInButtonLink: '',
        signInButtonLabel: '',
        signInButtonLinkInNewTab: '',
      },
      joinNowButton: {
        joinButtonLabel: 'joinButtonLabel',
        joinButtonLink: 'joinButtonLink',
        joinButtonLinkInNewTab: 'joinButtonLinkInNewTab',
      },
      termsAndConditions: 'termsAndConditions',
      signInText: '',
      noLoginInterfaceModal: false,
      showLoginModal: false,
      loginModal: {
        default: false,
        separatorLabel: false,
        userAccessCodeLabel: false,
        requestAccessCodeLabel: false,
        errorMessage: false,
        connectNowLabel: false,
      },
      showAccessModal: false,
      accessModal: {
        default: false,
        acErrorMessage: false,
      },
      showReqAccessModal: false,
      reqAccessModal: {
        default: false,
        racErrorMessage: false,
      },
      showUpgradeGenErr: false,
      showUpgradeLRErr: false,
      showUpgradeMaxErr: false,
      showUpgradeNoCCErr: false,
      showUpgradePMSErr: false,
      showUpgradeSuccessNAInfo: false,
      showUpgradeSuccessMAXInfo: false,
      showPricingModal: false,
      showUpgradeModal: true,
    },
    mac: false,
    cna: false,
    sameTab: false,
    marsha: 'phxdr',
    state: 'upgrade',
    userState: 'unknown',
    cookie: 'sessionID=39C7006B-7E45-5826-A938-137927E49324',
  },
};

export const settlementCurrenyDetailsMock = {
  data: {
    property: {
      basicInformation: {
        globalDivision: 'Caribbean Latin America',
        currency: 'USD',
        region: 'US East',
      },
      contactInformation: {
        address: {
          country: {
            code: 'CB',
          },
          stateProvince: {
            code: 'NY',
            description: 'New York',
            enumCode: null,
            label: null,
          },
        },
      },
      internet: {
        connectionPricingDetails: [
          {
            externalRecordId: 5443201,
            hasOtherThanHotelQuotingCurrency: true,
            isCompForBonvoyElite: null,
            isCompForBonvoyMember: null,
            tierType: 'Tier1',
            type: 'public_area_room_conn_pricing',
            feeDetails: {
              fee: 27,
              feeInterval: 'PD',
              feeType: 'F',
              settlementFee: 232,
              settlementCurrency: {
                description: 'Bermudian Dollar',
                code: 'BMD',
              },
            },
          },
          {
            externalRecordId: 299285,
            hasOtherThanHotelQuotingCurrency: true,
            isCompForBonvoyElite: null,
            isCompForBonvoyMember: null,
            tierType: 'Tier2',
            type: 'public_area_room_conn_pricing',
            feeDetails: {
              fee: 51,
              feeInterval: 'PD',
              feeType: 'F',
              settlementFee: 5000,
              settlementCurrency: {
                description: 'Bahamian Dollar',
                code: 'BSD',
              },
            },
          },
          {
            externalRecordId: 5439670,
            hasOtherThanHotelQuotingCurrency: true,
            isCompForBonvoyElite: false,
            isCompForBonvoyMember: true,
            tierType: 'Tier1',
            type: 'guest_room_conn_pricing',
            feeDetails: {
              fee: 15.7,
              feeInterval: 'PH',
              feeType: 'F',
              settlementFee: 150,
              settlementCurrency: {
                description: 'UAE Dirham',
                code: 'AED',
              },
            },
          },
          {
            externalRecordId: 299286,
            hasOtherThanHotelQuotingCurrency: false,
            isCompForBonvoyElite: true,
            isCompForBonvoyMember: false,
            tierType: 'Tier2',
            type: 'guest_room_conn_pricing',
            feeDetails: {
              fee: 22,
              feeInterval: 'PH',
              feeType: 'F',
              settlementFee: null,
              settlementCurrency: null,
            },
          },
        ],
      },
    },
  },
};

export const mockInternetBarForRegion = {
  upgradeButtonLabel: 'Upgrade',
  welcomeTitle: 'Welcome',
  signInButtonLink: '/sign-in.mi?layeredEncoding=true&returnTo=_PPV5URL_',
  loyaltyConnectNowButton: 'Connect Now',
  loyaltyJoinnowScenarioText: 'Not a Marriott Bonvoy® Member?',
  memberUpgradedDescription:
    'Welcome back! Please <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  loyaltyConnectScenarioText: 'Already a Marriott Bonvoy® Member?',
  wifiUpgradePmsFieldsBean: {
    errorMessagePms: "We can't connect you to the internet",
    descriptionPms: "Please try again. If you're still having trouble, contact the front desk",
    closingLabelPms: 'Close Dialog',
    headingtextPms: "We're Sorry",
  },
  wifiUpgradeSuccessNaFieldsBean: {
    descriptionSna: 'Please contact the front desk to see if an upgrade from your current plan is available.',
    upgradeMessageSna: 'Upgrade Not Available',
    closingLabelSna: 'Close Dialog',
    headingtextSna: "We're Sorry",
  },
  eliteMACDescription:
    "As an Elite Marriott Bonvoy™ member, you mean the world to us. You'll receive our fastest internet option at no charge.",
  uknownUpgradedDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free!',
  accessCodeModel: {
    returnLabel: 'Return to Login',
    accessCodePlaceholder: null,
    requiredLabel: 'All fields are required',
    accessCodeLabel: 'ACCESS CODE',
    acErrorMessage: 'Invalid Access Code: Please Try Again',
    acTitle: 'Connect to the Internet',
    acClosingLabel: 'Close',
    acSeparatorLabel: 'OR',
    accessCodeTitle: 'Please Enter Your Access Code',
    continueButtonLabel: 'Continue',
  },
  ':type': 'mi-aem-ppv5-spa/components/content/internet-box',
  loginModel: {
    loyaltyContinueAsMemberCta: 'Continue as a Member',
    roomNumberPlaceHolder: 'Enter Room Number',
    requestAccessCodeLabel: 'Request Access Code via SMS',
    loyaltyLoginDescriptionPublicZone: 'Connect now to the internet.',
    loyaltyConnectAsMemberCta: 'Connect as Member',
    errorMessage: 'Room Number/Last Name Not Found:  Please Try Again',
    loyaltyLoginConnectNowButton: 'Connect Now',
    loyaltyConnectAsGuestCta: 'Connect as Guest',
    loginButtonLabel: 'Submit',
    title: 'Connect to the Internet',
    connectNowLabel: 'Connect for Free',
    lastNameLabel: 'LAST NAME',
    userAccessCodeLabel: 'Use an Access Code ',
    loyaltyLoginWelcomeText: 'Welcome to Marriott Bonvoy®',
    loyaltyLoginThankYouText: 'Thank you for being Marriott Bonvoy® Member.',
    loyaltyGuestAtHotelDescription: 'For guests at the hotel, please enter your information below.',
    roomNumberLabel: 'ROOM NUMBER',
    loyaltyLoginDescription: "We'll email your Marriott Bonvoy® membership details.",
    closingLabel: 'Close Dialog',
    guestLoginLabel: 'Please Enter Your Information Below',
    requiredLabel: 'All fields are required',
    loginContinueButtonLabel: 'Continue',
    lastNamePlaceHolder: 'Enter Last Name',
    separatorLabel: 'OR',
  },
  upgradeButtonLinkInNewTab: '_self',
  signInButtonLabel: 'Sign In',
  memberNSDescription:
    'Welcome back! Please <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  memberInStayDescription:
    'Welcome back! Please <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  internetPricingModel: {
    connectNowLabel2: 'Connect Now',
    internetPricingClosingLabel: 'Close Dialog',
    choosedurationlabel: 'Choose Duration',
    pricelabel1: 'Price',
    resortDescription: 'Free for Marriott Bonvoy Members. Included with Resort Fee.',
    pricelabel2: 'Price',
    description2:
      'Free for Marriott Bonvoy Gold and Higher Status Members. Best for: Streaming, Video Conferencing and On-line video games',
    pricingInfo: {
      GuestRoom: {
        tierTwoFee: '4.95',
        tierTwoName: 'Tier 2',
        tierTwoId: 6057621,
        tierOneId: 0,
        hasTierTwo: true,
        hasTierOne: true,
      },
      PublicArea: {
        tierTwoId: 0,
        tierOneId: 0,
        hasTierTwo: false,
        hasTierOne: true,
      },
    },
    intUpgResortDescription:
      'Added to your current connection plan.  Enhanced Internet is included with the Resort Fee and is always free for Marriott Bonvoy Gold and higher Elite Members.',
    durationlabel2: 'Choose Duration',
    destinationDescription2: 'Free for Marriott Bonvoy Gold and Higher Status Members. Included with Destination Fee.',
    intUpgConnectLabel: 'Upgrade your Connection',
    intUpgDestinationDescription:
      'Added to your current connection plan.  Enhanced Internet is included with the Destination Fee and is always free for Marriott Bonvoy Gold and higher Elite Members.',
    currency: 'USD',
    intUpgDurationLabel: 'CHOOSE DURATION',
    resortDescriptionPP: 'Free for Marriott Bonvoy Members. Best for: Web browsing and Email',
    intUpgClosingLabel: 'Close',
    internetPricingConnectNowLabel: 'Connect Now',
    destinationDescriptionPP: 'Free for Marriott Bonvoy Members. Best for: Web browsing and Email',
    intUpgFreeLabel: 'Free',
    intUpgNoThanksLink: 'No thanks, Keep my current connection.',
    description2label: 'Description',
    intUpgTierTitle: 'Enhanced Internet',
    freeDescription: 'Connect to free internet',
    resortDescription2: 'Free for Marriott Bonvoy Gold and Higher Status Members. Included with Resort Fee.',
    intUpgDescription:
      'Added to your current connection plan.  Marriott Bonvoy Members receive free internet as a benefit. Gold and higher Elite Members receive free Enhanced Internet service as an Elite benefit.',
    title2: 'Enhanced Internet',
    dayLabel: '/day',
    intUpgConnectionLabel: 'Upgrade Connection',
    justDaysLabel: 'Days',
    internetPricingDescription: 'Free for Marriott Bonvoy Members. Best for: Web browsing and Email',
    justDayLabel: 'Day',
    internetPricingTitle: 'Internet',
    destinationDescription: 'Free for Marriott Bonvoy Members. Included with Destination Fee.',
    description1label: 'Description',
    connectlabelTier1: 'Connect  to the Internet',
    freeLabel: 'Free',
  },
  calaProperty: false,
  wifiUpgradeLrFieldsBean: {
    headingtextLr: "We're Sorry",
    errorMessageLr: "We can't connect you to the internet",
    closingLabelLr: 'Close Dialog',
    descriptionLr: "Please try again. If you're still having trouble, contact the front desk",
  },
  basicInStayMACDescription: 'Welcome back! And thank you for being a Marriott Bonvoy™ member.',
  joinButtonLinkInNewTab: '_blank',
  upgradeTitle: 'Upgrade Your Connection',
  eliteInStayMACDescription: 'Welcome back! And thank you for being an Elite Marriott Bonvoy™ member.',
  tier1Title: 'Connected to the Internet',
  unknownConnectedDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free!',
  connectButtonLabel: 'Connect',
  eliteMACUgradedDescription: 'Welcome back! And thank you for being an Elite Marriott Bonvoy™ member.',
  memberTierUnknownDescription: '<p>Thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  loyaltyConnectScenarioDescription: 'Enjoy your complimentary Wi-Fi benefit, and thank you for your loyalty.',
  basicUpgradeDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  eliteUpgradedDescription: 'Welcome back! And thank you for being an Elite Marriott Bonvoy™ member.',
  unknownDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">Join now</a> - it\'s free!',
  eliteDescription:
    "As an Elite Marriott Bonvoy™ member, you mean the world to us. You'll receive our fastest internet option at no charge.",
  eliteInStayDescription: 'Welcome back! And thank you for being an Elite Marriott Bonvoy™ member.',
  basicDescription:
    "<p>Welcome back! As a valued Marriott Bonvoy™ member, you'll receive a complimentary internet connection.</p>\r\n",
  signInButtonLinkInNewTab: '_blank',
  deviceTitle: 'Device Added to Your Internet Plan',
  title: 'Connect to the Internet',
  dataLists: {
    lrParamList: 'AF_AC,AF_MO,STRICT_AS,STRICT_A,STRICT_N,TCTC,STRICT_S,AF_AO',
    mSParamList: 'E,N',
    debounce: '5000',
    mVParamList: 'LOGIN,LOGERR,C_PRICE,REQSMS,REQSMSERR,ACCESS,ACCESSERR,U_PRICE,U_NA,U_MAX,U_CONF,INT_ERR',
    zoneValues: {
      CONFERENCE: 'SF',
      GUEST: 'FP',
      PUBLIC: 'SF',
    },
    zParamList: 'PUBLIC,GUEST,MEETING,LOBBY,CONFERENCE',
    enrollmentSourceCode: 'MRPI',
    segmentId: 'elite.nonrewards',
    loyaltyEnrollmentSourceCode: 'WFS8',
    calaLoyaltyEnrollmentSourceCode: 'WFC5',
  },
  termsAndConditions:
    'By connecting, you accept our <a href="/marriott/internet-access/termsofuse.mi" target="_blank">Terms of Use</a>',
  basicInStayDescription: 'Welcome back! And thank you for being a Marriott Bonvoy™ member.',
  signInText:
    'Already a member? <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">Sign in</a>',
  wifiUpgradeMaxFieldsBean: {
    errorMessageMax: "We can't connect you to the internet",
    descriptionMax: "Please try again. If you're still having trouble, contact the front desk",
    closingLabelMax: 'Close Dialog',
    headingtextMax: "We're Sorry",
  },
  joinButtonLabel: 'Join Now',
  eliteTier1Description:
    '<p>Your internet connection is complimentary, in recognition of your Marriott Bonvoy™ membership.</p>\r\n',
  loyaltyJoinnowScenarioDescription: 'Join for free to access complimentary Wi-Fi.',
  eliteTier2Description:
    '<p>Your Enhanced Internet connection is complimentary, in recognition of your Elite Marriott Bonvoy™ membership.</p>\r\n',
  wifiUpgradeSuccessMaxFieldsBean: {
    upgradeMessageSmax: "You're Already Connected at our Highest Speed",
    closingLabelSmax: 'Close Dialog',
    headingtextSmax: "We're Sorry",
    descriptionSmax: 'Your device is already connected at our highest-level internet option.',
  },
  joinButtonLink: '/loyalty/createAccount/createAccountPage1.mi',
  uknownInStayDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free! ',
  eliteMACUgradeDescription: 'Welcome back! And thank you for being an Elite Marriott Bonvoy™ member.',
  basicMACUpgradeDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  eliteUpgradeDescription: 'Welcome back! And thank you for being an Elite Marriott Bonvoy™ member.',
  welcomeText:
    'Your stay starts here. Browse our services below and explore all you can enjoy during your time with us.',
  wifiUpgradeCreditFieldsBean: {
    headingtext1: "We're Sorry",
    upgradeMessage: "We can't complete your request",
    closingLabel1: 'Close Dialog',
    description1:
      'We do not have a valid credit card on file to enable your internet access. Please contact the front desk to make payment arrangements.',
  },
  basicMACUpgradedDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  basicMACDescription:
    "<p>Welcome back! As a valued Marriott Bonvoy™ member, you'll receive a complimentary internet connection.</p>\r\n",
  uknownUpgradeDescription:
    'Marriott Bonvoy members who book direct receive a complimentary internet connection. <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">Sign in</a> or <a href="/loyalty/createAccount/createAccountPage1.mi" target="_blank">join now</a> - it\'s free!',
  upgradedTitle: 'Connection Upgraded',
  basicUpgradedDescription: '<p>Welcome back! And thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  tier2Title: 'Connected to Enhanced Internet',
  wifiUpgradeErrorFieldsBean: {
    upgradeErrorMessage: "We can't connect you to the internet",
    headingtext: "We're Sorry",
    upgradeErrorClosingLabel: 'Close Dialog',
    upgradeErrorDescription: "Please try again. If you're still having trouble, contact the front desk",
  },
  upgradeButtonLink: 'https://internetupgrade.marriott.com/',
  memberMACDescription: '<p>Thank you for being a Marriott Bonvoy™ member.</p>\r\n',
  memberUpgradeDescription:
    'Welcome back! Please <a href="/sign-in.mi?layeredEncoding=true&amp;returnTo=_PPV5URL_" target="_blank">sign in</a> to your Marriott Bonvoy™ account so that we can apply your member benefits.',
  basicTier1Description:
    '<p>Your internet connection is complimentary, in recognition of your Marriott Bonvoy™ membership.</p>\r\n',
};

export const mockPageParam = {
  preProcessorResponse: {
    userSegment: 'NOT Logged In',
    validMarsha: true,
    status: 'SUCCESS',
    message: '',
    statusCode: '200',
    brandCode: 'RI',
    experienceSegment: 'PreConnect-Unknown-Internet-Plan-Public',
    locale: 'en-US',
    subDirectory: 'en',
    enrollmentSourceCodeMapping: `{"nonloyalty":[{"globalDivision":["US Canada"],"countryCode":["US"],"pricingTier":[{"value":"WFS8","key":"*"}]},{"globalDivision":["US Canada"],"countryCode":[],"pricingTier":[{"value":"WFS8","key":"*"}]},{"globalDivision":["Caribbean Latin America"],"countryCode":["CB"],"pricingTier":[{"value":"CALA_BS_CODE_FP","key":"FP"},{"value":"CALA_BS_CODE_SF","key":"SF"},{"value":"CALA_BS_CODE_SP","key":"SP"},{"value":"CALA-PP","key":"PP"}]},{"globalDivision":["Caribbean Latin America"],"countryCode":[],"pricingTier":[{"value":"WFC5","key":"*"}]},{"globalDivision":["Greater China"],"countryCode":["HK"],"pricingTier":[{"value":"WFS6","key":"*"}]}],"loyalty":[{"globalDivision":["US Canada"],"countryCode":["US"],"pricingTier":[{"value":"WFS8","key":"*"},{"value":"CALA_BS_CODE_FP","key":"FP"},{"value":"CALA_BS_CODE_SF","key":"SF"},{"value":"CALA_BS_CODE_SP","key":"SP"}]},{"globalDivision":["US Canada"],"countryCode":[],"pricingTier":[{"value":"WFS8","key":"*"}]},{"globalDivision":["Caribbean Latin America"],"countryCode":["CB"],"pricingTier":[{"value":"CALA_BS_CODE_FP","key":"FP"},{"value":"CALA_BS_CODE_SF","key":"SF"},{"value":"CALA_BS_CODE_SP","key":"SP"},{"value":"CALA-PP","key":"PP"}]},{"globalDivision":["Caribbean Latin America"],"countryCode":[],"pricingTier":[{"value":"WFC5","key":"*"}]},{"globalDivision":["Greater China"],"countryCode":["HK"],"pricingTier":[{"value":"WFS6","key":"*"}]}]}`,
    currentStateMapping: {
      title: 'title',
      welcomeText: '',
      description: 'unknownDescription',
      isConnectState: true,
      connectUpgradeBtn: 'connectButtonLabel',
      signInButton: {
        signInButtonLink: '',
        signInButtonLabel: '',
        signInButtonLinkInNewTab: '',
      },
      joinNowButton: {
        joinButtonLabel: '',
        joinButtonLink: '',
        joinButtonLinkInNewTab: '',
      },
      termsAndConditions: 'termsAndConditions',
      signInText: 'signInText',
      noLoginInterfaceModal: false,
      showLoginModal: true,
      loginModal: {
        default: true,
      },
      showAccessModal: false,
      accessModal: {
        default: false,
        acErrorMessage: false,
      },
      showReqAccessModal: false,
      reqAccessModal: {
        default: false,
        racErrorMessage: false,
      },
      showUpgradeGenErr: false,
      showUpgradeLRErr: false,
      showUpgradeMaxErr: false,
      showUpgradeNoCCErr: false,
      showUpgradePMSErr: false,
      showUpgradeSuccessNAInfo: false,
      showUpgradeSuccessMAXInfo: false,
      showPricingModal: true,
      showUpgradeModal: false,
      showLoyaltyWelcomeModal: false,
    },
    appId: 'mi-aem-ppv5-spa',
    offeredPlan: 'SF',
    disableImageModifier: false,
    unknownFreeConnection: true,
    mac: false,
    cna: false,
    sameTab: false,
    loyaltyFlip: false,
    marsha: 'MIANM',
    state: 'preconnect',
    userState: 'unknown',
    cookie: 'sessionID=D8349FDB-BC50-55BC-BBED-643153FAF2E1',
    headersData: {},
  },
};
