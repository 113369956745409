/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import { SEARCH_RESULTS_FALLBACK_IMAGE } from '../utils/constants/constants';

export interface searchResultsState {
  searchResultData: any;
  searchResultDataLoading: boolean;
  searchResultDataError: any;
  facetsData: any;
  skipQuery: boolean;
  paginationChange: boolean;
  pageNumber: number;
  setData: (data: any, loading: boolean, error: any, fallbackData: any) => void;
  setPaginationChange: (paginationChange: boolean) => void;
  setPageNumber: (pageNumber: number) => void;
  isLoaderVisible: boolean;
  setIsLoaderVisible: (loader: boolean) => void;
  isComponentLoading: boolean;
  setIsComponentLoading: (isCompLoading: boolean) => void;
  shouldComponentReload: boolean;
  setShouldComponentReload: (shouldReload: boolean) => void;
  queryParam: string;
  setQueryParam: (query: string) => void;
  sortMessage: string;
  searchTextMessage: string;
  searchTextMessageDisplay: string;
  setSearchTypeMessage: (sortMessage: string, searchTextMessage: string, searchTextMessageDisplay?: string) => void;
  setFacetsData: (facetsData: any) => void;
}

const initialState: searchResultsState = {
  searchResultData: null,
  searchResultDataLoading: false,
  searchResultDataError: null,
  facetsData: null,
  skipQuery: false,
  paginationChange: false,
  pageNumber: 1,
  setData: () => {},
  setPaginationChange: () => {},
  setPageNumber: () => {},
  isLoaderVisible: false,
  setIsLoaderVisible: () => {},
  isComponentLoading: false,
  setIsComponentLoading: () => {},
  shouldComponentReload: false,
  setShouldComponentReload: () => {},
  queryParam: '',
  setQueryParam: () => {},
  sortMessage: '',
  searchTextMessage: '',
  searchTextMessageDisplay: '',
  setSearchTypeMessage: () => {},
  setFacetsData: () => {},
};

export const searchResultsStore: StateCreator<searchResultsState> = set => {
  return {
    ...initialState,
    setData: (data, loading, error, fallbackData) => {
      if (error) {
        set({
          searchResultData: null,
          searchResultDataLoading: loading,
          searchResultDataError: true,
          facetsData: null,
          skipQuery: true,
        });
      } else if (!Object.keys(data).length || loading) {
        set({
          searchResultData: [],
          searchResultDataLoading: loading,
          searchResultDataError: false,
          facetsData: [],
          skipQuery: false,
        });
      } else if (data && Object.keys(data).length) {
        let searchResultDetails = data?.searchRestaurantsByGeolocation
          ? data?.searchRestaurantsByGeolocation
          : data?.searchRestaurantsByOpenText
          ? data?.searchRestaurantsByOpenText
          : data?.searchRestaurantsByLocation || [];
        const searchResultFacetsData = searchResultDetails.facets || [];

        const edges = searchResultDetails.edges;
        let restaurants;
        if (edges?.length) {
          const viewPort = window.matchMedia('(max-width: 991px)').matches ? 'mobile' : 'desktop';
          const imagePath = SEARCH_RESULTS_FALLBACK_IMAGE[viewPort].split('?')[0];
          const dimensions = SEARCH_RESULTS_FALLBACK_IMAGE[viewPort].split('?')[1];

          restaurants = edges.map((restaurant: any) => {
            const cardImages: any = [];
            if (restaurant.node.media.imageConnection && restaurant.node.media.imageConnection.edges.length === 1) {
              const element = restaurant.node.media.imageConnection.edges.map((element: any) => {
                if (element.node.imageUrls[imagePath]) {
                  cardImages.push({
                    defaultImageUrl: `https://cache.marriott.com${element.node.imageUrls[imagePath]}?${dimensions}`,
                    isFallBackImage: false,
                  });
                } else {
                  cardImages.push({
                    dynamicMedia: fallbackData,
                    isFallBackImage: true,
                  });
                }
                element = { ...element, node: { ...element.node, cardImages: cardImages } };
                return element;
              });
              restaurant = {
                ...restaurant,
                node: {
                  ...restaurant.node,
                  media: {
                    ...restaurant.node.media,
                    imageConnection: {
                      ...restaurant.node.media.imageConnection,
                      edges: element,
                    },
                  },
                },
              };
              return restaurant;
            } else if (
              restaurant.node.media.imageConnection &&
              restaurant.node.media.imageConnection.edges.length > 1
            ) {
              const element1 = restaurant.node.media.imageConnection.edges.filter((element: any) => {
                return element.node.imageUrls[imagePath] !== '' && element.node.imageUrls[imagePath] !== null;
              });

              if (element1.length === 1) {
                cardImages.push({
                  defaultImageUrl: `https://cache.marriott.com${element1[0].node.imageUrls[imagePath]}?${dimensions}`,
                  isFallBackImage: false,
                });

                const element2 = [
                  {
                    node: {
                      cardImages: cardImages,
                    },
                  },
                ];
                restaurant = {
                  ...restaurant,
                  node: {
                    ...restaurant.node,
                    media: {
                      ...restaurant.node.media,
                      imageConnection: {
                        ...restaurant.node.media.imageConnection,
                        edges: element2,
                      },
                    },
                  },
                };
                return restaurant;
              } else if (element1.length > 0) {
                restaurant = {
                  ...restaurant,
                  node: {
                    ...restaurant.node,
                    media: {
                      ...restaurant.node.media,
                      imageConnection: {
                        ...restaurant.node.media.imageConnection,
                        edges: element1,
                      },
                    },
                  },
                };
              } else {
                cardImages.push({
                  dynamicMedia: fallbackData,
                  isFallBackImage: true,
                });
                const element2 = [
                  {
                    node: {
                      cardImages: cardImages,
                    },
                  },
                ];
                restaurant = {
                  ...restaurant,
                  node: {
                    ...restaurant.node,
                    media: {
                      ...restaurant.node.media,
                      imageConnection: {
                        ...restaurant.node.media.imageConnection,
                        edges: element2,
                      },
                    },
                  },
                };
                return restaurant;
              }
              return restaurant;
            } else {
              cardImages.push({
                dynamicMedia: fallbackData,
                isFallBackImage: true,
              });
              const element2 = [
                {
                  node: {
                    cardImages: cardImages,
                  },
                },
              ];
              restaurant = {
                ...restaurant,
                node: {
                  ...restaurant.node,
                  media: {
                    ...restaurant.node.media,
                    imageConnection: {
                      ...restaurant.node.media.imageConnection,
                      edges: element2,
                    },
                  },
                },
              };
              return restaurant;
            }
          });

          searchResultDetails = { ...searchResultDetails, edges: restaurants };

          set({
            searchResultData: searchResultDetails || [],
            searchResultDataLoading: loading,
            searchResultDataError: searchResultDetails ? false : true,
            facetsData: searchResultFacetsData,
            skipQuery: true,
          });
        }
      }
    },
    setPaginationChange: paginationChange => {
      set({
        paginationChange: paginationChange,
      });
    },
    setPageNumber: pageNumber => {
      set({
        pageNumber: pageNumber,
      });
    },
    setIsLoaderVisible: (loader: boolean): void => {
      set({ isLoaderVisible: loader });
    },
    setIsComponentLoading: (isCompLoading: boolean): void => {
      set({ isComponentLoading: isCompLoading });
    },
    setShouldComponentReload: (shouldReload: boolean): void => {
      set({ shouldComponentReload: shouldReload });
    },
    setQueryParam: (query: string): void => {
      set({ queryParam: query });
    },
    setSearchTypeMessage: (sortMessage: string, searchTextMessage: string, searchTextMessageDisplay?: string): void => {
      set({
        sortMessage: sortMessage,
        searchTextMessage: searchTextMessage,
        searchTextMessageDisplay: searchTextMessageDisplay,
      });
    },
    setFacetsData: (facetsData: any): void => {
      set({ facetsData: facetsData });
    },
  };
};

export const useStore = createAppStore(searchResultsStore, {
  usePersistentStore: false,
  persistentStoreName: 'searchResultsPersistent',
});
