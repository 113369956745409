import { golfCoursePropertiesDetails } from '@marriott/mi-rnb-graphql';

export const golfCourseLocationsMock = {
  data: {
    searchPropertiesByDestination: {
      total: 5911,
      edges: [
        {
          node: {
            basicInformation: {
              brand: {
                name: 'TownePlace Suites',
                id: 'TS',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "TownePlace Suites Boise West/Meridian caters to both business and leisure travelers. Centrally located in Meridian, TownePlace Boise Meridian places you close to everything. Business travelers appreciate easy access to major companies like Blue Cross of Idaho, Micron, HP, & St. Luke's Hospital. Families can easily access attractions like Roaring Springs Water Park & Wahooz Fun Center. Just a 10-minute drive away is downtown Boise, with its museums, Boise State University, & the Idaho State Capitol Building. Unwind in our modern, spacious suites, all featuring fully equipped kitchens, perfect for preparing meals at your own pace.  Whether you're here for a quick visit or an extended stay, TownePlace Suites Boise West/Meridian offers amenities and comforts that make you feel right at home. Need local recommendations or help planning your next meeting or event? We're happy to assist! Book your stay at TownePlace Suites Boise West/Meridian, your ideal hotel for exploring all that Meridian, Idaho has to offer.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "TownePlace Suites by Marriott Boise West / Meridian is a beautiful Platinum-Award-Winning, all-suite hotel located in the heart of the Valley. Downtown Boise is 10 minutes away. Located near St. Luke's Hospital and more.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "TownePlace Suites by Marriott Boise West / Meridian is a beautiful Platinum-Award-Winning, all-suite hotel located in the heart of the Valley. Downtown Boise is 10 minutes away. Located near St. Luke's Hospital and more.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'TownePlace Suites by Marriott Boise West / Meridian is a beautiful Platinum-Award-Winning, all-suite hotel located in the heart of the Treasure Valley, with Entertainment, Shopping, and local attractions near by.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'TownePlace Suites by Marriott Boise West / Meridian is a beautiful Platinum-Award-Winning, all-suite hotel located in the heart of the Treasure Valley, with Entertainment, Shopping, and local attractions near by.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Unwind & Explore: Your Meridian Idaho Headquarters',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Unwind & Explore Meridian!',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located in the heart of the Treasure Valley. Fully equipped in room kitchens.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'TownePlace Suites Boise West/Meridian',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIBW',
            media: {
              photoGallery: {
                golf: {
                  edges: [
                    {
                      node: {
                        title: 'nycrt-exterior-0064',
                        alternateDescription: 'Time Square luxury hotel',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCRT/nycrt-exterior-0064-hor-clsc.jpg',
                          __typename: 'ImageRendition',
                        },
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        title: 'nycrt-lounge-0114',
                        alternateDescription: 'R Lounge at Two Times Square',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCRT/nycrt-lounge-0114-hor-clsc.jpg',
                          __typename: 'ImageRendition',
                        },
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        title: 'nycrt-exterior-0064',
                        alternateDescription: 'Time Square luxury hotel',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCRT/nycrt-exterior-0064-hor-clsc.jpg',
                          __typename: 'ImageRendition',
                        },
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        title: 'nycrt-lounge-0114',
                        alternateDescription: 'R Lounge at Two Times Square',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCRT/nycrt-lounge-0114-hor-clsc.jpg',
                          __typename: 'ImageRendition',
                        },
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        title: 'nycrt-exterior-0064',
                        alternateDescription: 'Time Square luxury hotel',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCRT/nycrt-exterior-0064-hor-clsc.jpg',
                          __typename: 'ImageRendition',
                        },
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                    {
                      node: {
                        title: 'nycrt-lounge-0114',
                        alternateDescription: 'R Lounge at Two Times Square',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/NYCRT/nycrt-lounge-0114-hor-clsc.jpg',
                          __typename: 'ImageRendition',
                        },
                        defaultImage: false,
                        __typename: 'ProductImage',
                      },
                      __typename: 'ProductImageConnectionEdge',
                    },
                  ],
                },
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Meridian',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Courtyard',
                id: 'CY',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "This downtown Boise, ID, hotel features inviting, flexible spaces to work or relax in, free Wi-Fi throughout . The highlight of our lobby experience is The Bistro which provides guests with healthy food and beverage offerings in the morning and evening dinner service with cocktails. The Crate has snacks and packaged meal options available 24 hours. This downtown hotel is also conveniently located adjacent to Boise State University, St. Luke's Regional Medical Center, Micron, and the State of Idaho Offices. We offer complimentary Boise Airport shuttle service, a well-equipped fitness center, a 24-hour boarding pass print stations. Experience why the Courtyard Boise Downtown is the perfect blend of atmosphere, value, location and service!",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Re-imagined Courtyard featuring healthy bistro, spacious lobby to connect and business center. Each room in our downtown Boise, ID, hotel features new, comfy beds and linens, a refrigerator, a coffee maker and wireless/DSL Internet.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Re-imagined Courtyard featuring healthy bistro, spacious lobby to connect and business center. Each room in our downtown Boise, ID, hotel features new, comfy beds and linens, a refrigerator, a coffee maker and wireless/DSL Internet.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Courtyard Boise Downtown offers lodging in downtown Boise, Idaho, minutes from Boise State University, museums, Micron, State of Idaho Offices, shopping and fine dining. Business offices such as St. Luke's, County Courthouse are within walking distance.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Courtyard Boise Downtown offers lodging in downtown Boise, Idaho, minutes from Boise State University, museums, Micron, State of Idaho Offices, shopping and fine dining. Business offices such as St. Luke's, County Courthouse are within walking distance.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Fresh New Look Hotel in Boise, Idaho Area',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'We have a range of suites in Boise, Idaho to suit you',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Hotel in Boise, Idaho area, with free parking.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Courtyard Boise Downtown',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOICY',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'SpringHill Suites',
                id: 'SH',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Experience the premier all-suite Boise hotel at the SpringHill Suites Boise ParkCenter, the perfect balance of comfort, style, and convenience. Our Marriott hotel in Boise, ID offers easy access to Boise Airport, Downtown Boise, Boise River Greenbelt, and Boise State University plus a great location within walking distance to local restaurants and bars along with free parking. Make yourself at home in contemporary-inspired suites featuring separate spaces to work, relax and dream. Our modern, spacious suites include free Wi-Fi, mini-refrigerators, microwaves, and luxurious bedding. Start your day satisfied with a complimentary continental breakfast or work up a sweat in our expansive fitness center. When it is time to enjoy the outdoors, soak up the sun at our sparkling outdoor pool, or take advantage of our onsite bike rentals to get some exercise while exploring our city's breathtaking scenery.  After your day of adventure, relax in the evening on our expansive terrace with firepit.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our all-suite Boise hotel features stylish accommodations with separate spaces for working and relaxing. Enjoy complimentary Wi-Fi, breakfast and free parking along with an outdoor heated pool and a state-of-the-art fitness center.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our all-suite Boise hotel features stylish accommodations with separate spaces for working and relaxing. Enjoy complimentary Wi-Fi, breakfast and free parking along with an outdoor heated pool and a state-of-the-art fitness center.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Retreat to our Boise hotel in a fantastic location adjacent to the Boise River greenbelt offering easy access to downtown plus free parking, breakfast buffet and spacious new suites.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Retreat to our Boise hotel in a fantastic location adjacent to the Boise River greenbelt offering easy access to downtown plus free parking, breakfast buffet and spacious new suites.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our Boise Hotel Is the Perfect Home Base to Explore Idaho',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Top Marriott Boise Hotel',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Retreat to our Boise hotel near the Boise River Greenbelt with free parking & easy downtown access.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'SpringHill Suites Boise ParkCenter',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIDT',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Residence Inn',
                id: 'RI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Idaho is known for pristine Four-Season recreation including the Boise River Greenbelt, Eagle Island State Park, BanBury & Eagle Hills Golf Courses, Eagle Tennis Club, and Bogus Basin Ski Resort. Perfectly located at the gateway of Routes 44 & 55 there is perfect access to enjoy Northern Idaho recreation of river rafting/kayaking, camping, hunting, fishing, and skiing in Cascade, McCall, & Tamarack.  Our hotel is the closest to new communities in the Eagle Foothills of Valnova, Avimore and Dry Creek Ranch.  Enjoy Idaho's premier wine country in Garden City, Sunny Slope or the Eagle Foothills of 3 Horse Ranch, Rolling Hills, Dude DeWalt or Sol Invictus. The hotel is centrally located near great local companies like: Hewlett Packard, Yardi Systems, PetIQ, Intuit, Lamb Weston, Money Metals Exchange or Eagle Forest Products.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Residence Inn is perfectly located off Idaho Route 44 and near Eagle Road, surrounded by innovative dining & shopping experiences. The hotel is about a mile from the Boise Greenbelt, Eagle Island State Park, Eagle Hills & Banbury Golf Courses and wineries',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Residence Inn is perfectly located off Idaho Route 44 and near Eagle Road, surrounded by innovative dining & shopping experiences. The hotel is about a mile from the Boise Greenbelt, Eagle Island State Park, Eagle Hills & Banbury Golf Courses and wineries',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Eagle Residence Inn by Marriott is nestled along the Eagle Foothills conveniently located on Route 44 and Eagle Road and minutes from Highway 55. Hotel is walking distance from historic downtown Eagle and within a mile of unique local restaurants.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Eagle Residence Inn by Marriott is nestled along the Eagle Foothills conveniently located on Route 44 and Eagle Road and minutes from Highway 55. Hotel is walking distance from historic downtown Eagle and within a mile of unique local restaurants.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Newest Eagle Hotel is at the Gateway to premier recreation.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: '131 Kitchen Suites',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Newest Marriott in the Treasure Valley in upscale Eagle, gateway to Highway 55 recreation.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Residence Inn Eagle Boise',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIEA',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Eagle',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Fairfield Inn & Suites',
                id: 'FI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: 'The Fairfield has outstanding service at an exceptional value. With a great location near the Boise Airport, downtown Boise and Boise State University, we are always ready to welcome you with amenities like thoughtfully designed guest rooms with Perfect Serta Sleepers, free WiFi, fitness center, pool, and spa. Jump-start your day with our free extended hot breakfast buffet including fresh cut fruit and our burrito and salsa bar. At the Fairfield Boise, you are our number 1 priority. We promise you will be satisfied or we will make it right. That is our commitment to you. Ask us about The Fairfield 100 percent Guarantee.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Fairfield is located near the Boise Airport off I-84, with free airport transportation, free Wi-Fi, free breakfast buffet, fitness center, and  indoor pool/spa.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Fairfield is located near the Boise Airport off I-84, with free airport transportation, free Wi-Fi, free breakfast buffet, fitness center, and  indoor pool/spa.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'We are located just off of I-84 in Boise across from the airport.  Take exit#53, Vista Avenue and continue north and take a left at Elder Street.  The hotel is on your left in approximately .2 miles, behind the Simple Suites.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'We are located just off of I-84 in Boise across from the airport.  Take exit#53, Vista Avenue and continue north and take a left at Elder Street.  The hotel is on your left in approximately .2 miles, behind the Simple Suites.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: null,
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Fairfield Inn Boise',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIFI',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Fairfield Inn & Suites',
                id: 'FI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: 'Welcome to the Fairfield Inn and Suites Boise West, a beautiful Marriott located within a few feet of the Boise Town Square Mall. Our hotel is minutes from everything Boise has to offer from the beautiful Idaho State Capitol building in downtown to outdoor shopping at The Village at Meridian. This Marriott is a quick trip to Boise State University, and a 10-minute drive to the Boise Airport. Our property makes every stay easy with over 30 restaurants, activities, and eateries within 2 miles perfect for any family or sport teams looking to stay. Rest easy with our plush mattresses, wind down on our outdoor patio, take a dip into our heated pool or enjoy the spacious fitness center with upgraded equipment including free weights, treadmills, and ellipticals. We offer complimentary hot and grab and go breakfast with many morning favorites, complimentary WiFi, free parking, a business center and a 24-hour market with snacks, beverages and more.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Fairfield Inn & Suites Boise West is the place to stay when travelling for business or fun. Enjoy our hot breakfast, complimentary Wi-Fi , spacious fitness center and relax on our outdoor patio.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Fairfield Inn & Suites Boise West is the place to stay when travelling for business or fun. Enjoy our hot breakfast, complimentary Wi-Fi , spacious fitness center and relax on our outdoor patio.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: "We are located just off the I-84 exit 49 Franklin Rd giving easy access to downtown, Boise State University, and Boise Airport. Within a mile of Target, Dave & Busters, Longhorn Steakhouse and more, there's always something close by.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "We are located just off the I-84 exit 49 Franklin Rd giving easy access to downtown, Boise State University, and Boise Airport. Within a mile of Target, Dave & Busters, Longhorn Steakhouse and more, there's always something close by.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Experience comfort near Boise Town Square Mall',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Rest and relax in our spacious guest rooms',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Steps away from Boise Town Square Mall! Enjoy spacious rooms with complimentary breakfast!',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Fairfield Inn & Suites Boise West',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIFW',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Residence Inn',
                id: 'RI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: 'Residence Inn Boise Downtown University is minutes from Boise State University and downtown Boise, ID. Ranked by TripAdvisor as one of the top hotels in Boise, Residence Inn Boise Downtown University provides large spacious suites with full kitchens - some with multiple bedrooms along with a daily free hot breakfast buffet. In addition, we offer complimentary wireless internet throughout the hotel. Our all-suite extended stay hotel in Boise has an outdoor seasonal pool and the new outdoor experience with firepit. This Boise Marriott hotel is near BSU, Micron, Hewlett Packard, St. Lukes Regional Medical Center, and other government offices. Come stay with us and experience Boise while attending a Boise State University football game, attending "Alive after Five" Art in the Park, or watching the Albertson\'s Boise Open. Residence Inn Boise Downtown University offers award-winning service that few Boise hotels can match.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Residence Inn Boise Downtown University hotel is next to Boise State University and minutes to downtown Boise. Our Boise hotel provides free breakfast buffet.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Residence Inn Boise Downtown University hotel is next to Boise State University and minutes to downtown Boise. Our Boise hotel provides free breakfast buffet.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Residence Inn Boise Downtown University is walking distance to Boise State University and minutes from  Downtown Boise.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Residence Inn Boise Downtown University is walking distance to Boise State University and minutes from  Downtown Boise.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Award Winning Boise Hotel Minutes to Downtown Near BSU',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Boise Hotel Suites with Extra Space and Full Kitchens',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Award winning Boise hotel near Boise State University, convenient to downtown & popular attractions',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Residence Inn Boise Downtown/University',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIID',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Fairfield Inn & Suites',
                id: 'FI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Your comfortable home base for exploring Nampa Idaho. Situated just outside downtown Nampa, our hotel offers easy access to everything the area has to offer making us the perfect choice for Nampa Idaho hotels. We're close to Treasure Valley Market for shopping & dining, & a short drive to Boise's attractions. Hike, bike or fish in the nearby mountains & rivers, or tee off at Red Hawk Golf Course, Centennial Course, or Ridge Crest Golf Course. Ideal for business travelers, our hotel near is near Ford Idaho Center, Amalgamated Sugar, Plexus, The J.R. Simplot Company, Northwest Nazarene University, College of Idaho, & College of Western Idaho making your business trip a breeze. Catch the Snake River Stampede, Caldwell Night Rodeo, or Canyon County Fair during your stay. After a day of exploration, unwind in our inviting guest rooms or take a dip in our indoor pool. Fuel your day with our free hot breakfast. Book your stay at Fairfield Inn & Suites Boise Nampa, your perfect Nampa Idaho hotel!",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "At Fairfield by Marriott Boise Nampa, we provide you with  the hotel experience you're looking for - a clean, comfortable room at an affordable price. Enjoy a convenient location, complimentary hot breakfast, exercise room, and high-speed wi-fi access",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "At Fairfield by Marriott Boise Nampa, we provide you with  the hotel experience you're looking for - a clean, comfortable room at an affordable price. Enjoy a convenient location, complimentary hot breakfast, exercise room, and high-speed wi-fi access",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Fairfield by Marriott is located just off of I-84, and is conveniently located near many businesses, shops and restaurants including Costco, Target, Old Navy, Olive Garden, Panera, Cracker Barrel & Edwards Theater.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Fairfield by Marriott is located just off of I-84, and is conveniently located near many businesses, shops and restaurants including Costco, Target, Old Navy, Olive Garden, Panera, Cracker Barrel & Edwards Theater.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Welcome to Fairfield Inn & Suites by Marriott Boise Nampa!',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Your Nampa Adventure Starts Here!',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Fairfield Inn is conveniently located off I-84. Indulge in our complimentary breakfast buffet.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Fairfield Inn & Suites Boise Nampa',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOINP',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Nampa',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Residence Inn',
                id: 'RI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Located in the heart of Idaho's capital, Residence Inn Boise Downtown/City Center is a modern, all-suite hotel ideal for people traveling to the area for business or leisure. With Boise Centre, Boise State University, the Idaho State Capitol building and many other attractions just minutes away, getting where you need to be is easy and fast. Parking is just as simple thanks to our on-site garage. Each spacious suite is equipped with a full kitchen for your cooking convenience. Join us for breakfast in the dining area for a complimentary meal served every morning. In the evening, visit the Lobby Bar for a cocktail, then head upstairs to soak in the city views from a beautiful roof deck with two fireplaces. Whether you are here for an overnight visit or an extended stay, Residence Inn Boise Downtown/City Center offers the perfect respite from your busy day.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Soak in city views from Residence Inn Boise Downtown/City Center, an all-suite, pet-friendly hotel. Enjoy a free breakfast or prepare meals in a full kitchen. On-site parking, roof deck and Wi-Fi ensure a convenient stay.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Soak in city views from Residence Inn Boise Downtown/City Center, an all-suite, pet-friendly hotel. Enjoy a free breakfast or prepare meals in a full kitchen. On-site parking, roof deck and Wi-Fi ensure a convenient stay.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Located in the heart of Idaho's capital, Residence Inn Boise Downtown/City Center is an all-suite hotel just blocks away from Boise Centre. It is also near Boise State University, the Idaho State Capitol building and many restaurants.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Located in the heart of Idaho's capital, Residence Inn Boise Downtown/City Center is an all-suite hotel just blocks away from Boise Centre. It is also near Boise State University, the Idaho State Capitol building and many restaurants.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'View the best of Boise from our downtown hotel',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Feel at home at our all-suite hotel in downtown Boise',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Enjoy beautiful city views from this modern, all-suite hotel blocks away from Boise Centre.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Residence Inn Boise Downtown/City Center',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIRD',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Residence Inn',
                id: 'RI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Settle in for a short or extended stay at the Residence Inn Boise West. Our extended stay hotel in Boise, ID offers the modern amenities and convenience you need for a seamless stay like spacious suites with fully equipped kitchens, free Wi-Fi, and separate lounge areas great for your extra guests. Break a sweat in our 24-hour fitness center and go for a dip in our heated indoor pool. Explore the local area with a visit to nearby attractions like Boise State University, St Alphonsus hospital, Boise Towne Square Mall, and the Extra Mile Arena. Our location minutes from Boise Airport, Hewlett Packard Enterprise, and Micron Technology Inc, make our apartment-style suites a great choice for your group stay. Start your day off with a free hot breakfast. Whether you're in Boise, ID on business or leisure, enjoy your stay at our hotel.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Settle in for a short or extended stay at Residence Inn Boise West. Our hotel in Boise, ID offers free breakfast, fitness facilities, and spacious pet-friendly suites with fully equipped kitchens. Easily get to Boise State University and more nearby.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Settle in for a short or extended stay at Residence Inn Boise West. Our hotel in Boise, ID offers free breakfast, fitness facilities, and spacious pet-friendly suites with fully equipped kitchens. Easily get to Boise State University and more nearby.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located in Boise, ID, the Residence Inn Boise West is near Boise State University, St Alphonsus hospital, Boise Towne Square Mall, Extra Mile Arena, Boise Airport, offices for Hewlett Packard Enterprise, and more.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located in Boise, ID, the Residence Inn Boise West is near Boise State University, St Alphonsus hospital, Boise Towne Square Mall, Extra Mile Arena, Boise Airport, offices for Hewlett Packard Enterprise, and more.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Extended-Stay Hotel Near Boise State University',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Unwind in Our Extended Stay Hotel Suites in Boise, ID',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Extended-Stay Hotel Near Boise State University',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Residence Inn Boise West',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIRI',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'SpringHill Suites',
                id: 'SH',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: 'Stay near Boise State University with clean and modern rooms with views of the scenic mountain range. Our Treasure Valley hotel provides all the comforts of home with free WiFi, stylish West Elm furniture, flatscreen TV with streaming services, and a wet bar equipped with a microwave and mini fridge. Perfect for business or leisure, spread out in a separate living room with a sofa bed and work area. Free hot breakfast is served everyday, and an on-site marketplace is stocked with grab and go refreshments for purchase. Families and groups will enjoy swimming in our indoor pool or exploring nearby attractions like hiking at Eagle Island State Park, biking alongside the Boise River Greenbelt or year-round recreational activities at Bogus Basin. We also recommend the seasonal Boise River float! Boise Airport is about 10.4 miles away and our hotel provides easy access to mountains, rivers, lakes and more.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Springhill Suites Boise West offers modern suites with a flatscreen TV, streaming services, West Elm furniture, wet bar equipped with a microwave and mini fridge. Free hot breakfast, parking, WiFi, indoor pool, and fitness center are also available.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Springhill Suites Boise West offers modern suites with a flatscreen TV, streaming services, West Elm furniture, wet bar equipped with a microwave and mini fridge. Free hot breakfast, parking, WiFi, indoor pool, and fitness center are also available.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our modern Treasure Valley hotel provides easy access to Boise State University, Bogus Basin, Downtown Boise, The Village at Meridian, Idaho Botanical Gardens, Zoo Boise, and Roaring Springs Waterpark. Boise Airport is just 10.4 miles away.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our modern Treasure Valley hotel provides easy access to Boise State University, Bogus Basin, Downtown Boise, The Village at Meridian, Idaho Botanical Gardens, Zoo Boise, and Roaring Springs Waterpark. Boise Airport is just 10.4 miles away.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Modern Boise State University Hotel near Bogus Basin',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Enhanced Cleanliness During Your Next Boise Hotel Stay',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Modern and clean rooms with free hot breakfast near Boise State University and Downtown Boise.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'SpringHill Suites Boise West/Eagle',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOISH',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'TownePlace Suites',
                id: 'TS',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Book a relaxing stay at the TownePlace Suites Boise Downtown/University. Our extended stay hotel near Downtown Boise pairs modern design with the unparalleled comforts of home with full kitchens, free Wi-Fi, plush Marriott bedding and ergonomic workstations offered in all suites. Start your mornings in Boise with our free breakfast and enjoy lunch and dinner nearby at restaurants like Shingo's Japanese Cuisine and Elmer's Restaurant. Complimentary Wi-Fi throughout the hotel makes it easy to stay connected during your short or extended stay. During downtime, get active at our on-site fitness center and make a splash in our indoor pool. Venture over to attractions like Topgolf, Idaho Central Arena, Boise State University, the Boise Centre, Boise Zoo, Ada County Courthouse, activities in Downtown Boise, and more. Travelers will appreciate our location near St. Luke's Hospital and VA Hospital. Whether you're in town on business or leisure, live uninterrupted at the TownePlace Suites Boise Downtown/University.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Make yourself at home at TownePlace Suites Boise Downtown/University. Our extended stay hotel in downtown Boise offers free breakfast, an indoor pool, and pet-friendly suites with full kitchens. Easily get to activities in Boise minutes from our hotel.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Make yourself at home at TownePlace Suites Boise Downtown/University. Our extended stay hotel in downtown Boise offers free breakfast, an indoor pool, and pet-friendly suites with full kitchens. Easily get to activities in Boise minutes from our hotel.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located in Downtown Boise, the TownePlace Suites Boise Downtown/University offers easy access to VA Hospital, Idaho Central Arena, Boise State University, the Boise Centre, Boise Zoo, Ada County Courthouse, activities in Downtown Boise, and more.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located in Downtown Boise, the TownePlace Suites Boise Downtown/University offers easy access to VA Hospital, Idaho Central Arena, Boise State University, the Boise Centre, Boise Zoo, Ada County Courthouse, activities in Downtown Boise, and more.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Extended Stay Hotel Near BSU and Downtown Boise, ID',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Enjoy Our Hotel Rooms Near Boise State University',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Stay upbeat at our extended stay hotel in Downtown Boise, ID',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'TownePlace Suites Boise Downtown/University',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOITS',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Boise',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Courtyard',
                id: 'CY',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "On your next trip to the Treasure Valley stay at Boise's only full service Courtyard by Marriott. Our rooms are made for business stays but equally comfortable for leisurely getaways. Each guest room includes a microwave, mini-refrigerator and tea coffee maker or you can upgrade to a suite for extra space and a sofabed. Free high speed WiFi is available throughout the property, including our spacious lobby furnished with private and sociable spaces for working and eating. After a full day, order drinks or hot meals at The Bistro serving up made to order breakfast, dinner and Starbucks Coffee. Located in Meridian ID our Courtyard hotel is right between Boise and Nampa off I 84 offering easy access to the bars and restaurants in downtown Boise concerts at Ford Idaho Center and football games at Boise State University. Our hotel is only 10 minutes from the Boise Airport.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our newly renovated modern hotel is right off I-84 and central to Nampa, Meridian and downtown Boise. Guests receive access to free high-speed WiFi, an indoor pool, and 24-hour fitness center. Only 7 minutes to the Boise Airport.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Our newly renovated modern hotel is right off I-84 and central to Nampa, Meridian and downtown Boise. Guests receive access to free high-speed WiFi, an indoor pool, and 24-hour fitness center. Only 7 minutes to the Boise Airport.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Prominent companies nearby include Micron, Amazon and Power Engineers. Our location off Eagle Road places guests close to Roaring Springs Water Park, Ford Idaho Center, and The Village at Meridian. Only 10 minutes away from the Boise Airport.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Prominent companies nearby include Micron, Amazon and Power Engineers. Our location off Eagle Road places guests close to Roaring Springs Water Park, Ford Idaho Center, and The Village at Meridian. Only 10 minutes away from the Boise Airport.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'A central location off I-84 and Eagle Rd',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'A place to dream big dreams.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Free WiFi and only 10 minutes away from the Boise Airport.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Courtyard Boise West/Meridian',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOIWM',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Idaho',
                  __typename: 'Lookup',
                },
                city: 'Meridian',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'AC Hotels',
                id: 'AR',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Connect to the city in a new way at AC Boston Hotel Cleveland Circle, located at the crossroads of academia and athletics, history and entertainment, Boston and Brookline. Infused with European style and Boston soul, our boutique hotel offers innovative spaces, tailored to the needs of creative, well-traveled, entrepreneurial spirits, including a daily European-style breakfast and free WiFi throughout the hotel, 50-inch Smart TVs, comfortable pillow-top mattresses and ergonomic in-room workspaces. A convenient business center, flexible event venues, and a 24-hour fitness center allow you to work and meet where you choose. Every evening, unwind at the AC Lounge, where our bartenders serve handcrafted cocktails to pair with specialty tapas. Our hotel is also ideally located near Boston University, Fenway Park, Brigham and Women's Hospital, Faulkner Hospital, Longwood Medical, Boston Children's Hospital, Reservoir and Cleveland Circle Stations, MBTA Green Line, for further Boston adventures.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'At the confluence of Spanish roots and Boston soul, Beacon St. and Chestnut Hill Ave., our hotel near Boston College is conveniently located in Cleveland Circle, across from the Green Line, less than a mile to BC, and minutes from downtown Boston.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'At the confluence of Spanish roots and Boston soul, Beacon St. and Chestnut Hill Ave., our hotel near Boston College is conveniently located in Cleveland Circle, across from the Green Line, less than a mile to BC, and minutes from downtown Boston.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Across from Reservoir Station (MBTA Green Line) and next to Cassidy Park, our modern hotel near Boston College is close to academic institutions including BC & Boston University, plus just 7 minutes from Fenway Park via the Green Line.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Across from Reservoir Station (MBTA Green Line) and next to Cassidy Park, our modern hotel near Boston College is close to academic institutions including BC & Boston University, plus just 7 minutes from Fenway Park via the Green Line.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Lifestyle Hotel in Chestnut Hill minutes from Boston College',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Style, purpose & comfort at our hotel near Boston College',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'European style inspires innovative spaces at our Chestnut Hill hotel',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'AC Hotel Boston Cleveland Circle',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOSAB',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Massachusetts',
                  __typename: 'Lookup',
                },
                city: 'Boston',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'AC Hotels',
                id: 'AR',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Situated in Medford, MA, just a mere 4 miles away from downtown Boston and 6 miles from Boston Logan Intl Airport, you'll find an exceptional destination. This lifestyle hotel boasts meticulously designed and well-furnished guest rooms and suites, equipped with the latest technology, versatile workspaces, complimentary Wi-Fi, and a cutting-edge 48-inch smart TV. It's a haven that caters to the preferences of both business and leisure travelers. To start your day the AC Kitchen serves up a European-style breakfast. In the evening, the AC Lounge provides the perfect setting to unwind and connect, offering locally brewed beers and expertly crafted cocktails. Conveniently located within a stone's throw of Boston's MBTA public transportation system, this hotel offers seamless connections to the vibrant areas of Boston, Cambridge, and Assembly Row. Moreover, it's strategically positioned near prominent bioscience offices, Philips Research, Sanofi, Mass General Brigham, Encore, and the Chevalier Theater.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'A European inspired lifestyle hotel featuring 152 intelligently designed and well-appointed guest rooms that appeal to both business and leisure travelers.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'A European inspired lifestyle hotel featuring 152 intelligently designed and well-appointed guest rooms that appeal to both business and leisure travelers.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'AC Hotel by Marriott Boston North is located at Station Landing, Medford, MA, just four miles from downtown Boston and seven miles from Boston Logan International Airport.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'AC Hotel by Marriott Boston North is located at Station Landing, Medford, MA, just four miles from downtown Boston and seven miles from Boston Logan International Airport.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Discover AC Hotel Boston North, near the Boston Casino.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Intelligently Designed Guest Rooms and Suites',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'A European inspired lifestyle hotel that appeals to both business and leisure travelers.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'AC Hotel Boston North',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOSAC',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Massachusetts',
                  __typename: 'Lookup',
                },
                city: 'Medford',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Residence Inn',
                id: 'RI',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: 'The Residence Inn by Marriott Boston Andover Hotel is the top choice for your extended stay in Andover, MA. We are committed to cleaning with enhanced health and safety measures with hospital grade disinfectants. With our Andover suites, you have room to move around with separate areas for sleeping, living, eating and the convenience of a fully equipped kitchen. Guests will also enjoy free high-speed Internet access and luxury bedding in our spacious suites hotel in Andover, MA. Our pet friendly hotel in Andover, MA is conveniently located in Minuteman business park, home to Raytheon, Smith & Nephew, Straumann USA, Navisite and Draeger. When visiting Andover for pleasure you will be 25 miles north of Boston, a few miles from Canobie Lake Park and minutes away from the beautiful campus of Phillips Academy. We are also just 10 minutes from Lowell and Haverhill.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Residence Inn Boston Andover is the choice hotel for extended stays in Andover, Massachusetts. We're committed to excellence with enhanced cleaning, health and safety features in all areas of the hotel. All our suites offer all you need to feel at home.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Residence Inn Boston Andover is the choice hotel for extended stays in Andover, Massachusetts. We're committed to excellence with enhanced cleaning, health and safety features in all areas of the hotel. All our suites offer all you need to feel at home.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Residence Inn by Marriott Boston Andover hotel is located minutes from Salem, NH and a short drive to all the attractions Boston has to offer. Our hotel helps you thrive on long stays by combining all the comforts of home with the perks of a hotel.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'The Residence Inn by Marriott Boston Andover hotel is located minutes from Salem, NH and a short drive to all the attractions Boston has to offer. Our hotel helps you thrive on long stays by combining all the comforts of home with the perks of a hotel.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Your home away from home in Andover, Massachusetts',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Recharge in our Andover, MA, extended stay suites',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Find comfort at our all-suite extended stay hotel in Andover, MA.',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Residence Inn Boston Andover',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOSAD',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Massachusetts',
                  __typename: 'Lookup',
                },
                city: 'Andover',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Aloft Hotels',
                id: 'AL',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: "Immerse yourself in stylish comfort in loft-inspired hotel accommodations with free Wi-Fi, luxury bedding, and Dry Bar bathroom amenities. Under a mile from Natick Mall, one of New England's largest shopping centers. Minutes from Framingham State University and Wellesley College. Well-appointed for Business travelers and situated close to corporations such as MathWorks, TJX Companies, Bose Corporation, Genzyme, and Staples Inc. Our hotel also offers a formal meeting space that can hold up to 32 guests in a conference-style setup. Meet and mingle with friends or colleagues at our W XYZ bar with our signature cocktail and a fun atmosphere. During downtime, maintain your workout routine by exercising at our fitness center and taking a swim in our indoor heated saline pool. We are the next generation of hotels, using technology and design to enhance experiences and move at the pace of our guests. Different. By design.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Immerse yourself in stylish comfort in loft-inspired hotel accommodations with free Wi-Fi, luxury bedding, and Dry Bar bathroom amenities. Under a mile from Natick Mall, one of New England's largest shopping centers. Different. By design.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "Immerse yourself in stylish comfort in loft-inspired hotel accommodations with free Wi-Fi, luxury bedding, and Dry Bar bathroom amenities. Under a mile from Natick Mall, one of New England's largest shopping centers. Different. By design.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'We are situated in the heart of MetroWest MA, 18 miles west of Boston Downtown. The hotel is nestled in the Golden Triangle, the second largest shopping district in Massachusetts',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'We are situated in the heart of MetroWest MA, 18 miles west of Boston Downtown. The hotel is nestled in the Golden Triangle, the second largest shopping district in Massachusetts',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Step up your stay at our stylish hotel in MetroWest MA',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Non-Smoking, Connecting/adjoining rooms can be requested',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Stylish loft-inspired hotel with free Wi-Fi, luxury bedding, heated indoor saline pool, gym & Bar',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'Aloft Framingham',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOSAF',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Massachusetts',
                  __typename: 'Lookup',
                },
                city: 'Framingham',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
        {
          node: {
            basicInformation: {
              brand: {
                name: 'Autograph Collection',
                id: 'AK',
                __typename: 'Brand',
              },
              descriptions: [
                {
                  text: 'Part homage to the inspired community that surrounds it part ode to the Innovation District it calls home The Envoy Hotel ascends a new genre of hospitality cast in a very original way. A visionary hotel The Envoy is an inviting hybrid of comfort and creativity. Our mission is to inspire and delight. Our vessel a modern building radiating with light and glass and providing intimate views of the city and toward the harbor. Designed from the ground up to be a beacon of uncommonly forward thinking accommodation, The Envoy Hotel draws visitors just a few steps outside the ordinary. Those looking for artful, guest centric touches in every detail. Uncompromising yet unpretentious spaces with surprising flavor are around every corner. From our 136 stunningly appointed guest rooms and suites to our fiercely inspiring gathering areas to our award winning dining establishments Lookout Rooftop and Para Maria. The Envoy is equal parts fearless and approachable at every level imaginable.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: "The Envoy Hotel is the brand new and innovative waterfront property within Boston's booming Seaport District.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: "The Envoy Hotel is the brand new and innovative waterfront property within Boston's booming Seaport District.",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located on the waterfront in the Seaport Innovation District with sweeping views of the water and city. Coupled with a trendy, culinary-fashion forward restaurant and rooftop bar.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'Located on the waterfront in the Seaport Innovation District with sweeping views of the water and city. Coupled with a trendy, culinary-fashion forward restaurant and rooftop bar.',
                  __typename: 'PropertyDescription',
                },
                {
                  text: "The Envoy Hotel | Boston's Award-Winning Boutique Hotel",
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'BOSTON SEAPORT DISTRICT ACCOMMODATIONS',
                  __typename: 'PropertyDescription',
                },
                {
                  text: 'WINNER - BOSTON MAGAZINE 2017 BEST BOUTIQUE HOTEL',
                  __typename: 'PropertyDescription',
                },
              ],
              name: 'The Envoy Hotel, Autograph Collection',
              __typename: 'HotelBasicInformation',
            },
            id: 'BOSAK',
            media: {
              photoGallery: {
                golf: null,
                __typename: 'PhotoGalleryImageConnection',
              },
              __typename: 'HotelMediaContent',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Massachusetts',
                  __typename: 'Lookup',
                },
                city: 'Boston',
                __typename: 'PropertyAddress',
              },
              __typename: 'PropertyContactInformation',
            },
            __typename: 'Hotel',
          },
          __typename: 'PropertySearchEdge',
        },
      ],
      __typename: 'PropertyDestinationSearchConnection',
    },
  },
};

export const getCourseLocationsMock = {
  request: {
    query: golfCoursePropertiesDetails,
    variables: {
      search: {
        destination: 'United states',
        facets: {
          terms: [
            {
              type: 'ACTIVITIES',
              dimensions: ['golf'],
            },
          ],
        },
      },
      limit: 18,
      offset: 0,
    },
  },
  result: golfCourseLocationsMock,
};

export const errorMock = {
  request: {
    query: golfCoursePropertiesDetails,
    variables: {
      search: {
        destination: 'United states',
        facets: {
          terms: [
            {
              type: 'ACTIVITIES',
              dimensions: ['golf'],
            },
          ],
        },
      },
      limit: 18,
      offset: 0,
    },
  },
  error: new Error('Network Error'),
};
