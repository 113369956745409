import { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { Button, Heading, Types, Text, Icon } from '@marriott/mi-ui-library';
import { StyledOutletPrimaryDetails } from './OutletPrimaryDetails.styles';
import {
  trackImpression,
  useGetBreakpoint,
  getTitleIconOutlet,
  getEarnRedeemDetails,
  getMichelinData,
} from '../../../modules/utils/helpers';
import { PageParamsContext } from '../../../modules/context/PageContext';
import { TRACKING_CONSTANT } from '../../../modules/utils/constants';
import { getDirection } from '../../../modules/utils/helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OutletPrimaryDetails = (props: any) => {
  const { userId } = useContext(PageParamsContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const [isScrolled, setIsScrolled] = useState(false);
  const isDirectionRTL = getDirection() === 'rtl';

  const { OUTLET_DETAILS, RESERVE_BUTTON, INTERNAL_LINK } = TRACKING_CONSTANT;

  const outletDetails = props?.outletDetails;
  const iconTitle = getTitleIconOutlet(outletDetails?.basicInformation);
  const earnRedeem: string = getEarnRedeemDetails(outletDetails) ?? '';
  const michelinData: { title: string; starCount: number; iconPath: string } | null = getMichelinData(
    outletDetails,
    props
  );

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Header height is 53 + Image height is 310 + Bar with name after login is 48
    // Ignore the bar with name height if not logged in
    const searchBarOffsetTop: number = userId ? 420 : 400;

    function handleScroll() {
      if (window.scrollY >= searchBarOffsetTop) {
        setIsScrolled(true);
      } else if (window.scrollY === 0 || window.scrollY < searchBarOffsetTop) {
        setIsScrolled(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack)
      trackImpression(
        { ...props?.trackingProperties },
        '',
        '',
        `${props?.outletDetails?.properties?.edges[0]?.node?.property?.id}`
      );
  }, []);

  const handleReserveBtnClick = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);
  };

  const starIconArray = Array.from({ length: Number(michelinData?.starCount || 0) }, (_, index) => (
    <li key={index} className="pr-1 pr-sm-1 pr-md-0 pr-lg-0 pr-xl-0 icon-s">
      <Icon iconClass={clsx(`${michelinData?.iconPath} icon-alt icon-s mx-0`)}></Icon>
    </li>
  ));

  return (
    <StyledOutletPrimaryDetails className="outlet-primary-details d-flex flex-column-reverse flex-md-row justify-content-between">
      {/* eslint-disable-next-line no-extra-boolean-cast */}
      <div
        className={clsx(
          'outlet-primary-details__title-section d-flex flex-column pt-4 pb-0 px-0 p-lg-0',
          outletDetails?.restaurants?.edges[0]?.node?.reservationURL && isMobileViewPort ? 'add-separator' : ''
        )}
      >
        {/* eslint-disable-next-line no-extra-boolean-cast */}
        {outletDetails?.diningExperience?.cuisines?.length > 0 && (
          <Heading
            variation={Types.headingType.subtitle}
            titleText={outletDetails?.diningExperience?.cuisines[0]}
            element={Types.tags.h4}
            customClass={clsx('cuisine-type mb-2 t-overline-normal', isDirectionRTL ? 'text-right' : 'text-left')}
          ></Heading>
        )}
        <div className="d-flex flex-row">
          <Icon
            iconClass={clsx(
              'icon-decorative',
              'icon-outlet-class',
              'pr-0 my-1 pt-md-3',
              iconTitle ? props?.iconPathDining : props?.iconPathBar,
              isDirectionRTL ? 'pr-0 pl-2' : 'pr-2'
            )}
          ></Icon>
          <Heading
            variation={Types.headingType.title}
            titleText={outletDetails?.basicInformation?.name}
            fontSize={Types.size.large}
            element={Types.tags.h1}
            customClass={clsx(
              'm-0 pb-1',
              isMobileViewPort ? 't-subtitle-xl' : '',
              isDirectionRTL ? 'text-right' : 'text-left'
            )}
          ></Heading>
        </div>
        {(!!michelinData?.title || (earnRedeem !== 'Do not show' && earnRedeem !== '')) && (
          <div
            data-testid="text-test"
            className={clsx(
              'd-flex flex-column flex-md-row pt-md-3 pb-3',
              !isMobileViewPort ? 'align-items-center' : ''
            )}
          >
            {earnRedeem !== 'Do not show' && earnRedeem !== '' && (
              <Text
                copyText={earnRedeem}
                fontSize={Types.size.small}
                element={Types.tags.div}
                customClass={clsx(
                  't-font-alt-s',
                  earnRedeem === 'Do not show' || earnRedeem === '' ? 'd-none' : 'd-block'
                )}
              />
            )}
            {!!michelinData?.title && earnRedeem !== 'Do not show' && earnRedeem !== '' && (
              <span
                className={clsx(
                  'mx-2 separator-outlet',
                  earnRedeem === 'Do not show' || earnRedeem === '' ? 'd-none' : 'd-none d-md-block'
                )}
              ></span>
            )}

            {!!michelinData?.title && (
              <div className="d-flex flex-row m-icon-text">
                {michelinData?.title !== props?.bibGourmandLabel &&
                  michelinData?.title !== props?.greenStarLabel &&
                  !!michelinData?.iconPath && (
                    <ul className="pr-1 pl-0 m-0 list-group-horizontal d-flex">{starIconArray}</ul>
                  )}
                {(michelinData?.title === props?.bibGourmandLabel || michelinData?.title === props?.greenStarLabel) &&
                  !!michelinData?.iconPath && <Icon iconClass={clsx(michelinData?.iconPath, 'icon-s pr-2 m-0')}></Icon>}
                {!!michelinData?.title && (
                  <Text
                    copyText={michelinData?.title}
                    fontSize={Types.size.small}
                    element={Types.tags.div}
                    customClass={clsx('t-font-alt-s ')}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* eslint-disable-next-line no-extra-boolean-cast */}
      {!!outletDetails?.basicInformation?.reservationURL && (
        <div className={clsx(isScrolled && isMobileViewPort ? 'sticky-reserve-button' : '')}>
          <Button
            testId="btn-test"
            type={Types.ButtonTypeVariation.Button}
            buttonCopy={props?.reserveNowLabel || ''}
            callback={handleReserveBtnClick}
            className={clsx(
              'm-button-m m-button-primary text-center',
              'custom_click_track',
              isDisabled ? 'disabled' : '',
              props.className,
              'outlet-primary-details__cta'
            )}
            isLink={true}
            target={'_blank'}
            href={outletDetails?.basicInformation?.reservationURL}
            custom_click_track_value={`${OUTLET_DETAILS}|${RESERVE_BUTTON}|${INTERNAL_LINK}`}
          ></Button>
        </div>
      )}
    </StyledOutletPrimaryDetails>
  );
};
