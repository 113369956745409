import { useEffect, FC, useState } from 'react';
import { StyledAuxiliarySection } from './OutletAuxiliarySection.styles';
import { Button } from '@marriott/mi-ui-library';
import { TARGET_BLANK, TRACKING_CONSTANT } from '../../../modules/utils/constants';
import { trackImpression, getClassForBreakpoint, useGetBreakpoint } from '../../../modules/utils/helpers';
import { OutletAuxiliarySectionProps } from './OutletAuxiliarySection.types';
import clsx from 'clsx';
import { getDirection } from '../../../modules/utils/helpers';

export const sortDays = (days: string[]): string[] => {
  const sortedDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  return days.sort((a, b) => sortedDays.indexOf(a) - sortedDays.indexOf(b));
};

export const OutletAuxiliarySection: FC<OutletAuxiliarySectionProps> = ({
  trackingProperties,
  iconPath,
  BonvoyBenefitsLabel,
  BonvoyBenefitsLabelCtaLink,
  BonvoyBenefitsLabelCtaText,
  BonvoyBenefitsDescription,
  hoursLabel,
  outletDataProps,
  experiencesLabel,
  iconPathExperiences,
}) => {
  const outletData = outletDataProps;

  const openingHours = outletData?.operationalDetails?.schedule || '';
  const experiences = outletData?.diningExperience?.experiences || [];
  const [breakpoint, setBreakpoint] = useState<string | undefined>(useGetBreakpoint());

  const sortedDays = sortDays(Object.keys(openingHours));
  const [allHoursNull, setAllHoursNull] = useState(true);
  const isDirectionRTL = getDirection() === 'rtl';
  const { OUTLET_AUXILIARY_SECTION, OUTLET_BONVOY_FIND_MORE_LINK, EXTERNAL_LINK } = TRACKING_CONSTANT;

  useEffect(() => {
    if (trackingProperties?.impressionTrack)
      trackImpression(trackingProperties || {}, BonvoyBenefitsLabelCtaText || '');
  }, [BonvoyBenefitsLabelCtaText, trackingProperties]);

  useEffect(() => {
    for (const day in openingHours) {
      if (openingHours[day] !== null) {
        setAllHoursNull(false); // Return false as soon as a non-null value is found
      } else {
        setAllHoursNull(true);
      }
    }
  }, [allHoursNull, openingHours]);

  // Reset breakpoint on window resize.
  useEffect(() => {
    const handleResize = () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setBreakpoint(useGetBreakpoint());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledAuxiliarySection data-testid="auxiliary-container"
      className={clsx('d-flex flex-column flex-md-row m-0', getClassForBreakpoint(breakpoint, 'px-6', 'px-6', 'px-3'))}
    >
      {/* {!!menuUrl && (
        <div className="auxiliary-item-first text-left">
          <p className="auxiliary-item-title d-flex flex-row mb-2 align-items-center t-font-weight-b">
            <span className="icon-dining"></span>
            {menuLabel || ''}
          </p>
          <p className="t-font-alt-s m-0">
            <Button
              isLink={true}
              href={menuUrl}
              target={TARGET_BLANK}
              className="auxiliary-link t-font-alt-s"
              aria-label={'Outlet Menu'}
            >
              {menuTypeLabel}
            </Button>
          </p>
        </div>
      )} */}
      {!!experiences.length && (
        <div className="auxiliary-item text-left" data-testid="experience-container" >
          <p
            className={clsx(
              'auxiliary-item-title d-flex flex-row mb-2 align-items-center t-font-weight-b',
              isDirectionRTL ? 'text-right' : 'text-left'
            )}
          >
            <span className={iconPathExperiences}></span>
            {experiencesLabel}
          </p>
          <div className={clsx('t-font-alt-s m-0', isDirectionRTL ? 'text-right' : 'text-left')}>
            <p className="m-0">
              {experiences?.length > 1
                ? experiences?.slice(0, -1)?.join(', ') + ' and ' + experiences?.slice(-1)
                : experiences?.join('')}
            </p>
          </div>
        </div>
      )}

      <div className="auxiliary-item text-left">
        <p
          className={clsx(
            'auxiliary-item-title d-flex flex-row mb-2 t-font-weight-b',
            isDirectionRTL ? 'text-right' : 'text-left'
          )}
        >
          <span className={iconPath}></span>
          {BonvoyBenefitsLabel}
        </p>
        <div className={clsx('t-font-alt-s m-0', isDirectionRTL ? 'text-right' : 'text-left')}>
          <p className="m-0">
            {BonvoyBenefitsDescription}{' '}
            <Button
              isLink={true}
              href={BonvoyBenefitsLabelCtaLink}
              target={TARGET_BLANK}
              className="auxiliary-link t-font-alt-s d-block"
              aria-label={'Find Out More'}
              custom_click_track_value={`${OUTLET_AUXILIARY_SECTION}|${OUTLET_BONVOY_FIND_MORE_LINK}|${EXTERNAL_LINK}`}
            >
              {BonvoyBenefitsLabelCtaText}
            </Button>
          </p>
        </div>
      </div>
      {!!sortedDays.length && !allHoursNull && (
        <div className="auxiliary-item text-left">
          <p
            className={clsx(
              'auxiliary-item-title d-flex flex-row mb-2 align-items-center t-font-weight-b',
              isDirectionRTL ? 'text-right' : 'text-left'
            )}
          >
            <span className="icon-clock"></span>
            {hoursLabel || ''}
          </p>
          <div className={clsx('t-font-alt-s m-0 hours-content', isDirectionRTL ? 'text-right' : 'text-left')}>
            {sortedDays.map(day => {
              if (
                day !== '__typename' &&
                day !== null &&
                (openingHours[day]?.hours || openingHours[day]?.isOpen24Hours)
              ) {
                if (openingHours[day]?.hours) {
                  const dayHours = openingHours[day]?.hours;
                  const formattedHours = dayHours
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .map((hourRange: any) => `${hourRange.open} - ${hourRange.close}`)
                    .join(', ');
                  return (
                    <p className="m-0 mb-1" key={day}>
                      {day.charAt(0).toUpperCase() + day.slice(1)} {formattedHours}
                    </p>
                  );
                } else if (openingHours[day]?.isOpen24Hours) {
                  return (
                    <p className="m-0 mb-1" key={day}>
                      {`${day.charAt(0).toUpperCase()}${day.slice(1)} 24hrs`}
                    </p>
                  );
                }
                return null;
              }
              return null;
            })}
          </div>
        </div>
      )}
    </StyledAuxiliarySection>
  );
};
