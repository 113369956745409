import React, { FC, useRef } from 'react';
import clsx from 'clsx';

import { Button, Image, Heading, headingType, size, Eyebrow, RichText } from '@marriott/mi-ui-library';
import { useDCADynamicModel } from '@marriott/mi-headless-utils';
import { canUseDOM, convertToCommaSeparatedNumber } from '@marriott/shared/mi-helper-utils';
import {
  MerchandisingSmallBannerMBoxProps,
  MerchandisingSmallBannerProps,
  ButtonStyle,
  TrackingPropsInterface,
} from './MerchandisingSmallBanner.types';
import { StyledMerchandisingSmallBanner } from './MerchandisingSmallBanner.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { getEVAR48 } from '@marriott/mi-headless-utils';
import { getSPID } from '@marriott/mi-headless-utils';

import DOMPurify from 'isomorphic-dompurify';
// import { useDatalayer } from '@marriott/mi-headless-utils';

declare global {
  interface Window {
    mvpOffers?: {
      specialMessage?: string;
      rpcCode?: string;
      offerCode?: string;
      source?: string;
    };
  }
}

export const MerchandisingSmallBannerComp: FC<
  MerchandisingSmallBannerProps & MerchandisingSmallBannerMBoxProps
> = props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { targetData } = props.mboxParameter ? useDCADynamicModel(props.mboxParameter) : { targetData: props.targetData };
  if (!props.mboxParameter) {
    targetData = props;
  }

  const { mboxParameter } = props;
  const {
    openInNewTab,
    ctaType,
    destinationUrl1,
    callToActionText1,
    headerLong,
    headerTag,
    descriptionLong,
    descriptionLongMobile,
    trackingProperties,
    styleclass,
    image,
    componentId,
    openInNewWindowAriaLabel = '',
    customDescriptionClass,
    customContainerClass,
    customImageClass,
    logo,
    isTargetContentLoaded = mboxParameter && !AuthoringUtils.isInEditor() ? false : true,
    isTargetContentError = false,
  } = props.mboxParameter && !AuthoringUtils.isInEditor() ? targetData : props;

  const {
    mathType = '',
    mathValue = '',
    mathTitle = '',
    mathLabel1 = '',
    mathLabel2 = '',
    mathLabel3 = '',
    legalLong = '',
  } = props.mboxParameter && !AuthoringUtils.isInEditor() ? targetData : props.targetData;

  // const { datalayer } = useDatalayer();
  const currency = 'USD'; // needs to be updated
  const renditions = image?.renditions;
  const MerchandisingSmallBannerRef = useRef(null) as unknown as React.MutableRefObject<HTMLInputElement>;
  const isHeader = headerLong !== undefined && headerLong.length > 0 ? true : false;
  const isBodyCopy = descriptionLong !== undefined && descriptionLong.length > 0 ? true : false;
  const isBodyCopyMobile = descriptionLongMobile !== undefined && descriptionLongMobile.length > 0 ? true : false;
  const isCurrencyMath = mathType?.toLowerCase() === 'currency';
  const isPointsMath = mathType?.toLowerCase() === 'points';
  const totalStayAmountCurrency = parseFloat('0'); //needs to be updated
  const totalStayAmountPoints = parseInt('0', 10); //needs to be updated
  const isCobrand =
    isTargetContentError || !props.mboxParameter
      ? props?.trackingProperties?.merchandisingCategory === 'cobrandNonPointsMath'
      : targetData?.metaData?.type === 'CoBrand';
  let totalPoints = 0;
  let totalCurrency = 0;

  if (isCurrencyMath) {
    const currency = mathValue ? parseFloat(mathValue.replace(/,/g, '')) : 0;
    totalCurrency = totalStayAmountCurrency + currency;
  }
  if (isPointsMath) {
    const bonusPoint = mathValue ? parseInt(mathValue.replace(/,/g, ''), 10) : 0;
    totalPoints = bonusPoint + totalStayAmountPoints;
  }

  const trackingPropertiesDynamic: TrackingPropsInterface = {
    trackingContentPosition: props?.trackingProperties?.trackingContentPosition ?? 'banner',
    trackingDescription: props?.trackingProperties?.trackingDescription ?? 'dca',
    trackingOfferType: props?.trackingProperties?.trackingOfferType ?? 'dca',
    trackingTag: targetData?.metaData?.merTag ?? props?.trackingProperties?.trackingTag,
    additionalTrackingVariables: targetData?.metaData?.optionalFields ? 'true' : 'false',
    atCCeVar48: canUseDOM
      ? isCobrand // If type in MBOP response is cobrand then construct evar48
        ? isTargetContentError || !mboxParameter
          ? props?.trackingProperties?.atCCeVar48
          : getEVAR48(trackingProperties, targetData)
        : '' // if type in MBOP response is non cobrand then pass empty in evar48
      : '',
    clickTrack: props.trackingProperties?.isClickTrack ?? 'true',
    impressionTrack: props.trackingProperties?.impressionTrack ?? 'true',
    impressionCount: props.trackingProperties?.impressionCount ?? 'true',
    isAuth: false, //needs to be updated
    merchandisingCategory: props?.trackingProperties?.merchandisingCategory,
    clickTrackValSeparator: ';',
    cell: targetData?.metaData?.cell || '',
    spid: getSPID(targetData),
    rpcCode: targetData?.metaData?.rpcCode || '',
    offerCode: targetData?.metaData?.offerCode || '',
    type: targetData?.metaData?.type || '',
    isDCAEnabled: true,
  };

  const sanitizedNode = DOMPurify.sanitize(descriptionLong, { RETURN_DOM: true });
  const fragmentString = sanitizedNode.innerHTML;

  const sanitizedNode1 = DOMPurify.sanitize(descriptionLongMobile, { RETURN_DOM: true });
  const fragmentString1 = sanitizedNode1.innerHTML;

  const removeParentTagRTE = (textRTE: string): string => {
    return textRTE.substring(textRTE.indexOf('>') + 1, textRTE.lastIndexOf('<')) || headerLong;
  };

  // Using removeParentTagRTE to clean the text recieved from RTE
  const headerRTEText = isHeader ? removeParentTagRTE(headerLong) : '';

  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      default:
        return '';
    }
  };

  const renderCobrandMathContentSection = (
    pointsOrStayTotalLabel: string,
    pointsOrStayTotalAmt: number,
    bonusOrStatementLabel: string,
    bonusOrStatementAmt: number,
    totalLabel: string,
    totalAmt: number,
    hasPoints: boolean
  ) => {
    return (
      <div className={clsx('d-flex flex-column cobrand-math-content-section mt-3 mt-lg-0 flex-grow-1')}>
        {hasPoints && (
          <div className={clsx('d-flex mb-1 justify-content-end')}>
            <Eyebrow text={mathTitle} />
          </div>
        )}
        <div className={clsx('d-flex mb-1 justify-content-between')}>
          <RichText text={pointsOrStayTotalLabel} componentId={'points-stay-total-label'} customClass="t-font-alt-xs" />
          <span className="t-font-s">
            {hasPoints
              ? convertToCommaSeparatedNumber(pointsOrStayTotalAmt)
              : `${convertToCommaSeparatedNumber(pointsOrStayTotalAmt)} ${currency}`}
          </span>
        </div>
        <div className={clsx('d-flex mb-1 justify-content-between')}>
          <RichText text={bonusOrStatementLabel} componentId={'bonus-statement-label'} customClass="t-font-alt-xs" />
          <span className="t-font-s">{hasPoints ? bonusOrStatementAmt : `${bonusOrStatementAmt} ${currency}`}</span>
        </div>
        <div className={clsx('d-flex justify-content-between')}>
          <RichText text={totalLabel} componentId={'total-label'} customClass="t-font-m" />
          <span className="t-font-m total-amount">
            {hasPoints
              ? convertToCommaSeparatedNumber(totalAmt)
              : `${convertToCommaSeparatedNumber(totalAmt)} ${currency}`}
          </span>
        </div>
        <div
          className={clsx(
            'col-12 px-0 d-flex align-self-center align-self-md-end mt-md-3',
            isTargetContentLoaded &&
              ((isCurrencyMath && currency) || isPointsMath
                ? `disclaimer-and-cta-section flex-column-reverse flex-md-row justify-content-end`
                : 'align-self-lg-center mt-lg-0')
          )}
        >
          {isTargetContentLoaded && legalLong && isCurrencyMath && currency && (
            <RichText
              text={legalLong}
              componentId={'disclaimer-richtext'}
              customClass="t-font-alt-xs disclaimer-text mt-md-2 d-md-none pb-5"
            />
          )}
          {callToActionText1 && isTargetContentLoaded && (
            <Button
              className={`${buttonTypeClasses} flexible-click m-button-s my-4 m-md-0 text-center pt-2`}
              href={destinationUrl1}
              isLink={true}
              target={openInNewTab === true ? '_blank' : '_self'}
              trackingProperties={trackingPropertiesDynamic}
              rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
              ref={MerchandisingSmallBannerRef}
              buttonCopy={callToActionText1}
              linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
              compName={props?.trackingProperties?.trackingContentPosition}
              data-is-click-track={props.trackingProperties?.isClickTrack}
            />
          )}
        </div>
      </div>
    );
  };

  const buttonTypeClasses = getButtonStyle(ctaType || '', openInNewTab);

  return (
    <StyledMerchandisingSmallBanner
      data-component-name="o-merch-MerchandisingSmallBanner"
      data-testid="MerchandisingSmallBanner"
      id={componentId}
      className={clsx(
        `container-fluid pt-md-5 pb-5`,
        `${styleclass ? styleclass : ''}`,
        isTargetContentError && (!props.displayEvergreenContent || String(props.displayEvergreenContent) === 'false')
          ? 'd-none'
          : ''
      )}
    >
      {isTargetContentError && (!props.displayEvergreenContent || String(props.displayEvergreenContent) === 'false') ? (
        ''
      ) : (
        <div className="container">
          <div
            className={clsx(
              'card-container d-flex align-items-center align-items-md-start flex-column flex-md-row px-4 p-md-4 t-background-color color-scheme1',
              customContainerClass,
              isTargetContentLoaded && ((isCurrencyMath && currency) || isPointsMath)
                ? 'card-math-container align-items-lg-start'
                : 'align-items-lg-center'
            )}
          >
            <div
              className={clsx('image-container d-md-flex justify-content-center general-top mt-md-0', customImageClass)}
            >
              {!isTargetContentLoaded ? (
                <div className="skeleton-loader merch-img-loader"></div>
              ) : (
                <Image
                  altText={isCobrand ? logo?.altText || image?.altText : image?.altText}
                  renditions={isCobrand ? [] : renditions}
                  dynamic={isCobrand ? false : image?.dynamic}
                  defaultImageURL={isCobrand ? (logo ? logo?.imageSrc : image?.assetPath) : image?.assetPath}
                />
              )}
            </div>
            <div
              className={clsx(
                'flexible-card-content d-flex flex-grow-1',
                isTargetContentLoaded && ((isCurrencyMath && currency) || isPointsMath)
                  ? 'flexible-content-section flex-column'
                  : 'justify-content-between align-items-center flex-column flex-md-row evergreen-content-section mt-3 mt-md-0'
              )}
            >
              <div
                className={clsx(
                  isTargetContentLoaded && ((isCurrencyMath && currency) || isPointsMath)
                    ? 'd-flex content-section flex-column flex-lg-row'
                    : 'flexible-card-content-container'
                )}
              >
                <div
                  className={clsx('flexible-card d-flex flex-column text-center text-md-left px-md-5 mx-md-5', {
                    ' flexible-math-card mt-3 mt-md-0':
                      isTargetContentLoaded && ((isCurrencyMath && currency) || isPointsMath),
                  })}
                >
                  {isHeader && (
                    <div>
                      {!isTargetContentLoaded ? (
                        <div className="mb-1 pb-5 skeleton-loader ps-5"></div>
                      ) : (
                        <Heading
                          customClass="mb-1 mb-md-0"
                          variation={headingType.subtitle}
                          fontSize={size.large}
                          element={headerTag}
                          dangerouslySet={headerRTEText}
                        />
                      )}
                    </div>
                  )}
                  {isBodyCopy && descriptionLong && (
                    <div>
                      {!isTargetContentLoaded ? (
                        <div className="skeleton-loader mb-1 pb-4 mb-md-0"></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: fragmentString }}
                          className={clsx(
                            'd-none d-sm-block mt-2 pr-md-2 t-font-alt-s',
                            { 'd-block': !descriptionLongMobile },
                            customDescriptionClass
                          )}
                        ></div>
                      )}
                    </div>
                  )}
                  {isBodyCopyMobile && descriptionLongMobile && (
                    <div
                      dangerouslySetInnerHTML={{ __html: fragmentString1 }}
                      className={clsx('d-block d-sm-none pr-md-2 t-font-alt-s')}
                    ></div>
                  )}
                  {isTargetContentLoaded && legalLong && isCurrencyMath && currency && (
                    <RichText
                      text={legalLong}
                      componentId={'disclaimer-richtext'}
                      customClass="t-font-alt-xs disclaimer-text mt-md-2 d-none d-md-block"
                    />
                  )}
                </div>
                {isTargetContentLoaded &&
                  isCurrencyMath &&
                  currency &&
                  renderCobrandMathContentSection(
                    mathLabel1,
                    totalStayAmountCurrency,
                    mathLabel2,
                    mathValue,
                    mathLabel3,
                    totalCurrency,
                    false
                  )}
                {isTargetContentLoaded &&
                  isPointsMath &&
                  renderCobrandMathContentSection(
                    mathLabel1,
                    totalStayAmountPoints,
                    mathLabel2,
                    mathValue,
                    mathLabel3,
                    totalPoints,
                    true
                  )}
                {callToActionText1 && isTargetContentLoaded && !isPointsMath && !isCurrencyMath && (
                  <div
                    className={clsx(
                      'col-sm-12 col-md-3 col-lg-2 px-0 px-xl-3 px-lg-1 d-flex align-self-center mt-md-3 align-self-lg-center mt-lg-0'
                    )}
                  >
                    <Button
                      className={`${buttonTypeClasses} flexible-click m-button-s col-12 my-4 m-md-0 text-center pt-2`}
                      href={destinationUrl1}
                      isLink={true}
                      target={openInNewTab === true ? '_blank' : '_self'}
                      trackingProperties={trackingPropertiesDynamic}
                      rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
                      ref={MerchandisingSmallBannerRef}
                      buttonCopy={callToActionText1}
                      linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
                      compName={props?.trackingProperties?.trackingContentPosition}
                      data-is-click-track={props.trackingProperties?.isClickTrack}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledMerchandisingSmallBanner>
  );
};

export const MerchandisingSmallBannerConfig = {
  emptyLabel: 'MerchandisingSmallBanner',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/merchandisingsmallbanner`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MerchandisingSmallBanner = (props: any) => {
  return (
    <EditableComponent config={MerchandisingSmallBannerConfig} {...props}>
      <MerchandisingSmallBannerComp {...props} />
    </EditableComponent>
  );
};
