import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, mediaQuery } = baseVariables;

export const StyledPlannerEventCard = styled.div`
  background-color: ${color.base20};
  padding: ${toRem(24)};

  .event-card-container {
    flex-wrap: wrap;
    gap: ${toRem(32)};

    @media ${mediaQuery.lg} {
      flex-wrap: nowrap;
    }

    .event-image {
      margin: 0 auto;
      height: ${toRem(162)};
      position: relative;
      width: ${toRem(244)};

      &.alternate-image {
        background-color: ${color.neutral30};
      }

      img {
        display: block;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .event-card-details {
      flex: 1;
      gap: ${toRem(20)};

      .hotel-info-container {
        gap: ${toRem(20)};

        .hotel-info {
          gap: ${toRem(20)};
        }

        .select-cta {
          @media ${mediaQuery.sm} {
            margin-left: auto;
          }
        }
      }
    }
  }

  .associate-action {
    width: 100%;
    border-top: ${toRem(1)} solid ${color.neutral40};
  }
`;
