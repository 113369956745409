// Styles for PropertyPortalExperienceFragment go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

export const StyledPropertyPortalExperienceFragment = styled.div`
  ${rtl`
  .glide__arrows {
    [class^=icon-] {
      color: inherit;
    }
  }`}
`;
