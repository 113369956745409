import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledUserInfo = styled.div`
  .title {
    padding-bottom: ${toRem(40)};
  }

  .calling-code {
    ul {
      width: ${toRem(300)};
      li {
        white-space: break-spaces;
        .dropdown-option {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
`;
