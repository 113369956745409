import { FC } from 'react';
import { Heading, Types } from '@marriott/mi-ui-library';
import { EventOverviewProps } from './EventOverview.types';
import { StyledEventOverview } from './EventOverview.styles';
import { Controller } from 'react-hook-form';
import { TextFormField } from '../TextFormField';

export const EventOverview: FC<EventOverviewProps> = props => {
  const { labels, control, errors, clearErrors } = props;
  const { title, eventName } = labels;

  const eventNameField = (
    <Controller
      name="eventInfo.eventName"
      control={control}
      rules={{
        required: eventName.requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={eventName.label}
          ariaLabel={eventName.ariaLabel}
          className="m-input-field"
          maxLength={eventName.maxLength}
          showErrorMessage={!!errors?.eventInfo?.eventName?.message}
          errorMessage={errors?.eventInfo?.eventName?.message}
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  return (
    <StyledEventOverview data-component-name="m-groups-EventOverview" data-testid="groups-EventOverview">
      <Heading
        variation={Types.headingType.subtitle}
        element={Types.tags.div}
        titleText={title}
        fontSize={Types.size.large}
      />
      <div className="row mt-5">
        <div className="col-md-6 pb-5">{eventNameField}</div>
      </div>
    </StyledEventOverview>
  );
};
