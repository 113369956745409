import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

const { font } = baseVariables;

export const StyledBillingTypeSelection = styled.div`
  label {
    width: fit-content;
    margin-bottom: 0;
  }
  .personalized-content {
    label {
      font-size: ${font.fontXs};
    }
    .whats-this-cta {
      background: transparent;
      text-decoration: underline;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto ${toRem(1)};
      }
    }
  }
`;
