import { FC, useEffect, useState, useRef } from 'react';
import { connectBtnTrigger, getEnrolledUserFlag } from '../../utils/handler';
import { LoginModalType } from '../../InternetBar.types';
import { InternetBarLoginModalProps } from './InternetBarModal.types';
import { Button } from '@marriott/mi-ui-library';
import { ButtonTypeVariation } from '../../../../modules/utils/enums/enums';

export const InternetBarLoyaltyWelcomeModal: FC<InternetBarLoginModalProps> = (props: InternetBarLoginModalProps) => {
  const { internetBarDataValues, currentStateMapping } = props;
  const login = props?.login as LoginModalType;
  const isNoLoginInterfaceModal = currentStateMapping?.noLoginInterfaceModal ?? false;
  const loyaltyLoginConnectNowButtonRef = useRef(null);

  const [isUserEnrolled, setIsUserEnrolled] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const { ppv5Enrollment = false, loyaltyEnrollmentSourceCode } = internetBarDataValues || {};

  useEffect(() => {
    const enrolled = getEnrolledUserFlag(loyaltyEnrollmentSourceCode);
    setIsUserEnrolled(enrolled);

    const timeout = setTimeout(() => {
      if (loyaltyLoginConnectNowButtonRef && loyaltyLoginConnectNowButtonRef.current) {
        (loyaltyLoginConnectNowButtonRef?.current as HTMLInputElement)?.focus();
      }
    }, 20);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="body-container d-flex flex-column">
      {isUserEnrolled && ppv5Enrollment ? (
        <>
          {login?.loyaltyLoginWelcomeText && <h6 className="t-subtitle-m">{login?.loyaltyLoginWelcomeText}</h6>}
          {login?.loyaltyLoginDescription && <p className="t-font-xs pb-3">{login?.loyaltyLoginDescription}</p>}
        </>
      ) : null}

      <Button
        isLink={false}
        type={ButtonTypeVariation.Button}
        className={`m-button-m m-button-primary t-font-m ${isButtonLoading ? 'disabled' : ''}`}
        isDisabled={isButtonLoading}
        testId="loyalty-login-connect-now-btn"
        children={
          isButtonLoading ? (
            <div className="m-spinner-s" data-testid="activate-spinner"></div>
          ) : (
            login?.loyaltyLoginConnectNowButton
          )
        }
        ref={loyaltyLoginConnectNowButtonRef}
        callback={() => {
          setIsButtonLoading(true);
          connectBtnTrigger(isNoLoginInterfaceModal, internetBarDataValues);
        }}
      />
    </div>
  );
};
