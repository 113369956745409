import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { Text } from '@marriott/mi-ui-library';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import { PageParamsContext } from '../../../../modules/context/PageContext';
import { countryCodeDetails } from '../../../../modules/graphql/index';
import { size, tags } from '../../../../modules/utils/enums/enums';
import { submitDynamicForm } from '../../utils/handler';
import { InternetBarReqAccessModalProps } from './InternetBarModal.types';
import { Button, InputTextField } from '@marriott/mi-ui-library';
import { ButtonTypeVariation } from '../../../../modules/utils/enums/enums';
import { GuidLinkType } from '../../InternetBar.types';

export const InternetBarReqAccessModal: FC<InternetBarReqAccessModalProps> = (
  props: InternetBarReqAccessModalProps
) => {
  const {
    reqAccess,
    reqAccessModal,
    internetBarDataValues,
    reqAccessModalType,
    showDropdown,
    setShowDropdown,
    dropdownRef,
  } = props;
  const [returnToLogin, setReturnToLogin] = useState<string>('');

  const [DropdownSearchValue, setDropdownSearchValue] = useState('');
  const [ButtonValue, setButtonValue] = useState(reqAccess?.defaultOption);
  const [countryCode, setCountryCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [reqSMSModalError, setReqSMSModalError] = useState<boolean>(reqAccessModalType === 'REQSMSERR' ? true : false);
  const [reqSMSError, setReqSMSError] = useState<boolean>(reqAccessModalType === 'REQSMSERR' ? true : false);
  const [phoneFormatError, setPhoneFormatError] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const [countriesArray, setCountriesArray] = useState(reqAccess?.guidLinks);
  const [filterCountriesArray, setFilterCountriesArray] = useState(reqAccess?.guidLinks);

  const inputRef = useRef<HTMLInputElement>(null);
  const countryCodeRef = useRef(null);

  const isServer = !(typeof window != 'undefined' && window.document);
  const { preProcessorResponse, sessionID, requestId, lang } = useContext(PageParamsContext);

  const { data } = useQuery(countryCodeDetails, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: preProcessorResponse?.marsha ?? '',
      filter: 'PHONE',
    },
    context: {
      headers: {
        //Update the variables of AriesReservation for ppv5
        'accept-language': lang ?? 'en-US',
        'x-b3-traceId': sessionID ?? 'fallback-token',
        'x-b3-spanId': requestId !== '' ? requestId : `${Date.now()}`,
        'correlation-id': sessionID ?? 'fallback-token',
      },
    },
  });

  const {
    ACTION = '',
    MARSHA = '',
    postEndpoint = '',
    LR = '',
    Z = '',
    DOMAIN = '',
    MS,
    APS,
    zoneMapping = '',
  } = internetBarDataValues || {};

  useEffect(() => {
    const handleQueryParamUpdate = (paramName: string, paramValue: string) => {
      const accessParams = new URLSearchParams(window.location.search);
      accessParams.set(paramName, paramValue);
      return `?${accessParams.toString()}`;
    };

    const hrefURL = handleQueryParamUpdate('MV', 'LOGIN');
    setReturnToLogin(hrefURL);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countryCodeRef && countryCodeRef?.current) {
        (countryCodeRef?.current as HTMLInputElement)?.focus();
      }
    }, 20);
    return () => clearTimeout(timeout);
  }, []);

  const reqSMStBtnTrigger = () => {
    setIsButtonLoading(true);
    if (postEndpoint && countryCode) {
      const phoneNumber = (`+${countryCode}` ?? '') + (mobileNumber ?? '');
      const number = parsePhoneNumber(phoneNumber);
      try {
        const obj = {
          MARSHA: MARSHA ? MARSHA : undefined,
          LR: LR,
          Z: Z,
          ACTION: ACTION ? ACTION : undefined,
          DOMAIN: DOMAIN ? DOMAIN : undefined,
          MS: MS || MS === '' ? MS : undefined,
          APS: APS,
          CODE: number?.countryCallingCode,
          PHONE_NUMBER: number?.nationalNumber,
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, postEndpoint);
      } catch (error) {
        // ignore
      }
    }
  };

  const handleDropdownSearch = (value: string) => {
    const tempArray =
      countriesArray &&
      countriesArray?.filter(item => {
        return item?.guidDecode?.toLowerCase()?.startsWith(value?.toLowerCase());
      });
    setFilterCountriesArray(tempArray);
  };

  useEffect(() => {
    if (reqAccessModalType === 'REQSMS' || reqAccessModalType === 'REQSMSERR') {
      const countryCode = data?.property?.contactInformation?.address?.country?.code || '';
      const prorityList = reqAccess?.priorityCountries ?? [];
      const countriesList: Array<{
        guidDecode: string;
        guidCode: string;
        guidAltDecode: string;
        'jcr:primaryType': string;
      }> = reqAccess?.guidLinks ?? [];
      const prorityCountryObject =
        prorityList?.find(
          (item: { guidAltDecode: string }) => item?.guidAltDecode?.toUpperCase() === countryCode?.toUpperCase()
        ) || '';
      const chinaCountry = prorityCountryObject
        ? prorityList?.find((item: { guidAltDecode: string }) => item?.guidAltDecode?.toUpperCase() === 'CN')
        : '';
      if (prorityCountryObject && chinaCountry) {
        if (prorityCountryObject?.guidAltDecode === chinaCountry?.guidAltDecode) {
          setCountriesArray([chinaCountry, ...countriesList]);
          setFilterCountriesArray([chinaCountry, ...countriesList]);
        } else {
          setCountriesArray([chinaCountry, prorityCountryObject, ...countriesList]);
          setFilterCountriesArray([chinaCountry, prorityCountryObject, ...countriesList]);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    showDropdown && inputRef && inputRef?.current && inputRef?.current?.focus();
  }, [showDropdown]);

  return (
    <>
      {/* 1. Internet Bar Req Access Modal Default Configuration */}
      <div className="d-flex flex-column">
        {/* 1.1 Internet Bar Req Access Modal Error */}
        {reqAccessModal?.racErrorMessage && reqSMSModalError && (
          <div className="d-flex pb-2">
            <span className={clsx(`col-12 px-3 py-3 mb-4 t-font-s internetbarmodal__error`)}>
              {reqAccess?.racErrorMessage}
            </span>
          </div>
        )}
        <span className="t-subtitle-m pb-2">{reqAccess?.requestAccessCodeLabel}</span>
        <Text
          customClass="pb-4"
          copyText={reqAccess?.requiredLabel || ''}
          fontSize={size?.extraSmall}
          element={tags?.span}
        />
        <div ref={dropdownRef}>
          <div className="m-form-group pb-5 mb-3">
            <label htmlFor={reqAccess?.countryCode} className="t-overline-normal mb-2">
              {reqAccess?.countryCode}
            </label>
            <div data-search-placeholder={reqAccess?.searchCountryRegionLabel} data-search-country="true">
              <Button
                isLink={false}
                type={ButtonTypeVariation?.Button}
                className={clsx(
                  `d-flex justify-content-between pt-0 pb-2 px-0 internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__dropdownbtn`
                )}
                callback={() => {
                  setIsButtonLoading(false);
                  if (showDropdown) {
                    setShowDropdown !== undefined && setShowDropdown(false);
                  } else {
                    setShowDropdown !== undefined && setShowDropdown(true);
                  }
                }}
                ref={countryCodeRef}
                testId="country-code-btn"
                children={
                  <>
                    <span className="t-font-m pb-1">{ButtonValue}</span>
                    <span className={clsx(showDropdown ? 'icon-arrow-down' : 'icon-arrow-up')}></span>
                  </>
                }
              />
              {showDropdown && (
                <ul
                  className={clsx(
                    `mt-3 p-0 mb-0 internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__dropdownlist`
                  )}
                  data-testid="country-code-list"
                >
                  <div
                    className={clsx(
                      `pt-3 pb-3 px-4 internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__dropdownsearch`
                    )}
                  >
                    <input
                      className={clsx(
                        `internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__searchinput`
                      )}
                      placeholder={reqAccess?.searchCountryRegionLabel}
                      ref={inputRef}
                      value={DropdownSearchValue}
                      onChange={event => {
                        event?.stopPropagation();
                        setDropdownSearchValue(event?.target?.value);
                        handleDropdownSearch(event?.target?.value);
                      }}
                      data-testid="country-search"
                    />
                    <span
                      className={clsx(
                        `icon-clear internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__dropdownclosebtn`
                      )}
                      onClick={event => {
                        event?.stopPropagation();
                        setDropdownSearchValue('');
                        setFilterCountriesArray(countriesArray);
                      }}
                    ></span>
                  </div>
                  {filterCountriesArray &&
                    filterCountriesArray?.map((element: GuidLinkType, filteredCountryIndex: number) => {
                      return (
                        <li
                          className={clsx(
                            `pt-3 pb-3 d-flex justify-content-between align-items-center internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__dropdownitems`
                          )}
                          onClick={event => {
                            event?.stopPropagation();
                            setButtonValue(element?.guidDecode);
                            setCountryCode(element?.guidCode);
                            setShowDropdown !== undefined && setShowDropdown(false);
                            setMobileNumber('');
                            setReqSMSModalError(false);
                          }}
                          tabIndex={0}
                          onKeyDown={event => {
                            if (event?.code?.toLowerCase() === 'enter') {
                              event?.stopPropagation();
                              setButtonValue(element?.guidDecode);
                              setCountryCode(element?.guidCode);
                              setShowDropdown !== undefined && setShowDropdown(false);
                              setMobileNumber('');
                              setReqSMSModalError(false);
                            } else if (
                              filteredCountryIndex === filterCountriesArray?.length - 1 &&
                              event?.code?.toLowerCase() === 'tab'
                            ) {
                              setShowDropdown !== undefined && setShowDropdown(false);
                            }
                          }}
                        >
                          <span data-code={element?.guidCode}>{element?.guidDecode}</span>
                          <span className="icon-forward-arrow arrow ml-2 pr-3"></span>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            `m-form-group pb-5 mb-3 internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__phonenumbercontainer`
          )}
        >
          {countryCode !== '' ? (
            <>
              <label htmlFor="phoneNumber" className="t-overline-normal">
                {reqAccess?.enterYourPhoneNumber}
              </label>
              <div className="d-flex">
                <InputTextField
                  type="text"
                  inputTextFieldClassName={`t-font-m px-0 pb-2 internetbarmodal-${reqAccessModalType} internetbarmodal-${reqAccessModalType}__countrycode`}
                  showIcon={false}
                  variation="line-type"
                  showUnderline={true}
                  hideFontClass={true}
                  getLabelProps={() => ({
                    className: 'd-none',
                  })}
                  inputValue={`+${countryCode}`}
                  assignInputValue={true}
                  testId="country-code"
                  onFocus={() => setIsButtonLoading(false)}
                />
                <InputTextField
                  type="text"
                  testId="req-sms-phone"
                  getInputValue={value => {
                    const updatedValue = value?.replace(/[^0-9]/g, '').trim();
                    const combinedNumber = (`+${countryCode}` ?? '') + updatedValue;
                    const number = parsePhoneNumber(combinedNumber);
                    const formattedNumber = new AsYouType().input(combinedNumber);
                    const updatedNumber = formattedNumber.replace(`+${countryCode}` ?? '', '');
                    if (number?.isPossible()) {
                      setPhoneFormatError(false);
                    } else {
                      setPhoneFormatError(true);
                    }
                    setMobileNumber(updatedNumber);
                  }}
                  onFocus={() => setIsButtonLoading(false)}
                  getLabelProps={() => ({
                    className: 'd-none',
                  })}
                  classNameForLineType="flex-fill"
                  inputTextFieldClassName={clsx(`t-font-m pb-2 px-0`)}
                  showIcon={false}
                  variation="line-type"
                  showUnderline={true}
                  hideFontClass={true}
                  inputValue={mobileNumber}
                  assignInputValue={true}
                />
              </div>
            </>
          ) : (
            <InputTextField
              type="number"
              getInputValue={value => {
                setMobileNumber(value);
              }}
              getLabelProps={() => ({
                className: 't-overline-normal',
              })}
              onBlur={() => {
                if (reqSMSModalError) {
                  setReqSMSError(true);
                } else {
                  setReqSMSError(false);
                }
              }}
              onFocus={() => {
                setReqSMSError(false);
              }}
              classNameForLineType="m-form-group mb-0"
              inputTextFieldClassName={`t-font-m px-0 pb-2 ${reqSMSError ? 'is-error' : ''}`}
              showIcon={false}
              showErrorMessage={reqSMSError}
              label={reqAccess?.enterYourPhoneNumber}
              variation="line-type"
              showUnderline={true}
              hideFontClass={true}
              inputValue={mobileNumber}
              assignInputValue={true}
            />
          )}
        </div>
        <Button
          isLink={false}
          type={ButtonTypeVariation.Button}
          className={clsx(
            `m-button-m m-button-primary t-font-m`,
            ButtonValue === reqAccess?.defaultOption || mobileNumber === '' || isButtonLoading ? 'disabled' : '',
            phoneFormatError ? 'disabled' : ''
          )}
          isDisabled={
            ButtonValue === reqAccess?.defaultOption || mobileNumber === '' || phoneFormatError || isButtonLoading
          }
          testId="request-sms-btn"
          children={
            isButtonLoading ? (
              <div className="m-spinner-s" data-testid="activate-spinner"></div>
            ) : (
              reqAccess?.racContinueButtonLabel
            )
          }
          callback={reqSMStBtnTrigger}
        />
      </div>
      <div className="d-flex flex-column">
        {/* 2. Internet Bar Req Access Modal Separator Configuration  */}
        <span className="py-2 my-1 d-flex flex-row align-items-center justify-content-center">
          <span className="w-100 internetbarmodal__seperator"></span>
          <span className="px-2 mx-1 t-font-xs">{reqAccess?.racSeparatorLabel}</span>
          <span className="w-100 internetbarmodal__seperator"></span>
        </span>
        {/* 3. Internet Bar Access Modal Return Code Configuration  */}
        <div className="d-flex flex-column mx-auto">
          <a href={returnToLogin} className="t-font-s mb-1 internetbarmodal__link">
            {reqAccess?.racReturnLabel}
          </a>
        </div>
      </div>
    </>
  );
};
