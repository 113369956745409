import { ChangeEvent, FC, useId, useRef } from 'react';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { FileUploadProps, FileErrorType } from './FileUpload.types';

export const FileUpload: FC<FileUploadProps> = ({
  ctaLabel,
  ctaClass,
  disabled = false,
  allowedFileTypes,
  maxSize,
  onFileSelected,
  onError,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inputId = useId();

  const validateFile = (file: File): boolean => {
    if (maxSize) {
      if (file.size >= maxSize) {
        onError(FileErrorType.SIZE_ERROR);
        return false;
      }
    }
    if (allowedFileTypes) {
      const fileType = file.name.split('.').pop();
      if (!fileType || !allowedFileTypes.includes(`.${fileType.toLowerCase()}`)) {
        onError(FileErrorType.FORMAT_ERROR);
        return false;
      }
    }
    return true;
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (validateFile(file)) {
        onFileSelected(file);
        onError(FileErrorType.NO_ERROR);
      }
    }
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div data-component-name="m-groups-FileUpload" data-testid="groups-FileUpload">
      <label htmlFor={inputId} className="sr-only d-none">
        {ctaLabel}
      </label>
      <input
        id={inputId}
        type="file"
        className="d-none"
        ref={fileInputRef}
        {...(allowedFileTypes && { accept: allowedFileTypes.join(', ') })}
        data-testid="file-input"
        onChange={handleFileChange}
        onClick={event => {
          (event.target as HTMLInputElement).value = '';
        }}
      />
      <Button
        buttonCopy={ctaLabel}
        className={clsx('m-button-secondary', disabled && 'disabled', ctaClass)}
        isDisabled={disabled}
        callback={handleFileUpload}
      />
    </div>
  );
};
