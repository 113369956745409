import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledSearchButtonPill = styled.div`
  flex: 0 0 max-content;
  display: flex;

  .search-button-pill {
    white-space: nowrap;
    button {
      height: auto;
      width: auto;
      border-radius: ${toRem(16)};
      padding: ${toRem(8)} ${toRem(16)};
      text-align: center;
      background-color: ${baseVariables.color.neutral30};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.08);
      cursor: context-menu;
    }

    .clear-button {
      cursor: pointer;
    }
  }
`;
