import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Controller, useFormState } from 'react-hook-form';
import { Button, RichText } from '@marriott/mi-ui-library';
import { CheckBoxFormField } from '../CheckBoxFormField';
import { PasswordField } from '../PasswordField';
import { TextFormField } from '../TextFormField';
import { InlineEnrolmentProps } from './InlineEnrolment.types';
import { StyledInlineEnrolment } from './InlineEnrolment.styles';
import { TncModal } from './TncModal';
import { TncContent } from './TncModal/TncModal.types';

export const InlineEnrolment: FC<InlineEnrolmentProps> = props => {
  const {
    labels,
    isJoinNowOpted,
    openTncInModal = false,
    control,
    getValues,
    setValue,
    resetField,
    errors,
    triggerError,
  } = props;
  const {
    title,
    joinOptionLabel,
    createPassword,
    confirmPassword,
    passwordChecks,
    rememberMeLabel,
    updatesLabel,
    offersLabel,
    tnc,
    termsAndConditions,
    loyaltyTerms,
    privacyStatement,
    dataProtection,
  } = labels;

  const { touchedFields, isSubmitted } = useFormState({ control });

  const [isJoinBonvoyChecked, setIsJoinBonvoyChecked] = useState(false);
  const [showLoyaltyTermsModal, setShowLoyaltyTermsModal] = useState(false);
  const [showPrivacyStatementModal, setShowPrivacyStatementModal] = useState(false);
  const [showDataProtectionModal, setShowDataProtectionModal] = useState(false);

  useEffect(() => {
    if (isJoinNowOpted) {
      setIsJoinBonvoyChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJoinNowOpted]);

  useEffect(() => {
    if (!isJoinBonvoyChecked) {
      resetField('inlineEnrolment');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJoinBonvoyChecked]);

  const isPasswordFieldInvalid =
    control.getFieldState('inlineEnrolment.createPassword').invalid || !getValues('inlineEnrolment.createPassword');

  const joinMarriottBonvoyOption = (
    <Controller
      name="inlineEnrolment.joinMarriottBonvoy"
      control={control}
      render={({ field }) => (
        <CheckBoxFormField
          {...field}
          options={[{ label: joinOptionLabel, value: 'yes' }]}
          colLength={12}
          value={isJoinBonvoyChecked ? 'yes' : ''}
          onChange={value => {
            setIsJoinBonvoyChecked(!!value);
            setValue(field.name, !!value);
          }}
        />
      )}
    />
  );

  const createPasswordField = (
    <PasswordField
      name="inlineEnrolment.createPassword"
      control={control}
      labels={createPassword}
      showErrorMessage={errors?.inlineEnrolment?.createPassword?.type === 'custom'}
      passwordChecks={passwordChecks}
      triggerError={() => {
        triggerError('inlineEnrolment.createPassword');
        if (touchedFields['inlineEnrolment']?.confirmPassword) {
          getValues('inlineEnrolment.createPassword')
            ? triggerError('inlineEnrolment.confirmPassword')
            : resetField('inlineEnrolment.confirmPassword');
        }
        isSubmitted && resetField('inlineEnrolment.confirmPassword');
      }}
    />
  );

  const confirmPasswordField = (
    <Controller
      name="inlineEnrolment.confirmPassword"
      control={control}
      rules={{
        required: isPasswordFieldInvalid ? true : confirmPassword.requiredError,
        validate: (value: string) =>
          value === getValues('inlineEnrolment.createPassword') ? true : confirmPassword.matchError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="password"
          testId="confirm-password"
          label={confirmPassword.label}
          ariaLabel={confirmPassword.ariaLabel}
          disabled={isPasswordFieldInvalid}
          maxLength={confirmPassword.maxLength}
          className="m-input-field"
          showErrorMessage={!!errors?.inlineEnrolment?.confirmPassword?.message}
          errorMessage={errors?.inlineEnrolment?.confirmPassword?.message}
          onChange={event => {
            field.onChange(event);
            triggerError('inlineEnrolment.confirmPassword');
          }}
        />
      )}
    />
  );

  const rememberMeOption = (
    <Controller
      name="inlineEnrolment.rememberMe"
      control={control}
      render={({ field }) => (
        <CheckBoxFormField
          {...field}
          colLength={12}
          options={[{ label: rememberMeLabel, value: 'yes' }]}
          value={field.value ? 'yes' : ''}
          onChange={value => {
            setValue(field.name, !!value);
          }}
        />
      )}
    />
  );

  const receiveUpdatesOption = (
    <Controller
      name="inlineEnrolment.receiveUpdates"
      control={control}
      render={({ field }) => (
        <CheckBoxFormField
          {...field}
          colLength={12}
          options={[{ label: updatesLabel, value: 'yes' }]}
          value={field.value ? 'yes' : ''}
          onChange={value => {
            setValue(field.name, !!value);
          }}
        />
      )}
    />
  );

  const receiveOffersOption = (
    <Controller
      name="inlineEnrolment.receiveOffers"
      control={control}
      render={({ field }) => (
        <CheckBoxFormField
          {...field}
          colLength={12}
          options={[{ label: offersLabel, value: 'yes' }]}
          value={field.value ? 'yes' : ''}
          onChange={value => {
            setValue(field.name, !!value);
          }}
        />
      )}
    />
  );

  return (
    <StyledInlineEnrolment
      data-component-name="m-groups-InlineEnrolment"
      data-testid="groups-InlineEnrolment"
      // data-testid="inline-enrolment"
    >
      <div className="t-subtitle-l pt-2 pb-2">{title}</div>
      <div className={clsx('row', isJoinBonvoyChecked ? 'pb-4' : 'join-bonvoy')}>
        <div className="col-12">{joinMarriottBonvoyOption}</div>
      </div>

      {isJoinBonvoyChecked && (
        <>
          <div className="row">
            <div className="col-md-6 pb-5 pb-md-0">{createPasswordField}</div>
            <div className="col-md-6">{confirmPasswordField}</div>
          </div>
          <div className="row pb-4">
            <div className="col-12">{rememberMeOption}</div>
          </div>
          <div className="row">
            <div className="col-12">{receiveUpdatesOption}</div>
          </div>
          <div className="row pb-3">
            <div className="col-12">{receiveOffersOption}</div>
          </div>

          {openTncInModal ? (
            <div className="row align-items-center t-font-xs mx-0 tnc">
              <div className="mr-1">{termsAndConditions?.signUp}</div>

              <Button
                buttonCopy={termsAndConditions?.loyaltyTerms}
                className="m-link-action mx-1 px-0"
                callback={() => setShowLoyaltyTermsModal(true)}
              />
              {showLoyaltyTermsModal && (
                <TncModal
                  labels={loyaltyTerms as TncContent}
                  show={showLoyaltyTermsModal}
                  onCloseModal={() => setShowLoyaltyTermsModal(false)}
                />
              )}

              <Button
                buttonCopy={termsAndConditions?.privacyStatement}
                className="m-link-action mx-1 px-0"
                callback={() => setShowPrivacyStatementModal(true)}
              />
              {showPrivacyStatementModal && (
                <TncModal
                  labels={privacyStatement as TncContent}
                  show={showPrivacyStatementModal}
                  onCloseModal={() => setShowPrivacyStatementModal(false)}
                />
              )}

              <div>{termsAndConditions?.and}</div>

              <Button
                buttonCopy={termsAndConditions?.dataProtection}
                className="m-link-action mx-1 px-0"
                callback={() => setShowDataProtectionModal(true)}
              />
              {showDataProtectionModal && (
                <TncModal
                  labels={dataProtection as TncContent}
                  show={showDataProtectionModal}
                  onCloseModal={() => setShowDataProtectionModal(false)}
                />
              )}
            </div>
          ) : (
            <div className="row">
              <RichText text={tnc} customClass="col-12 t-font-xs" componentId={'termsAndConditions'}></RichText>
            </div>
          )}
        </>
      )}
    </StyledInlineEnrolment>
  );
};
