import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { createGlobalStyle } from 'styled-components';
import { constants } from '../../modules/utils/constants/constants';
import rtl from 'styled-components-rtl';

export const StyledHeroBanner = styled.div<{ styleClass: string }>`
  img {
    min-width: 100vw;
    @media ${baseVariables.mediaQuery.sm} {
      height: ${toRem(400)};
    }
    @media ${baseVariables.mediaQuery.md} {
      height: ${toRem(510)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      height: auto;
    }
  }
  ${rtl`
  &:is(${constants.RTL_LANG_LIST}){
    .bl_iconText-wrapper {
      left: 0;
      background: none;

      @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${
    baseVariables.mediaQuery.xl
  } {
      left: ${toRem(30)};
      }
    }

    .bl__cnt-ctr {
      text-align: right;
    }

    .banner-layerd-anchor {
      padding-right: ${toRem(8)};
    }

    .gradient {
      &:after {
        background: ${(props: { styleClass: string }) =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient05']}`
            : `${baseVariables.gradient['baseGradient01']}`};
        @media ${baseVariables.mediaQuery.sm} {
          background: ${(props: { styleClass: string }) =>
            props?.styleClass?.includes('inverse')
              ? 'linear-gradient(90deg,rgba(0,0,0,0) 26.95%,rgba(0,0,0,0.54) 67.69%,rgba(0,0,0,0.62) 100%)'
              : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 26.95%, rgba(255, 255, 255, 0.46) 66.99%, rgba(255, 255, 255, 0.54) 100%)'};
        }
        @media ${baseVariables.mediaQuery.lg} {
          background: ${(props: { styleClass: string }) =>
            props?.styleClass?.includes('inverse')
              ? 'linear-gradient(90deg,rgba(0,0,0,0) 26.95%,rgba(0,0,0,0.54) 67.69%,rgba(0,0,0,0.62) 100%)'
              : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 26.95%, rgba(255, 255, 255, 0.46) 66.99%, rgba(255, 255, 255, 0.54) 100%)'};
        }
      }
    }
  }
`}
`;

interface GlobalStyleProps {
  userId?: string;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  .rnb-additional-container {
    position: absolute;
    z-index: 2;
    top: ${toRem(54)};
    left: ${toRem(16)};
    right: ${toRem(16)};
    margin: 0 auto !important;
    width: calc(100% - ${toRem(32)}); 

    @media ${baseVariables.mediaQuery.sm}, @media ${baseVariables.mediaQuery.md} {
      top: ${toRem(60)};
    } 
    @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
      top: ${toRem(116)};
    }
}
  .bl-item img{
    aspect-ratio: 9/16;
    @media ${baseVariables.mediaQuery.sm} {
      aspect-ratio: 5/2;
    }
    @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
      min-height: 576px;
    }
  }

  .bl_content {
    padding: 0px;
  }

  .rnb-error-container {
      position: absolute;
      z-index:1;
      top: ${props => (props.userId ? `${toRem(170)}` : `${toRem(150)}`)};
      left: 0;
      right: 0;
      margin: 0 auto;

      @media ${baseVariables.mediaQuery.sm} {
        top: ${props => (props.userId ? `${toRem(180)}` : `${toRem(150)}`)};
      }

      @media ${baseVariables.mediaQuery.md} {
        top: ${toRem(190)};
      } 
      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        top: ${toRem(248)};
      }
  }
 
`;
