/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useContext } from 'react';
import { canUseDOM } from '@marriott/shared/mi-helper-utils';
import { OfferCardCarousel } from '@marriott/mi-offers-components';

import { experienceFragmentComponentTypes, merchandisingContectID } from '../../modules/utils/constants';
import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';
import { PageParamsContext } from '../../modules/context/PageContext';
import { AccuWeather, AccuWeatherProps } from '../AccuWeather';
import { BonvoyApplicationAd, BonvoyApplicationAdProps } from '../BonvoyApplicationAd';
import { JoinBanner, JoinBannerProps } from '../JoinBanner';
import { MMNComponent, MMNComponentProps } from '../MMNComponent';
import { PropertyPortalMerchandising } from '../PropertyPortalMerchandising';
import { PropertyPortalExperienceFragmentProps } from './PropertyPortalExperienceFragment.types';
import { StyledPropertyPortalExperienceFragment } from './PropertyPortalExperienceFragment.styles';

export const PropertyPortalExperienceFragment: FC<PropertyPortalExperienceFragmentProps> = (
  pageProps: PropertyPortalExperienceFragmentProps
) => {
  const { preProcessorResponse, requestId } = useContext(PageParamsContext);
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);
  const getExperienceFragmentComponent = (type: string, componentProps: any) => {
    let experienceFragmentComponent = null;
    switch (type) {
      case experienceFragmentComponentTypes.accuWeather:
        experienceFragmentComponent = <AccuWeather {...(componentProps?.model as AccuWeatherProps)} />;
        break;

      case experienceFragmentComponentTypes.cardHorizontalMini:
        experienceFragmentComponent = <BonvoyApplicationAd {...(componentProps as BonvoyApplicationAdProps)} />;
        break;

      case experienceFragmentComponentTypes.bannerLayered:
        experienceFragmentComponent = <JoinBanner {...(componentProps as JoinBannerProps)} />;
        break;

      case experienceFragmentComponentTypes.mmnContainer:
        experienceFragmentComponent = <MMNComponent {...(componentProps as MMNComponentProps)} />;
        break;

      case experienceFragmentComponentTypes?.offers:
        experienceFragmentComponent = (
          <OfferCardCarousel
            {...componentProps}
            key={type}
            offersData={componentProps?.model?.fallbackOfferIdsList ?? []}
            isAuthorMode={componentProps?.model?.isAuthor}
            acceptLanguage={preProcessorResponse?.locale ?? 'en-US'}
            requestId={requestId}
            cookie={canUseDOM ? document?.cookie : preProcessorResponse?.headersData.cookie}
            className=" pt-md-3"
          />
        );
        break;

      case experienceFragmentComponentTypes?.cardCarousel:
        experienceFragmentComponent = <PropertyPortalMerchandising componentProps={componentProps} type={type} />;
        break;

      case experienceFragmentComponentTypes.responsive:
        break;

      case experienceFragmentComponentTypes.text:
        experienceFragmentComponent = (
          <div className="heading-container-component d-flex justify-content-start align-items-start container mb-3 px-md-0">
            <div className="d-flex flex-column col-10 col-xl-6 align-items-start justify-content-start px-0">
              <p className="heading-container-component__description t-font-s mb-0 mt-2 text-left">
                {(componentProps?.model?.text || '').toString().replace(/<\/?p>/g, '')}
              </p>
            </div>
          </div>
        );
        break;

      case experienceFragmentComponentTypes.title:
        experienceFragmentComponent = (
          <div className="pt-4" id={pageProps?.contentID}>
            <div className="heading-container-component d-flex justify-content-start align-items-start container px-md-0">
              <div className="d-flex flex-column col-10 col-xl-6 align-items-start justify-content-start px-0">
                <h2 className="heading-container-component__title t-title-s text-left mb-0 mt-2 mb-3 mb-md-0 pb-3">
                  {componentProps?.model?.text}
                </h2>
              </div>
            </div>
          </div>
        );
        break;
    }

    return experienceFragmentComponent;
  };

  const getComponent = () => {
    const itemsOrder = pageProps?.[':itemsOrder'];
    const cardItems: Record<string, any> = pageProps?.[':items'];
    const component = [];
    if (
      pageProps?.contentID === merchandisingContectID &&
      Object.keys(cardItems).length === 0 &&
      itemsOrder.length === 0
    ) {
      setServiceData(
        {
          merchandisingAvailable: true,
        },
        false,
        false,
        {}
      );
    }
    for (const itemKey of itemsOrder) {
      if (cardItems[itemKey] && cardItems[itemKey]?.[':type']) {
        component.push(
          <div key={itemKey}>
            {getExperienceFragmentComponent(cardItems[itemKey]?.[':type'], { model: cardItems[itemKey] })}
          </div>
        );
      }
    }
    return component;
  };

  return (
    <StyledPropertyPortalExperienceFragment data-component-name="o-ppv5-PropertyPortalExperienceFragment">
      <>{getComponent()}</>
    </StyledPropertyPortalExperienceFragment>
  );
};
