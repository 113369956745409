/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

export interface earnRedeemStore {
  showFaqModal: boolean;
  faqModelHeader: string;
  setShowFaqModal: (showModal: boolean) => void;
  setFaqModelHeader: (setValue: string) => void;
}

export const initialState: earnRedeemStore = {
  showFaqModal: false,
  faqModelHeader: '',
  setShowFaqModal: () => {},
  setFaqModelHeader: () => {},
};

export const useStore: StateCreator<earnRedeemStore> = set => {
  return {
    ...initialState,

    setShowFaqModal: showModal => {
      set({ showFaqModal: showModal });
    },
    setFaqModelHeader: setValue => {
      set({ faqModelHeader: setValue });
    },
  };
};

export const earnRedeemStore = createAppStore(useStore, {
  usePersistentStore: false,
});
