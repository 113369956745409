import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library-shop';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledCardVertical = styled.div`
  height: 100%;
  .card-vertical {
    position: relative;
    box-sizing: border-box;
    border-radius: ${toRem(12)};
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-shadow: ${baseVariables.shadows['shadowSubtle01']};
    .image {
      aspect-ratio: 16/9;
      max-width: 100%;
      width: 100%;
    }
    &__body {
      padding: ${toRem(24)} ${toRem(32)} ${toRem(32)} ${toRem(32)};
      gap: ${toRem(7)};
      height: 100%;
    }
    &__content {
      height: 100%;
    }
    .bonvoy-wheels {
      padding-bottom: ${toRem(14)};
    }

    .points {
      padding: ${toRem(8)} ${toRem(16)} ${toRem(8)} ${toRem(16)};
    }

    .sep-lines {
      border-top: 1px solid ${baseVariables.color.neutral30};
      border-bottom: 1px solid ${baseVariables.color.neutral30};
    }

    .description-height {
      padding-bottom: ${toRem(24)};
      padding-top: ${toRem(16)};
    }
    .description {
      p {
        margin: ${toRem(0)};

        text-align: center;
      }
    }
    .socialIcons {
      position: absolute;
      top: 0;
      right: 0;
      margin: ${toRem(16)};
      padding: ${toRem(6)} ${toRem(6)} ${toRem(8)} ${toRem(6)};
    }
  }
  .glide__slides & {
    white-space: normal;
    text-align: center;
  }
`;
