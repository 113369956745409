import { FC } from 'react';
import { SummaryRowProps } from './SummaryRow.types';
import { StyledSummaryRow } from './SummaryRow.styles';

export const SummaryRow: FC<SummaryRowProps> = ({ label, value }) => {
  return (
    <StyledSummaryRow data-component-name="m-groups-SummaryRow" data-testid="groups-SummaryRow" className="t-font-s">
      {label && <span className="label">{label} </span>}
      <span className="value">{value}</span>
    </StyledSummaryRow>
  );
};
