import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

/* Additional styles are to render the way 
as required by RnB */

export const RnBCondensedFeature = styled.div`
  background: ${baseVariables.color.merch30};
  padding: ${toRem(40)} 0 ${toRem(20)};

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    padding: ${toRem(86)} 0 ${toRem(40)};
  }
  .rnb-condensed-feature {
    background: ${baseVariables.color.neutral00};
    margin: 0;

    .feature-header,
    .feature-text {
      display: block;
    }
    .feature-img {
      aspect-ratio: 3/2;
    }
    .content {
      background: ${baseVariables.color.neutral00};
    }
  }
  .condensed-feature-header-container {
    .condensed-feature-header {
      flex-basis: 82%;

      @media ${baseVariables.mediaQuery.desktop}, @media ${baseVariables.mediaQuery.largeDesktop} {
        flex-basis: 49%;
      }
    }

    @media ${baseVariables.mediaQuery.desktop}, @media ${baseVariables.mediaQuery.largeDesktop} {
      display: flex;
      justify-content: center;
      margin: 0 ${toRem(190)} ${toRem(40)} ${toRem(190)};
    }
  }
`;
