/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';

export const setCookie = (name: string, value: string, options = null): any => {
  if (name && value && options) {
    Cookies.set(name, value, options);
    return;
  }

  if (name && value) {
    Cookies.set(name, value);
  }
};

export const getCookies = (name: string): any => {
  if (name) {
    return Cookies.get(name);
  }

  return Cookies.get();
};

export const removeCookie = (name: string, options: any): any => {
  if (name && options) {
    Cookies.remove(name, options);
    return;
  }

  if (name) {
    Cookies.remove(name);
  }
};
