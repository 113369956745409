import React, { Suspense, lazy } from 'react';
import { OffersBannerLayeredProps } from './OffersBannerLayered.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { decodeHtml, getMediaData, getMediaSource } from '../../utils/OfferUtils';
import { PRIMARY_MEDIA } from '../../constants/CommonConstants';

import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OffersBannerLayeredWrapperConfig = {
  emptyLabel: 'OffersBannerLayered',
  isEmpty: false,
  resourceType: 'mi-aem-common-spa/components/content/bannerlayered',
};
const BannerLayered = lazy(() =>
  import('@marriott/mi-ui-library').then(module => ({
    default: module.BannerLayered,
  }))
);
export const OffersBannerLayeredWrapper: React.FC<OffersBannerLayeredProps> = props => {
  const { model, offersData, isAuthorMode } = props;
  const { offersBannerLayered } = ImageRenditionsData;
  const offersDataNode = offersData?.responseObject?.edges[0];

  //desktop media
  const mediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersBannerLayered?.aspectRatio?.dynamicMedia
      );
  const isMediaFromDAC = mediaSource === PRIMARY_MEDIA;
  const media = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersBannerLayered?.dynamicMedia,
        mediaSource,
        PRIMARY_MEDIA
      );

  //mobile media
  const mobileMediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersBannerLayered?.aspectRatio?.dynamicMediaMobile
      );
  const isMobileMediaFromDAC = mobileMediaSource === PRIMARY_MEDIA;
  const mobileMedia = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersBannerLayered?.dynamicMediaMobile,
        mediaSource,
        PRIMARY_MEDIA
      );

  return (
    <Suspense fallback={''}>
      <BannerLayered
        styleclass={model?.styleclass ?? ''}
        header={decodeHtml(offersDataNode?.node?.title) ?? model?.header}
        description={decodeHtml(offersDataNode?.node?.description) ?? model?.description}
        primaryCtaText={offersDataNode?.node?.callToActionLabel ?? model?.primaryCtaText}
        primaryCtaLink={offersDataNode?.node?.callToActionLink ?? model?.primaryCtaLink}
        secondaryCtaText={offersDataNode?.merchandisingNode?.secondaryCallToActionLabel ?? model?.secondaryCtaText}
        secondaryCtaLink={offersDataNode?.merchandisingNode?.secondaryCallToActionLink ?? model.secondaryCtaLink}
        dynamicMedia={isMediaFromDAC ? media : model?.dynamicMedia}
        dynamicMediaMobile={isMobileMediaFromDAC ? mobileMedia : model?.dynamicMediaMobile}
        iconText={model?.iconText}
        primaryCtaOpenInNewTab={model?.primaryCtaOpenInNewTab}
        tertiaryLinkText={model?.tertiaryLinkText}
        tertiaryLinkOpenInNewTab={model?.tertiaryLinkOpenInNewTab}
        secondaryCtaOpenInNewTab={model?.secondaryCtaOpenInNewTab}
        trackingProperties={model?.trackingProperties}
        ctaType={model?.ctaType}
        iconPath={model?.iconPath}
        tertiaryLinkUrl={model?.tertiaryLinkUrl}
        linkUrl={model?.linkUrl}
      />
    </Suspense>
  );
};

// Use named rather than default exports.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersBannerLayered = (props: any) => (
  <div data-testid="offers-banner-layered" data-component-name="o-offers-bannerlayered">
    <EditableComponent config={OffersBannerLayeredWrapperConfig} {...props}>
      <OffersBannerLayeredWrapper {...props} />
    </EditableComponent>
  </div>
);
