import React, { FC } from 'react';
import { clsx } from 'clsx';
import { Controller } from 'react-hook-form';
import { AdditionalInfoProps } from './AdditionalInfo.types';
import { StyledAdditionalInfo } from './AdditionalInfo.styles';
import { TextAreaFormField } from '../TextAreaFormField';

export const AdditionalInfo: FC<AdditionalInfoProps> = props => {
  const { labels, control, customClass } = props;

  const additionalInfoField = (
    <Controller
      name="additionalInfo"
      control={control}
      render={({ field }) => (
        <TextAreaFormField
          {...field}
          placeholder={labels.placeholder}
          maxLength={labels.maxLength}
          resizeable={false}
          className="col-12 col-lg-8"
          ariaLabel={labels.title}
          testId="additional-info"
          onChange={event => {
            field.onChange(event);
          }}
        />
      )}
    />
  );

  return (
    <StyledAdditionalInfo data-component-name="m-groups-AdditionalInfo" data-testid="groups-AdditionalInfo">
      <div className={clsx('t-subtitle-l', customClass || 'title')}>{labels.title}</div>
      <div className="row">{additionalInfoField}</div>
    </StyledAdditionalInfo>
  );
};
