export const localheaderMock = {
  openLogoInNewTab: 'true',
  dynamicMedia: {
    altText: 'Marriott Hotels and Resorts',
    assetPath:
      'https://marriott-sites2-qa.adobecqms.net/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
    enableSmartCrop: true,
    dynamic: false,
    renditions: [
      {
        renditionPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
        mediaValue: '768px',
        width: 0,
        dynamic: false,
        damPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
        mediaQuery: 'min-width',
        height: 0,
      },
      {
        renditionPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
        mediaValue: '576px',
        width: 0,
        dynamic: false,
        damPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
        mediaQuery: 'max-width',
        height: 0,
      },
      {
        renditionPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
        mediaValue: '576px',
        width: 0,
        dynamic: false,
        damPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
        mediaQuery: 'min-width',
        height: 0,
      },
    ],
    damPath: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
  },
  logoAltText: 'Marriott Hotels and Resorts',
  mobileMapCtaLabel: 'Map',
  hideL2Navigation: true,
  openMapInNewTab: 'true',
  mapCtaLabel: 'View Map',
  openHotelLogoInNewTab: 'true',
  pagePath: '/content/marriott-hws/na/en-us/hotels/n/nycmq-new-york-marriott-marquis/ppv5-phoenix/homepage',
  homePage: 'https://marriott-sites2-qa.adobecqms.net/en-us/hotels/nycmq-new-york-marriott-marquis/overview/',
  buttonLabel: 'Book Your Next Stay',
  ':items': {
    'container-0': {
      ':items': {
        brandedcontainer: {
          ':items': {
            'container-0': {
              ':items': {
                experiencefragment: {
                  configured: true,
                  ':items': {
                    accuWeather: {
                      imageAltText: 'AccuWeather Logo',
                      selectPositioning: 'headerAccuweather',
                      ':type': 'mi-aem-ppv5-spa/components/content/accuWeather',
                      weatherForecast: 'Weather Forecast',
                      daysWeatherForecast: 1,
                      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      accuWeatherImage:
                        'https://marriott-sites2-qa.adobecqms.net/content/dam/marriott-digital/mcomppv5/global/en_us/logo/assets/pdt-AccuWeather-Logo-168720693221076.png',
                    },
                    'cq:responsive': {
                      ':type': 'nt:unstructured',
                    },
                  },
                  cssClassNames: 'aem-xf',
                  localizedFragmentVariationPath:
                    '/content/experience-fragments/mi-aem-ppv5-spa/global/en-us/accuwhether-experience-fragment/master/jcr:content/root',
                  ':type': 'mi-aem-ppv5-spa/components/content/experiencefragment',
                  ':itemsOrder': ['cq:responsive', 'accuWeather'],
                  name: 'accuwhether-experience-fragment',
                },
              },
              allowedComponents: {
                components: [],
                applicable: false,
              },
              ':type': 'wcm/foundation/components/responsivegrid',
              ':itemsOrder': ['experiencefragment'],
              columnClassNames: {
                experiencefragment: 'aem-GridColumn aem-GridColumn--default--12',
              },
              gridClassNames: 'aem-Grid aem-Grid--12 aem-Grid--default--12',
              columnCount: 12,
            },
          },
          brandConfigCards: [],
          ':type': 'mi-aem-ppv5-spa/components/content/brandedcontainer',
          ':itemsOrder': ['container-0'],
          itemPath: 'root/responsivegrid/localheader/container-0/brandedcontainer',
          id: 'brandedcontainer-057fed905b',
          selectedStates: ['preconnect'],
          pagePath: '/content/marriott-hws/na/en-us/hotels/n/nycmq-new-york-marriott-marquis/ppv5-phoenix/homepage',
          items: [],
          doHide: true,
        },
      },
      allowedComponents: {
        components: [],
        applicable: false,
      },
      ':type': 'wcm/foundation/components/responsivegrid',
      ':itemsOrder': ['brandedcontainer'],
      columnClassNames: {
        brandedcontainer: 'aem-GridColumn aem-GridColumn--default--12',
      },
      gridClassNames: 'aem-Grid aem-Grid--12 aem-Grid--default--12',
      columnCount: 12,
    },
    navigationAttributes: {
      ':type': 'nt:unstructured',
    },
  },
  selectedStatesMap: ['preconnect'],
  hotelLogoFile: '/content/dam/marriott-digital/mc/global-property-shared/en_us/logo/assets/mc_logo_L.png',
  ':type': 'mi-aem-ppv5-spa/components/content/localheader',
  navigationAttributes: [
    {
      componentId: 'diningAndExperience',
      label: 'Dining & Experience',
    },
    {
      componentId: 'propertyDetails',
      label: 'Property Details',
    },
    {
      componentId: 'nearbyThingsToDo',
      label: 'Nearby Things to Do',
    },
    {
      componentId: 'merchandising',
      label: 'Offers & Packages',
    },
  ],
  ':itemsOrder': ['container-0', 'navigationAttributes'],
  itemPath: 'root/responsivegrid/localheader',
  buttonLink: '#',
  openButtonLinkInNewTab: 'true',
  id: 'localheader-feee5e809e',
  items: [],
  selectedStatesL2Navigation: ['preconnect'],
  hideMap: true,
};

export const localheaderMockUXL = {
  property: {
    basicInformation: {
      name: 'New York Marriott Marquis',
      latitude: 40.758855,
      longitude: -73.985869,
    },
    contactInformation: {
      contactNumbers: [
        {
          phoneNumber: {
            display: '+1 212 398 1900',
          },
        },
      ],
    },
  },
};
