import styled from 'styled-components';

export const StyledTncModal = styled.div`
  .tnc-modal {
    overflow: hidden;
    .more-info-cta {
      text-align: right;
    }
    a {
      text-decoration: underline;
    }
  }
`;
