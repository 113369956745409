// Imports for external libraries go here.
//import React, { FC } from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
//import { OffersVariableColumnContainerProps } from './OffersVariableColumnContainer.types';
//import { StyledOffersVariableColumnContainer } from './OffersVariableColumnContainer.styles';

// // Use named rather than default exports.
// export const OffersVariableColumnContainer: FC<OffersVariableColumnContainerProps> = ({ text }) => {
//   const textVal = text ?? 'OffersVariableColumnContainer prop fallback';
//   return <StyledOffersVariableColumnContainer>{textVal}</StyledOffersVariableColumnContainer>;
// };
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { AEMReactCompMap, VariableColumnContainer as VariableColumnContainerMolecule } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { FC, lazy, Suspense } from 'react';
import { VariableColumnProps, OffersVariableColumnContainerProps } from './OffersVariableColumnContainer.types';

export const VariableColumnContainerConfig = {
  emptyLabel: 'VariableColumnContainer',
  isEmpty: () => true,
  resourceType: `mi-aem-common-spa/components/content/variablecolumncontainer`,
};

export const VariableColumnContainerComp: FC<VariableColumnProps> = (props: VariableColumnProps) => {
  const {
    header,
    headerTag,
    subHeader,
    ctaLabel,
    ctaLink,
    eyebrow,
    ctaType,
    openInNewTab,
    trackingProperties,
    styleclass,
    cqItems,
    componentId,
    pagePath,
    itemPath,
    isAuthor,
    desktopColumns,
    tabletColumns,
    mobileColumns,
    contentAlignment,
    reverseStacking,
    offersData,
  } = props;

  const col = getColumnsObj(desktopColumns, tabletColumns);
  const formVariableColumnArray = () => {
    const columnsArray = [];
    for (let i = 0; i < col.length; i++) {
      columnsArray.push([
        `variable-column-${i}`,
        `col-xl-${col?.[i].deskVal} col-md-${col?.[i].tabVal} col-12 column-containers`,
      ]);
    }
    return columnsArray;
  };

  const variableColumnArray = formVariableColumnArray();

  const mapper = AEMReactCompMap(props?.allowedComponents);

  // This function is used to render the component in authoring mode authorComponentWrapper
  const authorComponentWrapper = (index: number) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${variableColumnArray[index][0]}`}
        columnCount="12"
        gridClassNames={''}
        customClassName={''}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode
  const publishComponentWrapper = (columnName: string, jsonData: any, columnClass: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(columnName)) {
      const column = jsonData[columnName];
      const columnItems = column[':items'];
      for (const itemKey in columnItems) {
        if (Object.prototype.hasOwnProperty.call(columnItems, itemKey)) {
          const item = columnItems[itemKey];
          const itemName = item[':type']?.split('/').pop();
          const itemType =
            itemKey.startsWith('offers') && !itemName.startsWith('offers')
              ? 'offers' + item[':type']?.split('/').pop()
              : item[':type']?.split('/').pop();
          if (Object.prototype.hasOwnProperty.call(mapper, itemType) && itemKey.startsWith('offers')) {
            const innerComp =
              mapper[itemType] === 'OffersCardHorizontalMini' ? 'OfferCardHorizontalMini' : mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );
            return (
              <li className={columnClass} key={itemKey}>
                <Suspense>
                  <Component model={item} isAuthorMode={isAuthor} offersData={offersData} />
                </Suspense>
              </li>
            );
          }
          return null;
        }
      }
    }
    return null;
  };

  return (
    <VariableColumnContainerMolecule
      componentId={componentId}
      subHeader={subHeader}
      styleclass={styleclass}
      eyebrow={eyebrow}
      trackingProperties={trackingProperties}
      ctaLabel={ctaLabel}
      header={header}
      headerTag={headerTag}
      ctaLink={ctaLink}
      openInNewTab={openInNewTab}
      ctaType={ctaType}
      isAuthor={isAuthor}
      desktopColumns={desktopColumns}
      tabletColumns={tabletColumns}
      mobileColumns={mobileColumns}
      reverseStacking={reverseStacking}
      contentAlignment={contentAlignment}
    >
      {isAuthor &&
        Array.from({ length: variableColumnArray.length }, (_, i) => (
          <div className={variableColumnArray[i][1]}>{authorComponentWrapper(i)}</div>
        ))}
      {!isAuthor &&
        variableColumnArray.map((columnName: string[], i) => {
          //console.log(publishComponentWrapper(columnName[0], cqItems, variableColumnArray[i][1]));
          return publishComponentWrapper(columnName[0], cqItems, variableColumnArray[i][1]);
        })}
    </VariableColumnContainerMolecule>
  );
};

export const VariableColumnEditableComponent = (props: any) => {
  return (
    <EditableComponent config={VariableColumnContainerConfig} {...props}>
      <VariableColumnContainerComp {...props} componentName={props?.model.cqType.split('/').pop()} />
    </EditableComponent>
  );
};

export const OffersVariableColumnContainer: FC<OffersVariableColumnContainerProps> = (
  props: OffersVariableColumnContainerProps
) => {
  const { model } = props;
  return (
    <div
      className={clsx(
        [model?.styleclass?.includes('inverse') ? 'inverse' : ''],
        [model?.styleclass?.includes('fullbleed') ? 'm-container-fullbleed' : 'container']
      )}
      data-testid="variable-column-container"
      data-component-name="o-offers-variablecolumncontainer"
    >
      <VariableColumnEditableComponent {...props} />
    </div>
  );
};

export const createColumn = (deskVal: number, tabVal: number) => {
  const item = {
    deskVal,
    tabVal,
  };
  return item;
};

export const getColumnsObj = (desktopColumns = '', tabletColumns = '') => {
  const col = [];
  if (desktopColumns !== '' || tabletColumns !== '') {
    const [desktopColVals, tabletColVals] = [desktopColumns.split(','), tabletColumns.split(',')];

    switch (true) {
      case desktopColVals.length === 1 && tabletColVals.length === 1:
        {
          const deskTopCols = parseInt(desktopColumns);
          const tabCols = parseInt(tabletColumns);
          const mdVal = 12 / deskTopCols;

          const smVal = 12 / tabCols;
          for (let i = 0; i < deskTopCols; i++) {
            const item = createColumn(mdVal, smVal);
            col.push(item);
          }
        }
        break;
      case desktopColVals.length > 1 && tabletColVals.length > 1:
        for (let i = 0; i < parseInt(desktopColVals[0]); i++) {
          const item = createColumn(parseInt(desktopColVals[i + 1]), parseInt(tabletColVals[i]));
          col.push(item);
        }
        break;
      case desktopColVals.length > 1 && tabletColVals.length === 1:
        {
          const tabCols = parseInt(tabletColumns);
          const smVal = 12 / tabCols + 1;
          for (let i = 0; i < parseInt(desktopColVals[0]); i++) {
            const item = createColumn(parseInt(desktopColVals[i + 1]), smVal);
            col.push(item);
          }
        }
        break;
      case desktopColVals.length === 1 && tabletColVals.length > 1:
        {
          const deskTopCols = parseInt(desktopColumns);
          const mdVal = 12 / deskTopCols;
          for (let i = 0, j = 0; i < deskTopCols; i++, j++) {
            if (j === tabletColVals.length) {
              j = 0;
            }
            const item = createColumn(mdVal, parseInt(tabletColVals[j]));
            col.push(item);
          }
        }
        break;
    }
  }
  return col;
};
