import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledGolfLocationContainer = styled.div`
  background-color: inherit;
`;

export const StyledVerticalGolfCards = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: ${toRem(16)};

  @media ${baseVariables.mediaQuery.md} {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  }

  .flex-nowrap {
    margin-top: 0 !important;
  }

  .property-card {
    height: auto;

    &__img-container,
    &__img-container--img {
      width: 100%;
      height: ${toRem(228)};
    }

    &__details-container {
      padding: ${toRem(16)} ${toRem(0)} ${toRem(16)} !important;
      height: ${toRem(268)} !important;
    }

    &__details-container_top {
      padding-right: ${toRem(16)};
      padding-left: ${toRem(16)};
      .property-card__title,
      .property-card__address,
      .property-card__details-container_desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .property-card__title {
        -webkit-line-clamp: 2;
        font-size: ${toRem(16)};
        line-height: ${toRem(22)};
      }

      .property-card__address {
        -webkit-line-clamp: 1;
      }

      .property-card__details-container_desc {
        -webkit-line-clamp: 3;
      }
    }

    .property-card__address,
    .property-card__title,
    .property-card__details-container_desc {
      padding: 0 !important;
    }

    .property-card__details-container_desc {
      margin: ${toRem(8)} 0 !important;
    }

    .property-card__details-container_bottom {
      .separator {
        margin: ${toRem(16)} 0 ${toRem(12)} !important;
      }
    }

    .property-card__details-container_footer {
      padding-right: ${toRem(16)} !important;
      padding-left: ${toRem(16)} !important;
    }
  }
`;
