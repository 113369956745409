import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../../modules/utils/constants/constants';

export const StyledCardStatic = styled.div`
  .card-static-container {
    border-bottom: 1px solid ${baseVariables.color.neutral30};
  }

  .card-static-minutes-read {
    margin-right: ${toRem(0)} !important;
    margin-left: ${toRem(4)} !important;
  }

  .col-12 {
    padding: 0;
  }

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}) {
    .m-link-tertiary-button::after
    {
      content: "\\e927";
      font-size: ${toRem(16)};
      padding-left: ${toRem(8)};
      line-height: ${toRem(18)};
    }
    .card-static-rtl{
      text-align: right;
    }
    .card-static-minutes-read {
      margin-right: ${toRem(4)} !important;
      margin-left: ${toRem(0)} !important;
    }
    .card-static-cta-rtl {
      margin-right: ${toRem(0)};
      margin-left: ${toRem(24)};
    }
  }
`}
`;
