import { ImageCollageContainer } from './PicCollage.styles';
import { Image } from '@marriott/mi-ui-library';
import { PicCollageProps } from './index';

export const PicCollage = (props: PicCollageProps) => {
  const { imageLoading = 'eager' } = props;
  return (
    <ImageCollageContainer>
      <div className="imageBox">
        <div className="roundedBox1">
          <Image
            title={props?.dynamicMediaRight?.altText}
            altText={props?.dynamicMediaRight?.altText}
            renditions={props?.dynamicMediaRight?.renditions}
            dynamic={props?.dynamicMediaRight?.dynamic}
            defaultImageURL={props?.dynamicMediaRight?.assetPath}
            customClass="image image1"
            loading={imageLoading}
          />
        </div>
        <div className="roundedBox2">
          <Image
            title={props?.dynamicMediaLeft?.altText}
            altText={props?.dynamicMediaLeft?.altText}
            renditions={props?.dynamicMediaLeft?.renditions}
            dynamic={props?.dynamicMediaLeft?.dynamic}
            defaultImageURL={props?.dynamicMediaLeft?.assetPath}
            customClass="image image2"
            loading={imageLoading}
          />
        </div>

        <div className="roundedBox3">
          <Image
            title={props?.dynamicMediaMiddle?.altText}
            altText={props?.dynamicMediaMiddle?.altText}
            renditions={props?.dynamicMediaMiddle?.renditions}
            dynamic={props?.dynamicMediaMiddle?.dynamic}
            defaultImageURL={props?.dynamicMediaMiddle?.assetPath}
            customClass="image image3"
            loading={imageLoading}
          />
        </div>
      </div>
    </ImageCollageContainer>
  );
};
