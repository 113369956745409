import * as React from 'react';
import clsx from 'clsx';
import { TextAreaFormFieldProps } from './TextAreaFormField.types';
import { StyledTextAreaFormField } from './TextAreaFormField.styles';

export const TextAreaFormField = React.forwardRef<HTMLTextAreaElement, TextAreaFormFieldProps>(
  (
    {
      ariaLabel,
      className,
      custom_click_track_value,
      disabled,
      errorMessage,
      maxLength,
      placeholder,
      resizeable = true,
      showErrorMessage,
      testId,
      value,
      ...rest
    },
    ref
  ) => {
    const inputId = React.useId();
    return (
      <StyledTextAreaFormField
        className={clsx(
          className,
          custom_click_track_value && 'custom_click_track',
          showErrorMessage && 'is-error',
          !resizeable || disabled ? 'disable-resize' : ''
        )}
        data-component-name="m-groups-TextAreaFormField"
        data-testid={testId}
        data-custom_click_track_value={custom_click_track_value}
      >
        <textarea
          aria-label={ariaLabel}
          disabled={disabled}
          id={inputId}
          maxLength={maxLength}
          placeholder={placeholder || ''}
          value={value}
          role="textbox"
          ref={ref}
          data-testid={`${testId}-textarea`}
          {...rest}
        ></textarea>
        {showErrorMessage && <span className={clsx('error-text t-font-xs')}>{errorMessage}</span>}
      </StyledTextAreaFormField>
    );
  }
);
