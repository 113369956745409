import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledRecommendedOutlets = styled.div`
  background-color: inherit;

  .show-venue-link {
    cursor: pointer;
    &::after {
      line-height: unset;
    }

    &::visited {
      border-bottom: none;
    }
  }

  .recommended-header-section {
    column-gap: ${toRem(32)};

    &__title {
      width: 100%;
    }

    &__location-link {
      min-width: 100%;
    }

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      &__title {
        width: 84%;
      }

      &__location-link {
        min-width: fit-content;
      }
    }
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      .show-venue-link {
        margin-right: 0 !important;
        margin-left: ${toRem(27)};
      }

      .m-link-tertiary-button::after
        {
          content: "\\e927";
          font-size: ${toRem(16)};
          padding-right: ${toRem(8)};
          line-height: ${toRem(18)};
        }
      }

      .property-card__details-container_top .icon-decorative {
        margin-left: ${toRem(4)};
        margin-right: 0 !important;
      }
  `}
`;

export const StyledVerticalSearchCards = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: ${toRem(16)};

  @media ${baseVariables.mediaQuery.md} {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  }

  .flex-nowrap {
    margin-top: 0 !important;
  }

  .property-card {
    height: auto;

    &__img-container,
    &__img-container--img {
      width: 100%;
      height: ${toRem(228)};
    }

    &__details-container {
      padding: ${toRem(16)} ${toRem(0)} ${toRem(12)} !important;
      height: ${toRem(276)} !important;
    }

    &__details-container_top {
      padding-right: ${toRem(16)};
      padding-left: ${toRem(16)};
      .property-card__title,
      .property-card__address {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }

      .property-card__eyebrow {
        margin-bottom: ${toRem(4)} !important;
      }

      .property-card__subTitle {
        margin-top: ${toRem(4)} !important;
      }

      .icon-decorative {
        margin-right: ${toRem(4)} !important;
      }

      .icon-decorative,
      .property-card__subTitle,
      .property-card__address,
      .property-card__title,
      .property-card__eyebrow,
      .property-card__details-container_desc {
        padding: 0 !important;
      }

      .icon-decorative {
        font-size: ${toRem(16)};
        line-height: ${toRem(16)};
        padding-top: 0 !important;
      }

      .property-card__title {
        font-size: ${toRem(16)};
        line-height: ${toRem(22)};
      }

      .property-card__details-container_desc {
        margin: ${toRem(8)} 0 !important;
      }
    }

    .property-card__details-container_bottom {
      .property-card__tertiary-link {
        padding-right: ${toRem(16)};
        margin: 0 !important;
        margin-left: ${toRem(16)} !important;
      }

      .separator {
        margin: ${toRem(16)} 0 ${toRem(12)} !important;
      }
    }

    .property-card__details-container_footer {
      padding-right: ${toRem(16)};
      padding-left: ${toRem(16)};
    }
  }

  .overlay-content {
    height: 100%;
  }

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}) {
    .property-card__tertiary-link {
      padding-right: ${toRem(16)} !important;
      margin: 0 !important;
      margin-left: 1rem !important;
    }
  }
`}
`;
