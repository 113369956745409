/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import clsx from 'clsx';
import { Types, Button, Heading } from '@marriott/mi-ui-library';
import { StaticArticlesProps } from './StaticArticles.types';
import { StyledStaticArticles } from './StaticArticles.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { CardStatic } from './CardStatic';
import { StaticAriticleItemsPerView, TRACKING_CONSTANT } from '../../../src/modules/utils/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const StaticArticles: FC<StaticArticlesProps> = (props: StaticArticlesProps) => {
  const staticArticles = props?.articleCardList?.slice(0, 9);
  const [currentIndex, setCurrentIndex] = useState(0);

  const start = 0;
  const end = currentIndex + StaticAriticleItemsPerView;
  const visiblestaticArticles = staticArticles?.slice(start, end);
  const { CITY_ARTICLE_COLLAGE_COMPONENT, CITY_ARTICLE_BUTTON, EXTERNAL_LINK } = TRACKING_CONSTANT;

  const handleButtonClick = () => {
    if (end < staticArticles?.length) {
      setCurrentIndex(currentIndex + StaticAriticleItemsPerView);
    } else {
      // Reset to the beginning if we've reached the end
      setCurrentIndex(0);
    }
  };

  return props?.articleCardList?.length > 0 ? (
    <StyledStaticArticles data-component-name="o-rnb-articlecards" data-testid="articlecards" className="container">
      <span className="separator-line"></span>
      <Heading
        titleText={props?.componentTitle}
        variation={Types.headingType.title}
        fontSize={Types.size.small}
        customClass="text-center pt-5 px-5 "
      />

      {visiblestaticArticles?.length > 0 &&
        visiblestaticArticles?.map((article: any, index: number) => (
          <CardStatic
            eyebrow={article?.eyebrow}
            fileReferenceImage={article?.assetPath}
            dynamicMedia={article?.dynamicMedia}
            imgAltText={article?.imgAltText}
            title={article?.title}
            iconPathMinuteRead={props?.iconPathMinuteRead}
            minuteRead={article?.minuteRead}
            description={article?.description}
            ctaLinkText={article?.ctaLinkText}
            openInNewTab={props?.openInNewTab}
            ctaLink={article?.ctaLink}
            key={index}
          />
        ))}
      <div className="d-flex flex-row justify-content-center p-4" data-testid="article-button-testid">
        {end < staticArticles?.length && (
          <Button
            testId="article-btn"
            callback={handleButtonClick}
            custom_click_track_value={`${CITY_ARTICLE_COLLAGE_COMPONENT}|${CITY_ARTICLE_BUTTON}|${EXTERNAL_LINK}`}
            className={clsx('m-button-s m-button-secondary-icon text-align-center')}
          >
            {props?.loadMoreArticlesCTALabel}
          </Button>
        )}
      </div>
    </StyledStaticArticles>
  ) : null;
};

export const StaticArticlesConfig = {
  emptyLabel: 'articlecards',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/articlecards`,
};

export const StaticArticlesEditable = (props: any) => (
  <EditableComponent config={StaticArticlesConfig} {...props}>
    <StaticArticles {...props} />
  </EditableComponent>
);
