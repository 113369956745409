import styled, { createGlobalStyle } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import TextField from '@material-ui/core/TextField';
import { PAGES, HEADER_HEIGHT_MOBILE, HEADER_HEIGHT_DESKTOP } from '../../modules/utils/constants';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

const { SEARCH_RESULTS_PAGE, HOMEPAGE } = PAGES;
interface SearchFormProps {
  isDropDownOpen: boolean;
  isHomePage: boolean;
}

interface SearchFormParentProps {
  currentPage?: string;
  isScrolledUp: boolean;
}

interface SearchFormContainerProps {
  isDropDownOpen: boolean;
  isHomePage: boolean;
  userId?: string;
  currentPage?: string;
  isScrolled: boolean;
}

export const SearchFormParent = styled.form<SearchFormParentProps>`
  .rnb-search-form {
    position: relative;
    width: ${props => (props.currentPage === SEARCH_RESULTS_PAGE ? '100%' : 'auto')};
    padding: 0;
    z-index: 2;
    @media ${baseVariables.mediaQuery.md} {
      position: ${props =>
        props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE ? 'relative' : 'static'};
      width: ${props =>
        props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE ? '100%' : 'auto'};
      padding: ${props =>
        props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE ? '0' : `${`0 ${toRem(8)}`}`};
    }
  }

  .back-color {
    background-color: white;
    left: 0;
    right: 0;
    width: 100%;
  }

  .sticky-search-form {
    position: fixed;
    z-index: 30;
    transition: top 0.4s ease 0s;
    top: ${props => (props.isScrolledUp ? HEADER_HEIGHT_MOBILE : '0')};

    @media ${baseVariables.mediaQuery.md} {
      position: ${props =>
        props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE ? 'fixed' : 'unset'};
    }

    @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
      position: ${props =>
        props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE ? 'fixed' : 'unset'};
      top: ${props =>
        props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE
          ? props.isScrolledUp
            ? HEADER_HEIGHT_DESKTOP
            : '0'
          : 'unset'};
    }
  }
`;

export const SearchFormContainer = styled.div<SearchFormContainerProps>`
  margin-top: ${props =>
    props.userId && props.isHomePage
      ? `${toRem(48)}`
      : props.isHomePage && props.isScrolled
      ? '0'
      : `${toRem(20)}`}; //Revisit tomorrow
  height: ${props => (props.isDropDownOpen ? `${toRem(92)}` : `${toRem(72)}`)};
  padding: ${props =>
    props.isDropDownOpen
      ? `${toRem(24)} ${toRem(24)} ${toRem(24)} ${toRem(20)} !important`
      : `${toRem(16)} !important`};
  background-color: ${props =>
    props.isDropDownOpen ? `${baseVariables.color.merch30}` : `${baseVariables.color.merch50}`};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: ${props => (props.isHomePage ? `${toRem(8)}` : 0)};
  box-shadow: ${props => (props.isHomePage ? `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07)` : 'none')};
  position: relative;

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    width: 100%;
    height: ${props => (props.isHomePage ? `${toRem(92)}` : `${toRem(86)}`)};
    padding: ${props => (props.isHomePage ? `0 ${toRem(40)} 0 0 !important` : `0 ${toRem(48)} 0 0 !important`)};
    padding-left: ${props =>
      !props.isDropDownOpen
        ? props.isHomePage
          ? `${toRem(40)} !important`
          : `${toRem(48)} !important`
        : '0 !important'};
    border-radius: ${props => (props.isDropDownOpen ? `${toRem(12)} ${toRem(12)} ${toRem(12)}  0` : `${toRem(12)}`)};
    margin-top: ${props =>
      props.isScrolled && (props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE)
        ? `0`
        : `${toRem(40)}`};
    border-radius: ${props =>
      props.isScrolled && (props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE)
        ? `0`
        : props.isDropDownOpen
        ? `${toRem(12)} ${toRem(12)} ${toRem(12)}  0`
        : `${toRem(8)}`};
    background-color: ${props =>
      props.isScrolled && (props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE)
        ? `white`
        : `${baseVariables.color.merch50}`};
    box-shadow: ${props =>
      props.isScrolled && (props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE)
        ? `none`
        : `0 ${toRem(4)} ${toRem(8)} 0 rgba(0, 0, 0, 0.05)`};

    ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      padding: ${(props: SearchFormContainerProps) =>
        props.isHomePage ? `0 0 0 ${toRem(40)} !important` : `0 0 0 ${toRem(40)}!important`};
        padding-right: ${(props: SearchFormContainerProps) =>
          !props.isDropDownOpen
            ? props.isHomePage
              ? `${toRem(40)} !important`
              : `${toRem(48)} !important`
            : '0 !important'};
      border-radius: ${(props: SearchFormContainerProps) =>
        props.isScrolled && (props.currentPage === SEARCH_RESULTS_PAGE || props.currentPage === HOMEPAGE)
          ? `0`
          : props.isDropDownOpen
          ? `${toRem(12)} ${toRem(12)} 0 ${toRem(12)}`
          : `${toRem(12)}`};
    }
  `}
  }

  .rnb-additional-container {
    margin: ${toRem(20)} ${toRem(16)} ${toRem(16)} ${toRem(16)};
    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      margin: 0;
    }

    &__form-cta {
      display: none;
      margin-top: ${props => (props.isHomePage ? `${toRem(20)}` : `${toRem(24)}`)};

      @media ${baseVariables.mediaQuery.md} {
        display: block;
        width: auto;
      }

      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        display: block;
        width: ${props => (props.isHomePage ? `${toRem(275)}` : 'auto')};
      }
    }
  }

  .back-button {
    background: none;
    padding: 0;
    margin: ${toRem(16)} ${toRem(20)} 0 0;

    .icon-back-arrow-cropped {
      font-size: ${toRem(16)};
      line-height: ${toRem(12)};
      width: ${toRem(16)};
      height: ${toRem(12)};
    }

    ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      margin: ${toRem(16)} 0 0 ${toRem(20)};
    }`}
  }
`;

export const SearchInputField = styled.div<SearchFormProps>`
  display: flex;
  flex-direction: row;

  .rnb-dest-search-input {
    width: 100%;
    display: inherit;

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      padding-bottom: ${props => (props.isDropDownOpen ? (props.isHomePage ? `${toRem(20)}` : `${toRem(15)}`) : 0)};
      border-bottom: ${props => (props.isDropDownOpen ? `${toRem(2)} solid` : 'none')};
    }
  }

  // below media query will target only on safari
  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      .MuiAutocomplete-popper {
        background-color: ${baseVariables.color.merch50};
      }
    }
  }
`;

export const TextInputField = styled(TextField)`
  .MuiInput-underline::after {
    border-bottom: 0 !important;
  }

  .MuiInput-underline::before {
    border-bottom: 0 !important;
  }

  .MuiInputBase-input,
  .MuiInputBase-inputTypeSearch {
    ${rtl`
    &:is(${constants.RTL_LANG_LIST}) {
      text-align: right;
    }
  `}
    text-align: left;
    height: ${toRem(26)};
    font-weight: 700 !important;
    font-size: ${toRem(16)} !important;
    line-height: ${toRem(20)} !important;
    padding: 0 !important;
    border-bottom: 0 !important;
    &:hover {
      border-bottom: 0 !important;
    }
    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      font-size: ${toRem(22)} !important;
      line-height: ${toRem(33)} !important;
    }
  }
`;

export const StyledLoader = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .m-spinner-m {
    z-index: 99;
  }
`;

export const GlobalStyledFullForm = createGlobalStyle`
  body {
    overflow: hidden;
  }

  .MuiAutocomplete-popperDisablePortal {
    height: 100vh;
  }
`;
