export const AccuWeatherMock = {
  imageAltText: 'AccuWeather Logo',
  selectPositioning: 'nearbyAccuweather',
  ':type': 'mi-aem-ppv5-spa/components/content/accuWeather',
  weatherForecast: 'Weather Forecast',
  daysWeatherForecast: 5,
  daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  accuWeatherImage:
    'https://cache.marriott.com/content/dam/marriott-digital/mcomppv5/global/en_us/logo/assets/pdt-AccuWeather-Logo-168720693221076.png',
};

//create the date
const myDate = new Date();

export const AccuWeatherHeaderMock = {
  imageAltText: 'AccuWeather Logo',
  selectPositioning: 'headerAccuweather',
  ':type': 'mi-aem-ppv5-spa/components/content/accuWeather',
  weatherForecast: 'Weather Forecast',
  daysWeatherForecast: 1,
  daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  accuWeatherImage:
    'https://cache.marriott.com/content/dam/marriott-digital/mcomppv5/global/en_us/logo/assets/pdt-AccuWeather-Logo-168720693221076.png',
};

export const AccuWeatherMockUXL = {
  property: {
    weather: {
      hourly: [
        {
          dateTime: myDate,
          weatherIcon: 11,
          temperature: {
            value: 42,
            unit: 'F',
            __typename: 'Temperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/hourly-weather-forecast/2627514?day=1&hbhhour=12&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/hourly-weather-forecast/2627514?day=1&hbhhour=12&lang=en-us',
          __typename: 'HourlyWeatherDetail',
        },
      ],
      tenDay: [
        {
          date: new Date(myDate.setDate(myDate.getDate() + 1)),
          temperature: {
            maximum: {
              value: 46,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=1&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=1&lang=en-us',
          day: {
            weatherIcon: 18,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 2)),
          temperature: {
            maximum: {
              value: 53,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=2&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=2&lang=en-us',
          day: {
            weatherIcon: 18,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 3)),
          temperature: {
            maximum: {
              value: 59,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=3&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=3&lang=en-us',
          day: {
            weatherIcon: 12,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 4)),
          temperature: {
            maximum: {
              value: 50,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=4&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=4&lang=en-us',
          day: {
            weatherIcon: 6,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 5)),
          temperature: {
            maximum: {
              value: 41,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=5&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=5&lang=en-us',
          day: {
            weatherIcon: 18,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 6)),
          temperature: {
            maximum: {
              value: 35,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=6&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=6&lang=en-us',
          day: {
            weatherIcon: 3,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 7)),
          temperature: {
            maximum: {
              value: 36,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=7&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=7&lang=en-us',
          day: {
            weatherIcon: 1,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 8)),
          temperature: {
            maximum: {
              value: 45,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=8&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=8&lang=en-us',
          day: {
            weatherIcon: 4,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 9)),
          temperature: {
            maximum: {
              value: 50,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=9&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=9&lang=en-us',
          day: {
            weatherIcon: 7,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
        {
          date: new Date(myDate.setDate(myDate.getDate() + 10)),
          temperature: {
            maximum: {
              value: 53,
              unit: 'F',
              __typename: 'Temperature',
            },
            __typename: 'HotelWeatherDailyTemperature',
          },
          mobileLink:
            'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=10&lang=en-us',
          link: 'http://www.accuweather.com/en/us/theater-district-ny/10036/daily-weather-forecast/2627514?day=10&lang=en-us',
          day: {
            weatherIcon: 18,
            __typename: 'HotelWeatherDailyForecastDetails',
          },
          __typename: 'HotelWeatherDailyForecast',
        },
      ],
      __typename: 'PropertyWeather',
    },
    __typename: 'Hotel',
  },
};

export const mockPageParamContext = {
  preProcessorResponse: {
    userSegment: 'NOT Logged In',
    validMarsha: true,
    status: 'SUCCESS',
    message: '',
    statusCode: '200',
    brandCode: 'MC',
    experienceSegment: 'Connected-Unknown-Tier1-Guest',
    locale: 'en-US',
    subDirectory: 'en-US',
    currentStateMapping: {
      title: 'tier1Title',
      welcomeText: 'welcomeText',
      description: 'unknownConnectedDescription',
      isConnectState: false,
      connectUpgradeBtn: 'upgradeButtonLabel',
      signInButton: {
        signInButtonLink: '',
        signInButtonLabel: '',
        signInButtonLinkInNewTab: '',
      },
      joinNowButton: {
        joinButtonLabel: 'joinButtonLabel',
        joinButtonLink: 'joinButtonLink',
        joinButtonLinkInNewTab: 'joinButtonLinkInNewTab',
      },
      termsAndConditions: 'termsAndConditions',
      signInText: '',
      noLoginInterfaceModal: false,
      showLoginModal: false,
      loginModal: {
        default: false,
        separatorLabel: false,
        userAccessCodeLabel: false,
        requestAccessCodeLabel: false,
        errorMessage: false,
        connectNowLabel: false,
      },
      showAccessModal: false,
      accessModal: {
        default: false,
        acErrorMessage: false,
      },
      showReqAccessModal: false,
      reqAccessModal: {
        default: false,
        racErrorMessage: false,
      },
      showUpgradeGenErr: false,
      showUpgradeLRErr: false,
      showUpgradeMaxErr: false,
      showUpgradeNoCCErr: false,
      showUpgradePMSErr: false,
      showUpgradeSuccessNAInfo: false,
      showUpgradeSuccessMAXInfo: false,
      showPricingModal: false,
      showUpgradeModal: false,
    },
    cna: false,
    mac: false,
    sameTab: true,
    marsha: 'nycmq',
    state: 'connected',
    userState: 'unknown',
    cookie: 'sessionID=0E8AAD48-4931-5F2E-808A-BB1F0A8B89BF',
  },
  sessionID: '',
  requestId: '/einterface/v5/nycmq~X~694F9E51-9538-556F-AF11-D009CB1F4061',
  lang: 'en-US',
};
