import { toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledInlineEnrolment = styled.div`
  .join-bonvoy {
    margin-bottom: ${toRem(-8)};
  }
  .tnc {
    .m-link-action {
      background: transparent;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto ${toRem(1)};
      }
    }
  }
  label {
    width: fit-content;
  }
`;
