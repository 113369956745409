import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { golfOverviewPage } from '../../modules/utils/constants/constants';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledHeroBannerCarousel = styled.div`
  .outlet-hero-banner--img {
    width: 100%;
    aspect-ratio: 1/1;
    max-height: ${toRem(310)};

    @media ${baseVariables.mediaQuery.md} {
      aspect-ratio: 2/1;
      max-height: ${toRem(480)};
    }

    @media ${baseVariables.mediaQuery.md} {
      aspect-ratio: 3/1;
      max-height: ${toRem(480)};
    }

    @media ${baseVariables.mediaQuery.md} {
      max-height: unset;
    }
  }

  .spinner-wrapper {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .m-spinner-m {
      z-index: 99;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .rnb-search-form {
    display: none !important;
  }
  .spinner-wrapper {
    min-height: ${toRem(500)};
  }
`;

export const ShowMiniCards = createGlobalStyle`
  .recommended-mini-card-wrapper {
    display: block !important;
  }
`;

interface StyledOverlayProps {
  iconPath: boolean;
}

export const StyledOverlayContainer = styled.div<StyledOverlayProps>`
  position: relative;
  .overlay-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${props => (props.iconPath ? '100%' : '120%')};
    width: 100%;
    opacity: ${props => (props.iconPath ? '0.7' : 'initial')};
    background: ${props => (props.iconPath ? `${baseVariables.color.base10}` : 'none')};

    .icon-dining {
      font-size: ${toRem(24)};
      line-height: ${toRem(28)};
      border-bottom: ${toRem(5)} solid #ff8d6b;
      padding-bottom: ${toRem(12)};
      @media ${baseVariables.mediaQuery.md && baseVariables.mediaQuery.lg} {
        font-size: ${toRem(32)};
        line-height: ${toRem(36)};
        border-bottom: ${toRem(7)} solid #ff8d6b;
        padding-bottom: ${toRem(16)};
      }
    }
  }
`;

interface CityPageVariationProps {
  pageType: string;
}

export const StyledCityPageHeroBanner = styled.div<CityPageVariationProps>`
  position: relative;

  img {
    width: 100%;
    aspect-ratio: ${props => (props.pageType === golfOverviewPage ? '2/3' : '1/1')};
  }

  @media ${baseVariables.mediaQuery.sm} {
    img {
      aspect-ratio: ${props => (props.pageType === golfOverviewPage ? '5/2' : '1/1')};
    }
  }

  @media ${baseVariables.mediaQuery.md} {
    img {
      aspect-ratio: ${props => (props.pageType === golfOverviewPage ? '5/2' : '3/1')};
    }
  }

  .bl_iconText-wrapper {
    position: absolute;
    padding: ${toRem(3)} ${toRem(8)};
    background-color: rgba(28, 28, 28, ${baseVariables.opacity['opacity5']});
    border-radius: ${toRem(5)};
    right: ${toRem(46)};
    bottom: ${toRem(14)};

    @media ${baseVariables.mediaQuery.sm} {
      right: ${toRem(38)};
      bottom: ${toRem(14)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      right: ${toRem(158)};
      bottom: ${toRem(35)};
    }

    ${rtl`
      &:is(${constants.RTL_LANG_LIST}) {
        left: ${toRem(46)};
        right: unset;
        
        @media ${baseVariables.mediaQuery.sm} {
          left: ${toRem(38)};
          right: unset;
        }

        @media ${baseVariables.mediaQuery.lg} {
          left: ${toRem(158)};
          right: unset;
        }
      }
    `}
  }
`;

export const StyledCampaignHeroBanner = styled.div<{ styleClass: string }>`
  position: relative;
  .bl__cnt-sec {
    position: absolute;
    display: flex;
    align-items: end;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    @media ${baseVariables.mediaQuery.sm} {
      align-items: center;
    }

    .banner-header {
      text-align: left;
    }
  }
  .bl__cnt-ctr {
    position: relative;
    z-index: 1;
    margin: 0 auto ${toRem(24)} auto;
    @media ${baseVariables.mediaQuery.sm} {
      margin: 0 auto;
    }
    @media ${baseVariables.mediaQuery.sm} {
      padding-bottom: ${toRem(0)};
    }
  }
  img {
    width: 100%;
    aspect-ratio: 1/1;
    @media ${baseVariables.mediaQuery.md} {
      aspect-ratio: 3/1;
    }
  }
  .bl_iconText-wrapper {
    position: absolute;
    padding: ${toRem(3)} ${toRem(8)};
    background-color: rgba(28, 28, 28, ${baseVariables.opacity['opacity5']});
    border-radius: ${toRem(5)};
    right: ${toRem(27)};
    top: ${toRem(16)};
    @media ${baseVariables.mediaQuery.sm} {
      right: ${toRem(27)};
      top: ${toRem(16)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      right: ${toRem(16)};
      top: ${toRem(16)};
    }
  }

  .gradient {
    &:after {
      height: 60%;
      background: ${props =>
        props?.styleClass?.includes('inverse')
          ? `${baseVariables.gradient['baseGradient05']}`
          : `${baseVariables.gradient['baseGradient01']}`};
      @media ${baseVariables.mediaQuery.sm} {
        height: 100%;
        background: ${props =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient06']}`
            : `${baseVariables.gradient['baseGradient02']}`};
      }
      @media ${baseVariables.mediaQuery.lg} {
        height: 100%;
        background: ${props =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient07']}`
            : `${baseVariables.gradient['baseGradient03']}`};
      }
      display: block;
      width: 100%;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  ${rtl`
  &:is(${constants.RTL_LANG_LIST}){

    .bl__cnt-sec {
      .banner-header {
        text-align: right;
      }
    }
    .bl_iconText-wrapper {
      position: absolute;
      right: unset;
      left: ${toRem(27)};
      top: ${toRem(16)};
   
      @media ${baseVariables.mediaQuery.sm} {
        left: ${toRem(27)};
        top: ${toRem(16)};
      }
    
      @media ${baseVariables.mediaQuery.lg} {
        left: ${toRem(16)};
        top: ${toRem(16)};
      }
    }

    .rnb-earn-redeem-rtl, .campaign-title, .bl_content {
      text-align: right;
    }

    .banner-layerd-anchor {
      padding-right: ${toRem(8)};
    }

    .bl_sec-cta {
      margin-right: 0;

      @media ${baseVariables.mediaQuery.sm}, @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        margin-right: ${toRem(16)};
      }
    }

    .gradient {
      &:after {
        background: ${(props: { styleClass: string }) =>
          props?.styleClass?.includes('inverse')
            ? `${baseVariables.gradient['baseGradient05']}`
            : `${baseVariables.gradient['baseGradient01']}`};
        @media ${baseVariables.mediaQuery.sm} {
          background: ${(props: { styleClass: string }) =>
            props?.styleClass?.includes('inverse')
              ? 'linear-gradient(90deg,rgba(0,0,0,0) 26.95%,rgba(0,0,0,0.54) 67.69%,rgba(0,0,0,0.62) 100%)'
              : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 26.95%, rgba(255, 255, 255, 0.46) 66.99%, rgba(255, 255, 255, 0.54) 100%)'};
        }
        @media ${baseVariables.mediaQuery.lg} {
          background: ${(props: { styleClass: string }) =>
            props?.styleClass?.includes('inverse')
              ? 'linear-gradient(90deg,rgba(0,0,0,0) 26.95%,rgba(0,0,0,0.54) 67.69%,rgba(0,0,0,0.62) 100%)'
              : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 26.95%, rgba(255, 255, 255, 0.46) 66.99%, rgba(255, 255, 255, 0.54) 100%)'};
        }
      }
    }
  }
`}
`;
