/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Button, RichText } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useLazyQuery, useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import {
  outletGalleryImages,
  offerSearchByOutlets,
  outletMenuAndSignatureCount,
  outletChefFeatureDetails,
} from '@marriott/mi-rnb-graphql';
import { PageParamsContext } from '../../../modules/context/PageContext';
import { IMAGE_URL_DOMAIN, menuId } from '../../../modules/utils/constants';
import { getDirection } from '../../../modules/utils/helpers';

import { OfferDataTypes } from '../OfferTab/OfferTab.types';
// Tabs
import { StyledOutletTabContainer } from './OutletTab.styles';
import { OverviewTab } from '../OverviewTab/index';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { outletDetailsState, useStore } from 'libs/mi-rnb-components/src/modules/store/outletDetailsStore';
import { scrollSection } from '../../../modules/utils/helpers';

// Dynamic Imports
const GalleryTab = dynamic(() => import('../GalleryTab/GalleryTab'));
const OfferTab = dynamic<OfferDataTypes>(() => import('../OfferTab').then(mod => mod.OfferTab));
const MenuTab = dynamic<any>(() => import('../MenuTab').then(mod => mod.MenuTab));
const SignatureTab = dynamic<any>(() => import('../SignatureTab/SignatureTab').then(mod => mod.SignatureTab));
const ChefFeatureTab = dynamic<any>(() => import('../ChefFeatureTab/ChefFeatureTab'));

interface TabType {
  title: string;
  index: number;
  render: () => ReactNode;
}

export const OutletTab = (props: any) => {
  const [selectedTabIndex, setselectedTabIndex] = useState<number>(0);
  const { marsha, outletId } = useContext(PageParamsContext);
  const outletData = useStore((state: outletDetailsState) => state.outletData);
  const outletGalleryTabData = useStore((state: outletDetailsState) => state.outletGalleryTabData);
  const setGalleryTabData = useStore((state: outletDetailsState) => state.setGalleryTabData);
  const outletGalleryError = useStore((state: outletDetailsState) => state.outletGalleryError);
  const setGalleryError = useStore((state: outletDetailsState) => state.setGalleryError);
  const outletChefFeatureData = useStore((state: outletDetailsState) => state.outletChefFeatureData);
  const setChefTabData = useStore((state: outletDetailsState) => state.setChefTabData);
  const outletChefFeatureError = useStore((state: outletDetailsState) => state.outletChefFeatureError);
  const setChefFeatureError = useStore((state: outletDetailsState) => state.setChefFeatureError);
  const setMenuCountError = useStore((state: outletDetailsState) => state.setMenuCountError);
  const setSignatureError = useStore((state: outletDetailsState) => state.setSignatureError);
  const menuCount = useStore((state: outletDetailsState) => state.menuCount);
  const setMenuCount = useStore((state: outletDetailsState) => state.setMenuCount);
  const signatureCount = useStore((state: outletDetailsState) => state.signatureCount);
  const setSignatureCount = useStore((state: outletDetailsState) => state.setSignatureCount);
  const outletOffersData = useStore((state: outletDetailsState) => state.outletOffersData);
  const setOutletOffersData = useStore((state: outletDetailsState) => state.setOutletOffersData);
  const outletOffersError = useStore((state: outletDetailsState) => state.outletOffersError);
  const setOutletOfferError = useStore((state: outletDetailsState) => state.setOutletOfferError);
  const outletMenuCountError = useStore((state: outletDetailsState) => state.outletMenuCountError);

  const imageArr: string[] = [];

  const overviewTitle = props?.overviewLabel;
  const cheffeatureTitle = props?.chefFeatureLabel;
  const galleryTitle = props?.galleryLabel;
  const signatureTitle = props?.signatureDishesLabel;
  const menuTitle = props?.menuLabel;
  const offerTitle = props?.offersLabel;

  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();

  const pageContext = useContext(createContext<any>({}));
  const [menuDetailsErrorMessage, setMenuDetailsErrorMessage] = useState('');
  const [showMenuDetailsError, setShowMenuDetailsError] = useState(false);
  const [errorStyleClass, setErrorStyleClass] = useState('');
  const scrollableSectionRef = useRef<HTMLUListElement | null>(null);
  const isDirectionRTL = getDirection() === 'rtl';
  const { currentLocale } = useContext(PageParamsContext);

  const skipQuery = useMemo(() => {
    return props?.isAuthorMode || outletGalleryTabData || outletGalleryError;
  }, [props?.isAuthorMode, outletGalleryTabData, outletGalleryError]);

  const skipChefQuery = useMemo(() => {
    return props?.isAuthorMode || outletChefFeatureError || outletChefFeatureData !== null;
  }, [props?.isAuthorMode, outletChefFeatureError, outletChefFeatureData]);

  const skipOffersQuery = useMemo(() => {
    return props?.isAuthorMode || outletOffersError || outletOffersData !== null;
  }, [props?.isAuthorMode, outletOffersError, outletOffersData]);

  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');

  const [tabList, setTabList] = useState<TabType[]>([
    { title: overviewTitle, index: 0, render: () => <OverviewTab {...props} /> },
  ]);

  const menuErrorDetails = (isMenuError: boolean, menuErrorMessage: string, errorClass: string) => {
    setShowMenuDetailsError(isMenuError);
    setMenuDetailsErrorMessage(menuErrorMessage);
    setErrorStyleClass(errorClass);
  };

  const getChefTab = (chefTabData: any): TabType | null => {
    if (chefTabData?.name && chefTabData?.description) {
      return {
        title: cheffeatureTitle,
        index: 5,
        render: () => <ChefFeatureTab chefData={chefTabData} {...props} />,
      };
    }
    return null;
  };

  useQuery(outletGalleryImages, {
    variables: {
      propertyId: marsha,
      restaurantId: Number(outletId),
      version: 'V2',
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
        'x-request-id': requestId,
        'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
        'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
      },
    },
    skip: skipQuery,
    errorPolicy: 'all',
    onCompleted: data => {
      setGalleryTabData(data);
    },
    onError: () => {
      setGalleryError(true);
    },
  });

  useEffect(() => {
    if (outletGalleryTabData) {
      let filteredTabList = false;
      for (const item of tabList) {
        if (item?.title === galleryTitle) {
          filteredTabList = true;
          break; // Exit the loop as soon as the title is found
        }
      }
      if (!filteredTabList) {
        if (outletGalleryTabData && outletGalleryTabData?.restaurantById?.media?.imageConnection?.edges?.length > 0) {
          outletGalleryTabData?.restaurantById?.media?.imageConnection?.edges?.forEach((item: any) => {
            if (item?.node?.imageUrls && item?.node?.imageUrls?.classicHorizontal?.trim()) {
              const imageUrl = `${IMAGE_URL_DOMAIN}${item?.node?.imageUrls?.classicHorizontal}?downsize=400:*`;
              imageArr.push(imageUrl);
            }
          });

          setTabList((prevState: TabType[]) => {
            const newTablist = [...prevState];

            newTablist.splice(1, 0, {
              title: galleryTitle,
              index: 2,
              render: () => <GalleryTab galleryData={imageArr} />,
            });
            return newTablist;
          });
        }
      }
    }
  }, [outletGalleryTabData]);

  const [getMenuSignatureCount, { error, data }] = useLazyQuery(outletMenuAndSignatureCount);

  useEffect(() => {
    getMenuSignatureCount({
      variables: {
        propertyId: marsha,
        restaurantId: Number(outletId),
        architecturalServicesAssetPlacement: false,
        version: 'V2',
      },
      context: {
        headers: {
          // Any header, including x-request-id, can be passed in args with query.
          // If you don't pass it in the authLink will generate a random ID.
          'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
          'x-request-id': requestId,
          'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
          'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        },
      },
      errorPolicy: 'all',
    });
  }, []);

  useEffect(() => {
    if (error && !data) {
      setMenuCountError(true);
      setSignatureError(true);
      setMenuCount(0);
      setSignatureCount(0);
    } else if (data) {
      if (data?.restaurantById?.menuCount && data?.restaurantById?.menuCount > 0) {
        setMenuCount(data?.restaurantById?.menuCount);
      }

      if (data?.restaurantById?.signatureDishCount && data?.restaurantById?.signatureDishCount > 0) {
        setSignatureCount(data?.restaurantById?.signatureDishCount);
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (menuCount > 0 || outletData?.basicInformation?.menuURL) {
      let filteredTabList = false;
      for (const item of tabList) {
        if (item?.title === menuTitle) {
          filteredTabList = true;
          break; // Exit the loop as soon as the title is found
        }
      }
      if (!filteredTabList) {
        setTabList((prevState: TabType[]) => {
          const newTablist = [...prevState];

          newTablist.push({
            title: menuTitle,
            index: 3,
            render: () => (
              <MenuTab {...props} marsha={marsha} outletId={outletId} menuErrorDetails={menuErrorDetails} />
            ),
          });
          return newTablist;
        });
      }
    }
  }, [menuCount]);

  useEffect(() => {
    if (signatureCount > 0) {
      let filteredTabList = false;
      for (const item of tabList) {
        if (item?.title === signatureTitle) {
          filteredTabList = true;
          break; // Exit the loop as soon as the title is found
        }
      }
      if (!filteredTabList) {
        setTabList((prevState: TabType[]) => {
          const newTablist = [...prevState];

          newTablist.push({
            title: signatureTitle,
            index: 4,
            render: () => <SignatureTab {...props} marsha={marsha} outletId={outletId} currentLocale={currentLocale} />,
          });
          return newTablist;
        });
      }
    }
  }, [signatureCount]);

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useQuery(outletChefFeatureDetails, {
      variables: {
        propertyId: marsha,
        restaurantId: Number(outletId),
        architecturalServicesAssetPlacement: false,
        version: 'V2',
      },
      context: {
        headers: {
          // Any header, including x-request-id, can be passed in args with query.
          // If you don't pass it in the authLink will generate a random ID.
          'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
          'x-request-id': requestId,
          'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
          'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        },
      },
      skip: skipChefQuery,
      errorPolicy: 'all',
    });

    if (data) {
      setChefTabData(data);
    }
  } catch (error) {
    if (error) {
      setChefFeatureError(true);
    }
  }

  useEffect(() => {
    if (outletChefFeatureData?.restaurantById) {
      const chefTab = getChefTab(outletChefFeatureData?.restaurantById?.chef);

      setTabList((prevState: TabType[]) => {
        const newTablist = [...prevState];
        if (chefTab) newTablist.push(chefTab);
        return newTablist;
      });
    }
  }, [outletChefFeatureData]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.has(menuId) && outletMenuCountError && !outletData?.basicInformation?.menuURL) {
      setShowMenuDetailsError(true);
      setMenuDetailsErrorMessage(props?.menuUrlError);
      setErrorStyleClass('info');

      setTabList((prevState: TabType[]) => {
        const newTablist = [...prevState];
        newTablist.push({
          title: menuTitle,
          index: 3,
          render: () => <MenuTab {...props} menuData={[]} menuErrorDetails={menuErrorDetails} />,
        });
        return newTablist;
      });
    }
  }, [outletMenuCountError]);

  useQuery(offerSearchByOutlets, {
    variables: {
      input: {
        queries: [
          {
            id: 'outlets',
            values: outletId,
          },
        ],
      },
      ...(props?.sortTypes && {
        sort: {
          field: props?.sortTypes,
        },
      }),
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
        'x-request-id': requestId,
        'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
        'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
      },
    },
    skip: skipOffersQuery,
    errorPolicy: 'all',
    onCompleted: data => {
      if (data) {
        setOutletOffersData(data);
      }
    },
    onError: data => {
      if (data) {
        setOutletOfferError(true);
      }
    },
  });

  useEffect(() => {
    if (outletOffersData) {
      if (outletOffersData?.offersSearch?.edges?.length > 0) {
        let filteredTabList = false;
        for (const item of tabList) {
          if (item?.title === offerTitle) {
            filteredTabList = true;
            break; // Exit the loop as soon as the title is found
          }
        }
        if (!filteredTabList) {
          setTabList((prevState: TabType[]) => [
            ...prevState,
            {
              title: offerTitle,
              index: 1,
              id: 'offertab',
              render: () => <OfferTab aemProps={props} offerData={outletOffersData?.offersSearch} />,
            },
          ]);
        }
      }
    }
  }, [outletOffersData]);

  useEffect(() => {
    scrollSection(scrollableSectionRef);
  }, [props]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (tabList?.length && query?.has(menuId)) {
      const menuIndex = tabList?.findIndex(tabItem => tabItem.title === menuTitle);
      if (menuIndex >= 0) setselectedTabIndex(menuIndex);
    }
  }, [window.location.search, tabList]);

  return (
    <StyledOutletTabContainer>
      <ul role="tablist" className="m-standard-tab-list p-0 mb-3 scrollable-section" ref={scrollableSectionRef}>
        {tabList?.map(
          (tab: TabType, index: number) =>
            tab && (
              <li
                role="tab"
                className={clsx(
                  'm-standard-tab-list-item mb-0 pb-3',
                  index === selectedTabIndex ? 'active' : '',
                  isDirectionRTL ? 'mr-0 ml-4' : 'mr-4 ml-0'
                )}
                onClick={e => {
                  setselectedTabIndex(index);
                  e.currentTarget.focus();
                }}
                key={tab?.index}
                tabIndex={index === selectedTabIndex ? 0 : -1}
                data-testid={tab?.title}
              >
                <Button id={'tab_' + tab?.title} data-toggle="tab" aria-controls="" aria-selected="true">
                  <span className="item-heading t-subtitle-m">{tab?.title}</span>
                </Button>
              </li>
            )
        )}
      </ul>
      {showMenuDetailsError && (
        <div className={clsx('m-message-inline col-12 rnb-error-message mt-3 mb-3', errorStyleClass)}>
          <div className="m-message-content-wrap">
            <div className="m-message-content">
              <RichText text={menuDetailsErrorMessage} componentId="menu-error-message" />
            </div>
          </div>
        </div>
      )}
      {tabList[selectedTabIndex].render()}
    </StyledOutletTabContainer>
  );
};
