import React, { FC } from 'react';
import { ProgressTrackerProps } from './ProgressTracker.types';
import { StyledProgressTracker } from './ProgressTracker.styles';

export const ProgressTracker: FC<ProgressTrackerProps> = ({ steps, currentStep }) => (
  <StyledProgressTracker data-component-name="m-groups-ProgressTracker" data-testid="groups-ProgressTracker">
    <ul className="progress-tracker-wrapper">
      {steps.map((step, index) => {
        const status = index + 1 < currentStep ? 'completed' : index + 1 === currentStep ? 'active' : '';
        return (
          <li key={index} className={`progress-tracker-item ${status}`}>
            <span className="progress-tracker-counter">{index + 1}</span>
            <span className="progress-tracker-label">{step}</span>
          </li>
        );
      })}
    </ul>
  </StyledProgressTracker>
);
