// Styles for __name__ go here.
import styled, { createGlobalStyle } from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';
import { constants } from '../../modules/utils/constants/constants';

export const StyledCampaignContainer = styled.div`
  padding-top: ${toRem(8)};
  padding-bottom: ${toRem(24)};

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(48)};
  }
`;

export const StyledOffersCardContainer = styled.div`
  .custom-scrollbar {
    max-width: ${toRem(948)};
  }

  .offers-card-container {
    display: grid;
    gap: ${toRem(16)};
    grid-template-columns: repeat(1, 1fr);

    @media ${baseVariables.mediaQuery.md} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
      gap: ${toRem(24)};
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .offers-overlay {
    &__header {
      padding: ${toRem(24)};
      height: auto;

      .icon-decorative {
        font-size: ${toRem(24)};
      }

      @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.lg},
        @media ${baseVariables.mediaQuery.xl} {
        padding: ${toRem(32)};

        .icon-decorative {
          font-size: ${toRem(32)};
          line-height: ${toRem(32)};
        }
      }
    }

    &__body {
      ::-webkit-scrollbar {
        width: ${toRem(14)};
      }

      ::-webkit-scrollbar-thumb {
        border: ${toRem(4)} solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        opacity: 0.5;
        border-radius: ${toRem(50)};
        background-color: ${baseVariables.color.neutral30};
      }

      ::-webkit-scrollbar-track {
        background-color: ${baseVariables.color.base20};
      }
    }

    &__body,
    &__footer {
      padding: ${toRem(24)} !important;

      @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.lg},
        @media ${baseVariables.mediaQuery.xl} {
        padding: ${toRem(32)} !important;
      }
    }

    &__footer > div {
      flex-direction: column-reverse;
      row-gap: ${toRem(16)};

      @media ${baseVariables.mediaQuery.sm},
        @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.lg},
        @media ${baseVariables.mediaQuery.xl} {
        flex-direction: row;
      }
    }
  }
  .custom-scrollbar {
    bottom: ${toRem(30)};
  }

  .rnb-pagination .rnb-pagination-arrow.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .rnb-pagination {
    margin-bottom: 0;
  }

  .m-message-content {
    p {
      margin: 0;
    }
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
      .m-badge-inline {
        right: unset;
        left: ${toRem(32)};
      }

      .offers-card__details-container_top {
        text-align: right;  
      }

      .offers-card__title {
        padding-left: unset;
        padding-right: ${toRem(8)};
      }

      .offers-card__tertiary-link {
        margin-left: ${toRem(27)};
        margin-right: unset;

        &::after {
          content: "\\e927";
          padding-left: unset;
          padding-right: ${toRem(8)};
        }
      }

      .m-message-content-wrap {
        &::before {
          margin-left: ${toRem(8)};
          margin-right: 0 !important;
        }
      }
    }`}
`;

export const StyledLoader = styled.div`
  height: 90vh;
  text-align: center;

  .m-spinner-m {
    position: fixed;
    top: 45%;
    margin: auto;
  }

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    height: 96vh;
  }
`;

export const StyledErrorContainer = styled.div`
  min-height: ${toRem(300)};
  padding-top: ${toRem(24)};

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    padding-top: ${toRem(48)};
  }

  .m-message-content {
    p {
      margin: 0;
    }
  }

  ${rtl`
    &:is(${constants.RTL_LANG_LIST}){
      .m-message-content-wrap {
        &::before {
          margin-left: ${toRem(8)};
          margin-right: 0 !important;
        }
      }
  }`}
`;

export const StyledCampaignContainerLoader = styled.div`
  min-height: ${toRem(500)};
`;

export const HideGlobalHeader = createGlobalStyle`
  .m-header {
    top: -54px !important;

   @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
      top: -114px !important;
    }
  }
`;
