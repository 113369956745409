import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, mediaQuery, font } = baseVariables;

export const StyledSignInModal = styled.div`
  .sign-in-modal {
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;

    .modal-header {
      border: none;
      padding: ${toRem(24)} ${toRem(24)} ${toRem(24)} ${toRem(16)};
      .header-heading {
        font-size: ${font.fontL};
        line-height: ${font.lineHeightL};
        font-weight: ${font.fontWeightMedium};
      }
    }

    .modal-body {
      display: grid;
      grid-template-columns: 1fr;
      max-height: 70vh;
      padding: 0 ${toRem(16)} ${toRem(48)};
      border-radius: 0;
      .button {
        display: block;
        width: 100%;
        text-align: center;
      }
      .sign-in {
        border-bottom: ${toRem(1)} solid ${color.neutral30};
        padding-bottom: ${toRem(24)};
        .divider {
          display: none;
          border-top: ${toRem(1)} solid ${color.neutral30};
          margin-bottom: ${toRem(24)};
        }
      }
      .join-now {
        &-title {
          font-size: ${font.fontL};
          line-height: ${font.lineHeightL};
          font-weight: ${font.fontWeightMedium};
        }
        &-image {
          display: block;
          width: 100%;
          height: ${toRem(152)};
          margin: ${toRem(16)} 0 ${toRem(28)};
          border-radius: ${toRem(14)};
        }
        &-benefit {
          font-size: ${font.fontXs};
          line-height: ${font.lineHeightS};
          &::before {
            font-size: ${font.fontL};
            padding-right: ${toRem(10)};
          }
        }
      }
    }
  }

  @media ${mediaQuery.md} {
    .sign-in-modal {
      border-radius: ${toRem(14)};

      .modal-header {
        justify-content: flex-end;
        height: fit-content;
        padding: ${toRem(24)} ${toRem(32)} ${toRem(10)};
      }

      .modal-body {
        max-height: fit-content;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: ${toRem(40)};
        padding: 0 ${toRem(32)} ${toRem(48)};
        .sign-in {
          border-bottom: none;
          padding-bottom: 0;
          .divider {
            display: block;
          }
        }
        .join-now {
          &-image {
            margin-top: ${toRem(32)};
          }
        }
      }
    }
  }

  @media ${mediaQuery.lg} {
    .sign-in-modal {
      max-width: ${toRem(932)};

      .modal-header {
        padding: ${toRem(16)} ${toRem(16)} ${toRem(18)};
      }

      .modal-body {
        padding: 0 ${toRem(94)} ${toRem(66)};
        gap: ${toRem(110)};
        .button {
          width: ${toRem(268)};
          margin: auto;
        }
        .sign-in {
          .divider {
            margin: ${toRem(30)} 0;
          }
        }
        .join-now {
          .button {
            margin-top: ${toRem(10)};
          }
        }
      }
    }
  }
`;
