import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { border, color, font, spacers } = baseVariables;

export const StyledTextAreaFormField = styled.div`
  textarea {
    border: ${border.borderWidth02} ${border.borderStyleDefault} ${color.neutral40};
    border-radius: ${border.borderRadius02};
    font-size: ${font.fontM};
    line-height: ${font.lineHeightXl};
    min-height: ${toRem(160)};
    padding: ${spacers.spacer};
    width: 100%;

    ::placeholder {
      font-size: ${font.fontM};
      line-height: ${font.lineHeightXl};
      margin: ${spacers.spacer};
    }

    :focus {
      border-color: ${color.base10};
    }
  }

  &.is-error {
    textarea {
      border-color: ${color.alert50};
    }
    .error-text {
      color: ${color.alert50};
      display: flex;
      line-height: 1;
    }
  }

  &.disable-resize {
    textarea {
      resize: none;
    }
  }
`;
