import React, { FC } from 'react';
import { Link, Modal, RichText } from '@marriott/mi-ui-library';
import { TncModalProps } from './TncModal.types';
import { StyledTncModal } from './TncModal.styles';

export const TncModal: FC<TncModalProps> = props => {
  const { labels, show, onCloseModal } = props;

  return (
    <StyledTncModal>
      <Modal
        modalId="tncModal"
        role="dialog"
        labelledBy="tncModal"
        secondaryClassName="tnc-modal"
        show={show}
        popupOpenState={show}
        handleBlur={true}
        disableScrollOnBody={true}
        setPopupOpenState={onCloseModal}
        onClose={onCloseModal}
      >
        <Modal.Header
          labelText={labels.title}
          customHeadingClass="t-subtitle-xl"
          popupHeaderOnCLoseFunc={onCloseModal}
        ></Modal.Header>
        <Modal.Body className="custom-scrollbar pb-5">
          <>
            <RichText text={labels.content} customClass="t-font-m" componentId="tncContent" />
            {labels?.ctaLabel && (
              <div className="more-info-cta">
                <Link
                  text={labels.ctaLabel}
                  linkHref={labels.ctaLink as string}
                  linkClassName="t-subtitle-m"
                  target="_blank"
                />
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
    </StyledTncModal>
  );
};
