import React, { FC, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonBack, ButtonNext, CarouselContext, CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import clsx from 'clsx';
import { baseVariables, InlineMessages, InlineMessagesType } from '@marriott/mi-ui-library';
import { useMediaQuery, useMounted } from '../../hooks';
import { ENTER_KEY, TAB_KEY } from '../../constants';
import {
  EventSpaceConfigurationPickerProps,
  EventSpaceConfigurationOption,
} from './EventSpaceConfigurationPicker.types';
import { StyledEventSpaceConfigurationPicker } from './EventSpaceConfigurationPicker.styles';

const OPTIONS_PER_SLIDE = {
  DESKTOP: 10,
  TABLET: 7,
  MOBILE: 3,
};

const EventSpaceNavigation = ({ totalSlides }: { totalSlides: number }) => {
  const carouselContext = useContext(CarouselContext);
  const [activeSlide, setActiveSide] = useState(0);

  useEffect(() => {
    function onChange() {
      const currentSlide = carouselContext.state.currentSlide;
      setActiveSide(Math.ceil(currentSlide));
    }
    carouselContext?.subscribe(onChange);
    return () => carouselContext?.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <div className="d-flex justify-content-center">
      <div className="carouselControlType3b">
        <ButtonBack className={clsx('left-arrow', activeSlide === 0 && 'is-disabled')}>
          <span className="icon-arrow-left"></span>
        </ButtonBack>
        <div>
          {Array.from(Array(totalSlides)).map((_, index) => (
            <Dot slide={index} className={clsx(activeSlide === index && 'is-active')} />
          ))}
        </div>
        <ButtonNext className={clsx('right-arrow', activeSlide + 1 === totalSlides && 'is-disabled')}>
          <span className="icon-arrow-right"></span>
        </ButtonNext>
      </div>
    </div>
  );
};

export const EventSpaceConfigurationPicker: FC<EventSpaceConfigurationPickerProps> = ({
  name,
  ariaLabel,
  value,
  options = [],
  showErrorMessage,
  errorMessage = '',
  onChange,
  onKeyDown,
}) => {
  const isDesktop = useMediaQuery(baseVariables.mediaQuery.lg);
  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);

  const isMounted = useMounted();

  const configPickerRef = useRef<HTMLDivElement>(null);

  const optionRefs = useMemo(() => options.map(() => createRef<HTMLInputElement>()), [options]);
  const visibleSlides = useMemo(() => {
    if (isDesktop) return OPTIONS_PER_SLIDE.DESKTOP;
    if (isTablet) return OPTIONS_PER_SLIDE.TABLET;
    return OPTIONS_PER_SLIDE.MOBILE;
  }, [isDesktop, isTablet]);
  const activeOptionIndices = useMemo(
    () =>
      options.reduce((acc: number[], option, index) => {
        if (!option.disabled) {
          acc.push(index);
        }
        return acc;
      }, []),
    [options]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      onKeyDown(event, index);
    }

    if (event.shiftKey && event.key === TAB_KEY) {
      event.preventDefault();
      const activeIndex = activeOptionIndices.indexOf(index);
      const targetElement =
        activeIndex === 0 ? configPickerRef.current : optionRefs[activeOptionIndices[activeIndex - 1]].current;
      targetElement?.focus();
    }
  };

  if (!isMounted) {
    return null;
  }

  const eventSpaceOption = (option: EventSpaceConfigurationOption, index: number) => {
    return (
      <label htmlFor={option.value + name} className={clsx(option.disabled && 'is-disabled')}>
        <div className="d-flex mb-2 option-container">
          <span className={`${option.icon} m-auto`}></span>
        </div>
        <div className="d-inline-flex">
          <input
            type="radio"
            id={option.value + name}
            name={name}
            value={option.value}
            checked={option.value === value}
            disabled={option.disabled}
            ref={optionRefs[index]}
            onChange={onChange}
            onKeyDown={event => handleKeyDown(event, index)}
          />
          <span className="option-label">{option.label}</span>
        </div>
      </label>
    );
  };

  return (
    <StyledEventSpaceConfigurationPicker
      data-component-name="m-groups-EventSpaceConfigurationPicker"
      data-testid="groups-EventSpaceConfigurationPicker"
      ref={configPickerRef}
      aria-label={ariaLabel}
      role="region"
      tabIndex={0}
    >
      {showErrorMessage && (
        <div className="mb-4">
          <InlineMessages type={InlineMessagesType.Error} title={errorMessage} severity="1" />
        </div>
      )}
      <CarouselProvider
        naturalSlideWidth={80}
        naturalSlideHeight={110}
        totalSlides={options?.length}
        visibleSlides={visibleSlides + 0.1}
        isIntrinsicHeight={true}
        step={1}
        dragStep={1}
      >
        <Slider classNameTray="slider" aria-label={`${ariaLabel} slider`}>
          {options?.map((option, index) => (
            <Slide
              key={option.value}
              index={index}
              className="slide"
              tabIndex={option.disabled ? -1 : 0}
              onFocus={() => {
                const optionEl = optionRefs[index].current;
                optionEl?.focus();
              }}
            >
              {eventSpaceOption(option, index)}
            </Slide>
          ))}
        </Slider>
        {options?.length > visibleSlides + 1 ? (
          <EventSpaceNavigation totalSlides={options.length - visibleSlides + 1} />
        ) : null}
      </CarouselProvider>
    </StyledEventSpaceConfigurationPicker>
  );
};
