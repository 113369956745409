// Styles for LocalHeader go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables, contextualVariable } from '@marriott/mi-ui-library';

const $boxBoardShadow = '#eaeaea';

export const StyledLocalHeader = styled.div`
  ${rtl`
  .local-header {
    &::-webkit-scrollbar {
      display: none;
    }
  
    li {
      color: inherit;
      list-style-type: none;
    }
  
    &__menu {
      &-top {
        border-bottom: none;
        @media ${baseVariables.mediaQuery.lg} {
          border-bottom: solid ${toRem(1)} ${baseVariables.color.neutral30};
        }
        &-reserve {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${baseVariables.color.base20};
            z-index: 3;
  
            @media ${baseVariables.mediaQuery.xl} {
              position: unset;
            }
  
            &_button {
              font-size: ${toRem(12)}
            }
        }
        &-links {
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
            font-weight: ${baseVariables.font['fontWeightRegular']};
            color: inherit;
          }
        }
        &-left {
          border-bottom: solid ${toRem(1)} ${baseVariables.color.neutral30};
  
          @media ${baseVariables.mediaQuery.md} {
            border: none;
            flex: 1;
          }
  
          &--logo {
            max-height: ${toRem(48)};
            max-width: ${toRem(80)};
            @media ${baseVariables.mediaQuery.md} {
              max-height: ${toRem(49)};
              max-width: ${toRem(110)};
            }
            @media ${baseVariables.mediaQuery.xl} {
              max-height: ${toRem(58)};
              max-width: ${toRem(130)};
            }
          }
          &--title {
            max-width: inherit;
          }
          
        }
        &-right {
          .view-map-desktop,
          .view-map-mobile {
            text-decoration: underline;
         }
          
          &-phone {
              border-left: solid ${toRem(1)} ${baseVariables.color.neutral30};
              border-right: solid ${toRem(1)} ${baseVariables.color.neutral30};
              @media ${baseVariables.mediaQuery.md} {
                border-right: none;
              }
                @media ${baseVariables.mediaQuery.xl} {
                border-left: none;
                border-right: none;
            }
            &-link {
              :lang(ar-AE), 
              :lang(ar) {
                  direction: rtl;
                }
              }
          }
          &-location {
            .nonChineseContent {
              display: block;
            }
          
            .chineseContent {
              display: none;
            }
          
            .chinese-page & {
              .nonChineseContent {
                display: none;
              }
        
              .chineseContent {
                display: block;
              }
            }          
          }
        }
        &:only-child {
          border-bottom: none;
        }
      }
  
      &-bottom {
        border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
        box-shadow: inset 0 ${toRem(1)} 0 0 ${$boxBoardShadow}, inset 0 ${toRem(-1)} 0 0 ${$boxBoardShadow};
        @media ${baseVariables.mediaQuery.lg} {
          border-top: unset;
          box-shadow: unset;
        }
        &.sticky {
          position: fixed;
          top: ${toRem(60)};
          z-index: 9;
          background: ${baseVariables.color.base20};
          margin: 0;
          @media ${baseVariables.mediaQuery.md} {
            margin: 0 ${toRem(-32)};
          }
        }
        &.top-sticky {
          top: 0;
        }
        &-list {
            overflow-x: auto;
            overflow-y: hidden;
            &::-webkit-scrollbar {
              display: none;
          }
        }
        &-link {
          &.links {
            color: ${baseVariables.color.neutral40};
            padding-bottom: ${toRem(1)};
            white-space: nowrap;
            border-bottom: none;
            font-weight: inherit;
            @media ${baseVariables.mediaQuery.lg} {
              &:hover {
                color: ${baseVariables.color.base10};
                font-weight: ${baseVariables.font['fontWeightMedium']};
                text-decoration: none;
                // border-bottom: solid ${toRem(3)} ${contextualVariable.linkNavBorderColor};
              }           
}
            
            &:active,
            &.active {
              color: ${baseVariables.color.base10};
              font-weight: ${baseVariables.font['fontWeightMedium']};
              text-decoration: none;
              // border-bottom: solid ${toRem(3)} ${contextualVariable.linkNavBorderColor};
            }
          }
        }
      }
      .accu-weather-fragment {
        overflow: hidden;
      }
    }
  `}
`;
