import { LookupType, getLookupsByTypeQuery } from '@marriott/mi-groups-graphql';
import { useQuery } from '@apollo/client';
import { useLocaleStore } from '../store';

export const useLookupsByType = (type: LookupType) => {
  const { locale } = useLocaleStore();

  const { data, loading, error } = useQuery(getLookupsByTypeQuery, {
    variables: { type },
    context: {
      headers: {
        'accept-language': locale,
      },
    },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    console.error('Error: ', error);
  }

  return {
    lookups: data?.lookupsByType?.lookups,
    loading,
    error,
  };
};
