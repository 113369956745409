import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledOutletPrimaryDetails = styled.div`
  padding: ${toRem(0)} ${toRem(16)} ${toRem(16)} ${toRem(16)};

  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(40)} ${toRem(54)} ${toRem(24)} ${toRem(54)};
  }

  .separator-outlet {
    box-sizing: border-box;
    height: ${toRem(20)};
    width: ${toRem(1)};
    border: 1px solid ${baseVariables.color['neutral30']};
  }

  ul {
    list-style-type: none;
  }

  .font-color {
    color: ${baseVariables.color['neutral40']};
  }
  .outlet-primary-details__title-section {
    border-top: 1px solid rgb(238, 238, 238);
    flex-basis: 70%;
    &.add-separator {
      border-top: ${toRem(1)} solid ${baseVariables.color['neutral20']};
    }

    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      border-top: none;
    }
  }
  .outlet-primary-details__cta {
    width: 100%;
    margin: ${toRem(20)} 0;
  }
  .sticky-reserve-button {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 5;
    padding: ${toRem(16)} ${toRem(32)};

    .outlet-primary-details__cta {
      margin: 0;
    }
  }

  @media ${baseVariables.mediaQuery.md} {
    .sticky-reserve-button {
      position: relative;
    }
    .outlet-primary-details__cta {
      margin: ${toRem(14)} 0 0;
    }
  }

  .icon-outlet-class {
    font-size: 25px;
    @media ${baseVariables.mediaQuery.md},
      @media ${baseVariables.mediaQuery.lg},
      @media ${baseVariables.mediaQuery.xl} {
      font-size: 50px;
    }
  }
`;
