import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color } = baseVariables;

export const StyledGuestRoomSummary = styled.div`
  .summary-header {
    overflow-wrap: break-word;
  }
  @media print {
    .guest-room {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
    .rate {
      display: inline-block !important;
      flex: 0 0 16.67%;
      max-width: 16.67%;
    }
  }
`;

export const StyledAccordionDivider = styled.div`
  border-top: ${toRem(1)} solid ${color.neutral40};
  margin: ${toRem(16)} 0;
`;
