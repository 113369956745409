/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';

export interface autoCompleteState {
  suggestedPlacesLoading: boolean;
  suggestedPlacesError: boolean;
  selectedPlaceDetailsData: any;
  suggestedPlaceDetailsLoading: boolean;
  suggestedPlaceDetailsError: boolean;
  setSuggestionsData: (loading: boolean, error: boolean) => void;
  setSelectedPlaceData: (data: any, loading: boolean, error: boolean) => void;
}

const initialState: autoCompleteState = {
  selectedPlaceDetailsData: null,
  suggestedPlacesLoading: false,
  suggestedPlacesError: false,
  suggestedPlaceDetailsLoading: false,
  suggestedPlaceDetailsError: false,
  setSuggestionsData: () => {},
  setSelectedPlaceData: () => {},
};

export const useStore: StateCreator<autoCompleteState> = set => {
  return {
    ...initialState,
    setSuggestionsData: (loading, error) => {
      if (error) {
        set({ suggestedPlacesLoading: loading, suggestedPlacesError: true });
      }
    },
    setSelectedPlaceData: (data, loading, error) => {
      if (error) {
        set({
          selectedPlaceDetailsData: null,
          suggestedPlaceDetailsLoading: loading,
          suggestedPlaceDetailsError: true,
        });
      } else if (data) {
        set({
          selectedPlaceDetailsData: data,
          suggestedPlaceDetailsLoading: loading,
          suggestedPlaceDetailsError: error,
        });
      }
    },
  };
};

export const useAutoCompleteStore = createAppStore(useStore, {
  usePersistentStore: false,
  persistentStoreName: 'selectedLocationPersistent',
});
