import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

const { color, font } = baseVariables;

export const StyledEstimatedTotal = styled.div`
  .estimated-total {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .currency {
      color: ${color.neutral40};
    }

    .amount {
      font-weight: ${font.fontWeightMedium};
    }
  }
`;
