import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

const { color, border, font, mediaQuery } = baseVariables;

export const StyledEventSummaryReviewCard = styled.div`
  border: ${toRem(1)} ${border.borderStyleDefault} ${color.neutral20};
  background-color: ${color.base20};
  box-shadow: 0 0 ${toRem(8)} 0 rgba(0, 0, 0, 0.07);
  padding: ${toRem(30)} ${toRem(38)} ${toRem(30)} ${toRem(60)};

  .event-summary {
    row-gap: ${toRem(24)};

    .hotel-info {
      gap: ${toRem(10)};

      .hotel-details {
        max-width: ${toRem(200)};
        margin-top: ${toRem(-6)};

        @media ${mediaQuery.md} {
          max-width: ${toRem(400)};
        }

        @media ${mediaQuery.lg} {
          max-width: ${toRem(250)};
        }
      }
    }
  }

  .group-rates-container {
    padding-top: ${toRem(24)};
    border-top: ${toRem(1)} solid ${color.neutral40};

    .group-rates-table {
      min-width: 100%;
      margin-top: ${toRem(22)};
      thead {
        tr {
          text-align: left;
          th {
            font-weight: ${font.fontWeightMedium};
            vertical-align: baseline;
            padding: 0 ${toRem(8)} ${toRem(10)} 0;
          }
        }
      }
      tbody {
        td {
          vertical-align: baseline;
          padding: 0 ${toRem(8)} ${toRem(10)} 0;
        }
      }
    }
  }
`;
