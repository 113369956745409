// Styles for Ppv5Modal go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables, toRem, contextualVariable } from '@marriott/mi-ui-library';

export const StyledPpv5Modal = styled.div`
  ${rtl`
  .ppv5modal {
    min-height: 100vh;
    position: relative;
    &:before {
      background: transparent;
    }
    &__container {
      position: relative;
      border-radius: ${toRem(14)};
      max-height: 100%;
      overflow: hidden;
      flex: 0 0 100%;
      max-width: 100%;
      outline: none;
      &-dining {
        position: relative;
        border-radius: 0;
        overflow: visible;
      }
      @media ${baseVariables.mediaQuery.md} {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media ${baseVariables.mediaQuery.xl} {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
    &__header {
      height: auto !important;
      border-top-left-radius: ${toRem(14)};
      border-top-right-radius: ${toRem(14)};
      border: 0;
      h2 {
        height: ${toRem(20)};
        width: calc(100% - ${toRem(50)});
      }
      &-dining {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &__body {
      height: inherit;
      max-height: inherit;
      min-height: inherit;
      overflow: visible;
    }
    &__close-btn {
      position: absolute;
      top: ${toRem(20)};
      right: ${toRem(16)};
      bottom: initial;
      width: auto;
      min-width: auto;
      height: auto;
      min-height: ${toRem(32.667)};
      background: transparent;
      border-radius: 50%;
      &:focus {
        outline: ${toRem(1)} solid;
      }
      [class*='icon-clear'],
      [class*='icon-clear']::before {
        color: ${contextualVariable.brandedFontColor};
      }
    }
    &__dialog {
      background-color: transparent;
    }
  }
 `}
`;
