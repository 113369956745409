import { PrimaryImage } from '../search/searchPropertiesByGeolocation.types';

export type EventSpaceDetailsInput = {
  propertyId: string;
  functionSpacePricingInput: FunctionSpacePricingInput;
};

type FunctionSpacePricingInput = {
  functionSpaceId: string;
  numberOfAttendees: string;
  startDate: string;
  endDate: string;
};

export type EventSpaceDetailsResponse = {
  media: {
    photoGallery: {
      eventsAndMeetings: PrimaryImage;
    };
  };
  functionSpacePricingDetails: FunctionSpacePricingResponse;
};

export type FunctionSpacePricingResponse = {
  dayParts: FunctionSpaceDayPart[];
  currencyCode: string;
  priceInfo: FunctionSpacePriceInfo[];
  styleInfo: FunctionSpaceStyleInfo[];
};

type FunctionSpaceDayPart = {
  dayPart: DayPart;
  startTime: string;
  endTime: string;
};

export enum DayPart {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export type FunctionSpaceStyleInfo = {
  styleCode: string;
  minCapacity: string;
  maxCapacity: string;
};

export type FunctionSpacePriceInfo = {
  functionDate: string;
  morningDayPartPrice: string;
  afternoonDayPartPrice: string;
  eveningDayPartPrice: string;
};
