export const HeroBannerMock = {
  cityPage: true,
  dynamicMedia: {
    assetPath: 'https://cache.marriott.com/content/dam/marriott-renditions/ATLPL/atlpl-restaurant-7846-hor-clsc.jpg',
  },
  overlayText: 'Hero Banner',
};

export const mockHeroBannerImage = [
  {
    defaultImageUrl:
      'https://cache.marriott.com/content/dam/marriott-renditions/CAEMH/caemh-midlands-0048-hor-pano.jpg?downsize=*:666',
    isFallBackImage: false,
  },
];

export const herobanerCampaignPage = {
  defaultImageUrl:
    'https://cache.marriott.com/content/dam/marriott-renditions/CAEMH/caemh-midlands-0048-hor-pano.jpg?downsize=*:666',
  isFallBackImage: false,
  cityName: 'Mexico',
  campaignTitle: 'Food and Beverage Promotions in Vietnam',
  campaignDescription:
    'Score a sweet deal and earn Marriott Bonvoy Points when dining in participating restaurant and bars in Asia.',
};

export const campaignAEMprops = {
  args: {
    pageType: 'campaignPage',
    header: 'Earn Far More Closer to Home US',
    styleclass: '',
    dynamicMedia: {
      altText: 'Chicago Boat Tours',
      assetPath:
        'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg',
      dynamic: false,
      renditions: [
        {
          renditionPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg?wid=1920&fit=constrain',
          mediaValue: '992px',
          width: 0,
          dynamic: false,
          damPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg?wid=768&fit=constrain',
          mediaValue: '768px',
          width: 0,
          dynamic: false,
          damPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg?wid=768&fit=constrain',
          mediaValue: '576px',
          width: 0,
          dynamic: false,
          damPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-hor-pano.jpg',
          mediaQuery: 'min-width',
          height: 0,
        },
        {
          renditionPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-sq.jpg?wid=364&fit=constrain',
          mediaValue: '576px',
          width: 0,
          dynamic: false,
          damPath:
            'https://cache.marriott.com/content/dam/marriott-renditions/CHIOX/chiox-attraction-chicago-0004-sq.jpg',
          mediaQuery: 'max-width',
          height: 0,
        },
      ],
      damPath:
        'https://cache.marriott.com/content/dam/marriott-digital/ox/us-canada/hws/c/chiox/en_us/photo/limited/assets/chiox-attraction-chicago-0004.jpg',
    },
    trackingProperties: {
      additionalTrackingVariables: '',
      enableScrollingBehavior: false,
      trackingDescription: '',
      impressionTrack: false,
      trackingContentPosition: '',
      atCCeVar48: '',
      rightArrowDesc: '',
      cardLocation: '',
      leftArrowDesc: '',
      clickTrack: false,
      location: '',
      merchandisingCategory: 'nonCobrand',
      impressionCount: false,
      trackingTag: '',
      trackingOfferType: '',
    },
    isAuthor: false,
    ':type': 'mi-aem-rnb/components/content/herobannercarousel',
    imgAltText: 'test Alt-12',
    fileReferenceImage:
      '/content/dam/marriott-digital/xr/apec/hws/v/verty/en_us/photo/limited/assets/Copy of Copy of Copy of Copy of Copy of Copy of dining test image.jpg',
    overlayText: 'test alt',
    iconPath: 'icon-globe',
    wcmMode: 'disabled',
  },
  argTypes: {
    styleclass: {
      name: 'Theme',
      options: ['standard', 'inverse'],
      control: { type: 'radio' },
      description: 'pass the required theme for checking with different styles',
      default: '',
    },
    pageType: {
      name: 'Page-Type',
      options: ['cityPage', 'campaignPage', 'earnRedeemPage'],
      control: { type: 'radio' },
      description: 'pass the required Page Type for checking with different styles',
      default: 'standard',
    },
    header: {
      name: 'Hero Banner Header',
      type: { name: 'string', required: true },
    },
    dynamicMedia: {
      name: 'Hero Banner Dynamic Media',
      description: 'Dynamic Media for the Hero Banner Component',
      table: {
        disable: true,
      },
    },
    trackingProperties: {
      name: 'Tracking Properties',
      description: 'Tracking Properties for the Hero Banner Component',
    },
  },
};
