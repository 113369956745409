import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  AddressType,
  CustomerInfoResponse,
  CustomerPaymentMethod,
  getCustomerInfoQuery,
} from '@marriott/mi-groups-graphql';
import { getSession } from '../utils';
import { useAuthStore, useLocaleStore } from '../store';
import { UserInfoFormData } from '../molecules/UserInfo/UserInfo.types';
import { AddressInfoFormData } from '../molecules/AddressInfo/AddressInfo.types';

export type CustomerSessionData = {
  sessionData: {
    cacheData: {
      data: {
        consumerID: string;
        rewardsId?: string;
      };
    };
  };
};

export const useCustomerInfo = (includePaymentMethods = false) => {
  const { locale } = useLocaleStore();
  const { isAuthenticated } = useAuthStore();

  const [customerInfo, setCustomerInfo] = useState<UserInfoFormData & AddressInfoFormData>();
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState<CustomerPaymentMethod[]>([]);

  const [getCustomerInfo] = useLazyQuery<CustomerInfoResponse>(getCustomerInfoQuery);

  const getCustomerSessionData = async () => {
    try {
      const { sessionData } = (await getSession()) as CustomerSessionData;
      const { consumerID, rewardsId } = sessionData?.cacheData?.data || {};
      return { customerId: consumerID, rewardId: rewardsId };
    } catch (error) {
      return { customerId: '', rewardId: '' };
    }
  };

  const getCustomerDetails = () => {
    getCustomerSessionData().then(({ customerId, rewardId }) => {
      if (customerId) {
        getCustomerInfo({
          variables: {
            customerId,
            includePaymentMethods,
          },
          context: {
            headers: {
              'accept-language': locale,
            },
          },
          fetchPolicy: 'no-cache',
          onCompleted: ({ customer, customerPaymentMethods }) => {
            const { profile, contactInformation } = customer;
            const phone = contactInformation?.phones?.[0];
            const address = contactInformation?.addresses?.[0]?.address;
            setCustomerInfo({
              userInfo: {
                firstName: profile.name?.givenName,
                lastName: profile.name?.surname,
                email: contactInformation.emails?.[0]?.address,
                callingCode: {
                  label: phone?.country?.description || '',
                  value: phone?.country?.description || '',
                  code: phone?.country?.code || '',
                  description: phone?.country?.description || '',
                  shortDescription: phone?.country?.code || '',
                },
                phoneNumber: phone?.number || '',
                rewardId: rewardId as string,
              },
              addressInfo: {
                addressType: (contactInformation?.addresses?.[0]?.type as AddressType) || AddressType.BUSINESS,
                companyName: address?.companyName || '',
                addressLine1: address?.line1 || '',
                addressLine2: address?.line2 || '',
                country: {
                  label: address?.country?.description || '',
                  value: address?.country?.code || '',
                  code: address?.country?.code || '',
                  description: address?.country?.description || '',
                },
                city: address?.city || '',
                state: address?.stateProvince || '',
                zipcode: address?.postalCode || '',
              },
            });
            customerPaymentMethods && setCustomerPaymentMethods(customerPaymentMethods);
          },
        });
      }
    });
  };

  useEffect(() => {
    isAuthenticated && getCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return {
    customerInfo,
    customerPaymentMethods,
  };
};
